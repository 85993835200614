import http from "utils/http-common";

const controller = "preprocessmaterial";

const getAllPreprocessRequestAsync = (
  pageNumber,
  pageSize,
  keySearch,
  startDate,
  endDate,
  businessSummaryWidgetFilter,
  branchId,
) => {
  let url = `/${controller}?pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}`;
  if (startDate) url += `&startDate=${startDate}`;
  if (endDate) url += `&endDate=${endDate}`;
  if (businessSummaryWidgetFilter) url += `&businessSummaryWidgetFilter=${businessSummaryWidgetFilter}`;
  if (branchId) url += `&branchId=${branchId}`;

  return http.get(url);
};

const getPreProcessByIdRequestAsync = (preProcessId) => {
  return http.get(`/${controller}/${preProcessId}`);
};

const preProcessMaterials = (data) => {
  return http.post(`/${controller}/pre-process`, data);
}

const preprocessMaterialDataService = {
  getAllPreprocessRequestAsync,
  getPreProcessByIdRequestAsync,
  preProcessMaterials,
};

export default preprocessMaterialDataService;
