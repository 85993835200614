import { useTranslation } from "react-i18next";
import "./check-delete-material.component.scss";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle, ConfirmViewActiveItemStyle, EnumMaterialDependencyType } from "constants/level-menu.constants";
import { useEffect, useState } from "react";

export default function CheckDeleteMaterial(props) {
  const [t] = useTranslation();
  const {
    listObjectLockMaterial,
    isModalVisible,
    isModalPurchaseOrderVisible,
    handleCancel,
    onDelete,
    materialName,
    materialDependencyType,
  } = props;
  const [activeItems, setActiveItems] = useState([]);
  const [typeViewActiveItems, setTypeViewActiveItems] = useState(ConfirmViewActiveItemStyle.LIST);
  const pageData = {
    checkMaterialNotificationMessage: t("messages.checkMaterialNotificationMessage"),
    checkMaterialPurchaseOrderNotificationMessage: t("messages.checkMaterialPurchaseOrderNotificationMessage"),
    deleteMaterialMessage: t("messages.deleteMaterialMessage"),
  };

  const onCancel = () => {
    handleCancel();
  };

  const formatNotificationMessage = (name, type) => {
    let mess = t(pageData.checkMaterialNotificationMessage, { name: name, type: type });
    return mess;
  };

  const formatNotificationPurchaseOrderMessage = (name) => {
    let mess = t(pageData.checkMaterialPurchaseOrderNotificationMessage, { name: name });
    return mess;
  };

  const formatDeleteMessage = (name) => {
    let mess = t(pageData.deleteMaterialMessage, { name });
    return mess;
  };

  const getLinkDetail = (id) => {
    switch (materialDependencyType) {
      case EnumMaterialDependencyType.ORDER:
        return `/report/order/detail/${id}`;
      case EnumMaterialDependencyType.PURCHASE_ORDER:
        return `/inventory/detail-purchase-order/${id}`
      default:
        return undefined;
    }
  }

  useEffect(() => {
    setActiveItems(
      listObjectLockMaterial?.listReferences?.map((item) => {
        const label = item?.stringCode;
        const link = getLinkDetail(item?.id);
        return { label, link };
      }),
    );
  }, [listObjectLockMaterial, materialDependencyType]);

  useEffect(() => {
    if (materialDependencyType === EnumMaterialDependencyType.OPTION) {
      setTypeViewActiveItems(ConfirmViewActiveItemStyle.LIST);
    } else if (materialDependencyType === EnumMaterialDependencyType.ORDER) {
      setTypeViewActiveItems(ConfirmViewActiveItemStyle.GRID);
    } else if (materialDependencyType === EnumMaterialDependencyType.PURCHASE_ORDER) {
      setTypeViewActiveItems(ConfirmViewActiveItemStyle.GRID);
    }
  }, [materialDependencyType]);

  return (
    <>
      <ConfirmDialogComponent
        width={500}
        type={ConfirmStyle.NOTIFICATION}
        activeItems={activeItems}
        onOk={onCancel}
        visible={isModalVisible && activeItems && activeItems?.length > 0}
        typeViewActiveItems={typeViewActiveItems}
        content={formatNotificationMessage(listObjectLockMaterial?.materialName, t(listObjectLockMaterial?.typeName))}
      />

      <ConfirmDialogComponent
        width={500}
        type={ConfirmStyle.NOTIFICATION}
        activeItems={activeItems}
        onOk={onCancel}
        visible={isModalPurchaseOrderVisible && activeItems && activeItems?.length > 0}
        typeViewActiveItems={typeViewActiveItems}
        content={formatNotificationPurchaseOrderMessage(listObjectLockMaterial?.materialName)}
      />

      <ConfirmDialogComponent
        type={ConfirmStyle.DELETE}
        onCancel={onCancel}
        onOk={onDelete}
        visible={isModalVisible && (!activeItems || activeItems?.length <= 0)}
        content={formatDeleteMessage(materialName)}
      />
    </>
  );
}
