import { Row, Col, Image } from "antd";
import { DateFormat } from "constants/string.constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./summary-shift.component.scss";
import reportDataService from "data-services/report/report-data.service";
import { BagStickIcon, MoneyIcon, ReceiptShiftIcon, DiscountShapeIcon } from "constants/icons.constants";
import { getCurrency, getUrlImageByLanguageCode } from "utils/helpers";
import { imagesWidgetType } from "constants/images.constants";
import FnbWidget from "components/fnb-widget/fnb-widget.component";

export default function SummaryShift(props) {
  const [t] = useTranslation();
  const { selectedDate, typeOptionDate, branchId } = props;
  const [initData, setInitData] = useState({});

  const pageData = {
    summaryTitle: {
      order: t("report.shift.order"),
      soldProducts: t("report.shift.soldProducts"),
      discount: t("report.shift.totalDiscount"),
      grossRevenue: t("report.shift.grossRevenue"),
    },
    orders: t("report.shift.orders", "Orders"),
    products: t("report.shift.products", "Products"),
    wiget: {
      totalOrder: t("wiget.totalOrder"),
      totalCost: t("wiget.totalCost"),
      totalDiscount: t("wiget.totalDiscount"),
      totalExtraFee: t("wiget.totalExtraFee"),
      totalTax: t("wiget.totalTax"),
      totalRevenue: t("wiget.totalRevenue"),
      totalProfit: t("wiget.totalProfit"),
    },
  };

  useEffect(() => {
    if (selectedDate || branchId || typeOptionDate)
      getShiftsSummaryByFilterAsync(branchId, selectedDate, typeOptionDate);
  }, [selectedDate, branchId, typeOptionDate]);

  const getShiftsSummaryByFilterAsync = async (branchId, date, typeOptionDate) => {
    let startDate = moment(date?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(date?.endDate).format(DateFormat.MM_DD_YYYY);
    var timeZone = new Date().getTimezoneOffset() / 60;
    await reportDataService
      .getShiftsSummaryAsync({
        branchId: branchId,
        startDate: startDate,
        endDate: endDate,
        typeOptionDate: typeOptionDate,
        timeZone: timeZone,
      })
      .then((response) => {
        setInitData(response);
      });
  };

  //Handle for Hint settings
  const renderHintTotalOrder = () => {
    return (
      <p className="hintTotalOrder">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalOrder }} />
      </p>
    );
  };

  const renderHintTotalDiscount = () => {
    return (
      <p className="hintTotalDiscount">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalDiscount }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalDiscount)} />
        </div>
      </p>
    );
  };

  const renderHintTotalRevenue = () => {
    return (
      <p className="hintTotalRevenue">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalRevenue }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalRevenue)} />
        </div>
      </p>
    );
  };

  const renderWidgets = () => {
    let listBusiness = [
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.summaryTitle.order,
        suffix: pageData.orders,
        summaryItem: {
          percent: initData?.order?.percent,
          total: initData?.order?.total,
          average: initData?.order?.averageTotalRevenue,
        },
        isShowHint: true,
        informationHint: renderHintTotalOrder()
      },
      {
        icon: <BagStickIcon />,
        title: pageData.summaryTitle.soldProducts,
        suffix: pageData.products,
        summaryItem: {
          percent: initData?.soldProducts?.percent,
          total: initData?.soldProducts?.total,
        },
      },
      {
        icon: <DiscountShapeIcon />,
        title: pageData.summaryTitle.discount,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.discount?.percent,
          total: initData?.discount?.total,
        },
        isShowHint: true,
        informationHint: renderHintTotalDiscount()
      },
      {
        icon: <MoneyIcon />,
        title: pageData.summaryTitle.grossRevenue,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.grossRevenue?.percent,
          total: initData?.grossRevenue?.total,
        },
        isShowHint: true,
        informationHint: renderHintTotalRevenue()
      },
    ];
    const widgets = listBusiness?.map((item, index) => {
      return (
        <Col key={index} sm={12} xl={6} span={24} className="col-group-business col-with-auto">
          <FnbWidget
            summaryItem={item.summaryItem}
            titleTotal={item.title}
            icon={item.icon}
            isShowHint={item.isShowHint}
            informationHint={item.informationHint}
            suffixData={item.suffix}
          />
        </Col>
      );
    });

    return <>{widgets}</>;
  };

  return (
    <Row className="summary-shift" gutter={[16, 16]}>
      {renderWidgets()}
    </Row>
  );
}
