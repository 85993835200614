import { message } from "antd";
import { useEffect, useState } from "react";
import "./select-edit-new-item-units.scss";
import SelectEditComponent from "components/select-edit-new-item/select-edit-new-item";
import unitDataService from "data-services/unit/unit-data.service";
import { useTranslation } from "react-i18next";

/// Select Unit in Form
export default function SelectEditUnitsComponent({
  units,
  selectNewItemFuncs,
  placeholder,
  onChangeOption,
  fetchUnits,
  setNewUnitId,
  removeItemIds,
  value,
  minWidth = 200,
  form
}) {
  const [t] = useTranslation();
  const [options, setOptions] = useState([]);
  const pageData = {
    unitNameExisted: t("productManagement.unit.unitNameExisted"),
    unit: t("material.unit"),
    addUnitSuccess: t("material.addUnitSuccess"),
  };

  const [isUnitNameExisted, setIsUnitNameExisted] = useState(false);
  const [newUnitName, setNewUnitName] = useState(null);

  useEffect(() => {
    setOptions(units);
  }, [units]);

  useEffect(() => {
    if(removeItemIds){
      let newRemoveItemIds = removeItemIds.filter((id) => id !== null);
      if(units && newRemoveItemIds?.length > 0) {
        setOptions(units.filter((u) => !newRemoveItemIds.some(item => item === u.id)));
      }
    }
  }, [removeItemIds]);

  const onNameChange = (value) => {
    setIsUnitNameExisted(units.filter((u) => u.name.trim().toLowerCase() === value.trim().toLowerCase()).length > 0);
    setNewUnitName(value);
  };

  const onAddNewUnit = async () => {
    if (!newUnitName) {
      return;
    }
    /// Handle add new unit
    let res = await unitDataService.createUnitAsync({ name: newUnitName });
    if (res.isSuccess) {
      fetchUnits();
      setNewUnitId({id:res.id, name:res.name});
      setNewUnitName(null);
      if (selectNewItemFuncs.current) {
        selectNewItemFuncs.current(res.id);
        setIsUnitNameExisted(true);
      }
      message.success(pageData.addUnitSuccess, { name: newUnitName });
    } else {
      message.error(pageData.unitNameExisted);
    }
  };

  return (
      <SelectEditComponent
        minWidth={minWidth}
        getPopupContainer={(trigger) => trigger.parentNode}
        showSearch
        allowClear
        functions={selectNewItemFuncs}
        placeholder={placeholder}
        listOption={options}
        onChangeOption={onChangeOption}
        onSearch={onNameChange}
        addNewItem={() => onAddNewUnit(true)}
        isNameExisted={isUnitNameExisted}
        name={newUnitName}
        isEditProduct={true}
        category={pageData.unit}
        value={value}
        form={form}
      />
  );
}
