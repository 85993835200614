import { PermissionKeys } from "constants/permission-key.constants";
import { useTranslation } from "react-i18next";
import { hasPermission } from "utils/helpers";
import PurchaseOrderManagement from "pages/inventory/purchase-order/purchase-order.page";
import TransferMaterialManagement from "pages/inventory/transfer-material/transfer-material-management";
import "./table-inventory-control.component.scss";
import FnbTabPane from "components/fnb-tab-pane/fnb-tab-pane";
import { Fragment, useMemo, useState } from "react";
import MaterialControlManagement from "./material-control.page";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { useHistory } from "react-router-dom";
import TableUpdateStocking from "./update-stock/update-stock.page";
import TablePreProcessMaterial from "./preprocess-material/preprocess-material.page";

export const KEY_TAB_PANE = {
  VIEW_BY_UPDATE_STOCK: "view-by-update-stock",
  VIEW_BY_PURCHASE_ORDER: "view-by-purchase-order",
  VIEW_BY_TRANSFER_MATERIAL: "view-by-transfer-material",
  VIEW_BY_INVENTORY_CONTROL: "view-by-inventory-control",
  VIEW_BY_PREPROCESS_MATERIAL: "view-by-preprocess-material",
};

const INDEX_KEY_TAB_PANE = {
  [KEY_TAB_PANE.VIEW_BY_UPDATE_STOCK]: 0,
  [KEY_TAB_PANE.VIEW_BY_PURCHASE_ORDER]: 1,
  [KEY_TAB_PANE.VIEW_BY_TRANSFER_MATERIAL]: 2,
  [KEY_TAB_PANE.VIEW_BY_INVENTORY_CONTROL]: 3,
  [KEY_TAB_PANE.VIEW_BY_PREPROCESS_MATERIAL]: 4,
};

const TableInventoryControlComponent = (props) => {
  const [t] = useTranslation();
  const screens = [
    {
      name: t("menu.inventoryManagement.preProcessMaterial", "Pre-process ingredient"),
      key: KEY_TAB_PANE.VIEW_BY_PREPROCESS_MATERIAL,
      component: hasPermission(PermissionKeys.VIEW_PREPROCESS_MATERIAL) && <TablePreProcessMaterial />,
      permission: PermissionKeys.VIEW_PREPROCESS_MATERIAL,
    },
    {
      name: t("menu.inventoryManagement.updateStock", "Update Stock"),
      key: KEY_TAB_PANE.VIEW_BY_UPDATE_STOCK,
      component: hasPermission(PermissionKeys.VIEW_STOCK_UPDATE_REQUEST) && <TableUpdateStocking />,
      permission: PermissionKeys.VIEW_STOCK_UPDATE_REQUEST,
    },
    {
      name: t("menu.inventoryManagement.purchaseOrder"),
      key: KEY_TAB_PANE.VIEW_BY_PURCHASE_ORDER,
      component: hasPermission(PermissionKeys.VIEW_PURCHASE) && <PurchaseOrderManagement />,
      permission: PermissionKeys.VIEW_PURCHASE,
    },
    {
      name: t("menu.inventoryManagement.transferMaterial"),
      key: KEY_TAB_PANE.VIEW_BY_TRANSFER_MATERIAL,
      component: hasPermission(PermissionKeys.VIEW_TRANSFER_MATERIAL) && <TransferMaterialManagement />,
      permission: PermissionKeys.VIEW_TRANSFER_MATERIAL,
    },
    {
      name: t("menu.inventoryManagement.unavailableIngredients"),
      key: KEY_TAB_PANE.VIEW_BY_INVENTORY_CONTROL,
      component: hasPermission(PermissionKeys.VIEW_INGREDIENT_REMOVE_REQUEST) && <MaterialControlManagement />,
      permission: PermissionKeys.VIEW_INGREDIENT_REMOVE_REQUEST,
    },
  ];
  const firstKeyPane = useMemo(() => {
    let firstKeyPane;
    screens?.forEach((screen) => {
      if (firstKeyPane) return;

      if (hasPermission(screen.permission)) {
        firstKeyPane = screen.key;
        return;
      }
    });
    return firstKeyPane;
  }, []);

  const history = useHistory();
  const tabActive = new URLSearchParams(window.location.search).get("tabActive");
  const [activeScreen, setActiveScreen] = useState(tabActive ?? firstKeyPane);

  const handleChangeScreen = (activeKey) => {
    history.push({
      pathname: "/inventory/transfer-control",
      search: `?tabActive=${activeKey}`,
    });
    setActiveScreen(activeKey);
  };

  return (
    <Fragment>
      <FnbHeadingPage title={t("menu.inventoryManagement.inventoryControlCapitalize")}></FnbHeadingPage>
      <FnbTabPane
        screens={screens}
        activeScreen={activeScreen}
        indexKeyTabPane={INDEX_KEY_TAB_PANE}
        onChange={handleChangeScreen}
        defaultScreen={firstKeyPane}
        firstKeyTabPane={firstKeyPane}
        lastKeyTabPane={KEY_TAB_PANE.VIEW_BY_INVENTORY_CONTROL}
        setTabPaneDisplayFullWidth={true}
        isShowMoreIcon={false}
        maxWidthItemConfig="unset"
      />
    </Fragment>
  );
};

export default TableInventoryControlComponent;
