import { Col, Form, Radio, Row, Space } from "antd";
import ComingSoonTag from "components/coming-soon-tag";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbSwitch from "components/switch";
import { InfoCircleFlashSaleIcon, StoreGeneralConfigInfoCircleIcon } from "constants/icons.constants";
import { ReservationType } from "constants/store-setting.constants";
import { MaxNumberInput, currency } from "constants/string.constants";
import { Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import theme from "theme";
import { formatterDecimalNumber, getCurrency, isDecimalKey, parserDecimalNumber } from "utils/helpers";
import "./styles.scss";

const CashierScreen = (props) => {
  const { t, isChangeForm, onChangeForm, defaultStoreConfig, updateStoreConfig } = props;
  const [form] = Form.useForm();
  const [isPaymentLater, setIsPaymentLater] = useState(false);
  const [isAutoConfirmOrder, setIsAutoConfirmOrder] = useState(false);
  const [allowReScanUnconfirmedOrders, setAllowReScanUnconfirmedOrders] = useState(false);
  const [isAllowReservation, setIsAllowReservation] = useState(false);
  const [reservationType, setReservationType] = useState(ReservationType.STAFF_ARRANGE);

  const [isDeposit, setIsDeposit] = useState(false);
  const [receiptState, setReceiptState] = useState({
    isAutoPrintBillWhenCreateOrder: false,
    isAutoPrintBillWhenDoPaymentForOrder: false,
    isAllowPrintBillAtOrderManagement: false,
  });
  const [isAutoPrintOrderSlips, setIsAutoPrintOrderSlips] = useState(false);
  const isMaxWidth780 = useMediaQuery({ maxWidth: 780 });

  const pageData = {
    annotate: t("configuration.cashierScreen.annotate"),
    button: {
      cancel: t("button.cancel"),
      saveChanges: t("button.saveChanges"),
    },
    typeOfPosOperation: t("configuration.cashierScreen.typeOfPosOperation"),
    payFirst: t("configuration.cashierScreen.payFirst"),
    orderFirstPayLater: t("configuration.cashierScreen.orderFirstPayLater"),
    receipts: t("configuration.cashierScreen.receipts"),
    autoPrint: t("configuration.cashierScreen.autoPrint"),
    allowToPrint: t("configuration.cashierScreen.allowToPrint"),
    receiptsAfterOrderIsCreated: t("configuration.cashierScreen.receiptsAfterOrderIsCreated"),
    receiptsAfterOrderIsPaid: t("configuration.cashierScreen.receiptsAfterOrderIsPaid"),
    receiptsAtOrder: t("configuration.cashierScreen.receiptsAtOrder"),
    orderSlips: t("configuration.cashierScreen.orderSlips"),
    orderSlipsAfterCreate: t("configuration.cashierScreen.orderSlipsAfterCreate"),
    autoConfirmOrder: t("configuration.cashierScreen.autoConfirmOrder"),
    orderSettings: t("configuration.cashierScreen.orderSettings"),
    autoConfirmOrderNote: t("configuration.cashierScreen.autoConfirmOrderNote"),
    allowOrderOutOfStock: t("configuration.cashierScreen.allowOrderOutOfStock"),
    allowOrderOutOfStockNote: t("configuration.cashierScreen.allowOrderOutOfStockNote"),
    reservationSettings: t("configuration.cashierScreen.reservationSettings"),
    staffWillContactForReserve: t("configuration.cashierScreen.staffWillContactForReserve"),
    lockReservationPeriodOfTime: t("configuration.cashierScreen.lockReservationPeriodOfTime"),
    reserveAtFixedTime: t("configuration.cashierScreen.reserveAtFixedTime"),
    minutes: t("configuration.minutes"),
    days: t("configuration.days"),
    reservation: {
      requiredTimeReservation: t("configuration.cashierScreen.requiredTimeReservation"),
      validate: t("store.operationConfiguration.reserveTable.numberOfDaysAdvanceReservations.validate"),
      placeholder: t("store.operationConfiguration.reserveTable.numberOfDaysAdvanceReservations.placeholder"),
    },
    deposit: {
      title: t("store.operationConfiguration.deposit.title"),
      tooltipLine1: t("store.operationConfiguration.deposit.tooltipLine1"),
      tooltipLine2: t("store.operationConfiguration.deposit.tooltipLine2"),
      inputTitle: t("store.operationConfiguration.deposit.inputTitle"),
      placeholder: t("store.operationConfiguration.deposit.placeholder"),
      validate: t("store.operationConfiguration.deposit.validate"),
      validateRangeMessage: t("store.operationConfiguration.deposit.validateRangeMessage"),
      amountDeposit: t("reservation.amountDeposit"),
    },
    amountDeposit: t("reservation.amountDeposit"),
    timeToAutoConfirm: t("configuration.cashierScreen.timeToAutoConfirm"),
    allowReservation: t("configuration.cashierScreen.allowReservation"),
    itemStickerStamp: t("configuration.cashierScreen.itemStickerStamp", "Item stickers (Stamp)"),
    autoPrintItemStickersAfterOrderCreated: t(
      "configuration.cashierScreen.autoPrintItemStickersAfterOrderCreated",
      "Auto print item sticker(s) after an order is created",
    ),
    allowPrintItemStickerAtOrderManagement: t(
      "configuration.cashierScreen.allowPrintItemStickerAtOrderManagement",
      "Allow to print item sticker(s) at order management / order details",
    ),
    validateRangeMessage: t("configuration.validateRangeMessage", "Allowed values range from $from$ to $to$"),
    pleaseInputValue: t("configuration.pleaseInputValue", "Please input value"),
    scanAndPlayASoundToNotify: t(
      "store.scanAndPlayASoundToNotify",
      "Scan and play a sound to notify if there are unconfirmed orders",
    ),
    enterThePeriodOfTime: t("store.enterThePeriodOfTime", "Enter the period of time the system will re-scan"),
    invalidValues: t("messages.invalidValues", "Invalid values"),
  };

  useEffect(() => {
    if (defaultStoreConfig) {
      setDefaultValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultStoreConfig]);

  const onFinishForm = async (e) => {
    if (!e) return;
    e.orderSettings = e.orderSettings ?? {};
    e.receipts = e.receipts ?? {};
    e.reservationSettings = e.reservationSettings ?? {};
    const configPaymentLater = isPaymentLater
      ? {
          isAutoPrintBillWhenDoPaymentForOrder: Boolean(e.receipts.isAutoPrintBillWhenDoPaymentForOrder),
          numberOfBillsPrintWhenDoPaymentForOrder: Number(e.receipts.numberOfBillsPrintWhenDoPaymentForOrder) || 0,
        }
      : {};

    const payload = {
      ...configPaymentLater,
      isPaymentLater,
      isAutoPrintBillWhenCreateOrder: e.receipts.isAutoPrintBillWhenCreateOrder,
      numberOfBillsPrintWhenCreateOrder: Number(e.receipts.numberOfBillsPrintWhenCreateOrder) || 0,
      isAllowPrintBillAtOrderManagement: e.receipts.isAllowPrintBillAtOrderManagement,
      numberOfBillsPrintAtOrderManagement: Number(e.receipts.numberOfBillsPrintAtOrderManagement) || 0,
      isOrderSlipAutoPrintAfterCreate: e.orderSlips?.isOrderSlipAutoPrintAfterCreate,
      numberOfSlipsWillBePrinted: Number(e.orderSlips?.numberOfSlipsWillBePrinted) || 0,
      isAutoPrintStampWhenCreateOrder: e.stamp?.isAutoPrintStampWhenCreateOrder,
      isAllowPrintStampAtOrderManagement: e.stamp?.isAllowPrintStampAtOrderManagement,
      isAutoConfirmOrder: e.orderSettings.isAutoConfirmOrder,
      autoConfirmOrderInMinutes: e.orderSettings.autoConfirmOrderInMinutes,
      isCheckProductSell: e.orderSettings.isCheckProductSell,
      reScanUnconfirmedOrdersInMinutes: e.orderSettings.reScanUnconfirmedOrdersInMinutes,
      allowReScanUnconfirmedOrders: e.orderSettings.allowReScanUnconfirmedOrders,
      isAllowReserveTable: e.reservationSettings.isAllowReserveTable,
      reservationType: e.reservationSettings.reservationType,
      numberOfDaysAdvanceReservations: e.reservationSettings.numberOfDaysAdvanceReservations,
      isDeposit: e.reservationSettings.isDeposit,
      depositAmount: e.reservationSettings.depositAmount,
    };
    updateStoreConfig(payload);
  };

  const onSave = () => {
    form.validateFields().then(() => {
      form.submit();
      onChangeForm(false);
    });
  };

  const setDefaultValue = () => {
    const {
      isPaymentLater,
      // receipts
      isAutoPrintBillWhenCreateOrder,
      numberOfBillsPrintWhenCreateOrder,
      isAutoPrintBillWhenDoPaymentForOrder,
      numberOfBillsPrintWhenDoPaymentForOrder,
      isAllowPrintBillAtOrderManagement,
      numberOfBillsPrintAtOrderManagement,
      // orderSlips
      isOrderSlipAutoPrintAfterCreate,
      numberOfSlipsWillBePrinted,
      // stamp
      isAutoPrintStampWhenCreateOrder,
      isAllowPrintStampAtOrderManagement,
      //Order settings
      isAutoConfirmOrder,
      isCheckProductSell,
      autoConfirmOrderInMinutes,
      allowReScanUnconfirmedOrders,
      reScanUnconfirmedOrdersInMinutes,
      //Reservation settings
      isAllowReserveTable,
      reservationType,
      numberOfDaysAdvanceReservations,
      isDeposit,
      depositAmount,
    } = defaultStoreConfig;
    setIsPaymentLater(isPaymentLater);
    setIsAutoConfirmOrder(isAutoConfirmOrder);
    setAllowReScanUnconfirmedOrders(allowReScanUnconfirmedOrders);
    setIsAllowReservation(isAllowReserveTable);
    setReservationType(reservationType > 0 ? reservationType : ReservationType.STAFF_ARRANGE);
    setIsDeposit(isDeposit);
    setReceiptState({
      isAutoPrintBillWhenCreateOrder,
      isAutoPrintBillWhenDoPaymentForOrder,
      isAllowPrintBillAtOrderManagement,
    });
    setIsAutoPrintOrderSlips(isOrderSlipAutoPrintAfterCreate);

    form.setFieldsValue({
      isPaymentLater,
      receipts: {
        isAutoPrintBillWhenCreateOrder,
        numberOfBillsPrintWhenCreateOrder,
        isAutoPrintBillWhenDoPaymentForOrder,
        numberOfBillsPrintWhenDoPaymentForOrder,
        isAllowPrintBillAtOrderManagement,
        numberOfBillsPrintAtOrderManagement,
      },
      orderSlips: {
        isOrderSlipAutoPrintAfterCreate,
        numberOfSlipsWillBePrinted,
      },
      stamp: {
        isAutoPrintStampWhenCreateOrder,
        isAllowPrintStampAtOrderManagement,
      },
      orderSettings: {
        isAutoConfirmOrder,
        isCheckProductSell,
        autoConfirmOrderInMinutes,
        allowReScanUnconfirmedOrders,
        reScanUnconfirmedOrdersInMinutes,
      },
      reservationSettings: {
        isAllowReserveTable,
        reservationType,
        numberOfDaysAdvanceReservations,
        isDeposit,
        depositAmount,
      },
    });
  };
  const onCancel = () => {
    setDefaultValue();
    onChangeForm(false);
  };

  const onChangeFormCashier = (_e) => {
    onChangeForm(true);
  };

  const onChangeAutoConfirmOrder = (e) => {
    setIsAutoConfirmOrder(e);
    onChangeFormCashier();
  };

  const onChangeAllowReScanUnconfirmedOrders = (e) => {
    setAllowReScanUnconfirmedOrders(e);
    onChangeFormCashier();
  };

  const onChangeAllowReservation = (e) => {
    setIsAllowReservation(e);
    onChangeFormCashier();
    onChangeCheckedSwitchInputNumber(e, ["reservationSettings", "numberOfDaysAdvanceReservations"]);
    if (e) {
      let formValues = form.getFieldsValue();
      formValues.reservationSettings.reservationType = ReservationType.STAFF_ARRANGE;
      form.setFieldsValue(formValues);
    }
  };

  const onChangeReservationType = (e) => {
    setReservationType(e.target.value);
    onChangeFormCashier();
    if (e.target.value !== ReservationType.STAFF_ARRANGE) {
      setIsDeposit(false);
      let formValues = form.getFieldsValue();
      formValues.reservationSettings.isDeposit = false;
      form.setFieldsValue(formValues);
    }
  };

  const onChangeSwitchDeposit = (checked) => {
    onChangeFormCashier();
    setIsDeposit(checked);
    if (!checked) {
      const depositAmountError = form.getFieldError(["reservationSettings", "depositAmount"]) ?? [];
      if (depositAmountError.length > 0) {
        form.setFields([
          {
            name: ["reservationSettings", "depositAmount"],
            value: defaultStoreConfig.depositAmount ?? "",
            errors: [],
          },
        ]);
      }
    }
  };

  const onChangeCheckedSwitchInputNumber = (checked, fieldNameInput) => {
    if (!checked) {
      const errors = form.getFieldError(fieldNameInput) ?? [];
      if (errors.length > 0) {
        form.setFields([
          {
            name: fieldNameInput,
            value: 0,
            errors: [],
          },
        ]);
      }
    }
  };

  const isVietnamese = getCurrency() === currency.vnd;

  useEffect(() => {
    form.validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  return (
    <div className="cashier-screen-operation">
      <div className="heading-screen-operation">
        <div className="contain-description">
          <InfoCircleFlashSaleIcon height={16} width={16} />
          <span className="body-operation">{pageData.annotate}</span>
        </div>
        {isChangeForm && (
          <div className="list-group-button">
            <FnbButton minWidth="100px" text={pageData.button.cancel} variant="tertiary" onClick={onCancel} />
            <FnbButton minWidth="140px" text={pageData.button.saveChanges} onClick={onSave} />
          </div>
        )}
      </div>
      {/* Type of POS Operation */}
      <Form
        form={form}
        onChange={onChangeFormCashier}
        className="form-cashier-screen-operation"
        onFinish={onFinishForm}
      >
        <div className="card-section-setting-cashier">
          <Row gutter={[12, 12]}>
            <Col md={5} style={{ paddingTop: "4px" }}>
              <span className="title-operation">{pageData.typeOfPosOperation}</span>
              <span className="title-operation title-operation__required">*</span>
            </Col>
            <Col md={19}>
              <Form.Item className="form-item-operation" name="isPaymentLater" required>
                <Radio.Group
                  onChange={(e) => {
                    setIsPaymentLater(e.target.value);
                  }}
                >
                  <Radio value={false}>
                    <span className="body-medium-operation">{pageData.payFirst}</span>
                  </Radio>
                  <Radio value={true}>
                    <span className="body-medium-operation">{pageData.orderFirstPayLater}</span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <div className="card-child-setting-cashier">
            <Row>
              <Col sm={24} md={4} className="col-child-title-operation">
                <span className="title-operation">{pageData.receipts}</span>
              </Col>
              <Col sm={24} md={20}>
                <Row gutter={[8, 8]}>
                  <Col xs={24} sm={7} md={8} lg={7} xl={5} className="column-gap-8">
                    <Form.Item
                      valuePropName="checked"
                      className="form-item-operation"
                      name={["receipts", "isAutoPrintBillWhenCreateOrder"]}
                    >
                      <FnbSwitch
                        onChange={(e) => {
                          onChangeFormCashier(true);
                          setReceiptState({
                            ...receiptState,
                            isAutoPrintBillWhenCreateOrder: e,
                          });
                          onChangeCheckedSwitchInputNumber(e, ["receipts", "numberOfBillsPrintWhenCreateOrder"]);
                        }}
                      />
                    </Form.Item>
                    <span className="body-operation">{pageData.autoPrint}</span>
                  </Col>
                  <Col xs={24} sm={17} md={16} lg={17} xl={19} className="column-gap-8 column-right-input-number">
                    <Form.Item
                      name={["receipts", "numberOfBillsPrintWhenCreateOrder"]}
                      className="round-input-number-cashier-screen"
                      rules={[
                        {
                          required: receiptState.isAutoPrintBillWhenCreateOrder,
                          message: "",
                        },
                        {
                          type: "number",
                          min: receiptState.isAutoPrintBillWhenCreateOrder ? 1 : 0,
                          message: "",
                        },
                      ]}
                    >
                      <FnbInputNumber
                        type="number"
                        disabled={!receiptState.isAutoPrintBillWhenCreateOrder}
                        className="input-number-operation"
                      />
                    </Form.Item>
                    <span className="body-operation">{pageData.receiptsAfterOrderIsCreated}</span>
                  </Col>
                </Row>
                {isPaymentLater && (
                  <Row gutter={[8, 8]} style={{ marginTop: "14px" }}>
                    <Col xs={24} sm={7} md={8} lg={7} xl={5} className="column-gap-8">
                      <Form.Item
                        valuePropName="checked"
                        className="form-item-operation"
                        name={["receipts", "isAutoPrintBillWhenDoPaymentForOrder"]}
                      >
                        <FnbSwitch
                          onChange={(e) => {
                            onChangeFormCashier();
                            setReceiptState({
                              ...receiptState,
                              isAutoPrintBillWhenDoPaymentForOrder: e,
                            });
                            onChangeCheckedSwitchInputNumber(e, [
                              "receipts",
                              "numberOfBillsPrintWhenDoPaymentForOrder",
                            ]);
                          }}
                        />
                      </Form.Item>
                      <span className="body-operation">{pageData.autoPrint} </span>
                    </Col>
                    <Col xs={24} sm={17} md={16} lg={17} xl={19} className="column-gap-8 column-right-input-number">
                      <Form.Item
                        name={["receipts", "numberOfBillsPrintWhenDoPaymentForOrder"]}
                        className="round-input-number-cashier-screen"
                        rules={[
                          {
                            required: receiptState.isAutoPrintBillWhenDoPaymentForOrder,
                            message: "",
                          },
                          {
                            type: "number",
                            min: receiptState.isAutoPrintBillWhenDoPaymentForOrder ? 1 : 0,
                            message: "",
                          },
                        ]}
                      >
                        <FnbInputNumber
                          disabled={!receiptState.isAutoPrintBillWhenDoPaymentForOrder}
                          className="input-number-operation "
                          type="number"
                        />
                      </Form.Item>
                      <span className="body-operation">{pageData.receiptsAfterOrderIsPaid}</span>
                    </Col>
                  </Row>
                )}
                <Row gutter={[8, 8]} style={{ marginTop: "14px" }}>
                  <Col xs={24} sm={7} md={8} lg={7} xl={5} className="column-gap-8">
                    <Form.Item valuePropName="checked" name={["receipts", "isAllowPrintBillAtOrderManagement"]}>
                      <FnbSwitch
                        onChange={(e) => {
                          onChangeFormCashier();
                          setReceiptState({
                            ...receiptState,
                            isAllowPrintBillAtOrderManagement: e,
                          });
                          onChangeCheckedSwitchInputNumber(e, ["receipts", "numberOfBillsPrintAtOrderManagement"]);
                        }}
                      />
                    </Form.Item>
                    <span className="body-operation">{pageData.allowToPrint}</span>
                  </Col>
                  <Col xs={24} sm={17} md={16} lg={17} xl={19} className="column-gap-8 column-right-input-number">
                    <Form.Item
                      name={["receipts", "numberOfBillsPrintAtOrderManagement"]}
                      className="round-input-number-cashier-screen"
                      rules={[
                        {
                          required: receiptState.isAllowPrintBillAtOrderManagement,
                          message: "",
                        },
                        {
                          type: "number",
                          min: receiptState.isAllowPrintBillAtOrderManagement ? 1 : 0,
                          message: "",
                        },
                      ]}
                    >
                      <FnbInputNumber
                        type="number"
                        disabled={!receiptState.isAllowPrintBillAtOrderManagement}
                        className="input-number-operation"
                      />
                    </Form.Item>
                    <span className="body-operation">{pageData.receiptsAtOrder}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="card-child-setting-cashier">
            <Row>
              <Col sm={24} md={4} className="col-child-title-operation">
                <span className="title-operation">{pageData.orderSlips}</span>
              </Col>
              <Col sm={24} md={20}>
                <Row>
                  <Col xs={24} sm={7} md={8} lg={7} xl={5} className="column-gap-8">
                    <Form.Item valuePropName="checked" name={["orderSlips", "isOrderSlipAutoPrintAfterCreate"]}>
                      <FnbSwitch
                        onChange={(e) => {
                          onChangeFormCashier();
                          setIsAutoPrintOrderSlips(e);
                          onChangeCheckedSwitchInputNumber(e, ["orderSlips", "numberOfSlipsWillBePrinted"]);
                        }}
                      />
                    </Form.Item>
                    <span className="body-operation">{pageData.autoPrint}</span>
                  </Col>
                  <Col xs={24} sm={17} md={16} lg={17} xl={19} className="column-gap-8 column-right-input-number">
                    <Form.Item
                      name={["orderSlips", "numberOfSlipsWillBePrinted"]}
                      className="round-input-number-cashier-screen"
                      rules={[
                        {
                          required: isAutoPrintOrderSlips,
                          message: "",
                        },
                        {
                          type: "number",
                          min: isAutoPrintOrderSlips ? 1 : 0,
                          message: "",
                        },
                      ]}
                    >
                      <FnbInputNumber
                        type="number"
                        disabled={!isAutoPrintOrderSlips}
                        className="input-number-operation"
                      />
                    </Form.Item>
                    <span className="body-operation">{pageData.orderSlipsAfterCreate}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="card-child-setting-cashier">
            <Row>
              <Col sm={24} md={4} style={{ paddingTop: "4px" }}>
                <span className="title-operation">{pageData.itemStickerStamp}</span>
              </Col>
              <Col sm={24} md={20}>
                <Row>
                  <Col className="column-gap-8">
                    <Form.Item valuePropName="checked" name={["stamp", "isAutoPrintStampWhenCreateOrder"]}>
                      <FnbSwitch onChange={onChangeFormCashier} />
                    </Form.Item>
                    <span className="body-operation">{`${pageData.autoPrintItemStickersAfterOrderCreated}`}</span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "16px" }}>
                  <Col className="column-gap-8">
                    <Form.Item valuePropName="checked" name={["stamp", "isAllowPrintStampAtOrderManagement"]}>
                      <FnbSwitch onChange={onChangeFormCashier} />
                    </Form.Item>

                    <span className="body-operation">{pageData.allowPrintItemStickerAtOrderManagement}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {/* Order Settings */}
        <div className="card-section-setting-cashier">
          <Row gutter={[0, 8]}>
            <Col sm={24} md={4} style={{ paddingTop: "4px" }}>
              <span className="title-operation">{pageData.orderSettings}</span>
            </Col>
            <Col sm={24} md={20} style={{ paddingLeft: "4px" }} className="list-order-settings">
              {/* Auto confirm order */}
              <div className="item-order-settings">
                <Form.Item valuePropName="checked" name={["orderSettings", "isAutoConfirmOrder"]}>
                  <FnbSwitch onChange={onChangeAutoConfirmOrder} />
                </Form.Item>
                <Space className="w-100" direction="vertical" size={8}>
                  <div className="content-right-item-order-settings">
                    <span className="body-medium-operation">{pageData.autoConfirmOrder}</span>
                    <span className="body-2-operation">{pageData.autoConfirmOrderNote}</span>
                  </div>
                  {isAutoConfirmOrder && (
                    <Fragment>
                      <span className="body-medium-operation">{pageData.timeToAutoConfirm}</span>
                      <Form.Item
                        name={["orderSettings", "autoConfirmOrderInMinutes"]}
                        rules={[
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.pleaseInputValue}
                              />
                            ),
                          },
                          {
                            type: "number",
                            min: 1,
                            max: 1440,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.validateRangeMessage.replace("$from$", 1).replace("$to$", 1440)}
                              />
                            ),
                          },
                        ]}
                      >
                        <FnbInputNumber type="number" className="w-100" addonAfter={pageData.minutes} />
                      </Form.Item>
                    </Fragment>
                  )}
                </Space>
              </div>

              {/* Auto re-scan order */}
              <div className="item-order-settings item-order-settings__rescan-order">
                <Form.Item
                  className="item-order-settings__form-scan-order"
                  valuePropName="checked"
                  name={["orderSettings", "allowReScanUnconfirmedOrders"]}
                >
                  <FnbSwitch onChange={onChangeAllowReScanUnconfirmedOrders} />
                </Form.Item>
                <Space className="w-100" direction="vertical" size={8}>
                  <div className="content-right-rescan-order">
                    <span className="body-medium-operation">{pageData.scanAndPlayASoundToNotify}</span>
                  </div>
                  {allowReScanUnconfirmedOrders && (
                    <Fragment>
                      <Form.Item
                        name={["orderSettings", "reScanUnconfirmedOrdersInMinutes"]}
                        rules={[
                          () => ({
                            validator(_, value) {
                              // check value is not accept decimal number and special character
                              const regex = /^[0-9]*$/;
                              if (value && !regex.test(value)) {
                                return Promise.reject(
                                  <InputValidateMessage
                                    type={EnumInputValidateType.ERROR}
                                    message={pageData.invalidValues}
                                  />,
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                          {
                            required: true,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.enterThePeriodOfTime}
                              />
                            ),
                          },
                          {
                            type: "number",
                            min: 1,
                            max: 999,
                            message: (
                              <InputValidateMessage
                                type={EnumInputValidateType.ERROR}
                                message={pageData.invalidValues}
                              />
                            ),
                          },
                        ]}
                        initialValue={10}
                      >
                        <FnbInputNumber
                          type="number"
                          className="w-100"
                          addonAfter={pageData.minutes}
                          placeholder={pageData.enterThePeriodOfTime}
                          onKeyPress={(event) => {
                            if (event.code === "Space" || !(Number(event.key) >= 0 || Number(event.key) <= 9)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </Fragment>
                  )}
                </Space>
              </div>
              <div className="item-order-settings">
                <Form.Item valuePropName="checked" name={["orderSettings", "isCheckProductSell"]}>
                  <FnbSwitch onChange={onChangeFormCashier} />
                </Form.Item>

                <div className="content-right-item-order-settings">
                  <span className="body-medium-operation">{pageData.allowOrderOutOfStock}</span>
                  <span className="body-2-operation">{pageData.allowOrderOutOfStockNote}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* Reservation Settings */}
        <div className="card-section-setting-cashier">
          <Row gutter={[0, 8]}>
            <Col sm={24} md={4} style={{ paddingTop: "4px" }}>
              <span className="title-operation">{pageData.reservationSettings}</span>
            </Col>
            <Col sm={24} md={20} style={{ paddingLeft: "4px" }} className="list-order-settings">
              <div className="item-order-settings" style={{ alignItems: "center" }}>
                <Form.Item valuePropName="checked" name={["reservationSettings", "isAllowReserveTable"]}>
                  <FnbSwitch onChange={onChangeAllowReservation} />
                </Form.Item>
                <div className="content-right-item-order-settings">
                  <span className="body-medium-operation">{pageData.allowReservation}</span>
                </div>
              </div>
            </Col>
          </Row>
          {isAllowReservation && (
            <Fragment>
              <div className="card-child-setting-cashier">
                <Space direction="vertical" size={8} className="w-100">
                  <span className="body-medium-operation">{pageData.reservation.requiredTimeReservation}</span>
                  <Form.Item
                    name={["reservationSettings", "numberOfDaysAdvanceReservations"]}
                    rules={[
                      {
                        required: isAllowReservation,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.reservation.validate}
                          />
                        ),
                      },
                      {
                        type: "number",
                        min: 1,
                        max: 100,
                        message: (
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.validateRangeMessage.replace("$from$", 1).replace("$to$", 100)}
                          />
                        ),
                      },
                    ]}
                  >
                    <FnbInputNumber
                      type="number"
                      className="w-100"
                      addonAfter={pageData.days}
                      maxLength={100}
                      placeholder={pageData.reservation.placeholder}
                    />
                  </Form.Item>
                </Space>
              </div>
              <div className="card-child-setting-cashier">
                <Form.Item className="form-item-operation" name={["reservationSettings", "reservationType"]}>
                  <Radio.Group className="list-reservation-settings" onChange={onChangeReservationType}>
                    <Radio value={ReservationType.STAFF_ARRANGE}>
                      <span className="body-medium-operation">{pageData.staffWillContactForReserve}</span>
                    </Radio>
                    {reservationType === ReservationType.STAFF_ARRANGE && (
                      <Space direction="vertical" size={12} className="w-100" style={{ paddingLeft: "32px" }}>
                        <div className="column-gap-8">
                          <Form.Item
                            valuePropName="checked"
                            className="form-item-operation"
                            name={["reservationSettings", "isDeposit"]}
                          >
                            <FnbSwitch onChange={onChangeSwitchDeposit} />
                          </Form.Item>
                          <span className="body-operation">{pageData.deposit.title}</span>
                          <span className={`d-flex-align-center ${isMaxWidth780 && "ml-8"}`}>
                            <FnbTooltip
                              placement="top"
                              overlayClassName="tooltip-inventory"
                              title={
                                <div>
                                  <ul style={{ marginBottom: 0, paddingLeft: "16px" }}>
                                    <li>
                                      <FnbTypography
                                        variant={theme.typography["b2-regular"]}
                                        text={pageData.deposit.tooltipLine1}
                                        color={theme.colors.default.white}
                                      />
                                    </li>
                                    <li>
                                      <FnbTypography
                                        variant={theme.typography["b2-regular"]}
                                        text={pageData.deposit.tooltipLine2}
                                        color={theme.colors.default.white}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              }
                            >
                              <StoreGeneralConfigInfoCircleIcon className="ml-1" />
                            </FnbTooltip>
                          </span>
                        </div>
                        <Space className="w-100" direction="vertical" size={8}>
                          <span className="body-medium-operation">{pageData.deposit.amountDeposit}</span>
                          <Form.Item
                            name={["reservationSettings", "depositAmount"]}
                            rules={[
                              () => ({
                                validator(_, value) {
                                  if (isDeposit) {
                                    if (!value && value !== 0) {
                                      return Promise.reject(
                                        <InputValidateMessage message={pageData.deposit.validate} />,
                                      );
                                    }
                                    if (value > MaxNumberInput || value < 0.01) {
                                      return Promise.reject(
                                        <InputValidateMessage message={pageData.deposit.validateRangeMessage} />,
                                      );
                                    }
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <FnbInputNumber
                              className="w-100"
                              placeholder={pageData.deposit.placeholder}
                              disabled={!isDeposit}
                              addonAfter={getCurrency()}
                              formatter={(value) =>
                                isVietnamese
                                  ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : formatterDecimalNumber(value, true)
                              }
                              parser={(value) =>
                                isVietnamese ? value.replace(/\$\s?|(,*)/g, "") : parserDecimalNumber(value, true)
                              }
                              onKeyPress={(event) => {
                                const checkKey = isVietnamese ? !/[0-9]/.test(event.key) : !isDecimalKey(event);
                                if (checkKey) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Space>
                      </Space>
                    )}

                    <Radio disabled value={ReservationType.LOCK_PERIOD_TIME}>
                      <div className="reservation-coming-soon">
                        <span className="body-medium-operation">{pageData.lockReservationPeriodOfTime}</span>
                        <ComingSoonTag />
                      </div>
                    </Radio>
                    <Radio disabled value={ReservationType.FIXED_TIME}>
                      <div className="reservation-coming-soon">
                        <span className="body-medium-operation">{pageData.reserveAtFixedTime}</span>
                        <ComingSoonTag />
                      </div>
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Fragment>
          )}
        </div>
      </Form>
    </div>
  );
};

export default CashierScreen;
