import { Col, Form, Input, InputNumber, Row, Typography, message } from "antd";
import { FnbCancelButton } from "components/cancel-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { DELAYED_TIME, tableSettings } from "constants/default.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { MaxNumberInput } from "constants/string.constants";
import transferMaterialDataService from "data-services/transfer-material/transfer-material-data.service";
import { useEffect, useState } from "react";
import { formatNumberDecimalOrInteger, formatterDecimalNumber, isDecimalKey, parserDecimalNumber } from "utils/helpers";
import "./edit-transfer-material.page.scss";
import { IngredientSearchModal, IngredientSearchModalType } from "components/ingredient-search-modal/ingredient-search-modal";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { SearchIcon } from "constants/icons.constants";
const { TextArea } = Input;
const { Text } = Typography;

export default function UpdateTransferMaterialComponent(props) {
  const { t, unitConversionDataService, match, history } = props;

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    createTransferMaterialSuccess: t("messages.createTransferMaterialSuccess"),
    updateTransferMaterialSuccess: t("messages.updateTransferMaterialSuccess"),
    createTransferMaterial: t("transferMaterial.createTransferMaterial"),
    updateTransferMaterial: t("transferMaterial.updateTransferMaterial"),
    from: t("transferMaterial.from"),
    materials: t("transferMaterial.materials"),
    pleaseSelectTransferMaterial: t("transferMaterial.pleaseSelectTransferMaterial"),
    pleaseInputQuantity: t("transferMaterial.pleaseInputQuantity"),
    selectTransferMaterial: t("transferMaterial.selectTransferMaterial"),
    materialInformation: t("purchaseOrder.materialInformation"),
    searchMaterial: t("purchaseOrder.searchMaterial"),
    pleaseSelectMaterialTransfer: t("inventoryTracking.pleaseSelectMaterialTransfer"),
    pleaseSelectMaterial: t("material.pleaseSelectMaterial"),
    note: t("form.note"),
    selectBranch: t("staffManagement.permission.selectBranch"),
    pleaseSelectBranch: t("staffManagement.permission.pleaseSelectBranch"),
    supplier: {
      title: t("supplier.title"),
      selectSupplier: t("supplier.selectSupplier"),
      pleaseSelectSupplier: t("supplier.pleaseSelectSupplier"),
    },
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      materialName: t("table.materialName"),
      material: t("material.title"),
      quantity: t("table.quantity"),
      importUnit: t("table.importUnit"),
      transferUnit: t("table.transferUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseEnterNameUnit"),
      unitPlaceholder: t("productManagement.unit.unitPlaceholder"),
      importPrice: t("table.importPrice"),
      total: t("table.total"),
      totalCost: t("table.totalCost"),
      action: t("table.action"),
      inventory: t("table.inventory"),
      branchName: t("table.branchName"),
    },
    generalInformation: t("purchaseOrder.generalInformation"),
    destination: t("purchaseOrder.destinationLabel"),
    btnUpdate: t("button.update"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    maximumAvailable: t("transferMaterial.maximumAvailable"),
    validateMinQtyMessage: t("productManagement.pricing.priceRange"),
    ingredientPlaceholder: t("productManagement.recipe.placeholder"),
  };

  const [form] = Form.useForm();
  const [transferMaterialId, setTransferMaterialId] = useState(false);
  const [branches, setBranches] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [initDataMaterials, setInitDataMaterials] = useState([]);
  const [dataSelectedMaterial, setDataSelectedMaterial] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [fromDataSelect, setFromDataSelect] = useState(branches);
  const [toDataSelect, setToDataSelect] = useState(branches);
  const [fromDataSelectChoice, setFromDataSelectChoice] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModalSearchIngredient, setShowModalSearchIngredient] = useState(false);

  const fetchInitData = async (res, transferMaterialDetails) => {
    const response = await transferMaterialDataService.getComboTransferMaterialDataAsync(
      res.transferMaterial?.fromWarehouseId,
    );
    if (response) {
      const { warehouses, materials } = response;
      setBranches(warehouses);
      getMaterialsUpdate(materials, transferMaterialDetails);
      onComboFromChangeFirst(res.transferMaterial?.fromWarehouseId, warehouses);
      onComboToChangeFirst(res?.transferMaterial?.toWarehouseId, warehouses);
    }
  };

  useEffect(() => {
    setTransferMaterialId(match?.params?.id);
    if (match?.params?.id) {
      transferMaterialDataService.getTransferMaterialIdAsync(match?.params?.id).then((res) => {
        if (res && res.transferMaterial) {
          fetchInitData(res, res.transferMaterial.transferMaterialDetails);
          mappingFormEditTransferMaterial(res.transferMaterial);
        }
      });
    }
  }, []);

  const mappingFormEditTransferMaterial = (data) => {
    let listData = [];
    let listDataUpdate = [];
    data?.transferMaterialDetails?.map((item, index) => {
      const restMaterials = materials.filter((o) => o.id !== item.materialId);
      const newRow = mappingToDataTableForEdit(item, index);
      listData.push(newRow);
      setMaterials(restMaterials);
      let detailMaterials = {
        unit: newRow?.unitId,
        quantity: newRow?.quantity,
      };
      listDataUpdate.push(detailMaterials);
    });
    setDataSelectedMaterial(listData);

    let formValue = {
      transferMaterial: {
        fromWarehouseId: data?.fromWarehouseId,
        toWarehouseId: data?.toWarehouseId,
        note: data?.note,
        selectedMaterials: listDataUpdate,
      },
    };
    form.setFieldsValue(formValue);
  };

  const onComboFromChangeFirst = (value, firstFromData) => {
    setToDataSelect(firstFromData.filter((x) => x?.id !== value));
    setFromDataSelectChoice(value);
  };

  const onComboToChangeFirst = (value, firstFromData) => {
    setFromDataSelect(firstFromData.filter((x) => x?.id !== value));
  };

  const mappingToDataTableForEdit = (item, index) => {
    let units = [];
    item.unitConversionUnits?.map((uc) => {
      let unit = {
        id: uc?.unitId,
        name: uc?.unit?.name,
        capacity: uc?.capacity,
      };
      units.push(unit);
    });
    let materialInventoryBranches = [];
    item.material?.materialInventoryBranches?.map((uc) => {
      let materialInventoryBranch = {
        branchId: uc?.branchId,
        quantity: uc?.quantity,
      };
      materialInventoryBranches.push(materialInventoryBranch);
    });
    units.unshift(item.material.unit);
    return {
      id: item.materialId,
      no: index + 1,
      sku: item?.material?.sku,
      material: item?.material?.name,
      quantity: item?.quantity,
      unitId: item?.unitId,
      unitConversionUnits: units,
      materialInventoryBranches: materialInventoryBranches,
      materialId: item?.materialId,
      units: units,
      inventoryQuantity: item?.material?.quantity,
    };
  };

  const getMaterialsUpdate = async (materials, transferMaterialDetails) => {
    setInitDataMaterials(materials);
    const materialsUpdate = materials.filter(
      (x) => transferMaterialDetails.filter((y) => y.materialId === x.id).length === 0,
    );
    setMaterials(materialsUpdate);
  };

  const maxQuantityByBranches = (index, rule, value, callback) => {
    const changeRecord = dataSelectedMaterial[index];
    const quantityConversions = changeRecord?.units?.find(
      (item) => item?.id === changeRecord?.unitId,
    )?.capacity ?? 1;
    var maxQuantity = 0;
    let isQuantity = true;
    
    if (fromDataSelectChoice && changeRecord) {
      maxQuantity = changeRecord.inventoryQuantity;
      
    console.log('quantity', quantityConversions, value, maxQuantity);
      if (quantityConversions && quantityConversions !== 0) {
        let maxQuantityConversion = quantityConversions * changeRecord?.quantity;
        isQuantity = maxQuantity >= maxQuantityConversion;
        maxQuantity = quantityConversions !== 0 ? maxQuantity / quantityConversions : maxQuantity;
      }
    }

    if ((value && value > maxQuantity) || !isQuantity) {
      const validateMessage = pageData.maximumAvailable + formatNumberDecimalOrInteger(maxQuantity);
      callback(validateMessage);
    }
    callback();
  };

  const handleSetDataSelectedMaterial = (listSelectedMaterials) => {
    if (listSelectedMaterials?.length === 0)
      return;
    let selectedIds = dataSelectedMaterial.map(z => z.id);
    let newMaterialIds = listSelectedMaterials.filter(item => !selectedIds.includes(item.materialId)).map(z => z.materialId);
    listSelectedMaterials.forEach((item, index) => {
      if (newMaterialIds.includes(item.materialId)) {
        let mapUnitConversions = item?.unitConversion?.map((unit) => {
          return {
            id: unit.unitId,
            name: unit.unitName,
            capacity: unit.capacity,
          };
        });

        let unitConversion = [{ id: item?.unitId, name: item?.unitName }, ...mapUnitConversions];
        item.id = item.materialId;
        item.material = item.name;
        item.quantity = 0;
        item.units = unitConversion;
        form.setFieldValue(["transferMaterial", "selectedMaterials", index, "quantity"], 0);
        form.setFieldValue(["transferMaterial", "selectedMaterials", index, "unit"], item.unitId);
      }
    });
    setDataSelectedMaterial(listSelectedMaterials);
  }

  const onFinish = async (values) => {
    const fromWarehouseType = branches.find((x) => x?.id === values.transferMaterial.fromWarehouseId)?.type;
    const toWarehouseType = branches.find((x) => x?.id === values.transferMaterial.toWarehouseId)?.type;

    if (dataSelectedMaterial.length > 0) {
      const createTransferMaterialRequestModel = {
        ...values.transferMaterial,
        fromWarehouseType,
        toWarehouseType,
        materials: dataSelectedMaterial,
      };

      let req = {
        id: transferMaterialId,
        transferMaterialDto: createTransferMaterialRequestModel,
      };
      const res = await transferMaterialDataService.updateTransferMaterialAsync(req);
      if (res) {
        message.success(pageData.updateTransferMaterialSuccess);
        onCompleted(transferMaterialId);
      }
    }
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "left",
        width: "5rem",
        render: (_, record, index) => index + 1,
      },
      {
        title: pageData.table.sku,
        dataIndex: "sku",
        align: "left",
        width: "15%",
      },
      {
        title: pageData.table.materialName,
        dataIndex: "material",
        align: "left",
        width: "50%",
        render: (value, record) => {
          return <FnbTypography.Link to={`/inventory/material/detail/${record.id}`} target="_blank" text={record?.material} />;
        },
      },
      {
        title: pageData.table.quantity,
        dataIndex: "quantity",
        width: 150,
        align: "left",
        render: (_, record, index) => (
          <Form.Item
            name={["transferMaterial", "selectedMaterials", index, "quantity"]}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.pleaseInputQuantity} />,
              },
              {
                validator: (rule, value, callback) => maxQuantityByBranches(index, rule, value, callback),
              },
              () => ({
                validator(_, value) {
                  if (value > MaxNumberInput || value < 0) {
                    return Promise.reject(<InputValidateMessage message={pageData.validateMinQtyMessage} />);
                  }
                  if (value === 0) {
                    return Promise.reject(<InputValidateMessage message={pageData.pleaseInputQuantity} />);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              onChange={(value) => onChangeRecord(index, "quantity", value)}
              style={{ width: "100%", minWidth: "150px" }}
              defaultValue={record.quantity}
              className="fnb-input quantity-material"
              formatter={(value) => formatterDecimalNumber(value)}
              parser={(value) => parserDecimalNumber(value)}
              onKeyPress={(event) => {
                if (!isDecimalKey(event)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        ),
      },
      {
        title: pageData.table.transferUnit,
        dataIndex: "unitId",
        width: 150,
        align: "left",
        render: (_, record, index) => (
          <Form.Item
            name={["transferMaterial", "selectedMaterials", index, "unit"]}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.table.pleaseEnterNameUnit} />,
              },
            ]}
            style={{ display: "contents" }}
            className="transfer-unit-select"
          >
            <FnbSelectSingle
              getPopupContainer={() => document.getElementsByClassName("transfer-unit-select")[index]?.parentNode?.parentNode?.parentNode}
              showSearch
              placeholder={pageData.table.unitPlaceholder}
              option={record?.units?.map((item) => ({
                id: item?.id,
                name: item?.name,
              }))}
              style={{ width: "90%", minWidth: "150px" }}
              defaultValue={record?.unitId}
              onChange={(value) => onSelectUnit(value, index)}
              className="mb-28"
            />
          </Form.Item>
        ),
      },
    ];
    return columns;
  };

  const onRemoveItemMaterial = (id) => {
    let restMaterials = dataSelectedMaterial.filter((o) => o.id !== id);
    setDataSelectedMaterial(restMaterials);

    restMaterials.map((product, index) => {
      form.setFieldValue(["transferMaterial", "selectedMaterials", index, "quantity"], product?.quantity);
      form.setFieldValue(["transferMaterial", "selectedMaterials", index, "unit"], product?.unitId);
    });

    let initDataMaterial = initDataMaterials.find((o) => o.id === id);
    materials.push(initDataMaterial);
  };

  const onChangeRecord = (index, column, value) => {
    changeForm();
    let changeRecord = dataSelectedMaterial[index];

    let quantity = column === "quantity" ? value : changeRecord.quantity;
    dataSelectedMaterial.map((item, i) => {
      if (i === index) {
        item.quantity = quantity;
      }
    });
    setDataSelectedMaterial([...dataSelectedMaterial]);
  };

  const onSelectUnit = (value, index) => {
    dataSelectedMaterial[index].unitId = value;
    form.setFieldValue(["transferMaterial", "selectedMaterials", index, "unit"], value);
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const onCompleted = (id) => {
    setIsChangeForm(false);
    setTimeout(() => {
      if (!id) return history.push(`/inventory/transfer-material`);
      return history.push(`/inventory/transfer-material/view/${id}`);
    }, DELAYED_TIME);
  };

  const onCancelNotChangeForm = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push(`/inventory/transfer-material`);
    }, DELAYED_TIME);
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      onCancelNotChangeForm();
    }
  };

  const getTotalMaterials = () => {
    let total = dataSelectedMaterial.length;
    return total;
  };

  const onChangeFromWarehouseId = async (warehouseId) => {
    const newFromData = [...branches];
    setToDataSelect(newFromData.filter((x) => x?.id !== warehouseId));
    setFromDataSelectChoice(warehouseId);
    const response = await transferMaterialDataService.getComboTransferMaterialDataAsync(warehouseId);
    const { materials } = response;
    setMaterials(materials);
  };

  const onComboToChange = (value) => {
    const newToData = [...branches];
    setFromDataSelect(newToData.filter((x) => x?.id !== value));
  };

  const onDeleteRowKeys = async (selectedRowKeys, selectedRows) => {
    onRemoveItemMaterial(selectedRows[0]?.id);
    setSelectedRowKeys([]);
  };

  const onSelectedRowKeysChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        onFieldsChange={(e) => changeForm(e)}
        layout="vertical"
        className="update-transfer-material-form custom-form"
      >
        <FnbHeadingPage
          title={pageData.updateTransferMaterial}
          listButtons={[<FnbCancelButton onOk={onCancel} />]}
          typeBtnCreate="submit"
          permissionCreate={PermissionKeys.EDIT_TRANSFER_MATERIAL}
          textAddNew={pageData.btnUpdate}
        />
        <FnbCard className="w-100" title={pageData.generalInformation}>
          <Row gutter={16} align="middle" className="mb-28">
            <Col className="gutter-row" span={12} md={12} sm={24} xs={24}>
              <Form.Item
                name={["transferMaterial", "fromWarehouseId"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        id={"select-transfer-material-error-from"}
                        message={pageData.pleaseSelectTransferMaterial}
                      />
                    ),
                  },
                ]}
                label={pageData.from}
              >
                <FnbSelectSingle
                  placeholder={pageData.selectTransferMaterial}
                  showSearch
                  option={fromDataSelect?.map((b) => ({
                    id: b.id,
                    name: b.name,
                  }))}
                  onChange={(value) => onChangeFromWarehouseId(value)}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12} md={12} sm={24} xl={12} xs={24}>
              <Form.Item
                name={["transferMaterial", "toWarehouseId"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        id={"select-transfer-material-error-to"}
                        message={pageData.pleaseSelectTransferMaterial}
                      />
                    ),
                  },
                ]}
                label={pageData.destination}
              >
                <FnbSelectSingle
                  showSearch
                  placeholder={pageData.selectTransferMaterial}
                  option={toDataSelect?.map((item) => ({
                    id: item.id,
                    name: item.name,
                  }))}
                  onChange={(value) => onComboToChange(value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row align="middle" className="mb-28">
            <Col span={24}>
              <Form.Item name={["transferMaterial", "note"]} label={pageData.note}>
                <TextArea
                  className="fnb-text-area-with-count no-resize combo-description-box"
                  showCount
                  maxLength={255}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  placeholder={pageData.freeTextArea}
                />
              </Form.Item>
            </Col>
          </Row>
        </FnbCard>
        <FnbCard className="mt-3" title={pageData.materialInformation}>
          <Row>
            <Col span={24} onClick={() => setShowModalSearchIngredient(true)}>
              <Form.Item className="w-100">
                <FnbInput
                  id={"btn-search-ingredient"}
                  className="fnb-input"
                  size="large"
                  placeholder={pageData.ingredientPlaceholder}
                  prefix={<SearchIcon />}
                  type={TYPE_INPUT.BUTTON}
                />
              </Form.Item>
              {dataSelectedMaterial.length === 0 && (
                <InputValidateMessage
                  className="mt-8"
                  id={"select-material-error"}
                  message={pageData.pleaseSelectMaterialTransfer}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FnbTable
                tableId="material-list"
                columns={columnsMaterial()}
                pageSize={tableSettings.pageSize}
                dataSource={dataSelectedMaterial}
                currentPageNumber={tableSettings.page}
                total={dataSelectedMaterial.length}
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectedRowKeysChange,
                  onDelete: onDeleteRowKeys,
                }}
              />
            </Col>
          </Row>
          <br />
          <Row justify="end" align="middle" className="h-bg-brad-transfer-material-class">
            <Col xs={0} sm={0} md={0} lg={14}></Col>
            <Col xs={24} sm={24} md={24} lg={10}>
              <Row align="middle">
                <Col span={24} className="pl20">
                  <br />
                  <Row>
                    <Col span={24} className="fz16 mb20 text-center">
                      <Text className="float-left" strong>
                        {pageData.table.total}:
                      </Text>
                      <span className="float-right pr10">
                        <span className="bold-materials">{getTotalMaterials()}</span>
                        {pageData.materials}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </FnbCard>
      </Form>
      <ConfirmDialogComponent
        visible={showConfirm}
        skipPermission={true}
        onCancel={onDiscard}
        onOk={onCompleted}
        type={ConfirmStyle.LEAVER}
      />
      <IngredientSearchModal
        visible={showModalSearchIngredient}
        handleCancel={() => setShowModalSearchIngredient(false)}
        listSelectedMaterials={dataSelectedMaterial}
        setListSelectedMaterials={handleSetDataSelectedMaterial}
        modalType={IngredientSearchModalType.TYPE_INGREDIENT}
        branchId={fromDataSelectChoice}
        loadAllData
      />
    </>
  );
}
