import { Col, Form, message, Row, Typography } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FnbCancelButton } from "components/cancel-button";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { MinimumNumber } from "constants/default.constants";
import {
  CustomTrashIcon,
  FolderIcon,
  ImageMaterialDefault,
  PreProcessIcon,
  SearchIcon,
} from "constants/icons.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { MaterialType, MaterialTypeLocalization } from "constants/pre-process.constants";
import branchDataService from "data-services/branch/branch-data.service";
import preprocessMaterialDataService from "data-services/material-control/preprocess-material-data.service";
import materialDataService from "data-services/material/material-data.service";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { formatNumberDecimalOrInteger, isDecimalKey, parserDecimalNumber, roundNumber } from "utils/helpers";
import i18n from "utils/i18n";
import { IngredientSearchModal, IngredientSearchModalType } from "../../../../components/ingredient-search-modal/ingredient-search-modal";
import { KEY_TAB_PANE } from "../table-inventory-control.component";
import "./pre-process-ingredient.scss";

export default function PreProcessIngredients(props) {
  const { t } = useTranslation();
  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    preProcessIngredient: t("materialControl.preprocess.preprocessIngredient"),
    searchByNameSku: t("materialControl.createRemoveRequest.searchByNameSku"),
    branch: t("materialControl.preprocess.branch"),
    placeholderBranch: t("materialControl.preprocess.selectBranch"),
    ingredientSearch: t("materialControl.preprocess.ingredientSearch"),
    ingredientPlaceholder: t("materialControl.preprocess.selectPreProcess"),
    ingredientToPreProcess: t("materialControl.preprocess.ingredientToPreProcess"),
    type: t("materialControl.preprocess.type"),
    quantityToPreProcess: t("materialControl.preprocess.quantityToPreProcess"),
    unit: t("materialControl.preprocess.unit"),
    noDataFound: t("materialControl.preprocess.noIngredientPreProcess"),
    pleaseSelectBranch: t("materialControl.preprocess.selectBranchError"),
    consumableIngredientUsed: t("materialControl.preprocess.consumableIngredientUsed"),
    consumableIngredient: t("materialControl.preprocess.consumableIngredient"),
    quantityUsed: t("materialControl.preprocess.quantityUsed"),
    quantityAvailable: t("materialControl.preprocess.quantityAvailable"),
    quantityRemain: t("materialControl.preprocess.quantityRemain"),
    btnCreate: t("button.create"),
    btnConfirmLeave: t("button.confirmLeave"),
    leaveWarningMessage: t("messages.leaveForm"),
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    generalInformation: t("purchaseOrder.generalInformation"),
    createdSuccessfully: t("materialControl.preprocess.toastPreProcessSuccessfully"),
    errQuantity: t("materialControl.preprocess.quantityError"),
    warningQuantity: t("materialControl.preprocess.notEnoughIngredient"),
    preProcess: t("materialControl.preprocess.preProcess"),
    warningTitle: t("materialControl.preprocess.notification.insufficientIngredients"),
    warningContent: t("materialControl.preprocess.notification.contentInsufficient"),
    notMatchContent: t("materialControl.preprocess.notification.content"),
    refresh: t("materialControl.preprocess.refresh"),
    errIngredient: t("materialControl.createRemoveRequest.errIngredient"),
  };

  const [form] = useForm();
  const history = useHistory();
  const [visibleModalLeaveConfirm, setVisibleModalLeaveConfirm] = useState(false);
  const [visibleModalWarning, setVisibleModalWarning] = useState(false);
  const [visibleModalNotEnough, setVisibleModalNotEnough] = useState(false);
  const [branches, setBranches] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listSelectedMaterials, setListSelectedMaterials] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();
  const [listConsumableMaterials, setListConsumableMaterials] = useState([]);
  const [recipeMaterials, setRecipeMaterials] = useState();

  const getBranches = async () => {
    const branchesResponse = await branchDataService.getAllBranchsAsync();
    if (branchesResponse) {
      setBranches(branchesResponse.branchs);
      updateLastTimeGetBranches();
    }
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  const leaveOnCancel = () => {
    if (visibleModalLeaveConfirm) {
      setVisibleModalLeaveConfirm(false);
    }
    if (visibleModalWarning) {
      setVisibleModalWarning(false);
    }
  };

  const leaveOnOk = () => {
    navigateToManagementPage();
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field.errors.length && arr.push(field.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const calculateMaterials = (
    quantity,
    capacity,
    materialId,
    ingredient,
    isDefault = false,
    isExist = false,
    newListSelectedMaterials,
    newRecipeMaterials,
    newListConsumableMaterials,
  ) => {
    let quantityUsed = quantity * capacity;
    const newListSelected = newListSelectedMaterials ?? listSelectedMaterials;
    const materialRecipe = newRecipeMaterials ? newRecipeMaterials : recipeMaterials;
    const listConsumables = newListConsumableMaterials ?? listConsumableMaterials;
    let isNotEnough = false;
    materialRecipe[materialId].forEach((material) => {
      let consumableMaterial = listConsumables.find((m) => m.materialId === material.consumableMaterialId);
      const used = roundNumber(material?.receiptQuantity * quantityUsed, 2);
      if (!consumableMaterial.totalUsed) {
        consumableMaterial.totalUsed = {};
        consumableMaterial.quantityUsed = used;
      } else {
        if (consumableMaterial.totalUsed[materialId]) {
          consumableMaterial.quantityUsed =
            consumableMaterial.quantityUsed - consumableMaterial.totalUsed[materialId] + used;
        } else {
          consumableMaterial.quantityUsed += used;
        }
      }
      consumableMaterial.totalUsed[materialId] = used;
      let preProcess = isExist
        ? listSelectedMaterials.find((m) => m.materialId === material.consumableMaterialId)
        : newListSelected.find((m) => m.materialId === material.consumableMaterialId);
      consumableMaterial.quantityRemain =
        consumableMaterial?.inventoryQuantity -
        consumableMaterial.quantityUsed +
        (preProcess?.quantityToPreprocess ?? 0) * (preProcess?.capacity ?? 1);
      if (consumableMaterial.quantityRemain < 0) {
        isNotEnough = true;
        consumableMaterial.isWarning = true;
      } else {
        consumableMaterial.isWarning = false;
      }
    });
    ingredient.isWarning = isNotEnough;
    let preProcessMaterial = listConsumables.find((m) => m.materialId === materialId);
    if (preProcessMaterial) {
      let selected = isExist
        ? listSelectedMaterials.find((m) => m.materialId === materialId)
        : newListSelected.find((m) => m.materialId === materialId);
      preProcessMaterial.quantityRemain =
        (preProcessMaterial.quantityRemain ?? 0) -
        (selected?.quantityToPreprocess ?? 0) * (selected?.capacity ?? 1) +
        quantityUsed;
      preProcessMaterial.isWarning = preProcessMaterial.quantityRemain < 0;
      //check show warning if change quantity to preprocess
      if (!isDefault) {
        newListSelected.forEach((item) => {
          let consumableIds = materialRecipe[item.materialId].map((z) => z.consumableMaterialId);
          if (consumableIds.includes(preProcessMaterial.materialId)) {
            item.isWarning = preProcessMaterial.quantityRemain < 0;
          }
        });
      }
    }
    if (!isDefault) {
      setListConsumableMaterials([...listConsumableMaterials]);
      setListSelectedMaterials([...newListSelected]);
    } else {
      setListConsumableMaterials([...listConsumables]);
    }
  };

  //calculate only when select new materials to pre-process
  const calculateDefaultMaterials = (newMaterials, newRecipe, newListConsumables, isRefresh = false, newListSelectedMaterialIds = []) => {
    newMaterials.forEach((material) => {
      let capacity = 1;
      if (isRefresh) {
        if (material?.currentUnitName != null) {
          let unit = material?.unitConversion?.find((u) => u.unitId === material?.currentUnitId);
          capacity = unit?.capacity ?? 1;
        }
      }
      calculateMaterials(
        isRefresh ? material?.quantityToPreprocess : 1,
        capacity,
        material.materialId,
        material,
        true,
        listSelectedMaterials?.length > 0,
        newMaterials,
        newRecipe,
        newListConsumables,
      );
    });
    let newListSelectedMaterials = isRefresh
      ? [...listSelectedMaterials]
      : [...listSelectedMaterials, ...newMaterials];
    newListSelectedMaterials = newListSelectedMaterials.filter(m => newListSelectedMaterialIds.includes(m.materialId));
    newListSelectedMaterials.forEach((item) => {
      const consumableItemIds = newRecipe[item.materialId].map(m => m.consumableMaterialId);
      const isWarning = newListConsumables.filter(m => consumableItemIds.includes(m.materialId)).some(z => z.quantityRemain < 0);
      item.isWarning = isWarning;
    });
    setListSelectedMaterials(newListSelectedMaterials);
  };

  const handleChangePreProcessQuantity = (value, index, materialId) => {
    if (listSelectedMaterials[index]?.currentUnitId) {
      calculateMaterials(
        value ?? 0,
        listSelectedMaterials[index]?.capacity ?? 1,
        materialId,
        listSelectedMaterials[index],
      );
    } else {
      calculateMaterials(value ?? 0, 1, materialId, listSelectedMaterials[index]);
    }
    listSelectedMaterials[index].quantityToPreprocess = value ?? "";
    form.setFieldValue(["preProcess", "selectedMaterials", index, "quantityToPreprocess"], value ?? "");
    form.validateFields([["preProcess", "selectedMaterials", index, "quantityToPreprocess"]]);
  };

  const handleChangeBranch = (value) => {
    form.setFieldValue("branchId", value);
    setListSelectedMaterials([]);
    setListConsumableMaterials([]);
  };

  const handleChangeUnit = (unitId, material, listUnit, index) => {
    if (unitId === material?.unitId) {
      let capacity = 1;
      // in case preProcess unit is not base unit of material, need to convert
      if (material?.preProcessUnitId && material?.unitId !== material?.preProcessUnitId) {
        let unitConversion = listUnit?.find((u) => u?.id === material?.preProcessUnitId);
        capacity = capacity / unitConversion?.capacity;
      }
      calculateMaterials(
        listSelectedMaterials[index]?.quantityToPreprocess ?? 0,
        capacity,
        material?.materialId,
        listSelectedMaterials[index],
      );
      listSelectedMaterials[index].currentUnitName = null;
      listSelectedMaterials[index].currentUnitId = unitId;
      listSelectedMaterials[index].capacity = capacity;
    } else {
      let unitConversion = listUnit?.find((u) => u?.id === unitId);
      listSelectedMaterials[index].currentUnitName = unitConversion?.name;
      listSelectedMaterials[index].currentUnitId = unitId;
      if (unitConversion?.capacity && unitConversion?.capacity !== 0) {
        let capacity = unitConversion?.capacity;
        // in case preProcess unit is not base unit of material, need to convert
        if (material?.preProcessUnitId && material?.preProcessUnitId === unitId) {
          capacity = 1;
        }
        calculateMaterials(
          listSelectedMaterials[index]?.quantityToPreprocess ?? 0,
          capacity,
          material?.materialId,
          listSelectedMaterials[index],
        );
        listSelectedMaterials[index].capacity = capacity;
      }
    }
  };

  const onRemoveMaterial = (material) => {
    let newListSelected = listSelectedMaterials.filter((x) => x.materialId !== material.materialId);
    let recipeConsumableIds = recipeMaterials[material.materialId].map((z) => z.consumableMaterialId);
    listSelectedMaterials
      .filter((z) => z.materialId !== material.materialId)
      .forEach((selected) => {
        recipeMaterials[selected.materialId]
          .map((m) => m.consumableMaterialId)
          .forEach((item) => {
            if (recipeConsumableIds.includes(item)) recipeConsumableIds = recipeConsumableIds.filter((c) => c !== item);
          });
      });
    calculateMaterials(0, material.capacity ?? 1, material.materialId, material);
    let newListConsumable = listConsumableMaterials.filter((item) => !recipeConsumableIds.includes(item.materialId));
    setListSelectedMaterials([...newListSelected]);
    setListConsumableMaterials([...newListConsumable]);
  };

  const onCancel = () => {
    navigateToManagementPage();
  };

  const changeForm = (e) => {
    if (e?.length > 0) {
      setIsChangeForm(true);
      setIsDisabled(false);
    }
  };

  const navigateToManagementPage = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push({
        pathname: "/inventory/transfer-control",
        search: `?tabActive=${KEY_TAB_PANE.VIEW_BY_PREPROCESS_MATERIAL}`,
      });
    }, 100);
  };

  const onFinish = async () => {
    form.validateFields().then(async () => {
      if (isSubmit) return;
      setIsSubmit(true);
      setIsChangeForm(true);
      setIsDisabled(true);
      if (listSelectedMaterials?.length === 0 || listConsumableMaterials?.length === 0) return;
      if (!visibleModalWarning && listConsumableMaterials?.some((material) => material.isWarning === true)) {
        setVisibleModalWarning(true);
        setIsSubmit(false);
        setIsDisabled(false);
        return;
      }
      let branch = branches.find((item) => item.id === form.getFieldValue("branchId"));
      const listMaterialToPreProcess = listSelectedMaterials.map((item) => {
        // have currentUnitName means that it's not base unit
        let baseQuantity = item?.quantityToPreprocess;
        if (item?.currentUnitName != null) {
          let unit = item?.unitConversion?.find((u) => u.unitId === item?.currentUnitId);
          baseQuantity = item?.quantityToPreprocess * unit?.capacity;
        }
        return {
          materialId: item.materialId,
          materialName: item.name,
          sku: item?.sku,
          unitId: item?.currentUnitId ?? item.unitId,
          unitName: item?.currentUnitName ?? item.unitName,
          preProcessQuantity: baseQuantity,
        };
      });
      const listMaterialConsumable = listConsumableMaterials.map((item) => {
        return {
          materialId: item.materialId,
          materialName: item.name,
          sku: item?.sku,
          unitId: item?.currentUnitId ?? item.unitId,
          unitName: item?.currentUnitName ?? item.unitName,
          materialType: item?.materialType,
          usedQuantity: item?.quantityUsed,
          availableQuantity: item?.inventoryQuantity,
          remainQuantity: item?.quantityRemain,
        };
      });
      const request = {
        branchId: branch?.id,
        branchName: branch?.name,
        listMaterialToPreProcess: listMaterialToPreProcess,
        listMaterialConsumable: listMaterialConsumable,
      };
      await preprocessMaterialDataService.preProcessMaterials(request).then((res) => {
        if (visibleModalWarning) setVisibleModalWarning(false);
        if (res?.isSuccess) {
          message.success(pageData.createdSuccessfully);
          navigateToManagementPage();
        } else {
          if (res?.message) {
            message.error(t(res?.message));
          } else {
            setVisibleModalNotEnough(true);
          }
          setIsSubmit(false);
          setIsDisabled(false);
        }
      });
    });
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.ingredientToPreProcess,
        dataIndex: "ingredients",
        width: "35%",
        align: "left",
        editable: true,
        render: (_, record, index) => {
          return (
            <Col style={{ flex: 1 }}>
              <Row style={{ gap: 12 }}>
                {record?.thumbnail ? (
                  <Thumbnail width={52} height={52} src={record?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={52} height={52} />
                )}
                <Col style={{ alignSelf: "center", flex: 1, gap: 6, maxWidth: "50%" }}>
                  <FnbTooltip title={record?.name} onlyShowWhenEllipsis></FnbTooltip>
                  {record?.sku && (
                    <Row>
                      <Typography style={{ fontWeight: 400, fontSize: 14, color: "#8B8899" }}>
                        SKU: {record?.sku}
                      </Typography>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          );
        },
      },
      {
        title: pageData.type,
        dataIndex: "type",
        align: "left",
        width: "20%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col>
                {record?.materialType === MaterialType.SemiFinished
                  ? t(MaterialTypeLocalization[MaterialType.SemiFinished])
                  : t(MaterialTypeLocalization[MaterialType.Raw])}
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.quantityToPreProcess,
        dataIndex: "quantityToPreProcess",
        align: "left",
        width: "20%",
        render: (_, record, index) => {
          return (
            <div className="error-select-quantity">
              <Form.Item
                rules={[
                  {
                    required: !record.quantityToPreprocess,
                    message: <InputValidateMessage id={"please-select-input"} message={pageData.errQuantity} />,
                  },
                  () => ({
                    validator(_, value) {
                      if (+value <= 0) {
                        return Promise.reject(
                          <InputValidateMessage id={"please-select-input"} message={pageData.errQuantity} />,
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                name={["preProcess", "selectedMaterials", index, "quantityToPreprocess"]}
              >
                <FnbInputNumber
                  min={MinimumNumber}
                  value={record?.quantityToPreprocess}
                  onChange={(value) => {
                    handleChangePreProcessQuantity(value, index, record?.materialId);
                  }}
                  showWrap={true}
                  formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => parserDecimalNumber(value, true)}
                  onKeyPress={(event) => {
                    if (!isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                  className={record?.isWarning ? "input-warning" : ""}
                />
                {record?.isWarning === true && (
                  <InputValidateMessage
                    type={EnumInputValidateType.WARRING}
                    id={"please-select-input"}
                    message={pageData.warningQuantity}
                  />
                )}
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: pageData.unit,
        dataIndex: "unit",
        align: "left",
        width: "20%",
        render: (_, record, index) => {
          let mapUnitConversions = record?.unitConversion?.map((unit) => {
            return {
              id: unit.unitId,
              name: unit.unitName,
              capacity: unit.capacity,
            };
          });

          let unitConversion = [{ id: record?.unitId, name: record?.unitName }, ...mapUnitConversions];
          return (
            <FnbSelectSingle
              getPopupContainer={(trigger) => trigger?.parentNode?.parentNode?.parentNode}
              value={record?.currentUnitId ?? (record?.preProcessUnitId ?? unitConversion[0]?.id)}
              option={unitConversion}
              onChange={(unitId) => {
                handleChangeUnit(unitId, record, unitConversion, index);
              }}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        align: "left",
        width: "5%",
        render: (_, record, index) => (
          <div
            className="icon-delete-price pt-3"
            onClick={() => {
              onRemoveMaterial(record);
            }}
          >
            <CustomTrashIcon color="#50429B" />
          </div>
        ),
      },
    ];
    return columns;
  };

  const columnsConsumableMaterial = () => {
    let columns = [
      {
        title: pageData.consumableIngredient,
        dataIndex: "consumableIngredients",
        width: "25%",
        align: "left",
        editable: true,
        render: (_, record) => {
          return (
            <Col style={{ flex: 1 }} className={record?.isWarning ? "warning-col" : ""}>
              <Row style={{ gap: 12 }}>
                {record?.thumbnail ? (
                  <Thumbnail width={52} height={52} src={record?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={52} height={52} />
                )}
                <Col style={{ alignSelf: "center", flex: 1, gap: 6, maxWidth: "50%" }}>
                  <FnbTooltip title={record?.name} onlyShowWhenEllipsis></FnbTooltip>
                  {record?.sku && (
                    <Row>
                      <Typography
                        style={{ fontWeight: 400, fontSize: 14, color: record?.isWarning ? "#D0810B" : "#8B8899" }}
                      >
                        SKU: {record?.sku}
                      </Typography>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          );
        },
      },
      {
        title: pageData.type,
        dataIndex: "type",
        align: "left",
        width: "21%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col className={record?.isWarning ? "warning-col" : ""}>
                {record?.materialType === MaterialType.SemiFinished
                  ? t(MaterialTypeLocalization[MaterialType.SemiFinished])
                  : t(MaterialTypeLocalization[MaterialType.Raw])}
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.quantityUsed,
        dataIndex: "quantityUsed",
        align: "left",
        width: "18%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col className={record?.isWarning ? "warning-col" : ""}>
                <FnbTooltip
                  title={`${formatNumberDecimalOrInteger(record?.quantityUsed ?? 0)} (${record?.unitName})`}
                  onlyShowWhenEllipsis
                >
                  {formatNumberDecimalOrInteger(record?.quantityUsed ?? 0)} ({record?.unitName})
                </FnbTooltip>
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.quantityAvailable,
        dataIndex: "available",
        align: "left",
        width: "18%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col className={record?.isWarning ? "warning-col" : ""}>
                <FnbTooltip
                  title={`${formatNumberDecimalOrInteger(record?.inventoryQuantity ?? 0)} (${record?.unitName})`}
                  onlyShowWhenEllipsis
                >
                  {formatNumberDecimalOrInteger(record?.inventoryQuantity ?? 0)} ({record?.unitName})
                </FnbTooltip>
              </Col>
            </Row>
          );
        },
      },
      {
        title: pageData.quantityRemain,
        dataIndex: "remain",
        align: "left",
        width: "18%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col className={record?.isWarning ? "warning-col" : ""}>
                <FnbTooltip
                  title={`${formatNumberDecimalOrInteger(
                    record?.isWarning ? 0 : record?.quantityRemain ?? record?.inventoryQuantity,
                  )} (${record?.unitName})`}
                  onlyShowWhenEllipsis
                >
                  {formatNumberDecimalOrInteger(
                    record?.isWarning ? 0 : record?.quantityRemain ?? record?.inventoryQuantity,
                  )}{" "}
                  ({record?.unitName})
                </FnbTooltip>
              </Col>
            </Row>
          );
        },
      },
    ];
    return columns;
  };

  const customEmptyData = () => {
    return (
      <div className="no-data-found">
        <p className="text-center">
          <FolderIcon />
        </p>
        <p className="text-center body-2 text-no-data">
          <div className="empty-text-table">
            <p className="text-center body-2 mb-2">{pageData.noDataFound}</p>
          </div>
        </p>
      </div>
    );
  };

  const onSelectedMaterials = async (listMaterials, isRefresh = false) => {
    const selectedIds = isRefresh ? [] : listSelectedMaterials.map((z) => z.materialId);
    const newSelectedMaterialIds = listMaterials.map((z) => z.materialId);
    //remove materials and their recipe which not selected now
    const removeMaterials = listSelectedMaterials.filter((m) => !newSelectedMaterialIds.includes(m.materialId));
    //only get new materials not exist in selected materials and their recipe
    let newMaterials = listMaterials.filter((m) => !selectedIds.includes(m.materialId));
    if (!isRefresh) {
      removeMaterials.forEach((material) => {
        onRemoveMaterial(material);
      });
      newMaterials.forEach((material) => {
        material.quantityToPreprocess = 1;
      });
    } else {
      newMaterials = [...listSelectedMaterials];
    }
    const ids = newMaterials.map((z) => z.materialId);
    if (ids.length <= 0) {
      // means that only remove selected materials
      const restMaterials = listSelectedMaterials.filter((m) => newSelectedMaterialIds.includes(m.materialId));
      setListSelectedMaterials([...restMaterials]);
      const restConsumableMaterials = listConsumableMaterials.filter(material => material.quantityUsed !== 0);
      setListConsumableMaterials([...restConsumableMaterials]);
      return;
    }
    const request = {
      branchId: form.getFieldValue("branchId"),
      materialIds: ids,
    };
    const data = await materialDataService.getConsumableMaterialsByMaterialIdsAsync(request);
    if (data) {
      //only get new consumable materials not exist in consumable materials
      const selectedConsumableIds = listConsumableMaterials?.map((z) => z.materialId);
      const newListConsumablesNotExist = data?.listConsumableMaterials?.filter(
        (m) => !selectedConsumableIds.includes(m.materialId),
      );
      let newListConsumables = isRefresh
        ? data?.listConsumableMaterials
        : listConsumableMaterials?.concat(newListConsumablesNotExist ?? data?.listConsumableMaterials);
      const newListRecipes = isRefresh
        ? { ...data?.consumableMaterialRecipes }
        : { ...recipeMaterials, ...data?.consumableMaterialRecipes };
      setRecipeMaterials(newListRecipes);
      calculateDefaultMaterials(newMaterials, newListRecipes, newListConsumables, isRefresh, newSelectedMaterialIds);
      newListConsumables = newListConsumables.filter(m => m?.quantityUsed !== 0);
      setListConsumableMaterials(newListConsumables);
    }
  };

  const handleRefresh = () => {
    setVisibleModalNotEnough(false);
    onSelectedMaterials(listSelectedMaterials, true);
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 22,
        }}
        className="pre-process-ingredients-form custom-form"
        layout="vertical"
      >
        <FnbHeadingPage
          title={pageData.preProcessIngredient}
          isCompactButtons={false}
          listButtons={[
            <FnbCancelButton showWarning={isChangeForm} onOk={onCancel} />,
            <FnbAddNewButton
              onClick={(e) => onFinish(e)}
              className="float-right"
              type="primary"
              text={pageData.preProcess}
              customIcon={<PreProcessIcon />}
              permission={PermissionKeys.PREPROCESS_MATERIAL}
              disabled={isDisabled}
            />,
          ]}
        />
        <div className="col-input-full-width">
          <Row align="middle">
            <FnbCard className="w-100" title={pageData.generalInformation} underlineTitle>
              <Row gutter={16} align="middle">
                <Col className="gutter-row mb-28" span={24}>
                  <Form.Item
                    name={["preProcess", "branchId"]}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage id={"please-select-brach"} message={pageData.pleaseSelectBranch} />
                        ),
                      },
                    ]}
                    label={pageData.branch}
                  >
                    <FnbSelectAddNewItem
                      isShowSearchIcon
                      mode="single"
                      placeholder={pageData.placeholderBranch}
                      options={branches?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      showSearch
                      allowClear
                      fieldItemName={pageData.branch.toLowerCase()}
                      onAddNewItem={onAddNewBranch}
                      onChange={handleChangeBranch}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24} className="button-show-search-modal">
                  <div
                    onClick={() => {
                      if (form.getFieldValue("branchId")) {
                        setIsOpenModal(true);
                      } else {
                        form.validateFields([["preProcess", "branchId"]]);
                      }
                    }}
                  >
                    <Form.Item
                      className="w-100"
                      name={["preProcess", listSelectedMaterials]}
                      rules={[
                        {
                          required: listSelectedMaterials?.length <= 0,
                          message: (
                            <InputValidateMessage id={"please-select-materials"} message={pageData.errIngredient} />
                          ),
                        },
                      ]}
                      label={pageData.ingredientSearch}
                      required={false}
                    >
                      <FnbInput
                        id={"btn-search-ingredient"}
                        className="fnb-input"
                        size="large"
                        placeholder={pageData.ingredientPlaceholder}
                        prefix={<SearchIcon />}
                        type={TYPE_INPUT.BUTTON}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <FnbTable
                  className="selected-pre-process-materials-table"
                  columns={columnsMaterial()}
                  dataSource={listSelectedMaterials}
                  total={listSelectedMaterials?.length}
                  numberRecordCurrent={listSelectedMaterials?.length}
                  showPaging={false}
                  scroll={listSelectedMaterials?.length > 5 ? { x: 1200, y: 86 * 5 } : { x: 1200 }}
                  customEmptyData={customEmptyData()}
                />
              </Row>
            </FnbCard>
          </Row>
        </div>
        {listConsumableMaterials?.length > 0 && (
          <div className="col-input-full-width mt-3">
            <Row align="middle">
              <FnbCard className="w-100" title={pageData.consumableIngredientUsed} underlineTitle>
                <FnbTable
                  className="selected-consumable-materials-table"
                  columns={columnsConsumableMaterial()}
                  dataSource={listConsumableMaterials}
                  total={listConsumableMaterials?.length}
                  numberRecordCurrent={listConsumableMaterials?.length}
                  showPaging={false}
                  scroll={listConsumableMaterials?.length > 5 ? { x: 1200, y: 86 * 5 } : { x: 1200 }}
                />
              </FnbCard>
            </Row>
          </div>
        )}
      </Form>
      <ConfirmDialogComponent visible={visibleModalLeaveConfirm} onCancel={leaveOnCancel} onOk={leaveOnOk} />
      <ConfirmDialogComponent
        visible={visibleModalWarning}
        onCancel={leaveOnCancel}
        onOk={() => onFinish()}
        title={pageData.warningTitle}
        content={pageData.warningContent}
        cancelText={pageData.discardBtn}
        okText={pageData.preProcess}
        centered
        closable={false}
      />
      <ConfirmDialogComponent
        visible={visibleModalNotEnough}
        type={ConfirmStyle.NOTIFICATION}
        centered
        onOk={handleRefresh}
        okText={pageData.refresh}
        contentElement={<span dangerouslySetInnerHTML={{ __html: pageData.notMatchContent }}></span>}
      />
      <IngredientSearchModal
        visible={isOpenModal}
        handleCancel={() => setIsOpenModal(false)}
        listSelectedMaterials={listSelectedMaterials}
        setListSelectedMaterials={setListSelectedMaterials}
        branchId={form.getFieldValue("branchId")}
        modalType={IngredientSearchModalType.TYPE_INGREDIENT}
        afterSelected={onSelectedMaterials}
      />
    </>
  );
}
