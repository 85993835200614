import { FnbCancelButton } from "components/cancel-button";
import { FnbEditButton } from "components/fnb-edit-button/fnb-edit-button.component";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Image, Tooltip } from "antd";
import FnbSwitch from "components/switch";
import { InfoCircleBasicIcon } from "constants/icons.constants";
import linkImage from "../../../../assets/images/advanced-setting-switch-tooltip.png";
import "./form-section.component.scss";
const FormSectionAdvancedSetting = ({ title, description, fields, onSave, onCancel, initialValues, switchTitle }) => {
  const [values, setValues] = useState(initialValues);
  const [isFirstTimeLoad, setIsFirstLoad] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [t] = useTranslation();
  const pageData = {
    saveChanges: t("onlineStore.saveChanges"),
    pleaseEnterYourMessegerAccountID: t("onlineStore.advancedSettings.messeger.pleaseEnterYourMessegerAccountID"),
    pleaseEnterYourZaloAccountID: t("onlineStore.advancedSettings.zalo.pleaseEnterYourZaloAccountID"),
    hotlineRequried: t("onlineStore.advancedSettings.hotline.hotlineRequried"),
  };

  const handleChange = (e, field) => {
    const newValues = { ...values, [field]: e.target.value };
    setValues(newValues);
    setHasChanges(JSON.stringify(newValues) !== JSON.stringify(initialValues));

    //Handle Validate Value Input
    if (values?.switchStatus) {
      const { value } = e.target;
      // Validate and set error state
      if (value.trim() === "") {
        if (field == "zaloId") {
          setErrors({ ...errors, [field]: t(pageData.pleaseEnterYourZaloAccountID) });
        } else if (field == "messegerId") {
          setErrors({ ...errors, [field]: t(pageData.pleaseEnterYourMessegerAccountID) });
        } else if (field == "hotlineNumber") {
          setErrors({ ...errors, [field]: t(pageData.hotlineRequried) });
        } else {
          setErrors({ ...errors, [field]: undefined });
        }
      } else {
        setErrors({ ...errors, [field]: undefined });
      }
    }
  };

  const handleSwitch = (e, field, fields) => {
    const newValues = { ...values, [field]: e };
    setValues(newValues);
    setHasChanges(JSON.stringify(newValues) !== JSON.stringify(initialValues));

    //Handle validate input when Switch turn on!
    if (newValues?.switchStatus == false) {
      setErrors({});
    } else {
      if (values?.messegerId == "") {
        setErrors({ ...errors, [fields[0]?.name]: t(pageData.pleaseEnterYourMessegerAccountID) });
      } else if (values?.zaloId == "") {
        setErrors({ ...errors, [fields[0]?.name]: t(pageData.pleaseEnterYourZaloAccountID) });
      } else if (values?.hotlineNumber == "") {
        setErrors({ ...errors, [fields[0]?.name]: t(pageData.hotlineRequried) });
      }
    }
  };

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isFirstTimeLoad && initialValues) {
      setValues(initialValues);
      setIsFirstLoad(false);
    }
  }, [initialValues]);

  const handleSave = () => {
    onSave(values);
    setHasChanges(!hasChanges);
  };

  const handleCancel = () => {
    setValues(initialValues);
    setHasChanges(!hasChanges);
    setErrors({});
  };

  return (
    <div className="form-section">
      <p className="title">{title}</p>
      <p className="content">{description}</p>
      <div className="underline"></div>
      {values?.switchStatus === false || values?.switchStatus === true ? (
        <div className="switch">
          <FnbSwitch
            checked={values?.switchStatus}
            onChange={(e) => {
              handleSwitch(e, "switchStatus", fields);
            }}
          />
          <span>
            {switchTitle}
            <Tooltip
              overlayClassName="advanced-setting-tooltip"
              title={
                <div className="custom-tool-tip">
                  <Image src={linkImage} preview={false} />
                </div>
              }
              arrow={true}
            >
              <InfoCircleBasicIcon className="image-tooltip" />
            </Tooltip>
          </span>
        </div>
      ) : (
        ""
      )}
      <div className={"form-group " + (fields.length === 1 ? "column" : "row")}>
        {fields?.map((field) => {
          return (
            <div
              className="item-group"
              key={field?.name}
              style={{ flex: fields?.length === 1 ? "1 1 100%" : "1 1 48%" }}
            >
              <label className="label">{field?.label}</label>
              <input
                className={`input ${errors[field?.name] ? "error" : ""}`}
                type="text"
                value={values != undefined ? values[field?.name] : null}
                onChange={(e) => handleChange(e, field?.name)}
                placeholder={field?.placeholder}
                maxLength={field?.maxLength || 100}
              />
              {errors[field.name] && <p className="error-message">{errors[field.name]}</p>}
            </div>
          );
        })}
      </div>

      {hasChanges && (
        <div className="form-actions">
          <FnbCancelButton onOk={() => handleCancel()} />
          <FnbEditButton
            disabled={errors[fields[0]?.name]}
            onClick={() => {
              handleSave();
            }}
            text={pageData.saveChanges}
          />
        </div>
      )}
    </div>
  );
};

export default FormSectionAdvancedSetting;
