import { useEffect, useState } from "react";
import { Form, message, Space } from "antd";
import FnbSelectMaterialComponent from "components/fnb-select-material/fnb-select-material";
import "../index.scss";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { formatNumberDecimalOrInteger, getValidationMessages } from "utils/helpers";
import { Thumbnail } from "components/thumbnail/thumbnail";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";

export default function FormNewManagementCategoryComponent(props) {
  const { t, showAddNewCategoryForm, onCancel, materialCategoryDataService, onCompleted, initialValues } = props;
  const [form] = Form.useForm();
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [listMaterial, setListMaterial] = useState([]);
  const [isSelectedAllMaterials, setIsSelectedAllMaterials] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    btnAdd: t("button.add"),
    leaveForm: t("messages.leaveForm"),
    createMaterialCategorySuccess: t("messages.createMaterialCategorySuccess"),
    materialCategory: {
      create: t("materialCategory.create"),
      nameCategory: t("materialCategory.nameCategory"),
      enterCategoryName: t("materialCategory.enterCategoryName"),
      pleaseEnterNameCategory: t("materialCategory.pleaseEnterNameCategory"),

      nameCategoryExisted: t("materialCategory.nameCategoryExisted"),
    },
    selectMaterial: {
      title: t("materialCategory.selectMaterial"),
      table: {
        name: t("table.name"),
        quantity: t("table.quantity"),
        unit: t("table.unit"),
        action: t("table.action"),
      },
    },
    allMaterials: t("materialCategory.allMaterials"),
  };

  useEffect(() => {
    props.func.current = setInitData;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    form.setFieldsValue(initialValues);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const setInitData = (data) => {
    const { listMaterial } = data;
    setListMaterial(listMaterial);
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      let materialIds = [];
      if (!isSelectedAllMaterials) {
        // eslint-disable-next-line array-callback-return
        selectedMaterials.map((item) => {
          materialIds.push(item.id);
        });
      } else {
        // eslint-disable-next-line array-callback-return
        listMaterial.map((item) => {
          materialIds.push(item.id);
        });
      }

      const createCategoryRequest = {
        ...values,
        materialIds: materialIds,
      };

      materialCategoryDataService
        .createMaterialCategoryAsync(createCategoryRequest)
        .then((res) => {
          if (res?.isSuccess) {
            form.resetFields();
            message.success(pageData.createMaterialCategorySuccess);
            setSelectedMaterials([]);
            setListMaterial([]);
            setIsSelectedAllMaterials(false);
            onCompleted(res?.id);
          }
        })
        .catch((errs) => {
          form.setFields(getValidationMessages(errs));
        });
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setSelectedMaterials([]);
    setListMaterial([]);
    setIsSelectedAllMaterials(false);
    onCancel();
  };

  const onSelectMaterial = (id) => {
    let selectedMaterial = listMaterial?.find((m) => m.id === id);
    onSelectItemMaterial(id);
    setSelectedMaterials([...selectedMaterials, selectedMaterial]);
  };

  const onSelectItemMaterial = (id) => {
    let materials = listMaterial.filter((m) => m.id !== id);
    setListMaterial(materials);
  };

  const removeItemMaterial = (id) => {
    let itemRemove = selectedMaterials.find((m) => m.id === id);
    let listSelectedMaterial = selectedMaterials.filter((m) => m.id !== id);
    setSelectedMaterials(listSelectedMaterial);
    removeMaterial(itemRemove);
  };

  const removeMaterial = (itemRemove) => {
    setListMaterial([...listMaterial, itemRemove]);
  };

  const onChangeOption = (e) => {
    const isChecked = e.target.checked;
    setIsSelectedAllMaterials(isChecked);
  };

  const onSelectedRowKeysChange = (keySelected) => {
    setSelectedRowKeys(keySelected);
  };

  const onDeleteRowKeys = (_, selectedRows) => {
    removeItemMaterial(selectedRows[0]?.id)
    setSelectedRowKeys([]);
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.selectMaterial.table.name,
        dataIndex: "name",
        key: "name",
        width: "50%",
        render: (_, record) => (
          <Space size={12}>
            <Thumbnail src={record?.thumbnail} width={48} height={48} />
            <FnbTooltip
              title={record?.name}
              placement="topLeft"
              onlyShowWhenEllipsis
              maxWidthContent={"280px"}
              width={"300px"}
            >
              <FnbTypography text={record?.name} />
            </FnbTooltip>
          </Space>
        ),
      },
      {
        title: pageData.selectMaterial.table.quantity,
        dataIndex: "quantity",
        key: "quantity",
        width: "30%",
        render: (val) => <FnbTypography text={val ? formatNumberDecimalOrInteger(val) : "-"} />,
      },
      {
        title: pageData.selectMaterial.table.unit,
        dataIndex: "unitName",
        key: "unitName",
        width: "20%",
        render: (val) => <FnbTypography text={val ?? "-"} />,
      },
    ];
    return columns;
  };

  const renderContent = () => {
    return (
      <Form form={form} name="basic" onFinish={onFinish} autoComplete="off" layout="vertical" className="custom-form">
        <Space className="w-100" direction="vertical" size={24}>
          <Form.Item
            label={pageData.materialCategory.nameCategory}
            name="name"
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.materialCategory.pleaseEnterNameCategory} />,
              },
            ]}
          >
            <FnbInput showCount maxLength={100} placeholder={pageData.materialCategory.enterCategoryName} />
          </Form.Item>

          <Space direction="vertical" size={!isSelectedAllMaterials ? 8 : 3} className="w-100">
            <div className="w-100 d-flex justify-space-between">
              <FnbTypography text={pageData.selectMaterial.title} variant={theme.typography["b1-medium"]} />
              <FnbCheckBox onChange={(event) => onChangeOption(event)} checked={isSelectedAllMaterials}>
                {pageData.allMaterials}
              </FnbCheckBox>
            </div>
            <Form.Item hidden={isSelectedAllMaterials}>
              <FnbSelectMaterialComponent
                t={t}
                materialList={listMaterial}
                onChangeEvent={(value) => onSelectMaterial(value)}
                isAllBranch={true}
              />
            </Form.Item>
            <Form.Item hidden={!isSelectedAllMaterials}>
              <FnbSelectSingle disabled={true} />
            </Form.Item>
          </Space>

          {selectedMaterials.length > 0 && !isSelectedAllMaterials && (
            <div style={{ marginTop: "-24px" }}>
              <FnbTable
                dataSource={selectedMaterials}
                columns={columnsMaterial()}
                total={selectedMaterials.length}
                scrollY={75 * 6}
                scrollX={600}
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectedRowKeysChange,
                  onDelete: onDeleteRowKeys,
                }}
              />
            </div>
          )}
        </Space>
      </Form>
    );
  };

  return (
    <FnbModal
      width={"800px"}
      className="wrapper-create-ingredient-category"
      title={pageData.materialCategory.create}
      visible={showAddNewCategoryForm}
      handleCancel={handleCancel}
      cancelText={pageData.btnCancel}
      okText={pageData.btnAdd}
      onOk={onFinish}
      content={renderContent()}
      centered={false}
      zIndex={1100}
    />
  );
}
