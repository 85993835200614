import { Button, Card, Col, Form, message, Row, Typography } from "antd";
import ActionButtonGroup from "components/action-button-group/action-button-group.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDeleteIcon } from "components/fnb-delete-icon/fnb-delete-icon";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { IngredientSearchModal, IngredientSearchModalType } from "components/ingredient-search-modal/ingredient-search-modal";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";
import PageTitle from "components/page-title";
import { DELAYED_TIME, tableSettings } from "constants/default.constants";
import { SearchIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { currency } from "constants/string.constants";
import { useEffect, useState } from "react";
import {
  checkOnKeyPressValidation,
  formatCurrency,
  formatterDecimalNumber,
  isDecimalKey,
  parserDecimalNumber,
  getCurrency,
} from "utils/helpers";
const { Text } = Typography;

export default function UpdatePurchaseOrderComponent(props) {
  const { t, storeDataService, purchaseOrderDataService, unitConversionDataService, match, history } = props;

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    createPurchaseOrderSuccess: t("messages.createPurchaseOrderSuccess"),
    updatePurchaseOrderSuccess: t("messages.updatePurchaseOrderSuccess"),
    createPurchaseOrder: t("purchaseOrder.createPurchaseOrder"),
    updatePurchaseOrder: t("purchaseOrder.updatePurchaseOrder"),
    materialInformation: t("purchaseOrder.materialInformation"),
    searchMaterial: t("purchaseOrder.searchMaterial"),
    pleaseSelectMaterial: t("material.pleaseSelectMaterial"),
    note: t("form.note"),
    selectBranch: t("staffManagement.permission.selectBranch"),
    pleaseSelectBranch: t("staffManagement.permission.pleaseSelectBranch"),
    pleaseEnterQuantity: t("inventoryTracking.pleaseEnterQuantity"),
    supplier: {
      title: t("supplier.title"),
      selectSupplier: t("supplier.selectSupplier"),
      pleaseSelectSupplier: t("supplier.pleaseSelectSupplier"),
    },
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      materialName: t("table.materialName"),
      material: t("material.title"),
      quantity: t("table.quantity"),
      importUnit: t("table.importUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseEnterNameUnit"),
      unitPlaceholder: t("productManagement.unit.unitPlaceholder"),
      importPrice: t("table.importPrice"),
      total: t("table.total"),
      totalCost: t("table.totalCost"),
      action: t("table.action"),
      inventory: t("table.inventory"),
      branchName: t("table.branchName"),
    },
    generalInformation: t("purchaseOrder.generalInformation"),
    destination: t("purchaseOrder.destinationLabel"),
    btnUpdate: t("button.update"),
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    pleaseImportPrice: t("purchaseOrder.pleaseImportPrice"),
    pleaseEnterMax1e9: t("messages.valueShouldBeFrom0to999999999"),
    ingredientPlaceholder: t("productManagement.recipe.placeholder"),
  };

  const [form] = Form.useForm();
  const [purchaseOrderId, setPurchaseOrderId] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [initDataMaterials, setInitDataMaterials] = useState([]);
  const [dataSelectedMaterial, setDataSelectedMaterial] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [pageNumber, setPageNumber] = useState(tableSettings.page);
  const [codePurchaseOrder, setCodePurchaseOrder] = useState("");
  const [selectedWarehouseId, setSelectedWarehouseId] = useState();
  const [showModalSearchIngredient, setShowModalSearchIngredient] = useState(false);

  useEffect(() => {
    setPurchaseOrderId(match?.params?.id);
    if (match?.params?.id) {
      purchaseOrderDataService.getPurchaseOrderByIdAsync(match?.params?.id).then((res) => {
        if (res && res.purchaseOrder) {
          setCodePurchaseOrder(res?.purchaseOrder?.code);
          fetchInitData(res, res.purchaseOrder.purchaseOrderMaterials);
          mappingFormEditPurchaseOrder(res.purchaseOrder);
        }
      });
    }
  }, []);

  const fetchInitData = async (res, purchaseOrderMaterials) => {
    const response = await purchaseOrderDataService.getPurchaseOrderPrepareDataAsync(res?.purchaseOrder?.storeBranchId);
    if (response) {
      const { suppliers, branches, materials } = response;
      setSuppliers(suppliers);
      setBranches(branches);
      getMaterialsUpdate(materials, purchaseOrderMaterials);
      setSelectedWarehouseId(res?.purchaseOrder?.storeBranchId);
    }
  };

  const mappingFormEditPurchaseOrder = (data) => {
    let listData = [];
    let listDataUpdate = [];
    data?.purchaseOrderMaterials?.map((item, index) => {
      const restMaterials = materials.filter((o) => o.id !== item.materialId);
      const newRow = mappingToDataTableForEdit(item, index);
      listData.push(newRow);
      setMaterials(restMaterials);

      listDataUpdate.push({
        unit: newRow?.unitId,
        quantity: item?.quantity,
        price: item?.price,
      });
    });
    setDataSelectedMaterial(listData);

    let formValue = {
      purchaseOrder: {
        supplierId: data?.supplierId,
        branchId: data?.storeBranchId,
        note: data?.note,
        selectedMaterials: listDataUpdate,
      },
    };

    form.setFieldsValue(formValue);
  };

  const mappingToDataTableForEdit = (item, index) => {
    let units = [];
    item.unitConversionUnits?.map((uc) => {
      let unit = {
        id: uc?.unitId,
        name: uc?.unit?.name,
      };
      units.push(unit);
    });
    units.unshift(item.material.unit);
    return {
      id: item.materialId,
      no: index + 1,
      sku: item?.material?.sku,
      material: item?.material?.name,
      quantity: item?.quantity,
      unitId: item?.unitId,
      price: item?.price,
      total: item?.total,
      unitConversionUnits: units,
      materialId: item.materialId,
    };
  };

  const getMaterialsUpdate = async (materials, purchaseOrderMaterials) => {
    setInitDataMaterials(materials);
    const materialsUpdate = materials.filter(
      (x) => purchaseOrderMaterials.filter((y) => y.materialId === x.id).length === 0,
    );
    setMaterials(materialsUpdate);
  };

  const onFinish = async (values) => {
    if (dataSelectedMaterial.length > 0) {
      const createPurchaseOrderRequestModel = {
        ...values.purchaseOrder,
        materials: dataSelectedMaterial,
      };
      let req = {
        id: purchaseOrderId,
        purchaseOrderDto: createPurchaseOrderRequestModel,
      };
      const res = await purchaseOrderDataService.updatePurchaseOrderByIdAsync(req);
      if (res) {
        message.success(pageData.updatePurchaseOrderSuccess);
        onCompleted(purchaseOrderId);
      }
    }
  };

  const onChangeWarehouse = async (warehouseId) => {
    const response = await purchaseOrderDataService.getPurchaseOrderPrepareDataAsync(warehouseId);
    const { materials } = response;
    setMaterials(materials);
    setInitDataMaterials(materials);
    setSelectedWarehouseId(warehouseId);
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "left",
        width: "5%",
        render: (_, record, index) => index + 1,
      },
      {
        title: pageData.table.sku,
        dataIndex: "sku",
        align: "left",
        width: "10%",
      },
      {
        title: pageData.table.materialName,
        dataIndex: "material",
        align: "left",
        width: "20%",
        render: (_, record) => {
          return (
            <FnbTypography.Link to={`/inventory/material/detail/${record.id}`} target="_blank" text={record?.material} />
          );
        },
      },
      {
        title: pageData.table.quantity,
        dataIndex: "quantity",
        width: "15%",
        align: "left",
        render: (_, record, index) => (
          <Row align="middle" justify="start">
            <Col span={24}>
              <Form.Item
                name={["purchaseOrder", "selectedMaterials", index, "quantity"]}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.pleaseEnterQuantity} />,
                  },
                ]}
                style={{ marginBottom: "unset" }}
              >
                <FnbInputNumber
                  key={record?.id}
                  onChange={(value) => onChangeRecord(index, "quantity", value)}
                  className="fnb-input quantity-material"
                  style={{ width: "95%" }}
                  formatter={(value) => formatterDecimalNumber(value)}
                  parser={(value) => parserDecimalNumber(value)}
                  onKeyPress={(event) => {
                    if (!isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ),
      },
      {
        title: pageData.table.importUnit,
        dataIndex: "unitId",
        width: "15%",
        align: "left",
        render: (_, record, index) => (
          <Form.Item
            name={["purchaseOrder", "selectedMaterials", index, "unit"]}
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.table.pleaseEnterNameUnit} />,
              },
            ]}
            style={{ display: "contents" }}
          >
            <FnbSelectSingle
              showSearch
              placeholder={pageData.table.unitPlaceholder}
              option={record?.unitConversionUnits?.map((item) => ({
                id: item?.id,
                name: item?.name,
              }))}
              defaultValue={record?.unitId}
              onChange={(value) => onSelectUnit(value, index)}
                value={record?.unitId}
              />
            </Form.Item>
        ),
      },
      {
        title: `${pageData.table.importPrice} (${getCurrency()})`,
        dataIndex: "price",
        align: "right",
        width: "20%",
        render: (_, record, index) => (
          <Row align="middle" justify="start">
            <Col span={24}>
              <Form.Item
                name={["purchaseOrder", "selectedMaterials", index, "price"]}
                rules={[
                  {
                    required: true,
                    message: pageData.pleaseImportPrice,
                  },
                  () => ({
                    validator(_, value) {
                      if (value && (value <= 0 || value >= 1e9)) {
                        return Promise.reject(
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pleaseEnterMax1e9}
                          />,
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FnbInputNumber
                  onChange={(value) => onChangeRecord(index, "price", value)}
                  style={{ width: "80%" }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  defaultValue={record.price}
                  min={getCurrency() === currency.vnd ? 1 : 0}
                  precision={getCurrency() === currency.vnd ? 0 : 2}
                  className="fnb-input input-material-price"
                  id={`purchaseOrder-selectedMaterials-${index}-price`}
                  onKeyPress={(event) => {
                    const checkStatus = checkOnKeyPressValidation(
                      event,
                      `purchaseOrder-selectedMaterials-${index}-price`,
                      0,
                      null,
                      getCurrency() === currency.vnd ? 0 : 2,
                    );
                    if (!checkStatus) event.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ),
      },
      {
        title: pageData.table.total,
        dataIndex: "total",
        align: "right",
        width: "10%",
        render: (_, record) => {
          return (
            <p>
              {record?.total?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </p>
          );
        },
      },
      {
        title: pageData.table.action,
        dataIndex: "action",
        align: "center",
        justify: "middle",
        width: "10%",
        render: (_, record) => (
          <Row align="center" justify="center">
            <FnbButton
              variant="tertiary"
              iconHeader={<FnbDeleteIcon />}
              onClick={() => onRemoveItemMaterial(record?.id)}
            />
          </Row>
        ),
      },
    ];
    return columns;
  };

  const handleSetDataSelectedMaterial = (listSelectedMaterials) => {
    if (listSelectedMaterials?.length === 0)
      return;
    let selectedIds = dataSelectedMaterial.map(z => z.id);
    let newMaterialIds = listSelectedMaterials.filter(item => !selectedIds.includes(item.materialId)).map(z => z.materialId);
    listSelectedMaterials.forEach((item, index) => {
      if (newMaterialIds.includes(item.materialId)) {
        item.quantity = 0;
        item.price = 0;
        item.total = 0;
        form.setFieldValue(["purchaseOrder", "selectedMaterials", index, "quantity"], 0);
        form.setFieldValue(["purchaseOrder", "selectedMaterials", index, "unit"], null);
        form.setFieldValue(["purchaseOrder", "selectedMaterials", index, "price"], 0);
        item.material = item.name;
        let unitConversions = item?.unitConversion.map(unit => {
          return {
            id: unit.unitId,
            name: unit.unitName,
            capacity: unit.capacity,
          };
        })
        item.unitConversionUnits = [{id: item?.unitId, name: item?.unitName}, ...unitConversions];
        item.id = item.materialId;
      }
    })
    setDataSelectedMaterial(listSelectedMaterials);
  }

  const onRemoveItemMaterial = (id) => {
    let restMaterials = dataSelectedMaterial.filter((o) => o.id !== id);
    setDataSelectedMaterial(restMaterials);

    restMaterials.map((material, index) => {
      form.setFieldValue(["purchaseOrder", "selectedMaterials", index, "quantity"], material?.quantity);
      form.setFieldValue(["purchaseOrder", "selectedMaterials", index, "unit"], material?.unitId);
    });

    let initDataMaterial = initDataMaterials.find((o) => o.id === id);
    materials.push(initDataMaterial);
  };

  const onChangeRecord = (index, column, value) => {
    changeForm();
    let changeRecord = dataSelectedMaterial[index];

    let quantity = column === "quantity" ? value : changeRecord.quantity;
    let price = column === "price" ? value : changeRecord.price;
    dataSelectedMaterial.map((item, i) => {
      if (i === index) {
        item.total = quantity * price;
        item.quantity = quantity;
        item.price = price;
      }
    });
    setDataSelectedMaterial([...dataSelectedMaterial]);
  };

  const onSelectUnit = (value, index) => {
    dataSelectedMaterial.map((item, i) => {
      if (i === index) {
        item.unitId = value;
      }
    });
    setDataSelectedMaterial([...dataSelectedMaterial]);
  };

  const onDiscard = () => {
    setShowConfirm(false);
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const onCompleted = (id) => {
    setIsChangeForm(false);
    setTimeout(() => {
      if (!id) return history.push(`/inventory/purchase-orders`);
      return history.push(`/inventory/detail-purchase-order/${id}`);
    }, DELAYED_TIME);
  };

  const onCancelNotChangeForm = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push(`/inventory/purchase-orders`);
    }, DELAYED_TIME);
  };

  const onCancel = () => {
    if (isChangeForm) {
      setShowConfirm(true);
    } else {
      setShowConfirm(false);
      onCancelNotChangeForm();
    }
  };

  const getTotalMaterials = () => {
    let total = dataSelectedMaterial.reduce((n, { total }) => n + total, 0).toFixed(2);
    return formatCurrency(total);
  };

  return (
    <>
      <Form form={form} name="basic" onFinish={onFinish} autoComplete="off" onFieldsChange={(e) => changeForm(e)}>
        <Row className="fnb-row-page-header">
          <Col span={12} xs={24} sm={24} md={24} lg={12} className="link">
            <p className="card-header">
              <PageTitle className="text-transform-upper" content={`#${codePurchaseOrder}`} />
            </p>
          </Col>
          <Col span={12} xs={24} sm={24} md={24} lg={12}>
            <ActionButtonGroup
              arrayButton={[
                {
                  action: <FnbAddNewButton type="primary" text={pageData.btnUpdate} htmlType="submit" />,
                  permission: PermissionKeys.EDIT_PURCHASE,
                },
                {
                  action: (
                    <Button htmlType="button" onClick={() => onCancel()} className="action-cancel">
                      {pageData.btnCancel}
                    </Button>
                  ),
                  permission: null,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Card className="w-100 mb-3 fnb-card h-auto">
            <Row gutter={16} align="middle">
              <Col span={24}>
                <h4 className="title-group">{pageData.generalInformation}</h4>
              </Col>
              <Col className="gutter-row" span={12} md={12} sm={24} xs={24}>
                <h4 className="fnb-form-label">
                  {pageData.supplier.title}
                  <span className="text-danger">*</span>
                </h4>
                <Form.Item
                  name={["purchaseOrder", "supplierId"]}
                  rules={[
                    {
                      required: true,
                      message: pageData.supplier.pleaseSelectSupplier,
                    },
                  ]}
                >
                  <FnbSelectSingle
                    placeholder={pageData.supplier.selectSupplier}
                    showSearch
                    option={suppliers?.map((b) => ({
                      id: b.id,
                      name: b.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12} md={12} sm={24} xl={12} xs={24}>
                <h4 className="fnb-form-label">
                  {pageData.destination}
                  <span className="text-danger">*</span>
                </h4>
                <Form.Item
                  name={["purchaseOrder", "branchId"]}
                  rules={[
                    {
                      required: true,
                      message: pageData.pleaseSelectBranch,
                    },
                  ]}
                >
                  <FnbSelectSingle
                    showSearch
                    placeholder={pageData.selectBranch}
                    option={branches?.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }))}
                    onChange={(value) => onChangeWarehouse(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align="middle">
              <Col span={24}>
                <h4 className="fnb-form-label">
                  {pageData.note}
                  <span className="text-danger"></span>
                </h4>
                <Form.Item name={["purchaseOrder", "note"]}>
                  <FnbTextArea
                    showCount
                    maxLength={255}
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    placeholder={pageData.freeTextArea}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row className="display-contents">
          <Card className="mt-1 fnb-card h-auto">
            <Row>
              <Col span={24}>
                <h4 className="title-group">{pageData.materialInformation}</h4>
                <div onClick={() => setShowModalSearchIngredient(true)}>
                  <Form.Item className="w-100">
                    <FnbInput
                      id={"btn-search-ingredient"}
                      className="fnb-input"
                      size="large"
                      placeholder={pageData.ingredientPlaceholder}
                      prefix={<SearchIcon />}
                      type={TYPE_INPUT.BUTTON}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FnbTable
                  tableId="material-list"
                  className="mt-4"
                  columns={columnsMaterial()}
                  pageSize={tableSettings.pageSize}
                  dataSource={dataSelectedMaterial}
                  currentPageNumber={pageNumber}
                  total={dataSelectedMaterial.length}
                />
                {dataSelectedMaterial.length === 0 && <Text type="danger">{pageData.pleaseSelectMaterial}</Text>}
              </Col>
            </Row>
            <br />
            <Row justify="end" align="middle" className="h-bg-brad-class">
              <Col xs={0} sm={0} md={0} lg={14}></Col>
              <Col xs={24} sm={24} md={24} lg={10}>
                <Row align="middle">
                  <Col span={24} className="pl20">
                    <Row gutter={16}>
                      <Col span={24} className="fz20 text-center">
                        <Text className="float-left">{pageData.table.quantity}: </Text>
                        <span className="float-right pr10">
                          <span className="fwb mr10">{dataSelectedMaterial.length}</span>
                          {pageData.table.material}
                        </span>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col span={24} className="fz26 text-center">
                        <Text className="float-left" strong>
                          {pageData.table.totalCost}:
                        </Text>
                        <span className="float-right pr10">{getTotalMaterials()}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Row>
      </Form>
      <DeleteConfirmComponent
        title={pageData.leaveDialog.confirmation}
        content={pageData.leaveDialog.content}
        visible={showConfirm}
        skipPermission={true}
        cancelText={pageData.discardBtn}
        okText={pageData.confirmLeaveBtn}
        onCancel={onDiscard}
        onOk={onCompleted}
        isChangeForm={isChangeForm}
      />
      <IngredientSearchModal
        visible={showModalSearchIngredient}
        handleCancel={() => setShowModalSearchIngredient(false)}
        listSelectedMaterials={dataSelectedMaterial}
        setListSelectedMaterials={handleSetDataSelectedMaterial}
        modalType={IngredientSearchModalType.TYPE_INGREDIENT}
        loadAllData
        branchId={form.getFieldValue(["purchaseOrder", "branchId"])}
      />
    </>
  );
}
