import { useEffect, useState } from "react";
import { Form, message, Space } from "antd";
import FnbSelectMaterialComponent from "components/fnb-select-material/fnb-select-material";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { formatNumberDecimalOrInteger, getValidationMessages } from "utils/helpers";
import { Thumbnail } from "components/thumbnail/thumbnail";
import "./edit-material-category.component.scss";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";

export default function EditMaterialCategoryComponent(props) {
  const { t, isEditMaterialCategory, onCancel, materialCategoryDataService, onCompleted } = props;
  const [form] = Form.useForm();
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [listMaterial, setListMaterial] = useState([]);
  const [materialCategoryId, setMaterialCategoryId] = useState();
  const [title, setTitle] = useState("");
  const [isSelectedAllMaterials, setIsSelectedAllMaterials] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const pageData = {
    btnCancel: t("button.cancel"),
    btnUpdate: t("button.update"),
    leaveForm: t("messages.leaveForm"),
    updateMaterialCategorySuccess: t("messages.updateMaterialCategorySuccess"),
    materialCategory: {
      create: t("materialCategory.create"),
      nameCategory: t("materialCategory.nameCategory"),
      enterCategoryName: t("materialCategory.enterCategoryName"),
      pleaseEnterNameCategory: t("materialCategory.pleaseEnterNameCategory"),

      nameCategoryExisted: t("materialCategory.nameCategoryExisted"),
    },
    selectMaterial: {
      title: t("materialCategory.selectMaterial"),
      table: {
        name: t("table.name"),
        quantity: t("table.quantity"),
        unit: t("table.unit"),
        action: t("table.action"),
      },
    },
    allMaterials: t("materialCategory.allMaterials"),
  };

  useEffect(() => {
    props.func.current = setInitData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setInitData = (data) => {
    const { currentMaterialCategory } = data;
    setSelectedMaterials(data?.listMaterialCategoryMaterial);
    const listMaterial = data?.listMaterial.filter(
      (b) => !data?.listMaterialCategoryMaterial.find((v) => v.id === b.id),
    );
    setListMaterial(listMaterial);
    setMaterialCategoryId(currentMaterialCategory?.id);
    setTitle(currentMaterialCategory?.name);
    form.setFieldsValue({
      name: currentMaterialCategory?.name,
    });
  };

  const onSelectMaterial = (id) => {
    let selectedMaterial = listMaterial?.find((m) => m.id === id);
    let material = listMaterial.filter((m) => m.id !== id);
    setSelectedMaterials([...selectedMaterials, selectedMaterial]);
    setListMaterial(material);
  };

  const onFinish = () => {
    form.validateFields().then(async (values) => {
      let materialIds = [];

      if (!isSelectedAllMaterials) {
        // eslint-disable-next-line array-callback-return
        selectedMaterials.map((item) => {
          materialIds.push(item.id);
        });
      } else {
        // eslint-disable-next-line array-callback-return
        listMaterial.map((item) => {
          materialIds.push(item.id);
        });
      }

      const updateCategoryRequest = {
        ...values,
        materialIds: materialIds,
        id: materialCategoryId,
      };

      materialCategoryDataService
        .updateMaterialCategoryByIdAsync(updateCategoryRequest)
        .then((res) => {
          if (res) {
            form.resetFields();
            message.success(pageData.updateMaterialCategorySuccess);
            onCompleted();
          }
        })
        .catch((errs) => {
          form.setFields(getValidationMessages(errs));
        });
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setSelectedMaterials([]);
    setListMaterial([]);
    setIsSelectedAllMaterials(false);
    onCancel();
  };

  const removeItemMaterial = (id) => {
    let itemMaterial = selectedMaterials.find((m) => m.id === id);
    let listSelectedMaterial = selectedMaterials.filter((m) => m.id !== id);
    setSelectedMaterials(listSelectedMaterial);
    setListMaterial([...listMaterial, itemMaterial]);
  };
  const onChangeOption = (e) => {
    const isChecked = e.target.checked;
    setIsSelectedAllMaterials(isChecked);
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.selectMaterial.table.name,
        dataIndex: "name",
        key: "name",
        width: "50%",
        render: (_, record) => (
          <Space wrap size={12}>
            <Thumbnail src={record?.thumbnail} width={48} height={48} />
            <FnbTooltip
              title={record?.name}
              placement="topLeft"
              onlyShowWhenEllipsis
              maxWidthContent={"230px"}
              width={"280px"}
            >
              <FnbTypography text={record?.name} />
            </FnbTooltip>
          </Space>
        ),
      },
      {
        title: pageData.selectMaterial.table.quantity,
        dataIndex: "quantity",
        key: "quantity",
        width: "30%",
        render: (val) => <FnbTypography text={val ? formatNumberDecimalOrInteger(val) : "-"} />,
      },
      {
        title: pageData.selectMaterial.table.unit,
        dataIndex: "unitName",
        key: "unitName",
        width: "20%",
        render: (val) => <FnbTypography text={val ?? "-"} />,
      },
    ];
    return columns;
  };

  const onSelectedRowKeysChange = (keySelected) => {
    setSelectedRowKeys(keySelected);
  };

  const onDeleteRowKeys = (_, selectedRows) => {
    removeItemMaterial(selectedRows[0]?.id);
    setSelectedRowKeys([]);
  };

  const renderContent = () => {
    return (
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
        className="custom-form"
        layout="vertical"
      >
        <Space direction="vertical" size={24} className="w-100">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: <InputValidateMessage message={pageData.materialCategory.pleaseEnterNameCategory} />,
              },
            ]}
            label={pageData.materialCategory.nameCategory}
          >
            <FnbInput showCount maxLength={100} placeholder={pageData.materialCategory.enterCategoryName} />
          </Form.Item>

          <Space direction="vertical" size={!isSelectedAllMaterials ? 8 : 3} className="w-100">
            <div className="d-flex justify-content-between w-100">
              <FnbTypography variant={theme.typography["b1-medium"]} text={pageData.selectMaterial.title} />
              <FnbCheckBox onChange={(event) => onChangeOption(event)} checked={isSelectedAllMaterials}>
                {pageData.allMaterials}
              </FnbCheckBox>
            </div>
            <Form.Item hidden={isSelectedAllMaterials}>
              <FnbSelectMaterialComponent
                t={t}
                materialList={listMaterial}
                onChangeEvent={(value) => onSelectMaterial(value)}
                isAllBranch={true}
              />
            </Form.Item>
            <Form.Item hidden={!isSelectedAllMaterials} className="last-item">
              <FnbSelectSingle disabled />
            </Form.Item>
          </Space>

          {selectedMaterials.length > 0 && !isSelectedAllMaterials && (
            <div style={{ marginTop: "-24px" }}>
              <FnbTable
                dataSource={selectedMaterials}
                columns={columnsMaterial()}
                total={selectedMaterials.length}
                scrollY={75 * 6}
                scrollX={600}
                rowSelection={{
                  selectedRowKeys: selectedRowKeys,
                  onChange: onSelectedRowKeysChange,
                  onDelete: onDeleteRowKeys,
                }}
              />
            </div>
          )}
        </Space>
      </Form>
    );
  };

  return (
    <FnbModal
      width={"800px"}
      className="wrapper-edit-ingredient-category"
      title={title}
      visible={isEditMaterialCategory}
      handleCancel={handleCancel}
      cancelText={pageData.btnCancel}
      okText={pageData.btnUpdate}
      onOk={onFinish}
      content={renderContent()}
      top={36}
    />
  );
}
