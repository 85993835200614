import { Space } from "antd";
import FnbCard from "components/fnb-card/fnb-card.component";
import "./ingredient-price-unit-conversion.page.scss";

import FnbInputGroup, { FnbInputGroupType } from "components/fnb-input-group/fnb-input-group";
import { FnbSelect } from "components/fnb-select/fnb-select";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbSwitch from "components/switch";
import { MaximumNumber, MinimumNumber } from "constants/default.constants";
import { BoxIcon, CrownIcon, LeadingIcon } from "constants/icons.constants";
import branchDataService from "data-services/branch/branch-data.service";
import { useEffect, useRef, useState } from "react";
import theme from "theme";
import "./image-other-setting.page.scss";
import "./ingredient-quantity-of-branch.page.scss";
import debounce from "lodash/debounce";
import { Sort, formatPhoneNumber } from "utils/helpers";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";

export const IngredientQuantityOfBranchMaterial = ({
  t,
  form,
  isCreatePage,
  baseUnitName,
  updateIsShowQuantityOfBranch,
  setIsUpdateIngredientQuantity,
}) => {
  const pageData = {
    title: t("material.ingredientQuantityOfBranch", "Ingredient Quantity In Stock By Branch"),
    setGeneralQuantity: t("material.setGeneralQuantity", "Set the general quantity for selected branches"),
    branchAndWarehouse: t("material.branchAndWarehouse"),
    branch: t("store.branch"),
    mainStore: t("material.mainStore", "Main Store"),
    wareHouse: t("material.wareHouse", "Branch and Warehouse"),
    validateMinQtyMessage: t("productManagement.pricing.priceRange"),
    inventory: {
      branchSelectPlaceholder: t("material.inventory.branchSelectPlaceholder"),
    },
    setupQuantity: t("material.setupQuantity"),
    allBranches: t("productCategory.branch.all"),
    currentQuantityInStock: t("material.currentQuantityInStock"),
  };
  const DEFAULT_PAGESIZE = 10;
  const [isShowQuantityOfBranch, setIsShowQuantityOfBranch] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [branches, setBranches] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [isShowIngredientQuantity, setIsShowIngredientQuantity] = useState(false);
  const filterBranchsRef = useRef([]);
  const [branchValues, setBranchValues] = useState(["all"]);
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();
  useEffect(() => {
    fetchBranches();
  }, []);

  useEffect(() => {
    setIsShowIngredientQuantity(!isCreatePage);
  }, [isCreatePage]);

  useEffect(() => {
    if (branches.length > 0) {
      loadDataSource();
    }
  }, [branches]);

  useEffect(() => {
    if (updateIsShowQuantityOfBranch) {
      updateIsShowQuantityOfBranch(isShowIngredientQuantity);
    }
  }, [isShowIngredientQuantity]);

  const loadDataSource = () => {
    filterBranchsRef.current = [];
    setPageNumber(1);
    setTotalRecords(branches.length);
    setDataSource(mappingRecordToColumns(branches, 1));
  };

  const onSelectBranchesInventory = (values) => {
    if(values[values?.length - 1] === "all" || values?.length === 0) {
      setTotalRecords(branches?.length);
      setPageNumber(1);
      setDataSource(mappingRecordToColumns(branches, 1));
      filterBranchsRef.current = [];
      setBranchValues(["all"]);
      return;
    }
    else {
      values = values.filter((item) => item !== "all");
    }
    if (filterBranchsRef && filterBranchsRef.current) {
      filterBranchsRef.current = values;
    }
    const dataFilter = branches.filter((branch) => values.includes(branch.id));
    setBranchValues(values);
    setTotalRecords(dataFilter?.length);
    setDataSource(mappingRecordToColumns(dataFilter, pageNumber));
  };

  // region handle branches
  const fetchBranches = () => {
    const isSortByDefaultBranch = true;
    branchDataService.getAllBranchsAsync(isSortByDefaultBranch).then((res) => {
      if (res && res?.branchs?.length > 0) {
        setBranches(res.branchs);
        updateLastTimeGetBranches();
      }
    });
  };

  useEffect(() => {
    if (needToReloadBranches) {
      fetchBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  // end region handle branches

  const mappingRecordToColumns = (data, pageNumber) => {
    data = data?.slice((pageNumber - 1) * tableSettings.pageSize, pageNumber * tableSettings.pageSize);
    return data?.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        addressInfo: item?.addressInfo,
        isMainBranch: item?.isDefault,
        isWareHouse: false
      };
    });
  };

  const tableSettings = {
    pageSize: DEFAULT_PAGESIZE,
    columns: [
      {
        title: pageData.branchAndWarehouse,
        dataIndex: "name",
        key: "name",
        width: "50%",
        ellipsis: true,
        render: (_, record) => (
          <div className="branch-and-warehouse">
            <div className="name">
              <FnbTooltip onlyShowWhenEllipsis={true} title={record?.name} maxWidthContent={record?.isMainBranch || record?.isWareHouse ? "calc(100% - 200px)" : undefined}>
                <FnbTypography text={record?.name} />
              </FnbTooltip>
              {record?.isMainBranch && (
                <FnbTypography
                  color={theme.colors.secondary[70]}
                  className="tag-highlight"
                  text={
                    <>
                      <CrownIcon width={20} height={20}></CrownIcon>
                      {pageData.mainStore}
                    </>
                  }
                ></FnbTypography>
              )}
              {record?.isWareHouse && (
                <FnbTypography
                  className="tag-highlight"
                  color={theme.colors.primary[80]}
                  text={
                    <>
                      <BoxIcon width={20} height={20}></BoxIcon>
                      {pageData.wareHouse}
                    </>
                  }
                ></FnbTypography>
              )}
            </div>
            <FnbTooltip title={record?.addressInfo} onlyShowWhenEllipsis={true}>
              <FnbTypography variant="b2-regular" color={theme.colors.gray[70]} text={record?.addressInfo} />
            </FnbTooltip>
          </div>
        ),
      },
      {
        title: t(pageData.currentQuantityInStock, { unit: baseUnitName }),
        dataIndex: "quantity",
        key: "quantity",
        width: "50%",
        ellipsis: true,
        render: (_, record) => {
          return (
            <FnbInputGroup
              className="mb-16 input-quantity"
              firstInput={{
                type: FnbInputGroupType.NUMBER_DECIMAL,
                form: {
                  name: ["quantity", record?.id],
                  rules: [
                    () => ({
                      validator(_, value) {
                        if (value > MaximumNumber || value < MinimumNumber) {
                          return Promise.reject(pageData.validateMinQtyMessage);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ],
                },
              }}
              secondInput={{
                type: FnbInputGroupType.LABEL,
                value: baseUnitName,
              }}
            />
          );
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      setPageNumber(page);
      if (filterBranchsRef && filterBranchsRef.current && filterBranchsRef.current.length > 0) {
        const dataFilter = branches.filter((branch) => filterBranchsRef.current.includes(branch.id));
        const dataSource = mappingRecordToColumns(dataFilter, page);
        setDataSource(dataSource);
        return;
      }
      setDataSource(mappingRecordToColumns(branches, page));
    },
  };

  const handleChangeShowIngredientQuantity = (visible) => {
    if(setIsUpdateIngredientQuantity){
      setIsUpdateIngredientQuantity(visible);
    }

    setIsShowIngredientQuantity(visible);
  };
  const handleClearSelect = () => {
    setBranchValues(["all"]);
    loadDataSource();
  };

  const onchangeDefaultQuantity = (value) => {
    let quantity = form.getFieldValue("quantity") ?? {};

    branches.filter((branch) => branchValues?.some(id => id === "all") || branchValues?.includes(branch?.id)).forEach((branch) => {
      quantity[branch?.id] = value;
    })
    
    form.setFieldValue("quantity", quantity);
    tableSettings.onChangePage(1);
  };

  return (
    <FnbCard className="init-ingred ingredient-quantity-component " title={pageData.title} underlineTitle>
      <Space style={{ display: "flex" }} size={10} direction="vertical">
        { isCreatePage && (
          <div className="init-ingred__switch">
            <FnbSwitch checked={isShowIngredientQuantity} onChange={handleChangeShowIngredientQuantity} />
            <div style={{ maxWidth: 'calc(100% - 40px)' }}>
              <FnbTypography text={pageData.setupQuantity} />
            </div>
          </div>
        )}

        {isShowIngredientQuantity ? (
          <div className="init-ingred__dv-bg init-ingred__dv-bg--rear">
            <div className="init-ingred__dv-bg init-ingred__dv-bg--fore">
              <Space style={{ display: "flex" }} className="init-ingred_space--vertical" direction="vertical" size={12}>
                <FnbSelectAddNewItem
                  onClear={handleClearSelect}
                  mode={"multiple"}
                  onChange={(values) => onSelectBranchesInventory(values)}
                  placeholder={pageData.inventory.branchSelectPlaceholder}
                  className="w-100"
                  allowClear={!branchValues.includes("all")}
                  options={branches
                    ?.map((b) => ({
                      value: b.id,
                      label: b?.name,
                      description: formatPhoneNumber(b?.phonecode, b?.phoneNumber),
                    }))
                    .sort((a, b) => Sort(a?.label, b?.label))}
                  value={branchValues}
                  isHeaderCheckedIcon
                  isShowPrefixDescription
                  defaultOption={{
                    label: pageData.allBranches,
                    value: "all",
                    prefixIcon: <LeadingIcon width={20} height={20} />,
                  }}
                  hiddenDefaultItemOnSearch
                  fieldItemName={pageData.branch?.toLowerCase()}
                  onAddNewItem={onAddNewBranch}
                />

                <div className="init-ingred__dv-flex init-ingred__dv-flex--justify-space">
                  <div className="init-ingred__dv-init" style={{ alignItems: 'center' }}>
                    <FnbSwitch checked={isShowQuantityOfBranch} onChange={setIsShowQuantityOfBranch} />
                    <div style={{ maxWidth: 'calc(100% - 76px)' }}>
                      <FnbTypography text={pageData.setGeneralQuantity} />
                    </div>
                  </div>
                  { isShowQuantityOfBranch && <FnbInputGroup
                    firstInput={{
                      type: FnbInputGroupType.NUMBER_DECIMAL,
                      form: {
                        name: ["defaultQuantity"],
                        rules: [
                          () => ({
                            validator(_, value) {
                              if (value > MaximumNumber || value < MinimumNumber) {
                                return Promise.reject(pageData.validateMinQtyMessage);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ],
                      },
                      onChange: (value) => debounce(onchangeDefaultQuantity(value), 1000, { leading: true }),
                    }}
                    secondInput={{
                      type: FnbInputGroupType.LABEL,
                      value: baseUnitName,
                    }}
                  />}
                </div>

                <FnbTable
                  className="table-staff-revenue"
                  columns={tableSettings.columns}
                  pageSize={tableSettings.pageSize}
                  dataSource={dataSource}
                  currentPageNumber={pageNumber}
                  total={totalRecords}
                  onChangePage={tableSettings.onChangePage}
                  scrollX={600}
                />
              </Space>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Space>
    </FnbCard>
  );
};
