import { Col, Row } from "antd";
import { TriangleIncreaseIcon, TriangleReduceIcon, CustomInfoCircleIcon } from "constants/icons.constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrency, getShortValue, getSuffixShortValue, isDecimalNumber } from "utils/helpers";
import "./fnb-widget.component.scss";
import FnbWidgetStyle from "./fnb-widget.component.style";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";

function FnbWidget(props) {
  const [t] = useTranslation();
  const {
    summaryItem,
    titleTotal,
    icon,
    suffixData,
    isShowAverageSection = false,
    styles, //Custome color component via this prop
    isShowAverageOnly = false,
    titleAverage,
    isShowPaidAndUnpaid = false,
    isShowHint = false,
    informationHint = "",
    colorHint = "#FFFFFF",
    isShowPercent = true,
  } = props;
  const pageData = {
    order: t("dashboard.order", "Order"),
    average: t("dashboard.average", "Average"),
  };

  const formatTextNumberWidget = (number) => {
    if (isNaN(number) || number === 0) {
      return number;
    }

    return isDecimalNumber(number)
      ? `${number.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ""
      : `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "";
  };

  return (
    <FnbWidgetStyle styles={styles}>
      <div className={`summary-card-average`}>
        <div className="section-total">
          <Row>
            <Col>
              <div className="sumnary-icon">{icon}</div>
            </Col>
            <Col>
              <div className="summary-title">
                <p className="title">{titleTotal}</p>
                {isShowHint &&
                  <span className="summary-hint">
                    <FnbTooltip
                      autoAdjustOverflow
                      placement="bottom"
                      maxWidth="480px"
                      variant="secondary"
                      title={() => informationHint}
                    >
                      <CustomInfoCircleIcon color={colorHint} />
                    </FnbTooltip>
                  </span>
                }

              </div>
            </Col>
            <Col>{isShowPercent && 
              <div className={`acceleration-card ${summaryItem?.percent < 0 ? "decrease" : "increase"}`}>
                <Row>
                  {summaryItem?.percent < 0 ? (
                    <TriangleReduceIcon className="icon-increase-triangle" />
                  ) : (
                    <TriangleIncreaseIcon className="icon-increase-triangle" />
                  )}
                  <p className="percent">{Math.abs(summaryItem?.percent)}%</p>
                </Row>
              </div>
              }
            </Col>
          </Row>
          <Row>
            <Col className="section-total__col-amount">
              <div className="summary-short-value">
                <p>{getShortValue(summaryItem?.total)}</p>
                <p className="suffix">{getSuffixShortValue(summaryItem?.total)}</p>
              </div>
            </Col>
            <Col>
              {isShowPaidAndUnpaid && (
                <div className="summary-paid">
                  <Row>
                    <Col>
                      <p>Paid: </p>
                    </Col>
                    <Col>
                      <Row>
                        <p>{formatTextNumberWidget(summaryItem?.paid ?? 0)}</p>
                        <p>{getCurrency()}</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="section-total__col-amount">
              <div className="summary-suffix">
                <p className="data">{formatTextNumberWidget(summaryItem?.total)}</p>
                <p className="suffix-text">{suffixData}</p>
              </div>
            </Col>
            <Col>
              {isShowPaidAndUnpaid && (
                <div className="summary-paid">
                  <Row>
                    <Col>
                      <p>Unpaid: </p>
                    </Col>
                    <Col>
                      <Row>
                        <p>{formatTextNumberWidget(summaryItem?.unpaid ?? 0)}</p>
                        <p>{getCurrency()}</p>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </div>
        {isShowAverageSection && (
          <div className={`section-average ${isShowAverageOnly && "single-average-total"}`}>
            {isShowAverageOnly ? (
              <React.Fragment>
                <Row className="row-average section-average__row--space-between mb-0">
                  <Col className="row-average__col--w-fit-content row-average__col--no-flex" sm={12} span={24}>
                    <p className="section-average-title">{titleAverage}</p>
                  </Col>
                  <Col className="row-average__col--w-fit-content row-average__col--no-flex" sm={12} span={24}>
                    <Row>
                      <p className="section-average-amount">{formatTextNumberWidget(summaryItem?.average)}</p>
                      <p className="section-average-currency">{`${getCurrency()}/${pageData.order}`}</p>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Row>
                  <Col>
                    <div className="sumnary-icon">{icon}</div>
                  </Col>
                  <Col>
                    <div className="summary-title">
                      <p className="title">{pageData.average}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className={`acceleration-card ${summaryItem?.percentAverage < 0 ? "decrease" : "increase"}`}>
                      <Row>
                        {summaryItem?.percentAverage < 0 ? (
                          <TriangleReduceIcon className="icon-increase-triangle" />
                        ) : (
                          <TriangleIncreaseIcon className="icon-increase-triangle" />
                        )}
                        <p className="percent">{Math.abs(summaryItem?.percentAverage)}%</p>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="section-total__col-amount">
                    <div className="summary-short-value">
                      <p>{getShortValue(summaryItem?.average)}</p>
                      <p className="suffix">{getSuffixShortValue(summaryItem?.average)}</p>
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col className="section-total__col-amount">
                    <div className="summary-suffix">
                      <p className="data">{formatTextNumberWidget(summaryItem?.average)}</p>
                      <p className="suffix-text">{`${getCurrency()}/${pageData.order}`}</p>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </FnbWidgetStyle>
  );
}

export default FnbWidget;
