/**
 * Theme data - storage the information of the theme
 */

import _defaultConfig from "./default-store.config";
import { BlogDetailCustomizes } from "./pages/blog/blog-detail/blog-detail.customize";
import BlogDetailPage from "./pages/blog/blog-detail/blog-detail.page";
import { BlogListCustomizes } from "./pages/blog/blog-list/blog-list.customize";
import BlogListPage from "./pages/blog/blog-list/blog-list.page";
import { CheckoutCustomizes } from "./pages/checkout/checkout.customize";
import Theme1Checkout from "./pages/checkout/checkout.page";
import { ContactCustomizePage } from "./pages/contact/contact.customize.jsx";
import ContactPage from "./pages/contact/contact.page.jsx";
import { HomeCustomizes } from "./pages/home/home.customize";
import { LoginCustomizes } from "./pages/login/login.customize";
import { MyProfileCustomizes } from "./pages/my-profile/my-profile.customize";
import PaymentStatusPage from "./pages/payment-status/components/payment-status.componen.js";
import { ProductDetailCustomizes } from "./pages/product-detail/product-detail.customize";
import ProductDetailPage from "./pages/product-detail/product-detail.page";
import { ProductListCustomize } from "./pages/product-list-new/components/product-list-page.customize";
import { ReserveTableCustomizes } from "./pages/reserve-table/reserve-table.customize";
import ReserveTablePage from "./pages/reserve-table/ReserveTablePage.jsx";

const { default: PageType } = require("./constants/page-type.constants");
const { default: HomePage } = require("./pages/home/home.page");
const { default: LoginPage } = require("./pages/login/login.page");
const { default: MyProfilePage } = require("./pages/my-profile/my-profile.page");
const { default: ProductListPage } = require("./pages/product-list-new/product-list.page");

const translateData = {
  homePage: "storeWebPage.homePage",
  productList: "storeWebPage.productList",
  productDetail: "storeWebPage.productDetail",
  comboDetail: "storeWebPage.comboDetail",
  booking: "storeWebPage.booking",
  cartPage: "storeWebPage.cartPage",
  checkoutPage: "storeWebPage.checkoutPage",
  registerPage: "storeWebPage.registerPage",
  loginPage: "storeWebPage.loginPage",
  forgotPasswordPage: "storeWebPage.forgotPasswordPage",
  myProfilePage: "storeWebPage.myProfilePage",
  contactPage: "storeWebPage.contactPage",
  aboutUsPage: "storeWebPage.aboutUsPage",
  blog: "storeWebPage.blog",
  blogDetail: "storeWebPage.blogDetail",
  reservation: "storeWebPage.reservation",
  paymentStatus: "storeWebPage.paymentStatus",
  allProducts: "storeWebPage.allProducts",
};

export const themeData = {
  id: "921016fe-d34e-4192-beb8-15d775d0ee5b", // the static id generated by GoF&B system
  name: "TropicalFruit", // theme name
  pages: [
    // the all pages of theme
    {
      id: PageType.HOME_PAGE, // page id - static data get from system
      name: translateData.homePage, // name of page
      component: HomePage, // main page
      customizes: HomeCustomizes, // customize objects
      path: "/home", // path to access to page from browser
      metaInfo: {
        title: translateData.homePage,
        description: translateData.homePage,
      },
    },
    {
      id: PageType.PRODUCT_LIST,
      name: translateData.productList,
      component: ProductListPage,
      customizes: ProductListCustomize, // customize objects
      path: "/product-list",
      metaInfo: {
        title: translateData.productList,
        description: translateData.allProducts,
      },
    },
    {
      id: PageType.PRODUCT_LIST,
      name: translateData.productList,
      component: ProductListPage,
      customizes: ProductListCustomize, // customize objects
      path: "/product-list/:productCategoryId",
      metaInfo: {
        title: translateData.productList,
        description: translateData.allProducts,
      },
    },
    {
      id: PageType.PRODUCT_DETAIL,
      name: translateData.productDetail,
      component: ProductDetailPage,
      customizes: ProductDetailCustomizes,
      path: "/product-detail/:productId",
      metaInfo: {
        title: translateData.productDetail,
        description: translateData.productDetail,
      },
    },
    {
      // IMPORTANT -- Currently there is no customization for COMBO_DETAIL, so switch to PRODUCT_DETAIL's config
      id: PageType.PRODUCT_DETAIL,
      name: translateData.comboDetail,
      component: ProductDetailPage,
      customizes: [],
      path: "/combo-detail/:comboType/:comboId",
      metaInfo: {
        title: translateData.productDetail,
        description: translateData.productDetail,
      },
    },
    {
      id: PageType.BOOKING,
      name: translateData.booking,
      component: HomePage,
      customizes: [],
      path: "/booking",
      metaInfo: {
        title: translateData.booking,
        description: translateData.booking,
      },
    },
    {
      id: PageType.CART_PAGE,
      name: translateData.cartPage,
      component: HomePage,
      customizes: [],
      path: "/cart",
      metaInfo: {
        title: translateData.cartPage,
        description: translateData.cartPage,
      },
    },
    {
      id: PageType.CHECKOUT_PAGE,
      name: translateData.checkoutPage,
      component: Theme1Checkout,
      customizes: CheckoutCustomizes,
      path: "/checkout",
      metaInfo: {
        title: translateData.checkoutPage,
        description: translateData.checkoutPage,
      },
    },
    {
      id: PageType.REGISTER_PAGE,
      name: translateData.registerPage,
      component: LoginPage,
      customizes: [],
      path: "/register",
      metaInfo: {
        title: translateData.registerPage,
        description: translateData.registerPage,
      },
    },
    {
      id: PageType.LOGIN_PAGE,
      name: translateData.loginPage,
      component: LoginPage,
      customizes: LoginCustomizes, // customize objects
      path: "/login",
      metaInfo: {
        title: translateData.loginPage,
        description: translateData.loginPage,
      },
    },
    {
      id: PageType.FORGOT_PASSWORD_PAGE,
      name: translateData.forgotPasswordPage,
      component: LoginPage,
      customizes: LoginCustomizes, // customize objects
      path: "/forgot-password",
      metaInfo: {
        title: translateData.forgotPasswordPage,
        description: translateData.forgotPasswordPage,
      },
    },
    {
      id: PageType.MY_PROFILE_PAGE,
      name: translateData.myProfilePage,
      component: MyProfilePage,
      customizes: MyProfileCustomizes,
      path: "/my-profile",
      metaInfo: {
        title: translateData.myProfilePage,
        description: translateData.myProfilePage,
      },
    },
    {
      id: PageType.MY_PROFILE_PAGE,
      name: translateData.myProfilePage,
      component: MyProfilePage,
      customizes: MyProfileCustomizes,
      path: "/my-profile/:index",
      metaInfo: {
        title: translateData.myProfilePage,
        description: translateData.myProfilePage,
      },
    },
    {
      id: PageType.MY_PROFILE_PAGE,
      name: translateData.myProfilePage,
      component: MyProfilePage,
      customizes: MyProfileCustomizes,
      path: "/my-profile/:index/:id",
      metaInfo: {
        title: translateData.myProfilePage,
        description: translateData.myProfilePage,
      },
    },
    {
      id: PageType.CONTACT_PAGE,
      name: translateData.contactPage,
      component: ContactPage,
      customizes: ContactCustomizePage,
      path: "/contact",
      metaInfo: {
        title: translateData.contactPage,
        description: translateData.contactPage,
      },
    },
    {
      id: PageType.ABOUT_US_PAGE,
      name: translateData.aboutUsPage,
      component: HomePage,
      customizes: [],
      path: "/about-us",
      metaInfo: {
        title: translateData.aboutUsPage,
        description: translateData.aboutUsPage,
      },
    },
    {
      id: PageType.BLOG_PAGE,
      name: translateData.blog,
      component: BlogListPage,
      customizes: BlogListCustomizes,
      path: "/blog",
      metaInfo: {
        title: translateData.blog,
        description: translateData.blog,
      },
    },
    {
      id: PageType.BLOG_DETAIL_PAGE,
      name: translateData.blogDetail,
      component: BlogDetailPage,
      customizes: BlogDetailCustomizes,
      path: "/blog/:path",
      metaInfo: {
        title: translateData.blogDetail,
        description: translateData.blogDetail,
      },
    },
    {
      id: PageType.RESERVE_TABLE_PAGE,
      name: translateData.reservation,
      component: ReserveTablePage,
      customizes: ReserveTableCustomizes,
      path: "/reserve-table",
      metaInfo: {
        title: translateData.reservation,
        description: translateData.reservation,
      },
    },
  ],
};

export const themeToken = {
  fontFamily: "Plus Jakarta Sans",
};

export const getRouteMetaInfo = (routeId) => {
  const route = themeData.pages.find((r) => r.id === routeId);
  return route?.metaInfo ?? {};
};

export const defaultConfig = _defaultConfig;

// this property for admin scan
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  themeData,
  defaultConfig,
};
