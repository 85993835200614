import { message } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { DELAYED_TIME } from "constants/default.constants";
import { ConfirmStyle, EnumMaterialDependencyType } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import materialDataService from "data-services/material/material-data.service";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getCurrency, hasPermission } from "utils/helpers";
import CheckDeleteMaterial from "../components/check-delete-material.component";
import DeleteMaterial from "../components/delete-material.component";
import DeleteProductRecipes from "../components/delete-product-recipes.component";
import { CreateMaterialPage } from "./component/create-material.page";
import EditMaterialPage from "./component/edit-material.page";
import ViewMaterialPage from "./component/view-material.page";
import "./detail-material.page.scss";
import UpdateCostPerUnitComponent from "./update-cost-per-unit.component";
import { TrashIcon } from "constants/icons.constants";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { IngredientStatus } from "constants/ingredient-status.constants";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { GuidancePurposeType } from "constants/guidance-link.constants";
import FnbSuffixHeadingGuideline from "components/fnb-heading-guidline/fnb-suffix-heading-guideline.component";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";

export const DETAIL_MATERIAL_TYPE = {
  CREATE: 0,
  EDIT: 1,
  VIEW: 2,
};

const MaterialDetail = ({ detailMaterialType = DETAIL_MATERIAL_TYPE.VIEW }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const pageNotFoundLink = "/pageNotFound";
  const configByDetailTypesRef = useRef({
    textAddNew: null,
    permissionCreate: PermissionKeys.VIEW_MATERIAL,
    textButtonCancel: null,
    fromName: null,
  });
  const nameFromSearchMaterial = new URLSearchParams(window.location.search).get("name");

  const param = useParams();

  const pageData = {
    create: {
      title: t("material.addMaterial"),
      btnCancel: t("button.cancel"),
    },
    edit: {
      btnSubmit: t("button.update"),
    },
    view: {
      btnSubmit: t("button.edit"),
      btnLeave: t("button.leave"),
    },

    activate: t("status.activate"),
    deactivate: t("status.deactivate"),
    backTo: t("material.back"),
    materialManagement: t("material.materialManagement"),
    name: t("material.name"),
    description: t("material.description"),
    category: t("form.category"),
    general: t("material.generalInformation"),
    pricing: t("material.pricing"),
    currentCostPerUnit: t("material.currentCostPerUnit"),
    tooltipCostPerUnit: t("material.tooltipCostPerUnit"),
    baseUnit: t("material.baseUnit"),
    inventory: t("material.inventory.title"),
    inventoryMinQuantityTooltip: t("material.inventory.minQuantityTooltip"),
    sku: t("table.sku"),
    minQuantity: t("material.inventory.minQuantity"),
    branch: t("material.inventory.branch"),
    productHasExpiryDate: t("material.ingredientHasExpiryDate"),
    image: t("material.inventory.image"),
    btnEdit: t("button.edit"),
    btnDelete: t("button.delete"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    isDeletedSuccessfully: t("messages.isDeletedSuccessfully"),
    notificationTitle: t("form.notificationTitle"),
    isUpdatedSuccessfully: t("messages.isUpdatedSuccessfully"),
    updateMaterialCost: t("material.updateMaterialCost"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    back: t("button.back"),
  };

  const [initData, setInitData] = useState();
  const [materialQuantityInBranch, setMaterialQuantityInBranch] = useState();
  const [ingredientHistory, setIngredientHistory] = useState();
  const [leaverConfirmVisible, setLeaverConfirmVisible] = useState(false);
  const [infoMaterial, setInfoMaterial] = useState({});
  const [isModalProductVisible, setIsModalProductVisible] = useState(false);
  const [isModalPOVisible, setIsModalPOVisible] = useState(false);
  const [isModalCostUnitVisible, setIsModalCostUnitVisible] = useState(false);
  const [checkDeleteModalVisible, setCheckDeleteModalVisible] = useState(false);
  const [titleModalCheckDeleteMaterial, setTitleModalCheckDeleteMaterial] = useState("");
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [costPerUnitNumber, setCostPerUnitNumber] = useState(0);
  const tableFuncs = React.useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activateMaterial, setActivateMaterial] = useState();
  const [materialDependencyType, setMaterialDependencyType] = useState(EnumMaterialDependencyType.NONE);
  const [listObjectLockMaterial, setListObjectLockMaterial] = useState({});
  const createMaterialRef = useRef();
  const [pageTitle, setPageTitle] = useState("");
  const [currentPageNumberQuantityInStock, setCurrentPageNumberQuantityInStock] = useState(1);
  const [currentPageNumberIngredientHistory, setCurrentPageNumberIngredientHistory] = useState(1);

  const tableSettingsQuantityInStock = {
    page: currentPageNumberQuantityInStock,
    pageSize: 10,
  };

  const tableSettingsIngredientHistory = {
    page: currentPageNumberIngredientHistory,
    pageSize: 10,
  };

  useEffect(() => {
    if (detailMaterialType !== DETAIL_MATERIAL_TYPE.CREATE) {
      getInitDataAsync(param.id);
    }
    renderConfigByDetailType();
  }, []);

  useEffect(() => {
    let newInitData = initData;
    if (newInitData?.material) {
      newInitData.material.costPerUnit = costPerUnitNumber;
    }
    setInitData(newInitData);
  }, [costPerUnitNumber]);

  useEffect(() => {
    if (detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW) getQuantityInStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumberQuantityInStock]);

  useEffect(() => {
    if (detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW) getIngredientHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumberIngredientHistory]);

  const getInitDataAsync = async (id) => {
    if (detailMaterialType === DETAIL_MATERIAL_TYPE.EDIT) {
      const res = await materialDataService.getPrepareMaterialEditDataAsync(id);
      if (res?.material) {
        setInitData(res);
        onChangeMaterialName(res?.material?.name);
        setCostPerUnitNumber(res?.material?.costPerUnit);
        setActivateMaterial(res?.material?.isActive);
      }
    } else if (detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW) {
      const res = await materialDataService.getMaterialByIdAsync(id);
      if (res?.material) {
        onChangeMaterialName(res?.material?.name);
        setInitData(res);
        setCostPerUnitNumber(res?.material?.costPerUnit);
        setActivateMaterial(res?.material?.isActive);
        getQuantityInStock();
        getIngredientHistory();
      } else {
        history.push(`${pageNotFoundLink}`);
      }
    }

    if (hasPermission(PermissionKeys.DELETE_MATERIAL)) {
      var checkMaterial = await materialDataService.checkDeleteMaterialByIdAsync(id);
      if (checkMaterial?.isInUse) {
        setMaterialDependencyType(checkMaterial?.type);
        setListObjectLockMaterial(checkMaterial);
      }
    }
  };

  const getQuantityInStock = async () => {
    const materialQuantityInBranchData = await materialDataService.getMaterialQuantityInBranchAsync(
      currentPageNumberQuantityInStock,
      tableSettingsQuantityInStock.pageSize,
      param.id,
    );
    if (materialQuantityInBranchData) {
      setMaterialQuantityInBranch(materialQuantityInBranchData);
    }
  };

  const getIngredientHistory = async () => {
    const ingredientHistoryData = await materialDataService.getIngredientHistoryAsync(
      currentPageNumberIngredientHistory,
      tableSettingsIngredientHistory.pageSize,
      param.id,
    );
    if (ingredientHistoryData) {
      setIngredientHistory(ingredientHistoryData);
    }
  };

  const onActivateMaterialAsync = async () => {
    const response = await materialDataService.activateMaterialByIdAsync(param.id);
    if (response?.isSuccess === true) {
      message.success(`${pageTitle} ${t(pageData.isUpdatedSuccessfully)}`);
    }

    await getInitDataAsync(param.id);
  };

  async function onDeActiveMaterialAsync() {
    const response = await materialDataService.deactivateMaterialByIdAsync(param.id);
    const { isSuccess, data } = response;
    if (isSuccess === false) {
      const { isOpenPurchaseOrder, isOpenProduct } = data;
      if (isOpenPurchaseOrder) {
        setInfoMaterial(data);
        setIsModalPOVisible(true);
      } else if (isOpenProduct) {
        setInfoMaterial(data);
        setIsModalProductVisible(true);
      }
    } else {
      message.success(`${pageTitle} ${t(pageData.isUpdatedSuccessfully)}`);
      await getInitDataAsync(param.id);
    }
  }

  const renderConfigByDetailType = () => {
    const configByDetailTypes = configByDetailTypesRef.current;

    if (detailMaterialType === DETAIL_MATERIAL_TYPE.CREATE) {
      configByDetailTypes.title = pageData.create.title;
      configByDetailTypes.permissionCreate = PermissionKeys.CREATE_MATERIAL;
      configByDetailTypes.textButtonCancel = pageData.create.btnCancel;
      configByDetailTypes.fromName = "createMaterialForm";
    } else if (detailMaterialType === DETAIL_MATERIAL_TYPE.EDIT) {
      configByDetailTypes.textAddNew = pageData.edit.btnSubmit;
      configByDetailTypes.permissionCreate = PermissionKeys.EDIT_MATERIAL;
      configByDetailTypes.textButtonCancel = pageData.create.btnCancel;
      configByDetailTypes.fromName = "editMaterialForm";
    } else if (detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW) {
      configByDetailTypes.textAddNew = pageData.view.btnSubmit;
      configByDetailTypes.permissionCreate = PermissionKeys.VIEW_MATERIAL;
      configByDetailTypes.textButtonCancel = pageData.view.btnLeave;
    }
  };

  const onChangeMaterialName = (name) => {
    setPageTitle(name);
  };

  const renderContent = () => {
    const materialPage = {
      [DETAIL_MATERIAL_TYPE.CREATE]: (
        <CreateMaterialPage
          t={t}
          fromName={configByDetailTypesRef.current.fromName}
          ref={createMaterialRef}
          history={history}
          onChangeForm={onChangeForm}
        />
      ),
      [DETAIL_MATERIAL_TYPE.EDIT]: (
        <EditMaterialPage
          t={t}
          history={history}
          initData={initData}
          updateCostPerUnit={updateCostPerUnit}
          fromName={configByDetailTypesRef.current.fromName}
          costPerUnitNumber={costPerUnitNumber}
          onChangeMaterialName={onChangeMaterialName}
          onChangeForm={onChangeForm}
        />
      ),
      [DETAIL_MATERIAL_TYPE.VIEW]: (
        <ViewMaterialPage
          t={t}
          initData={initData}
          materialQuantityInBranch={materialQuantityInBranch}
          ingredientHistory={ingredientHistory}
          updateCostPerUnit={updateCostPerUnit}
          costPerUnitNumber={costPerUnitNumber}
          pageSize={tableSettingsQuantityInStock.pageSize}
          setCurrentPageNumberQuantityInStock={setCurrentPageNumberQuantityInStock}
          currentPageNumberQuantityInStock={currentPageNumberQuantityInStock}
          setCurrentPageNumberIngredientHistory={setCurrentPageNumberIngredientHistory}
          currentPageNumberIngredientHistory={currentPageNumberIngredientHistory}
        />
      ),
    };

    return materialPage[detailMaterialType];
  };

  const onCancel = () => {
    if (detailMaterialType !== DETAIL_MATERIAL_TYPE.VIEW) {
      if (isChangeForm) {
        setLeaverConfirmVisible(true);
      } else {
        setLeaverConfirmVisible(false);
        onCancelEditMaterialForm();
      }
    } else {
      history.push(`/inventory/material`);
    }
  };

  const onDeleteProductPriceMaterial = async (id) => {
    await materialDataService.deleteProductPriceMaterialByMaterialIdAsync(id);
    setIsModalProductVisible(false);
    await onDeActiveMaterialAsync();
  };

  const onDiscard = () => {
    setLeaverConfirmVisible(false);
  };

  const onCancelEditMaterialForm = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/inventory/material");
    }, DELAYED_TIME);
  };

  const renderBaseUnitLabel = () => {
    const text = initData?.material?.unit ? `${getCurrency()}/${initData?.material?.unit?.name}` : "--";
    return text;
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleDeleteItem(param.id, pageTitle);
  };

  const handleDeleteItem = async (id, name) => {
    await materialDataService.deleteMaterialManagementAsync(id).then((res) => {
      if (res) {
        history.push("/inventory/material");
        message.success(`${name} ${pageData.isDeletedSuccessfully}`);
      }
    });
  };

  const renderModalDelete = () => {
    if (listObjectLockMaterial?.listObjectUseMaterial?.length > 0) {
      return (
        <CheckDeleteMaterial
          isModalVisible={isModalVisible}
          listObjectLockMaterial={listObjectLockMaterial}
          titleModal={pageData.notificationTitle}
          handleCancel={() => setIsModalVisible(false)}
          onDelete={handleOk}
          materialDependencyType={materialDependencyType}
        />
      );
    }

    return (
      <ConfirmDialogComponent
        ItemNameInContent={pageTitle}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
        type={ConfirmStyle.DELETE}
      />
    );
  };

  const updateCostPerUnit = () => {
    if (tableFuncs && tableFuncs.current) {
      tableFuncs.current(costPerUnitNumber);
    }
    setIsModalCostUnitVisible(true);
  };

  const handleOpenDeletePopup = async () => {
    var response = await materialDataService.checkDeleteMaterialByIdAsync(param.id);
    if (response?.isInUse) {
      setTitleModalCheckDeleteMaterial(pageData.notificationTitle);
      setListObjectLockMaterial(response);
      setCheckDeleteModalVisible(true);
    } else setIsModalVisible(true);
  };

  const onCreate = (e) => {
    if (detailMaterialType === DETAIL_MATERIAL_TYPE.CREATE) {
      createMaterialRef.current?.onAddNewMaterial(e);
    } else if (detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW) {
      history.push(`/inventory/material/edit-material/${param.id}`);
    }
  };

  const onChangeForm = (isChange) => {
    setIsChangeForm(isChange);
  };

  return (
    <>
      <FnbHeadingPage
        itemsBreadcrumb={
          detailMaterialType !== DETAIL_MATERIAL_TYPE.CREATE && [
            {
              href: `/inventory/material/`,
              title: pageData.back,
            },
            {
              title: <FnbTypography className="ingredient-header-name" text={pageTitle}></FnbTypography>,
            },
          ]
        }
        title={pageData.create.title} //only for create page
        activeStatus={{
          content:
            +initData?.material?.isActive === IngredientStatus.Activate ? (
              <FnbBadge className="badge-ingredient-status" variant="success" text={pageData.active}></FnbBadge>
            ) : (
              +initData?.material?.isActive === IngredientStatus.Deactivate && (
                <FnbBadge className="badge-ingredient-status" variant="error" text={pageData.inactive}></FnbBadge>
              )
            ),
        }}
        permissionCreate={PermissionKeys.EDIT_MATERIAL}
        onCreate={onCreate}
        textAddNew={
          detailMaterialType === DETAIL_MATERIAL_TYPE.EDIT
            ? pageData.edit.btnSubmit
            : detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW
            ? pageData.view.btnSubmit
            : null
        }
        fromBtnCreate={configByDetailTypesRef?.current?.fromName}
        typeBtnCreate={detailMaterialType === DETAIL_MATERIAL_TYPE.EDIT ? "submit" : "button"}
        listButtons={[
          <>
            {detailMaterialType === DETAIL_MATERIAL_TYPE.VIEW ? (
              <FnbButton variant="tertiary" text={pageData.view.btnLeave} onClick={onCancel} />
            ) : (
              <FnbButton variant="tertiary" text={pageData.create.btnCancel} onClick={onCancel} />
            )}
            {detailMaterialType !== DETAIL_MATERIAL_TYPE.CREATE && (
              <FnbButton
                danger
                permission={PermissionKeys.DELETE_MATERIAL}
                iconHeader={<TrashIcon></TrashIcon>}
                onClick={handleOpenDeletePopup}
                variant="tertiary"
              ></FnbButton>
            )}
            {detailMaterialType !== DETAIL_MATERIAL_TYPE.CREATE &&
              (activateMaterial ? (
                <FnbButton
                  variant="secondary"
                  permission={PermissionKeys.DEACTIVATE_MATERIAL}
                  text={pageData.deactivate}
                  onClick={onDeActiveMaterialAsync}
                ></FnbButton>
              ) : (
                <FnbButton
                  variant="secondary"
                  permission={PermissionKeys.ACTIVATE_MATERIAL}
                  text={pageData.activate}
                  onClick={onActivateMaterialAsync}
                ></FnbButton>
              ))}
          </>,
        ]}
        suffix={
          detailMaterialType !== DETAIL_MATERIAL_TYPE.VIEW && (
            <FnbSuffixHeadingGuideline
              purposeType={GuidancePurposeType.Ingredient}
              visible={detailMaterialType === DETAIL_MATERIAL_TYPE.CREATE ? true : pageTitle !== ""}
            />
          )
        }
      />
      <div className="material-form">{renderContent()}</div>
      <DeleteMaterial
        isModalVisible={isModalPOVisible}
        infoMaterial={infoMaterial}
        handleCancel={() => setIsModalPOVisible(false)}
      />
      <DeleteProductRecipes
        isModalVisible={isModalProductVisible}
        infoMaterial={infoMaterial}
        handleCancel={() => setIsModalProductVisible(false)}
        onDelete={() => onDeleteProductPriceMaterial(param.id)}
      />
      <DeleteConfirmComponent
        visible={leaverConfirmVisible}
        skipPermission={true}
        onCancel={onDiscard}
        onOk={onCancelEditMaterialForm}
        isChangeForm={isChangeForm}
      />
      <UpdateCostPerUnitComponent
        isModalVisible={isModalCostUnitVisible}
        handleCancel={() => setIsModalCostUnitVisible(false)}
        materialId={param?.id}
        setCostPerUnit={setCostPerUnitNumber}
        tableFuncs={tableFuncs}
        renderBaseUnitLabel={renderBaseUnitLabel}
      />
      <CheckDeleteMaterial
        isModalVisible={checkDeleteModalVisible}
        listObjectLockMaterial={listObjectLockMaterial}
        titleModal={titleModalCheckDeleteMaterial}
        handleCancel={() => setCheckDeleteModalVisible(false)}
        materialDependencyType={materialDependencyType}
      />
      {renderModalDelete()}
    </>
  );
};

export default MaterialDetail;
