import { FnbButton } from "components/fnb-button/fnb-button";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { ConfirmStyle } from "constants/level-menu.constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Prompt } from "react-router";
import { hasPermission } from "utils/helpers";

/**
 * Cancel to leave with confirm dialog
 * @param {*} param
 * @returns
 */
export function FnbCancelButton({
  buttonText,
  skipPermission,
  permission,
  className = "",
  onOk,
  onCancel,
  showWarning,
  buttonCancelText,
  minWidth,
  contentWarning,
  buttonOkText,
}) {
  const [t] = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRouteChanged, setIsRouteChanged] = useState(false);

  useEffect(() => {
    setIsRouteChanged(showWarning);
  }, [showWarning]);

  const showWarningDialog = () => {
    if (showWarning && showWarning === true) {
      setIsModalVisible(true);
      setIsRouteChanged(false);
    } else {
      if (onOk) {
        onOk();
      }
    }
  };

  const onConfirm = () => {
    setIsModalVisible(false);
    if (onOk) {
      onOk();
    }
  };

  const onCloseWarningDialog = () => {
    setIsRouteChanged(true);
    setIsModalVisible(false);
    if (onCancel) {
      onCancel();
    }
  };

  const renderWarningDialog = () => {
    return (
      <ConfirmDialogComponent
        title={t("leaveDialog.confirmation")}
        okText={buttonOkText ?? t("button.confirmLeave")}
        cancelText={buttonCancelText ? buttonCancelText : t("button.ignore")}
        onOk={onConfirm}
        onCancel={onCloseWarningDialog}
        visible={isModalVisible}
        type={ConfirmStyle.LEAVER}
        contentElement={<span dangerouslySetInnerHTML={{ __html: contentWarning ?? t("messages.leaveForm") }}></span>}
      />
    );
  };

  const renderActionButton = () => {
    return (
      (!permission || hasPermission(permission) || skipPermission) && (
        <>
          <FnbButton
            variant="tertiary"
            onClick={showWarningDialog}
            className={`${className}`}
            text={buttonText ?? t("button.cancel", "Cancel")}
            minWidth={minWidth}
          />
          {renderWarningDialog()}
        </>
      )
    );
  };

  return (
    <>
      <Prompt
        when={isRouteChanged === true}
        message={() => {
          setIsModalVisible(true);
          setIsRouteChanged(false);
          return false;
        }}
      />
      {renderActionButton()}
    </>
  );
}
