import http from "../../utils/http-common";

const controller = "promotioncampaign";

const createPromotionCampaignBuyXGetYAsync = (data) => {
  return http.post(`/${controller}`, data);
};

const getPromotionCampaignDetailOrderListAsync = (
  languageCode,
  pageNumber,
  pageSize,
  keySearch,
  promotionCampaignId,
  flashSaleId,
  discountCodeId,
  startDate,
  endDate,
  typeOptionDate,
  branchId,
) => {
  return http.get(
      `/${controller}/get-promotions-campaign-detail-order-list?languageCode=${languageCode}&pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}&promotionCampaignId=${promotionCampaignId}&flashSaleId=${flashSaleId}&discountCodeId=${discountCodeId}&startDate=${startDate}&endDate=${endDate}&typeOptionDate=${typeOptionDate}&branchId=${branchId}`,
  );
};

const getPromotionCampaignDetailCustomerListAsync = (
  pageNumber,
  pageSize,
  keySearch,
  promotionCampaignId,
  flashSaleId,
  discountCodeId,
  startDate,
  endDate,
  typeOptionDate,
  branchId,
) => {
  return http.get(
      `/${controller}/get-promotions-campaign-detail-customer-list?pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}&promotionCampaignId=${promotionCampaignId}&flashSaleId=${flashSaleId}&discountCodeId=${discountCodeId}&startDate=${startDate}&endDate=${endDate}&typeOptionDate=${typeOptionDate}&branchId=${branchId}`,
  );
};

const promotionCampaignDataService = {
  createPromotionCampaignBuyXGetYAsync,
  getPromotionCampaignDetailOrderListAsync,
  getPromotionCampaignDetailCustomerListAsync,
};
export default promotionCampaignDataService;
