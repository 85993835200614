import {
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Row,
  Table,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { FnbCancelButton } from "components/cancel-button";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbCustomUploadImageComponent } from "components/fnb-custom-upload-image/fnb-custom-upload-image.component";
import FnbSuffixHeadingGuideline from "components/fnb-heading-guidline/fnb-suffix-heading-guideline.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import FnbModalCreateTopping from "components/fnb-modal-create-topping/fnb-modal-create-topping.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbNotifyDialog } from "components/fnb-notify-dialog/fnb-notify-dialog.component";
import { FnbSelectOptions } from "components/fnb-select-options/fnb-select-options";
import { FnbSelect } from "components/fnb-select/fnb-select";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import {
  IngredientSearchModal,
  IngredientSearchModalType,
} from "components/ingredient-search-modal/ingredient-search-modal";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import SelectEditComponent from "components/select-edit-new-item/select-edit-new-item";
import FnbSwitch from "components/switch";
import { DELAYED_TIME, EnumStoreRegion } from "constants/default.constants";
import { GuidancePurposeType } from "constants/guidance-link.constants";
import {
  AddCircleOutlined,
  CustomInfoCircleIcon,
  CustomTickCircleIcon,
  CustomTrashIcon,
  Ellipse,
  ExclamationIcon,
  ImageMaterialDefault,
  LampOn,
  MessageQuestionIcon,
  NewDragIcon,
  TrashFill,
  UploadLogoIcon,
} from "constants/icons.constants";
import { images } from "constants/images.constants";
import { ConfirmStyle } from "constants/level-menu.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { Platform } from "constants/platform.constants";
import { ProductStatus } from "constants/product-status.constants";
import { StampType } from "constants/stamp-type.constants";
import { currency } from "constants/string.constants";
import taxType from "constants/taxType.constants";
import branchDataService from "data-services/branch/branch-data.service";
import productCategoryDataService from "data-services/product-category/product-category-data.service";
import productDataService from "data-services/product/product-data.service";
import stampDataService from "data-services/stamp/stamp-data.service";
import taxDataService from "data-services/tax/tax-data.service";
import unitDataService from "data-services/unit/unit-data.service";
import cloneDeep from "lodash/cloneDeep";
import { StampTemplatePreviewComponent } from "pages/settings/stamp/components/stamp-template-preview.component";
import CreateNewTax from "pages/store/fee-tax/tax/components/create-tax.component";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { getUserInfo } from "services/auth.service";
import languageService from "services/language/language.service";
import theme from "theme";
import {
  checkOnKeyPressValidation,
  executeAfter,
  formatNumberDecimalOrInteger,
  formatterDecimalNumber,
  getApiError,
  getCurrency,
  getValidationMessages,
  getValidationMessagesWithParentField,
  hasPermission,
  isDecimalKey,
  parserDecimalNumber,
  randomGuid,
} from "utils/helpers";
import productDefaultImage from "../../../assets/images/combo-default-img.jpg";
import { DialogHowToSetupProductRecipes } from "../components/dialogHowToSetUpProductRecipe";
import { DialogHowToSetupProductVariants } from "../components/dialogHowToSetUpProductVariants";
import CreateNewOptionManagement from "../option/components/create-new-option-management.component";
import "../product-dependencies.scss";
import DeleteProductComponent from "../product-management/components/delete-product.component";
import EditProductInCombo from "./edit-product-in-combo.component";
import "./edit-product.scss";

const { Text } = Typography;
const { TabPane } = Tabs;

export default function EditProductPage(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const fnbImageSelectRef = React.useRef();

  const tableMaterialSettings = {
    page: 1,
    pageSize: 20,
  };

  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [prices, setPrices] = useState([{}]);
  const [initDataOptions, setInitDataOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [initDataMaterials, setInitDataMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [dataInventoryTable, setDataInventoryTable] = useState([]);
  const [listPlatforms, setListPlatforms] = useState([]);
  const [isUnitNameExisted, setIsUnitNameExisted] = useState(false);
  const [isNameTouched, setIsNameTouched] = useState(false);
  const [showConfirmTouched, setShowConfirmTouched] = useState(false);
  const [listAllProductCategory, setListAllProductCategory] = useState([]);
  const [listAllTax, setListAllTax] = useState([]);
  const [pageNumber, setPageNumber] = useState(tableMaterialSettings.page);
  const [units, setUnits] = useState([]);
  const [nameUnit, setNameUnit] = useState("");
  const [isTopping, setTopping] = useState(false);
  const [isPrintStampValue, setIsPrintStampValue] = useState();
  const [titleName, setTitleName] = useState("");
  const selectNewItemFuncs = React.useRef(null);
  const [form] = Form.useForm();
  const [totalCost, setTotalCost] = useState(0);
  const [unitId, setUnitId] = useState(null);
  const [activate, setActivate] = useState(null);
  const [isSelectedMaterial, setIsSelectedMaterial] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [valuePlatforms, setValuePlatforms] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [titleModal, setTitleModal] = useState("");
  const [allproductToppings, setAllProductToppings] = useState([]);
  const [productToppings, setProductToppings] = useState([]);
  const [productToppingSelected, setProductToppingSelected] = useState([]);
  const [isVisibleProductToppingModal, setIsVisibleProductToppingModal] = useState(false);
  const [isModalComboVisible, setIsModalComboVisible] = useState(false);
  const [comboInfos, setComboInfos] = useState([]);
  const [valuePlatformInits, setValuePlatformInits] = useState([]);
  const [isBelongsActiveCombo, setIsBelongsActiveCombo] = useState(false);
  const [preventDeleteProduct, setPreventDeleteProduct] = useState({});
  const [activeKeyInventoryTracking, setActiveKeyInventoryTracking] = useState("0");
  const [statusId, setStatusId] = useState(null);
  const [isModalNotificationVisible, setIsModalNotificationVisible] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [notificationDatasource, setNotificationDatasource] = useState(null);
  const [notificationTable, setNotificationTable] = useState(null);
  const [isMobileSize, setIsMobileSize] = useState(window.innerWidth < 500);
  const [isPreventEditProduct, setIsPreventEditProduct] = useState(false);
  const storeLogoUrl = useSelector((state) => state?.session?.storeLogo);
  const [stampConfigs, setStampConfigs] = useState();
  const [stampDatas, setStampDatas] = useState();
  const timeOutTouched = useRef(null);
  const [toppingOptionValue, setToppingOptionValue] = useState(false);
  const [isShowModalSearchMaterial, setIsShowModalSearchMaterial] = useState(false);
  const [formAddCategory] = Form.useForm();
  const [nameCategory, setNameCategory] = useState("");
  const [nameTax, setNameTax] = useState("");
  const selectNewCategoryFuncs = React.useRef(null);
  const [isCategoryNameExisted, setIsCategoryNameExisted] = useState(false);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isNameTaxExisted, setIsNameTaxExisted] = useState(false);
  const [isTaxModalVisible, setIsTaxModalVisible] = useState(false);
  const [branchOptionValue, setBranchOptionValue] = useState(false);
  const [disableAllBranches, setDisableAllBranches] = useState(false);
  const [branches, setBranches] = useState([]);
  const [newOptionName, setNewOptionName] = useState("");
  const [isOptionNameExisted, setIsOptionNameExisted] = useState(false);
  const [showAddNewOptionForm, setShowAddNewOptionForm] = useState(false);
  const [createOptionName, setCreateOptionName] = useState("");
  const [isOptionNameSelected, setIsOptionNameSelected] = useState(false);
  const loggedUserInfo = getUserInfo();
  const isInternationalRegion = loggedUserInfo?.storeType === EnumStoreRegion.INTERNATIONAL;
  const [currencyCode, setCurrencyCode] = useState(false);

  const [isShowGuideProductVariants, setIsShowGuideProductVariants] = useState(false);
  const [isShowGuideProductRecipes, setIsShowGuideProductRecipes] = useState(false);
  const [isShowModalAddTopping, setIsShowModalAddTopping] = useState(false);
  const [searchToppingValue, setSearchToppingValue] = useState("");

  const [storeInfo, setStoreInfo] = useState({});
  const [isClickSubmitForm, setIsClickSubmitForm] = useState(false);

  useEffect(() => {
    verifyProductCanEdit();
    getInitData();
    getInitialStampData();
    getBranches();
    window.addEventListener("resize", updateDimensions);

    return () => {
      setIsNameTouched(false);
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const pageData = {
    notificationTitle: t("form.notificationTitle"),
    title: t("productManagement.addProduct"),
    btnCancel: t("button.cancel"),
    btnSave: t("button.saveChanges"),
    btnAddNew: t("button.addNew"),
    btnDelete: t("button.delete"),
    action: t("button.action"),
    confirmDelete: t("leaveDialog.confirmDelete"),
    confirmDeleteMessage: t("messages.confirmDeleteMessage"),
    productDeleteSuccess: t("productManagement.productDeleteSuccess"),
    productDeleteFail: t("productManagement.productDeleteFail"),
    productInformation: {
      title: t("productManagement.productInformation.title"),
      name: {
        label: t("productManagement.productInformation.name"),
        placeholder: t("productManagement.productInformation.namePlaceholder"),
        required: true,
        maxLength: 100,
        validateMessage: t("productManagement.productInformation.nameValidateMessage"),
        toppingName: t("productManagement.productInformation.toppingName"),
        toppingNamePlaceholder: t("productManagement.productInformation.toppingNamePlaceholder"),
        toppingValidateMessage: t("productManagement.productInformation.toppingValidateMessage"),
      },
      totalProductCost: t("productManagement.productInformation.totalProductCost"),
    },
    generalInformation: {
      title: t("productManagement.generalInformation.title"),
      name: {
        label: t("productManagement.generalInformation.name"),
        placeholder: t("productManagement.generalInformation.namePlaceholder"),
        required: true,
        maxLength: 100,
        validateMessage: t("productManagement.generalInformation.nameValidateMessage"),
      },
      description: {
        label: t("productManagement.generalInformation.description"),
        placeholder: t("productManagement.description.placeholder"),
        required: false,
        maxLength: 255,
      },
      uploadImage: t("productManagement.generalInformation.uploadImages"),
    },
    pricing: {
      pricesAndVariations: t("productManagement.pricing.pricesAndVariations"),
      productPrice: t("productManagement.pricing.productPrice"),
      title: t("productManagement.pricing.price"),
      addPrice: t("productManagement.pricing.addPrice"),
      price: {
        label: t("productManagement.pricing.price"),
        placeholder: t("productManagement.pricing.pricePlaceholder"),
        required: true,
        max: 999999999,
        min: 0,
        format: "^[0-9]*$",
        validateMessage: t("productManagement.pricing.priceRange"),
      },
      priceName: {
        label: t("productManagement.pricing.priceName"),
        placeholder: t("productManagement.pricing.priceNamePlaceholder"),
        required: true,
        maxLength: 100,
        validateMessage: t("productManagement.pricing.priceNameValidateMessage"),
      },
    },
    tax: {
      taxNameExisted: t("productManagement.tax.taxNameExisted"),
      pleaseEnterNameTax: t("productManagement.unit.pleaseEnterNameTax"),
    },
    unit: {
      title: t("productManagement.unit.title"),
      unitPlaceholder: t("productManagement.unit.unitPlaceholder"),
      pleaseSelectUnit: t("productManagement.unit.pleaseSelectUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseEnterNameUnit"),
      unitNameExisted: t("productManagement.unit.unitNameExisted"),
      recipe: t("productManagement.unit.recipe"),
    },
    optionInformation: {
      title: t("productManagement.option.title"),
      selectOption: t("productManagement.option.placeholder"),
      pleaseSelectOption: t("option.pleaseSelectOption"),
      defaultOptionText: t("option.defaultOptionText"),
    },
    inventoryTracking: {
      title: t("inventoryTracking.title"),
      byMaterial: t("inventoryTracking.byMaterial"),
      totalCost: t("table.totalCost"),
      pleaseEnterQuantity: t("inventoryTracking.pleaseEnterQuantity"),
      selectMaterial: t("inventoryTracking.selectMaterial"),
      pleaseSelectMaterial: t("inventoryTracking.pleaseSelectMaterial"),
      quantityMoreThanZero: t("inventoryTracking.quantityGreaterThanZero"),
      pleaseSetupRecipe: t("inventoryTracking.pleaseSetupRecipe"),
      table: {
        materialName: t("table.materialName"),
        quantity: t("table.quantity"),
        unit: t("table.unit"),
        cost: t("table.cost"),
        totalCost: t("table.totalCost"),
        action: t("table.action"),
      },
      recipeTip: t("productManagement.recipe.recipeTip"),
    },
    productCategory: {
      label: t("productManagement.category.title"),
      placeholder: t("productManagement.category.placeholder"),
      pleaseEnterNameCategory: t("materialCategory.pleaseEnterNameCategory"),
      nameCategoryExisted: t("materialCategory.nameCategoryExisted"),
      pleaseSelectCategory: t("productManagement.category.pleaseSelectCategory"),
    },
    includeTopping: t("productManagement.includeTopping"),
    allToppings: t("productManagement.allToppings"),
    toppingPlaceholder: t("productManagement.toppingPlaceholder"),
    productNameExisted: t("productManagement.productNameExisted"),
    fileSizeLimit: t("productManagement.fileSizeLimit"),
    productEditedSuccess: t("productManagement.productEditedSuccess"),
    leaveWarningMessage: t("productManagement.leaveWarningMessage"),
    cancelText: t("button.ignore"),
    okText: t("button.confirmLeave"),
    topping: t("productManagement.isTopping"),
    taxLabel: t("table.tax"),
    pleaseSelectTax: t("table.pleaseSelectTax"),
    media: {
      title: t("media.title"),
      textNonImage: t("media.textNonImage"),
    },
    platform: {
      title: t("platform.title"),
    },
    upload: {
      adFromUrl: "Add from URL",
    },
    discardBtn: t("button.discard"),
    confirmLeaveBtn: t("button.confirmLeave"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    table: {
      name: t("productManagement.table.name"),
      action: t("productManagement.table.action"),
    },
    addTopping: t("productManagement.addTopping"),
    toppingSelected: t("productManagement.toppingSelected"),
    selectTopping: t("productManagement.selectTopping"),
    bestDisplayImage: t("messages.imageBestDisplay"),
    productHasActiveCombos: t("messages.productHasActiveCombos"),
    productHasActiveCampaigns: t("messages.productHasActiveCampaigns"),
    buttonIGotIt: t("form.buttonIGotIt"),
    flashSale: t("promotion.flashSale.name"),
    comboName: t("combo.generalInformation.comboName"),
    campaignName: t("promotion.flashSale.name"),
    no: t("table.no"),
    active: t("status.active"),
    inactive: t("status.inactive"),
    productActivatedSuccess: t("productManagement.productActivatedSuccess"),
    productDeactivatedSuccess: t("productManagement.productDeactivatedSuccess"),
    btnIgnore: t("productManagement.btnIgnore"),
    btnContinueUpdate: t("productManagement.btnContinueUpdate"),
    othersSetting: {
      title: t("productManagement.otherSetting.title"),
      isPrintStampTitle: t("productManagement.otherSetting.isPrintStampTitle"),
      stampPreviewDescription: t("productManagement.otherSetting.stampPreviewDescription"),
    },
    productImage: {
      title: t("productManagement.productImage.title"),
    },
    addProductCategory: t("productCategory.addProductCategory"),
    branch: {
      title: t("productCategory.branch.title"),
      all: t("productCategory.branch.all"),
      displayAll: t("productCategory.branch.displayAll"),
      displaySpecific: t("productCategory.branch.displaySpecific"),
      placeholder: t("productCategory.branch.selectBranchPlaceholder"),
      validateMessage: t("productCategory.branch.selectBranchValidateMessage"),
    },
    productCategoryAddedSuccess: t("productCategory.productCategoryAddedSuccess"),
    priority: {
      title: t("productCategory.priority.title"),
      placeholder: t("productCategory.priority.placeholder"),
      validateMessage: t("productCategory.priority.validateMessage"),
      tooltip: t("productCategory.priority.tooltip"),
    },
    cancel: t("button.cancel"),
    categoryName: t("productCategory.categoryName"),
    createCategory: t("productCategory.createCategory"),
    searchMaterial: t("productManagement.recipe.placeholder"),
    ingredientSearch: t("material.ingredientSearch"),
    hint: {
      howToSetUpProductVariants: t("productManagement.hint.howToSetUpProductVariants"),
      howToSetUpProductRecipes: t("productManagement.hint.howToSetUpProductRecipes"),
      howToSetUpRecipes: t("productManagement.hint.howToSetUpRecipes"),
      optionDescriptionTooltip: t("productManagement.hint.optionDescriptionTooltip"),
      POSCashRegister: t("productManagement.hint.platformGuide.POSCashRegister"),
      POSDevices: t("productManagement.hint.platformGuide.POSDevices"),
      POSServing: t("productManagement.hint.platformGuide.POSServing"),
      storeApp: t("productManagement.hint.platformGuide.storeApp"),
      storeWebIs: t("productManagement.hint.platformGuide.storeWebIs"),
      storeWebLink: t("productManagement.hint.platformGuide.storeWebLink"),
      storeWebExample: t("productManagement.hint.platformGuide.storeWebExample"),
    },
    pleaseEnterMax1e9: t("messages.valueShouldBeFrom0to999999999"),
  };

  const getInitMaterial = async () => {
    const resDataInitialCreateProduct = await productDataService.getInitialDataCreateProduct();
    if (resDataInitialCreateProduct) {
      const materials = resDataInitialCreateProduct?.materials;
      if (materials) {
        setMaterials(materials);
        setInitDataMaterials(materials);
      }
    }
  };

  const isTouchDevice = !!("ontouchstart" in window || navigator.maxTouchPoints);

  const tableComboSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.comboName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/combo/detail/${record?.id}`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableCombo403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.comboName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscountSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link
            to={`/store/discount/detail/${record?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="combo-name"
          >
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscount403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableFlashSaleSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link
            to={`/store/flashSale/view/${record?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="combo-name"
          >
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableFlashSale403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscountCodeSettings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link
            to={`/store/discountCode/view/${record?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="combo-name"
          >
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const tableDiscountCode403Settings = [
    {
      title: pageData.no,
      dataIndex: "id",
      key: "id",
      align: "left",
      width: "40%",
      render: (value, record, index) => {
        return index + 1;
      },
    },
    {
      title: pageData.campaignName,
      dataIndex: "name",
      key: "name",
      align: "left",
      width: "60%",
      render: (value, record) => {
        return (
          <Link to={`/page-not-permitted`} target="_blank" rel="noopener noreferrer" className="combo-name">
            {record?.name}
          </Link>
        );
      },
    },
  ];

  const getInitData = async () => {
    productDataService.getProductByIdAsync(match?.params?.id).then((data) => {
      setTopping(data?.product?.isTopping);
      setTitleName(data?.product?.name);

      if (data?.product?.productToppingIds?.length > 0 && !data?.product?.isIncludedAllToppings) {
        const toppingNotSelected = data?.productToppings?.filter(
          (item) => !data?.product?.productToppingIds?.includes(item.id),
        );
        setProductToppings(toppingNotSelected);

        const toppingSelected = data?.productToppings?.filter((item) =>
          data?.product?.productToppingIds?.includes(item.id),
        );
        setAllProductToppings(toppingNotSelected);
        setProductToppingSelected(toppingSelected);
      } else {
        setAllProductToppings(data?.productToppings);
        setToppingOptionValue(data?.product?.isIncludedAllToppings);
        setProductToppings(data?.productToppings);
      }

      setStatusId(data?.product?.statusId);
      if (data?.product?.statusId === ProductStatus.Activate) {
        setActivate("productManagement.deactivate");
      } else {
        setActivate("productManagement.activate");
      }

      if (data?.product?.options) {
        setOptions(data?.product?.options);
        setInitDataOptions(data?.product?.options);
      }

      setUnits(data?.product?.units);

      if (data?.product?.materials) {
        setMaterials(data?.product?.materials);
        setInitDataMaterials(data?.product?.materials);
      }
      setListAllProductCategory(data?.product?.allProductCategories);

      if (data?.product?.taxes) {
        setListAllTax(
          data?.product?.taxes?.map((tax) => ({
            id: tax.id,
            name: tax?.formatName,
            originalName: tax?.name,
          })),
        );
      }

      if (data?.product?.platforms) {
        setListPlatforms(data?.product?.platforms);
      }

      let listSelectedOption = [];
      let listRestOptions = [];
      data?.product?.listOptionIds.map((optionId) => {
        let selectedOption = data?.product?.options.find((o) => o.id === optionId);
        if (selectedOption) {
          listSelectedOption.push(selectedOption);
        }
      });

      setValuePlatforms(data?.product?.listPlatformIds);
      setValuePlatformInits(data?.product?.listPlatformIds);
      setIsPrintStampValue(data?.product?.isPrintStamp);

      data?.product?.options.map((option) => {
        listRestOptions.push(option);
      });

      listSelectedOption.map((option) => {
        var index = listRestOptions.indexOf(option);
        if (index !== -1) {
          listRestOptions.splice(index, 1);
        }
      });

      setSelectedOptions(listSelectedOption);
      setOptions(listRestOptions);

      const pricesData = [];
      const inventoryTable = [];
      if (data?.product?.productPrices.length > 0) {
        data?.product?.productPrices.map((price, index) => {
          pricesData.push({
            position: index,
            id: price?.id,
            name: price?.priceName,
            price: price?.priceValue,
            isPriceBelongsCombo: price?.isPriceBelongsCombo,
          });
        });
        setPrices(pricesData);
      }

      setTotalCost(data?.product?.productInventoryData[0]?.totalCost);
      data?.product?.productInventoryData.map((productInventory) => {
        let listSelectedMaterials = [];
        let listRestMaterials = [];
        let listInitMaterials = [];

        data?.product?.materials.map((material) => {
          listInitMaterials.push(material);
          listRestMaterials.push(material);
        });

        productInventory?.listProductPriceMaterial.map((material) => {
          const selectedMaterials = listInitMaterials.find((o) => o.id === material?.key);
          listSelectedMaterials.push(selectedMaterials);
        });

        if (productInventory?.listProductPriceMaterial.length > 0) {
          listSelectedMaterials.map((material) => {
            var index = listRestMaterials.indexOf(material);
            if (index !== -1) {
              listRestMaterials.splice(index, 1);
            }
          });
          setMaterials(listRestMaterials);
        }
        inventoryTable.push(productInventory);
      });

      setDataInventoryTable(inventoryTable);
      const initData = {
        product: {
          description: data?.product?.description,
          name: data?.product?.name,
          productCategoryId: data?.product?.productCategoryId,
          taxId: data?.product?.tax?.id,
          unitId: data?.product?.unit?.id,
          price: data?.product?.productPrices.length === 1 ? data?.product?.productPrices[0].priceValue : null,
          prices: pricesData,
        },
      };

      /// Set value into select new item dropdown list component
      if (selectNewItemFuncs.current) {
        selectNewItemFuncs.current(data?.product?.unit?.id);
      }
      setUnitId(data?.product?.unit?.id);

      /// Update image
      if (fnbImageSelectRef && fnbImageSelectRef.current) {
        fnbImageSelectRef.current.setImage(data?.product?.thumbnail);
      }

      if (data?.product?.thumbnail) {
        setImage(data?.product?.thumbnail);
      }

      setIsBelongsActiveCombo(data?.isBelongsActiveCombo);
      form.setFieldsValue(initData);
    });
  };

  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const onSubmitForm = async () => {
    setIsClickSubmitForm(true);
    let checkChangePlatForm =
      (valuePlatformInits.find((x) => x === Platform.POS?.toLowerCase()) &&
        !valuePlatforms.find((x) => x === Platform.POS?.toLowerCase())) ||
      (valuePlatformInits.find((x) => x === Platform.GoFnBApp?.toLowerCase()) &&
        !valuePlatforms.find((x) => x === Platform.GoFnBApp?.toLowerCase()));

    if (checkChangePlatForm) {
      await productDataService
        .getProductInComboByProductIdAsync(match?.params?.id)
        .then((res) => {
          if (!res.isEditProduct) {
            setComboInfos(res.combos);
            setIsModalComboVisible(true);
          } else {
            editProduct();
          }
          setIsLoading(false);
        })
        .catch((errs) => {
          form.setFields(getValidationMessagesWithParentField(errs, "product"));
          setIsLoading(false);
        });
    } else {
      if (isNameTouched) {
        checkExistName();
      } else {
        editProduct();
      }
    }
  };

  const editProduct = async () => {
    if (fnbImageSelectRef && fnbImageSelectRef.current) {
      var imageUrl = fnbImageSelectRef.current.getImage();
    }
    form
      .validateFields()
      .then(async (values) => {
        let optionIds = [];
        selectedOptions.map((o) => {
          optionIds.push(o.id);
        });
        const editProductRequestModel = {
          units: units,
          ...values.product,
          optionIds: optionIds,
          image: imageUrl[0]?.data_url,
          isTopping: isTopping,
          productId: match?.params?.id,
          platformIds: valuePlatforms,
          productToppingIds: toppingOptionValue
            ? []
            : productToppingSelected?.map(function (item) {
                return item["id"];
              }),
          isPrintStamp: isPrintStampValue,
          isIncludedAllToppings: toppingOptionValue,
        };

        // Check valid material list
        const validMaterials = editProductRequestModel?.materials?.priceName?.filter((material) => material);
        if (
          validMaterials?.length > 0 &&
          (editProductRequestModel?.price > 0 ||
            (editProductRequestModel.prices && editProductRequestModel?.prices?.length === validMaterials?.length))
        ) {
          if (editProductRequestModel.prices) {
            editProductRequestModel.prices.map((item, index) => {
              let listMaterials = [];
              dataInventoryTable[index]?.listProductPriceMaterial?.map((material, index) => {
                var newMaterials = {
                  materialId: material.key,
                  quantity: material.quantity,
                  unitCost: material.unitCost,
                  unitId: material.unitId ?? material.selectedUnit,
                };
                listMaterials.push(newMaterials);
              });
              item.materials = listMaterials;
            });
            editProductRequestModel.materials = null;
          } else {
            let listMaterials = [];
            dataInventoryTable[0]?.listProductPriceMaterial?.map((material, index) => {
              var newMaterials = {
                materialId: material.key,
                quantity: material.quantity,
                unitCost: material.unitCost,
                unitId: material.unitId ?? material.selectedUnit,
              };
              listMaterials.push(newMaterials);
            });
            editProductRequestModel.materials = listMaterials;
          }

          try {
            productDataService
              .updateProductAsync(editProductRequestModel)
              .then((res) => {
                if (res) {
                  message.success(pageData.productEditedSuccess);
                  onCompleted();
                }
                setIsLoading(false);
              })
              .catch((errs) => {
                setIsClickSubmitForm(false);
                form.setFields(getValidationMessagesWithParentField(errs, "product"));
                setIsLoading(false);
              });
          } catch (errors) {
            setIsClickSubmitForm(false);
            // build error message
            const errorData = getApiError(errors);
            const errMessage = t(errorData?.message, {
              comboName: errorData?.comboName,
              productName: errorData?.productName,
            });

            message.error(errMessage);
            getInitData();
            setIsLoading(false);
          }
        } else {
          let position = editProductRequestModel?.materials?.priceName?.length || 0;
          editProductRequestModel?.materials?.priceName?.forEach((item, index) => {
            if (typeof item !== "object") position = index;
          });
          getTotalCost(`${position}`, true);
          setIsSelectedMaterial(false);
          scrollToElement("error-recipe-message");
          setIsLoading(false);
        }
      })
      .catch((errors) => {
        setIsClickSubmitForm(false);
        setIsLoading(false);
        if (errors?.errorFields?.length > 0) {
          const elementId = `basic_${errors?.errorFields[0]?.name?.join("_")}_help`;
          scrollToElement(elementId);

          let tabPanelMaterialErr = [];
          let otherErr = [];
          errors?.errorFields?.map((errorItem) => {
            let checkMaterialQuantityErr = errorItem?.name?.find((x) => x === "material");
            if (checkMaterialQuantityErr) {
              tabPanelMaterialErr.push(errorItem);
            } else {
              otherErr.push(errorItem);
            }
          });
          if (tabPanelMaterialErr?.length > 0 && otherErr?.length === 0) {
            message.error(
              tabPanelMaterialErr?.[0]?.errors?.[0]?.props?.message ?? tabPanelMaterialErr?.[0]?.errors?.[0],
            );
            setActiveKeyInventoryTracking(tabPanelMaterialErr[0]?.name[3]?.toString());
            let nameInputFirst = `basic_${tabPanelMaterialErr[0]?.name?.join("_")}`;
            scrollToElement(nameInputFirst);
          }
        }
      });
  };

  const onChangeStatus = async () => {
    if (statusId === ProductStatus.Activate) {
      const productID = match?.params?.id;
      var relatedObjects = await productDataService.getAllProductDependenciesByIdAsync(productID);
      if (relatedObjects?.isHasDependencies) {
        //Show notification dialog
        setIsModalNotificationVisible(true);
        let message = t(pageData.productHasActiveCombos, { name: titleName });
        setNotificationMessage(message);

        if (relatedObjects.activeCombos.length > 0) {
          if (hasPermission(PermissionKeys.VIEW_COMBO)) setNotificationTable(tableComboSettings);
          else setNotificationTable(tableCombo403Settings);
          setNotificationDatasource(relatedObjects.activeCombos);
        } else if (relatedObjects.activeDiscounts.length > 0) {
          message = t(pageData.productHasActiveCampaigns, { name: titleName });
          setNotificationMessage(message);
          if (hasPermission(PermissionKeys.VIEW_PROMOTION)) setNotificationTable(tableDiscountSettings);
          else setNotificationTable(tableDiscount403Settings);
          setNotificationDatasource(relatedObjects.activeDiscounts);
        } else if (relatedObjects.activeFlashSales.length > 0) {
          message = t(pageData.productHasActiveCampaigns, { name: titleName });
          setNotificationMessage(message);
          if (hasPermission(PermissionKeys.VIEW_FLASH_SALE)) setNotificationTable(tableFlashSaleSettings);
          else setNotificationTable(tableFlashSale403Settings);
          setNotificationDatasource(relatedObjects.activeFlashSales);
        } else if (relatedObjects.activeDiscountCodes.length > 0) {
          message = t(pageData.productHasActiveCampaigns, { name: titleName });
          setNotificationMessage(message);
          if (hasPermission(PermissionKeys.VIEW_DISCOUNT_CODE)) setNotificationTable(tableDiscountCodeSettings);
          else setNotificationTable(tableDiscountCode403Settings);
          setNotificationDatasource(relatedObjects.activeDiscountCodes);
        }
        return;
      } else setIsModalNotificationVisible(false);
    }

    var res = await productDataService.changeStatusAsync(match?.params?.id);
    if (res) {
      if (statusId === ProductStatus.Deactivate) {
        message.success(pageData.productActivatedSuccess);
      } else {
        message.success(pageData.productDeactivatedSuccess);
      }
      getInitData();
    }
  };

  const onSelectOption = (key) => {
    let selectedOption = options.filter((o) => o !== undefined)?.find((o) => o.id === key);
    let restOptions = options.filter((o) => o !== undefined)?.filter((o) => o.id !== key);
    setOptions(restOptions);
    selectedOptions.push(selectedOption);
  };

  const onDeleteSelectedOption = (key) => {
    let restOptions = selectedOptions.filter((o) => o.id !== key);
    setSelectedOptions(restOptions);

    let initDataOption = initDataOptions.find((o) => o.id === key);
    options.push(initDataOption);
  };

  const onNameTouched = () => {
    if (timeOutTouched.current) {
      clearTimeout(timeOutTouched.current);
    }
    timeOutTouched.current = setTimeout(() => {
      setIsNameTouched(true);
    }, 200);
  };

  const checkExistName = async () => {
    await form
      .validateFields()
      .then(async () => {
        const id = match?.params?.id;
        const name = form.getFieldValue(["product", "name"]);
        await productDataService.checkExistNameAsync({ id, name }).then((res) => {
          if (res) {
            setShowConfirmTouched(true);
            setIsLoading(false);
          } else {
            editProduct();
          }
        });
      })
      .catch((errors) => {
        setIsLoading(false);
      });
  };

  const onConfirmTouched = async () => {
    setShowConfirmTouched(false);
    editProduct();
  };

  const onCancelTouched = () => {
    setShowConfirmTouched(false);
  };

  const onChangeImage = (file) => {
    setImage(file);
  };

  const onClickAddPrice = () => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;

    const newPrice = {
      position: prices.length || 0,
      id: randomGuid(),
      isPriceBelongsCombo: false,
      name: "",
      price: "",
    };
    if (prices.length === 1) {
      prices[0].price = product.price || 0;
    }
    const listPrice = [...(product.prices ?? prices), newPrice];
    product.prices = listPrice;
    setPrices(listPrice);

    const newInventoryTable = [...dataInventoryTable];
    // Handle Material Deleted
    if (newInventoryTable?.length === 0) {
      listPrice?.forEach((price) => {
        newInventoryTable.push({
          priceId: price?.id,
          priceName: price?.name,
          listProductPriceMaterial: [],
        });
      });
    } else {
      newInventoryTable.push({
        priceId: newPrice?.id,
        priceName: "",
        listProductPriceMaterial: [],
      });
    }
    setDataInventoryTable(newInventoryTable);
    form.setFieldsValue(formValue);
    setTimeout(() => {
      const dragDropPrices = document.getElementById("dragDropPrices");
      if (dragDropPrices) dragDropPrices.scrollTop = dragDropPrices.scrollHeight;
    }, 100);
  };

  const onInputPriceName = (index, evt) => {
    let valueInput = evt?.target?.value;
    var dataInventory = [...dataInventoryTable];
    dataInventory[index].priceName = evt.target.value;
    if (valueInput.length > 0) {
      setActiveKeyInventoryTracking(index.toString());
      getTotalCost(index.toString());
    } else {
      setActiveKeyInventoryTracking("0");
      getTotalCost("0");
    }
    setDataInventoryTable(dataInventory);
  };

  const onDeletePrice = (index) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    let productPriceDeleteId = product.prices[index]?.id;
    if (product.prices.length > 0) {
      product.prices.splice(index, 1);
      product.selectedMaterials?.priceName?.splice(index, 1);
      product.prices.forEach((item, index) => (item.position = index));
    }
    setPrices(product.prices);
    var dataInventoryTableFilter = dataInventoryTable.filter((x) => x.priceId !== productPriceDeleteId);
    setDataInventoryTable(dataInventoryTableFilter);
    if (product.prices.length === 1) {
      product.price = product.prices[0].price;
      product.prices[0].position = 0;
    }
    setActiveKeyInventoryTracking("0");
    form.setFieldsValue(formValue);
  };

  //Enter Unit name and check existed
  const onNameChange = (value) => {
    if (units.filter((u) => u.name.trim().toLowerCase() === value.trim().toLowerCase()).length > 0) {
      setIsUnitNameExisted(true);
    } else {
      setIsUnitNameExisted(false);
    }
    setNameUnit(value);
  };

  const onNameTaxChange = (value) => {
    if (
      listAllTax?.filter((u) => u?.originalName?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())?.length > 0
    ) {
      setIsNameTaxExisted(true);
    } else {
      setIsNameTaxExisted(false);
    }
    setNameTax(value);
  };

  const onChangeOption = (id) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.unitId = id;
    form.setFieldsValue(formValue);
    if (selectNewItemFuncs.current) {
      selectNewItemFuncs.current(id);
      setUnitId(id);
    }
  };

  const onChangeOptionTax = (id) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.taxId = id;
    form.setFieldsValue(formValue);
  };

  //Handle add New Unit
  const addNewUnit = async (e) => {
    if (nameUnit) {
      e.preventDefault();
      let req = {
        name: nameUnit,
      };
      let res = await unitDataService.createUnitAsync(req);
      if (res.isSuccess) {
        let newItem = {
          id: res.id,
          name: res.name,
        };
        setUnits([newItem, ...units]);
        let formValue = form.getFieldsValue();
        let { product } = formValue;
        product.unitId = res.id;
        form.setFieldsValue(formValue);
        if (selectNewItemFuncs.current) {
          selectNewItemFuncs.current(res.id);
          setUnitId(res.id);
          setIsUnitNameExisted(true);
        }
      } else {
        message.error(pageData.unit.unitNameExisted);
      }
    }
  };

  const onCompleted = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      return history.push("/product-management");
    }, DELAYED_TIME);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((item, index) => (item.position = index));
    return result;
  };

  const onDragEnd = (result) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const listPrice = reorder(product.prices, sourceIndex, destinationIndex);
    product.prices = listPrice;
    setPrices(listPrice);

    const tempPriceName = product.materials.priceName[sourceIndex];
    product.materials.priceName[sourceIndex] = product.materials.priceName[destinationIndex];
    product.materials.priceName[destinationIndex] = tempPriceName;

    let listProductPrice = dataInventoryTable;
    const tempPrice = listProductPrice[sourceIndex];
    listProductPrice[sourceIndex] = listProductPrice[destinationIndex];
    listProductPrice[destinationIndex] = tempPrice;
    setDataInventoryTable(listProductPrice);

    form.setFieldsValue(formValue);
  };

  const onOptionSearch = (value) => {
    const checkOptionSelected =
      selectedOptions
        ?.filter((o) => o !== undefined)
        ?.filter((u) => u?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())?.length > 0;

    if (checkOptionSelected) {
      setIsOptionNameSelected(true);
      setIsOptionNameExisted(true);
    } else {
      setIsOptionNameExisted(
        options
          ?.filter((o) => o !== undefined)
          ?.filter((u) => u?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase())?.length > 0,
      );
      value && setNewOptionName(value);
      setIsOptionNameSelected(false);
    }
    setNewOptionName(value);
  };

  const onAddNewOption = () => {
    hasPermission(PermissionKeys.CREATE_OPTION) ? setShowAddNewOptionForm(true) : setShowAddNewOptionForm(false);
    setCreateOptionName(newOptionName);
  };

  const onCancelAddNewFormOption = async (newOptionId, optionLevel) => {
    setShowAddNewOptionForm(false);
    setNewOptionName("");
    setCreateOptionName("");
  };

  const onSuccessAddNewFormOption = async (newOptionId, optionLevel) => {
    setShowAddNewOptionForm(false);
    let newOption = { id: newOptionId, name: createOptionName, optionLevel: optionLevel };
    let listNewOption = options;
    listNewOption?.push(newOption);
    if (listNewOption) {
      setOptions(listNewOption);
      setInitDataOptions(listNewOption);
      addSelectOption(newOptionId, listNewOption);
    }
    setNewOptionName("");
    setCreateOptionName("");
  };

  const addSelectOption = (value, newOptions) => {
    setIsChangeForm(true);
    let selectedOption = newOptions.find((o) => o.id === value);
    let restOptions = newOptions.filter((o) => o.id !== value);
    setOptions(restOptions);
    selectedOption && selectedOptions.push(selectedOption);
  };

  const addNewTaxDialog = async (e) => {
    setTimeout(function () {
      setIsTaxModalVisible(true);
    }, 500);
  };

  const handleCompletedTaxModal = (value) => {
    onChangeOptionTax(value);
    taxDataService.getAllTaxByTaxTypeAsync(taxType.SellingTax).then((res) => {
      if (res) {
        setListAllTax(
          res.taxesByType.map((tax) => ({
            id: tax.id,
            name: tax?.formatName,
            originalName: tax?.name,
          })),
        );
      }
    });
    setIsTaxModalVisible(false);
  };

  const handleCancelTaxModal = () => {
    setIsTaxModalVisible(false);
  };

  const renderPrices = () => {
    const addPriceButton = (
      <div style={{ display: "flex", gap: 4 }}>
        <AddCircleOutlined width={20} height={20} color="#50429B" />
        <FnbTypography.Link
          style={{ textDecoration: "underline" }}
          text={pageData.pricing.addPrice}
          onClick={onClickAddPrice}
        ></FnbTypography.Link>
      </div>
    );

    const singlePrice = (
      <>
        <Row>
          <Col span={24}>
            <h4 className="fnb-form-label mt-24">
              {pageData.pricing.price.label}
              <span className="text-danger">*</span>
            </h4>
            <Form.Item
              name={["product", "price"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage
                      type={EnumInputValidateType.ERROR}
                      message={pageData.pricing.price.validateMessage}
                    />
                  ),
                },
                () => ({
                  validator(_, value) {
                    if (value && (value <= 0 || value >= 1e9)) {
                      return Promise.reject(
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.pricing.price.validateMessage}
                        />,
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                className="w-100 fnb-input-number"
                placeholder={pageData.pricing.price.placeholder}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                addonAfter={getCurrency()}
                disabled={isBelongsActiveCombo}
                id="product-price"
                precision={getCurrency() === currency.vnd ? 0 : 2}
                onKeyPress={(event) => {
                  const checkValidKey = checkOnKeyPressValidation(
                    event,
                    "product-price",
                    0,
                    null,
                    getCurrency() === currency.vnd ? 0 : 2,
                  );
                  if (!checkValidKey) event.preventDefault();
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="ml-2" hidden={isTopping || isBelongsActiveCombo}>
              {addPriceButton}
            </div>
          </Col>
        </Row>
      </>
    );

    const multiplePrices = (
      <>
        <DragDropContext className="mt-4" onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="list-price">
                <div
                  id="dragDropPrices"
                  style={prices.length >= 6 ? { height: 500, overflowY: "scroll" } : { minHeight: prices.length * 70 }}
                >
                  <div style={{ minHeight: prices.length * 70 }}>
                    {prices.map((price, index) => {
                      return (
                        <Draggable key={price.id} draggableId={price.id} index={index}>
                          {(provided) => (
                            <Row
                              className={`mb-4 pointer price-item`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Col span={24} className="col-title">
                                <NewDragIcon className="title-center drag-icon-edit" width={16} height={16} />
                                <Row className="mt-10 w-100">
                                  <Col span={isMobileSize ? 19 : 22}>
                                    <Row gutter={[16, 16]}>
                                      <Col xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item
                                          name={["product", "prices", price.position, "position"]}
                                          hidden={true}
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item name={["product", "prices", price.position, "id"]} hidden={true}>
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={["product", "prices", price.position, "isPriceBelongsCombo"]}
                                          hidden={true}
                                        >
                                          <Input />
                                        </Form.Item>
                                        <Form.Item
                                          name={["product", "prices", price.position, "name"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: (
                                                <div className="mt-2">
                                                  <InputValidateMessage
                                                    type={EnumInputValidateType.ERROR}
                                                    message={pageData.pricing.priceName.validateMessage}
                                                  />
                                                </div>
                                              ),
                                            },
                                          ]}
                                        >
                                          <Input
                                            className="fnb-input"
                                            onBlur={(evt) => onInputPriceName(price.position, evt)}
                                            placeholder={pageData.pricing.priceName.placeholder}
                                            maxLength={pageData.pricing.priceName.maxLength}
                                            disabled={isBelongsActiveCombo && price?.isPriceBelongsCombo}
                                            id={`product-prices-${price.position}-name`}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item
                                          name={["product", "prices", price.position, "price"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: (
                                                <div className="mt-2">
                                                  <InputValidateMessage
                                                    type={EnumInputValidateType.ERROR}
                                                    message={pageData.pricing.price.validateMessage}
                                                  />
                                                </div>
                                              ),
                                            },
                                            () => ({
                                              validator(_, value) {
                                                if (value && (value <= 0 || value >= 1e9)) {
                                                  return Promise.reject(
                                                    <InputValidateMessage
                                                      type={EnumInputValidateType.ERROR}
                                                      message={pageData.pricing.price.validateMessage}
                                                    />,
                                                  );
                                                }
                                                return Promise.resolve();
                                              },
                                            }),
                                          ]}
                                        >
                                          <InputNumber
                                            className="fnb-input-number w-100"
                                            placeholder={pageData.pricing.price.placeholder}
                                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                                            addonAfter={getCurrency()}
                                            precision={getCurrency() === currency.vnd ? 0 : 2}
                                            onKeyPress={(event) => {
                                              const checkValidKey = checkOnKeyPressValidation(
                                                event,
                                                `product-prices-${price.position}-price`,
                                                0,
                                                null,
                                                getCurrency() === currency.vnd ? 0 : 2,
                                              );
                                              if (!checkValidKey) event.preventDefault();
                                            }}
                                            disabled={isBelongsActiveCombo && price?.isPriceBelongsCombo}
                                            id={`product-prices-${price.position}-price`}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={isMobileSize ? 5 : 2} className="icon-delete-price">
                                    <span
                                      className="m-2"
                                      hidden={isBelongsActiveCombo && price?.isPriceBelongsCombo}
                                      onClick={() => onDeletePrice(price.position)}
                                    >
                                      <FnbTooltip
                                        hideTooltip={isTouchDevice}
                                        disabledStopPropagation={isTouchDevice}
                                        placement="top"
                                        title={t("button.delete")}
                                      >
                                        <CustomTrashIcon color="#50429B" />
                                      </FnbTooltip>
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Col span={24}>
          <div className="mt-2" hidden={isTopping}>
            {addPriceButton}
          </div>
        </Col>
      </>
    );

    return (
      <>
        {prices.length === 1 && singlePrice}
        {prices.length > 1 && multiplePrices}

        <Row className="w-100 mt-3" gutter={[16, 0]}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <h4 className="fnb-form-label mt-14">{pageData.taxLabel}</h4>
            <Form.Item name={["product", "taxId"]}>
              <SelectEditComponent
                showSearch
                allowClear
                placeholder={pageData.pleaseSelectTax}
                pleaseEnterName={pageData.tax.pleaseEnterNameTax}
                nameExisted={pageData.tax.taxNameExisted}
                btnAddNew={pageData.btnAddNew}
                listOption={listAllTax}
                onChangeOption={onChangeOptionTax}
                onSearch={onNameTaxChange}
                addNewItem={addNewTaxDialog}
                isNameExisted={isNameTaxExisted}
                name={nameTax}
                category={pageData.taxLabel}
                isShowSearchIcon={true}
                isLowerCase={false}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <h4 className="fnb-form-label mt-14">
              {pageData.unit.title}
              <span className="text-danger">*</span>
            </h4>
            <Form.Item
              name={["product", "unitId"]}
              rules={[
                {
                  required: true,
                  message: (
                    <InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.unit.pleaseSelectUnit} />
                  ),
                },
              ]}
            >
              <SelectEditComponent
                getPopupContainer={(trigger) => trigger.parentNode}
                showSearch
                allowClear
                functions={selectNewItemFuncs}
                placeholder={pageData.unit.unitPlaceholder}
                pleaseEnterName={pageData.unit.pleaseEnterNameUnit}
                nameExisted={pageData.unit.unitNameExisted}
                btnAddNew={pageData.btnAddNew}
                listOption={units}
                onChangeOption={onChangeOption}
                onSearch={onNameChange}
                addNewItem={addNewUnit}
                isNameExisted={isUnitNameExisted}
                name={nameUnit}
                isEditProduct={true}
                category={pageData.unit.title}
                isShowSearchIcon={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  //form item option
  const renderOptions = () => {
    return (
      <>
        <Row className="mt-3" gutter={[0, 16]}>
          {selectedOptions.map((option, index) => {
            option?.optionLevel?.sort((elementA, elementB) => elementB?.isSetDefault - elementA?.isSetDefault);
            return (
              <>
                <Col span={20}>
                  <Paragraph
                    className="option-name"
                    placement="top"
                    ellipsis={{ tooltip: option.name }}
                    color="#50429B"
                    key={option.id}
                  >
                    <Text className="option-name-text">{option.name}</Text>
                  </Paragraph>
                </Col>
                <Col span={4}>
                  <a className="mr-3 float-right">
                    <a onClick={() => onDeleteSelectedOption(option.id)}>
                      <FnbTooltip
                        hideTooltip={isTouchDevice}
                        disabledStopPropagation={isTouchDevice}
                        placement="top"
                        title={t("button.delete")}
                      >
                        <CustomTrashIcon color="#50429B" />
                      </FnbTooltip>
                    </a>
                  </a>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <div className="option-description">
                        <div className="option-description__dv--flex-horizon">
                          <div>
                            <Ellipse className="option-description__icon--eclipse" />
                          </div>
                          <div>
                            <span className="option-description__dv-default__txt">=</span>
                          </div>
                          <div>
                            <span className="option-description__dv-default__txt">
                              {pageData.optionInformation.defaultOptionText}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        id={"container-options-" + index}
                        className="w-100 container-options"
                        style={{ display: "flex", gap: 12 }}
                      >
                        {option?.optionLevel?.map((item) => {
                          return (
                            <>
                              <FnbChip isDefault={item?.isSetDefault} title={item?.name}></FnbChip>
                            </>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </>
            );
          })}
        </Row>
      </>
    );
  };

  // Function to create a new row
  const createNewRow = (item, price, dataSelected) => {
    return {
      ...item,
      isAddNew: item.unitConversionUnits ? false : true,
      priceId: price?.id ?? item?.priceId ?? dataSelected[0]?.priceId,
      priceName: price?.name ?? item?.priceName ?? dataSelected[0]?.priceName,
      unit: item?.unitName ?? item?.unit,
      material: item?.name ?? item?.material,
      unitCost: item?.costPerUnit ?? item?.cost,
      quantity: item?.key ? item.quantity : 0,
      key: item?.key ?? item.id,
    };
  };

  function onAddSelectedMaterial(dataSelected, position) {
    let updatedDataInventoryTable = [...dataInventoryTable];

    // Handle material deleted
    if (updatedDataInventoryTable?.length === 0) {
      prices?.forEach((price, index) => {
        let listProductPriceMaterial = [];
        if (index === position) {
          dataSelected?.forEach((item) => {
            const newRow = createNewRow(item, price, dataSelected);
            listProductPriceMaterial.push(newRow);
          });
        }
        updatedDataInventoryTable.push({
          priceId: price?.id,
          priceName: price?.name,
          listProductPriceMaterial: listProductPriceMaterial,
        });
      });
    } else {
      updatedDataInventoryTable?.map((inventory, index) => {
        if (index === position) {
          let listProductPriceMaterial = [];
          dataSelected?.forEach((item) => {
            const newRow = createNewRow(item, {}, dataSelected);
            listProductPriceMaterial.push(newRow);
          });
          inventory.listProductPriceMaterial = listProductPriceMaterial;
        }
        return inventory;
      });
    }
    setDataInventoryTable([...updatedDataInventoryTable]);
  }

  function onCancelModalSearchMaterial() {
    setIsShowModalSearchMaterial(false);
  }

  const clickInventoryTrackings = () => {
    setIsShowModalSearchMaterial(true);
  };

  const getDataSelected = () => {
    const position = parseInt(activeKeyInventoryTracking);
    const selectMaterials = dataInventoryTable?.[position]?.listProductPriceMaterial || [];
    return selectMaterials;
  };

  // Form item inventory trackings
  const renderInventoryTrackings = () => {
    return (
      <>
        <Row>
          <h4 className="fnb-form-label">{pageData.inventoryTracking.byMaterial}</h4>
          <Col span={24}>
            <FnbSelect
              onChange={(e) => {}}
              popupClassName="fnb-select-multiple-dropdown"
              placeholder={pageData.searchMaterial}
              onClick={() => clickInventoryTrackings()}
              fixed={true}
              multiLine={false}
              open={false}
              showSearch={false}
              isShowSearchIcon={true}
              suffixIcon={<></>}
            />
          </Col>
        </Row>
      </>
    );
  };

  const getFormSelectedMaterials = () => {
    let inventoryTableSorts = [];

    prices.map((item) => {
      var inventoryTable = dataInventoryTable.find((inventory) => inventory.priceId === item.id);
      if (inventoryTable) {
        inventoryTableSorts.push(inventoryTable);
      }
    });

    dataInventoryTable
      .filter((inventory) => !inventory.priceId)
      .forEach((inventory) => {
        inventory.priceId = prices[activeKeyInventoryTracking]?.id;
        inventory.priceName = prices[activeKeyInventoryTracking]?.name;

        let sum = 0;
        inventory?.listProductPriceMaterial?.map((material) => {
          sum = sum + material.quantity * material.unitCost;
        });
        inventory.totalCost = sum;
        inventoryTableSorts.push(inventory);
      });

    var inventoryTableSortCopy = cloneDeep(inventoryTableSorts);
    dataInventoryTable.map((item, indexPrice) => {
      item.priceId = inventoryTableSortCopy[indexPrice]?.priceId;
      item.priceName = inventoryTableSortCopy[indexPrice]?.priceName;
      item.totalCost = inventoryTableSortCopy[indexPrice]?.totalCost;
      item.listProductPriceMaterial = inventoryTableSortCopy[indexPrice]?.listProductPriceMaterial;
      inventoryTableSortCopy[indexPrice]?.listProductPriceMaterial.map((item, index) => {
        form.setFieldValue(
          ["product", "materials", "priceName", indexPrice, "material", index, "quantity"],
          item.quantity,
        );
      });
    });

    return (
      <>
        {prices?.length > 1 && (
          <Row>
            <Tabs
              onChange={(index) => getTotalCost(index, true)}
              className="w-100 recipe-product-price-tab-container"
              id="tab-inventory"
              defaultActiveKey={activeKeyInventoryTracking}
              activeKey={activeKeyInventoryTracking}
            >
              {dataInventoryTable.map((productPrice, index) => {
                return (
                  <TabPane
                    tab={
                      <div className="recipe-product-price-tab">
                        <div className="recipe-product-price-tab-icon">
                          {parseInt(activeKeyInventoryTracking) === index && <CustomTickCircleIcon />}
                        </div>
                        <FnbTypography className="recipe-product-price-tab-text" text={productPrice?.priceName} />
                      </div>
                    }
                    forceRender
                    key={index}
                  >
                    <Row>
                      <Col span={24}>
                        <FnbTable
                          columns={columnsMaterial(index)}
                          dataSource={productPrice?.listProductPriceMaterial || []}
                          pageSize={tableMaterialSettings.pageSize}
                          pageNumber={pageNumber}
                          total={productPrice?.listProductPriceMaterial?.length}
                          scrollY={96 * 5}
                          className="table-product-receipt custom-form"
                        />
                      </Col>
                    </Row>
                  </TabPane>
                );
              })}
            </Tabs>
          </Row>
        )}
        {prices?.length === 1 && dataInventoryTable.length > 0 && (
          <>
            <br />
            <Row className="w-100">
              <Col span={24}>
                <FnbTable
                  dataSource={dataInventoryTable?.[0]?.listProductPriceMaterial}
                  columns={columnsMaterial(0)}
                  pageSize={tableMaterialSettings.pageSize}
                  pageNumber={pageNumber}
                  total={dataInventoryTable?.[0]?.listProductPriceMaterial?.length}
                  className="table-product-receipt custom-form"
                />
              </Col>
            </Row>
          </>
        )}
      </>
    );
  };

  const getTotalCost = (index, isChangeTab = false) => {
    let sum = 0;
    dataInventoryTable[index]?.listProductPriceMaterial?.map((material, index) => {
      sum = sum + material.quantity * material.unitCost;
    });
    if (isChangeTab) {
      form.validateFields();
      setActiveKeyInventoryTracking(index);
    }
    setTotalCost(sum);
  };

  const columnsMaterial = (indexPriceName) => {
    let columns = [
      {
        title: pageData.inventoryTracking.table.materialName,
        dataIndex: "material",
        align: "left",
        width: "34%",
        ellipsis: true,
        render: (_, record, index) => {
          return (
            <Form.Item
              name={["product", "materials", "priceName", indexPriceName, "material", index, "materialId"]}
              className="form-item-material"
            >
              <p>
                <FnbTooltip onlyShowWhenEllipsis maxWidthContent="95%" title={record.material}>
                  {record.material}
                </FnbTooltip>
              </p>
            </Form.Item>
          );
        },
      },
      {
        title: pageData.inventoryTracking.table.quantity,
        dataIndex: "quantity",
        width: "22%",
        align: "left",
        editable: true,
        render: (_, record, index) => {
          var unitDatas = [];
          if (!record?.isAddNew) {
            let baseUnit = {
              id: record?.units?.unitId,
              name: record?.units?.unitName,
              isBase: true,
              quantity: 1,
            };
            unitDatas.push(baseUnit);
            if (record?.unitConversionUnits) {
              record?.unitConversionUnits?.map((item) => {
                let unit = {
                  id: item?.unitId,
                  name: item?.unit?.name,
                  quantity: item?.capacity,
                  isBase: false,
                };
                unitDatas.push(unit);
              });
            }
          } else {
            unitDatas = record?.units?.filter((a) => a?.id !== undefined);
          }

          return (
            <div style={{ display: "flex", gap: 8, justifyContent: "space-around" }}>
              <Form.Item
                name={["product", "materials", "priceName", indexPriceName, "material", index, "quantity"]}
                rules={[
                  {
                    type: "number",
                    min: 0.01,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.inventoryTracking.quantityMoreThanZero}
                      />
                    ),
                  },
                  {
                    required: true,
                    message: (
                      <InputValidateMessage
                        type={EnumInputValidateType.ERROR}
                        message={pageData.inventoryTracking.pleaseEnterQuantity}
                      />
                    ),
                  },
                ]}
                className="form-item-quantity"
                initialValue={record?.quantity}
                style={{ marginBottom: "1em" }}
              >
                <FnbInputNumber
                  onChange={(value) => onChangeQuantity(record, indexPriceName, index, value)}
                  defaultValue={record?.selectedUnit ? record?.selectedUnit : record?.units?.unitId}
                  style={{ width: "100%" }}
                  formatter={(value) => formatterDecimalNumber(value)}
                  parser={(value) => parserDecimalNumber(value)}
                  onKeyPress={(event) => {
                    if (!isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                  showWrap={true}
                />
              </Form.Item>
              <Form.Item
                className="form-item-units"
                name={["product", "materials", "priceName", indexPriceName, "material", index, "unitId"]}
              >
                <FnbTypography className="form-item-units-text" text={record?.unit ?? record?.unitName}></FnbTypography>
              </Form.Item>
            </div>
          );
        },
      },
      {
        title: `${pageData.inventoryTracking.table.cost} (${getCurrency()})`,
        dataIndex: "unitCost",
        align: "center",
        width: "22%",
        render: (_, record, index) => (
          <Form.Item
            name={["product", "materials", "priceName", indexPriceName, "material", index, "unitCost"]}
            className="form-item-unit-cost"
          >
            <p>{formatNumberDecimalOrInteger(record?.unitCost)}</p>
          </Form.Item>
        ),
      },
      {
        title: `${pageData.inventoryTracking.table.totalCost} (${getCurrency()})`,
        dataIndex: "cost",
        align: "center",
        width: "17%",
        render: (_, record, index) => {
          let formValue = form.getFieldsValue();
          let { product } = formValue;
          const item = product.materials?.priceName[indexPriceName]?.material[index];
          return item && item?.quantity > 0 && item?.unitCost > 0 ? (
            <p>{formatNumberDecimalOrInteger(item?.quantity * item?.unitCost)}</p>
          ) : (
            <p>{formatNumberDecimalOrInteger(record?.quantity * record?.unitCost)}</p>
          );
        },
      },
      {
        title: "",
        dataIndex: "action",
        align: "center",
        width: "5%",
        render: (_, record) => (
          <span onClick={() => onRemoveItemMaterial(record?.key, indexPriceName)} className="icon-delete-price">
            <FnbTooltip
              hideTooltip={isTouchDevice}
              disabledStopPropagation={isTouchDevice}
              placement="top"
              title={t("button.delete")}
            >
              <CustomTrashIcon color="#50429B" />
            </FnbTooltip>
          </span>
        ),
      },
    ];
    return columns;
  };

  // Hanlde change quantity and get total cost
  const onChangeQuantity = (record, indexPriceName, index, value) => {
    let newInventoryTable = [];

    dataInventoryTable?.forEach((inventory, indexInventory) => {
      if (indexInventory === indexPriceName) {
        inventory?.listProductPriceMaterial?.forEach((material, indexMaterial) => {
          if (indexMaterial === index) {
            material.materialId = record?.key;
            material.unitCost = record?.unitCost;
            material.quantity = value;
          }
        });
      }
      newInventoryTable.push(inventory);
    });
    setDataInventoryTable(newInventoryTable);
    getTotalCost(indexPriceName);
  };

  const onRemoveItemMaterial = (key, position) => {
    const newDataInventory = dataInventoryTable?.map((inventory, index) => {
      let arrNew = [];
      let selectMaterials = inventory?.listProductPriceMaterial;

      if (index === position) {
        selectMaterials = selectMaterials?.filter((material) => material?.key !== key);
      }

      selectMaterials?.map((productPriceMaterial) => {
        let newProductPriceMaterial = {
          key: productPriceMaterial?.key,
          material: productPriceMaterial?.material,
          quantity: productPriceMaterial?.quantity,
          unit: productPriceMaterial?.unit,
          unitCost: productPriceMaterial?.unitCost,
          cost: productPriceMaterial?.cost,
          units: productPriceMaterial?.units,
          unitConversionUnits: productPriceMaterial?.unitConversionUnits,
        };
        arrNew.push(newProductPriceMaterial);
      });
      inventory.listProductPriceMaterial = arrNew;
      return inventory;
    });
    setDataInventoryTable(newDataInventory);
    getTotalCost(position);

    let formValue = form.getFieldsValue();
    let { product } = formValue;

    product.materials.priceName?.map((item) => {
      item.material.splice(position, 1);
      return item;
    });

    form.setFieldsValue(formValue);
  };

  const handleDeleteItem = async (productId, productName) => {
    var res = await productDataService.deleteProductByIdAsync(productId);
    if (res) {
      onCompleted();
      message.success(pageData.productDeleteSuccess);
    } else {
      message.error(pageData.productDeleteFail);
    }
  };

  const changeForm = (e) => {
    setIsChangeForm(true);
  };

  const onChangePlatform = (values) => {
    setValuePlatforms(values);
  };

  const formCreateProductSubmitCapture = () => {
    let tabControl = document.getElementById("tab-inventory");
    if (tabControl) {
      let tabItem = tabControl.querySelectorAll("div.ant-tabs-tab");
      tabItem?.forEach((itemControl, index) => {
        let breakException = {};
        let tabInventoryContent = document.getElementById(`tab-inventory-panel-${index}`);
        if (tabInventoryContent) {
          let errorControl = tabInventoryContent.querySelectorAll(".ant-form-item-explain-error");
          if (errorControl.length > 0) {
            let activeTab = document.getElementById(`tab-inventory-tab-${index}`);
            activeTab.click();
            onSubmitForm();
            throw breakException;
          }
        }
      });
    }
  };

  const onDeleteItem = (productId) => {
    productDataService.getAllOrderNotCompletedByProductIdAsync(productId).then((res) => {
      if (res.orderOpenStatus.isOpenOrder) {
        setTitleModal(pageData.notificationTitle);
      } else {
        setTitleModal(pageData.confirmDelete);
      }
      setIsModalVisible(true);
    });
  };

  const renderModalContent = () => {
    return (
      <Form>
        <div className="modal-product-topping">
          <Row className="modal-product-topping-search" style={{ display: "contents" }}>
            <Col span={24}>
              <FnbSelect
                value={null}
                placeholder={pageData.selectTopping}
                showSearch
                onChange={(key) => onSelectProductTopping(key)}
                listHeight={400}
                options={productToppings?.map((item) => ({
                  value: item?.id,
                  label: item?.name,
                  thumbnail: item?.thumbnail,
                }))}
                showThumbnail
                isShowSearchIcon={true}
              />
            </Col>
          </Row>
          <Row className="modal-product-topping-table">
            <Col span={24}>
              <FnbTable
                className="selected-product-topping-modal"
                dataSource={productToppingSelected}
                columns={productToppingSelectedColumnTable()}
              />
            </Col>
          </Row>
        </div>
      </Form>
    );
  };

  const renderUpdateDuplicateName = () => {
    const productName = form.getFieldValue(["product", "name"]);
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: t("productManagement.confirmUpdateDuplicateName", { productName: `"${productName}"` }),
        }}
      />
    );
  };

  const onSelectProductTopping = (id) => {
    const selectedTopping = allproductToppings.find((item) => item?.id === id);
    setProductToppingSelected([...productToppingSelected, selectedTopping]);
    setAllProductToppings(allproductToppings.filter((item) => item?.id !== id));
  };

  const onRemoveProductTopping = (id) => {
    const removedTopping = productToppingSelected.find((item) => item?.id === id);
    setProductToppingSelected(productToppingSelected.filter((item) => item?.id !== id));
    setAllProductToppings([...allproductToppings, removedTopping]);
  };

  const productToppingSelectedColumnTable = () => {
    const column = [
      {
        title: pageData.table.name,
        dataIndex: "thumbnail",
        render: (_, record) => {
          return <Image preview={false} src={record.thumbnail ?? "error"} fallback={productDefaultImage} />;
        },
      },
      {
        title: " ",
        width: "80%",
        dataIndex: "name",
        align: "left",
      },
      {
        title: pageData.table.action,
        key: "action",
        width: "20%",
        align: "center",
        render: (_, record) => {
          return (
            <>
              <a onClick={() => onRemoveProductTopping(record?.id)}>
                <div className="fnb-table-action-icon">
                  <Tooltip placement="top" title={t("button.delete")} color="#50429B">
                    <TrashFill className="icon-svg-hover" />
                  </Tooltip>
                </div>
              </a>
            </>
          );
        },
      },
    ];

    return column;
  };

  const handleOK = () => {
    setIsVisibleProductToppingModal(false);
  };

  const handleOpenDeletePopup = () => {
    let productName = form.getFieldValue(["product", "name"]);
    productDataService.getAllOrderNotCompletedByProductIdAsync(match?.params?.id).then((res) => {
      const { preventDeleteProduct } = res;
      // Set property for object
      Object.assign(preventDeleteProduct, { productName: productName });

      setPreventDeleteProduct(preventDeleteProduct);
      if (!preventDeleteProduct?.isPreventDelete) {
        setTitleModal(pageData.confirmDelete);
      } else {
        setTitleModal(pageData.notificationTitle);
      }
      setIsModalVisible(true);
    });
  };

  const updateDimensions = () => {
    setIsMobileSize(window.innerWidth < 500);
  };

  const verifyProductCanEdit = async () => {
    const res = await productDataService.getAllOrderNotCompletedByProductIdAsync(match?.params?.id);
    const preventEditProduct = res?.preventDeleteProduct;
    if (preventEditProduct?.isPreventDelete) {
      setPreventDeleteProduct(preventEditProduct);
      setIsPreventEditProduct(true);
      setIsModalVisible(true);
    }
  };

  const handlePreventEditProduct = () => {
    setIsModalVisible(false);
    history.push("/product-management");
  };

  //Handle for Other settings
  const renderStampPreview = () => {
    return (
      <div className="stamp-preview-setting">
        <div
          className="stamp-preview-setting-description"
          dangerouslySetInnerHTML={{ __html: pageData.othersSetting.stampPreviewDescription }}
        ></div>
        <div className="stamp-preview-setting-template">
          {stampConfigs && stampDatas && (
            <StampTemplatePreviewComponent stampConfig={stampConfigs} stampData={stampDatas} />
          )}
        </div>
      </div>
    );
  };

  const onChangePrintProduct = (value) => {
    setIsPrintStampValue(value);
    setIsChangeForm(true);
  };

  const getInitialStampData = async () => {
    const stampData = createStampMockupData();
    let res = await stampDataService.getStampConfigByStoreIdAsync();
    if (res) {
      let formValue = undefined;
      if (res.stampConfig !== undefined) {
        let data = res?.stampConfig;
        formValue = {
          stampType: data?.stampType,
          isShowLogo: data?.isShowLogo,
          isShowTime: data?.isShowTime,
          isShowNumberOfItem: data?.isShowNumberOfItem,
          isShowNote: data?.isShowNote,
          isShowArea: data?.isShowArea,
          isShowAreaTable: data?.isShowAreaTable,
        };

        form.setFieldsValue(formValue);
      } else {
        formValue = {
          stampType: StampType.mm50x30,
          isShowLogo: true,
          isShowTime: true,
          isShowNumberOfItem: true,
          isShowNote: true,
          isShowArea: true,
          isShowAreaTable: true,
        };

        form.setFieldsValue(formValue);
      }
      setTimeout(() => {
        stampData.logo = storeLogoUrl;
      }, 300);
      setTimeout(() => {
        setStampConfigs(formValue);
        setStampDatas(stampData);
      }, 500);
    }
  };

  const createStampMockupData = () => {
    const stampData = {
      code: "#I1003",
      logo: storeLogoUrl,
      createdTime: "2022-07-06 10:03:41.6983432",
      areaName: "VIP",
      areaTableName: "V01",
      itemList: [
        {
          no: "1",
          name: "Coffee",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
            {
              name: "Ice",
              value: "50%",
            },
            {
              name: "Pudding",
              value: "x122",
            },
          ],
          current: true,
        },
        {
          no: "2",
          name: "Milk tea",
          note: "This is note",
          options: [
            {
              name: "Sugar",
              value: "30%",
            },
          ],
          current: false,
        },
      ],
    };

    return stampData;
  };

  const onRemoveToppingChip = (id) => {
    onRemoveProductTopping(id);
  };

  const onChangeToppingOption = (e) => {
    const isChecked = e.target.checked;
    setToppingOptionValue(isChecked);
  };

  //Enter category name and check existed
  const onCategoryNameChange = (value) => {
    if (listAllProductCategory.filter((u) => u.name.trim() === value.trim()).length > 0) {
      setIsCategoryNameExisted(true);
    } else {
      setIsCategoryNameExisted(false);
    }
    setNameCategory(value);
  };

  const onChangeCategory = (id) => {
    let formValue = form.getFieldsValue();
    let { product } = formValue;
    product.productCategoryId = id;
    setNameCategory(listAllProductCategory?.find((u) => u.id === id)?.name);
    form.setFieldsValue(formValue);
    if (selectNewCategoryFuncs.current) {
      selectNewCategoryFuncs.current(id);
    }
  };

  const showModalAddCategory = async (e) => {
    let productCategory = document.getElementById("productCategory");
    if (productCategory) {
      productCategory.blur();
    }
    formAddCategory.resetFields();
    setBranchOptionValue(false);
    setDisableAllBranches(false);
    formAddCategory.setFieldValue("name", nameCategory);
    setTimeout(function () {
      setIsCategoryModalVisible(true);
    }, 500);
  };

  const addNewCategory = async (e) => {
    e.preventDefault();
    formAddCategory.validateFields().then(async (values) => {
      const createProductCategoryRequestModel = {
        name: values.name,
        isDisplayAllBranches: branchOptionValue,
        products: [],
        storeBranchIds: values.storeBranchIds,
        priority: values.priority,
      };
      productCategoryDataService
        .createProductCategoryAsync(createProductCategoryRequestModel)
        .then((res) => {
          if (res) {
            message.success(pageData.productCategoryAddedSuccess);
            setIsCategoryModalVisible(false);
            setBranchOptionValue(false);
            setDisableAllBranches(false);
            let newItem = {
              id: res.id,
              name: res.name,
            };
            setListAllProductCategory([newItem, ...listAllProductCategory]);
            setNameCategory(res.name);
            let formValue = form.getFieldsValue();
            let { product } = formValue;
            product.productCategoryId = res.id;
            form.setFieldsValue(formValue);
            setIsCategoryNameExisted(true);
            if (selectNewCategoryFuncs.current) {
              selectNewCategoryFuncs.current(res.id);
            }
          }
        })
        .catch((errs) => {
          formAddCategory.setFields(getValidationMessages(errs));
        });
    });
  };

  const getBranches = async () => {
    var res = await branchDataService.getAllBranchsAsync();
    if (res) {
      setBranches(res.branchs);
    }
  };

  const onChangeBranchOption = (e) => {
    const isChecked = e.target.checked;
    setBranchOptionValue(isChecked);
    setDisableAllBranches(isChecked);
  };

  const renderSelectBranch = () => {
    return (
      <>
        <h3 className="fnb-form-label">
          {pageData.branch.title}
          <span className="text-danger">*</span>
        </h3>
        <div className="material-check-box-select-all-branch">
          <FnbCheckBox onChange={(event) => onChangeBranchOption(event)} checked={branchOptionValue}>
            {pageData.branch.all}
          </FnbCheckBox>
        </div>
        <Form.Item
          hidden={disableAllBranches}
          name="storeBranchIds"
          className="last-item"
          rules={[
            {
              required: !disableAllBranches,
              message: pageData.branch.validateMessage,
            },
          ]}
        >
          <FnbSelect
            mode="multiple"
            placeholder={pageData.branch.placeholder}
            allowClear
            options={branches?.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          ></FnbSelect>
        </Form.Item>
        <Form.Item hidden={!disableAllBranches} className="last-item">
          <FnbSelect disabled={true}></FnbSelect>
        </Form.Item>
      </>
    );
  };

  const handleHiddenGuideProductVariants = () => setIsShowGuideProductVariants(false);
  const handleShowGuideProductVariants = () => setIsShowGuideProductVariants(true);

  const handleHiddenGuideProductRecipes = () => setIsShowGuideProductRecipes(false);
  const handleShowGuideProductRecipes = () => setIsShowGuideProductRecipes(true);

  const onAddNewItem = () => {
    setIsShowModalAddTopping(true);
  };

  const handleAddSuccessTopping = (topping) => {
    setProductToppingSelected([...productToppingSelected, topping]);
    executeAfter(500, () => {
      setAllProductToppings(allproductToppings.filter((item) => item?.id !== topping.id));
    });
  };

  const getOptionImageByLanguageCode = () => {
    switch (languageService.getLang()) {
      case "vi":
        return images.productCustomizeVI;
      default:
        return images.productCustomizeEN;
    }
  };

  const styleImage = { border: `1px solid ${theme.colors.primary[100]}`, borderRadius: "12px" };

  const renderOptionPreview = () => {
    return (
      <div className="option-preview-setting">
        <FnbTypography className="option-preview-setting-description" text={pageData.hint.optionDescriptionTooltip} />
        <div className="option-preview-image">
          <Image style={styleImage} src={getOptionImageByLanguageCode()} />
        </div>
      </div>
    );
  };

  const renderPlatformPreview = (id) => {
    switch (id.toString()) {
      case Platform.POS.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="topRight"
              maxWidth="524px"
              variant="secondary"
              title={() => (
                <div className="guide-text">
                  <FnbTypography className="pos-devices" text={pageData.hint.POSCashRegister + ": "} />
                  {<span dangerouslySetInnerHTML={{ __html: pageData.hint.POSDevices }}></span>}
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      case Platform.StoreWebsite.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="topRight"
              maxWidth="524px"
              variant="secondary"
              title={() => (
                <div className="store-web-guide-tooltip guide-text">
                  <FnbTypography text={pageData.hint.storeWebIs} />
                  <a className="store-web-link" href={storeInfo?.domainName} target={"_blank"} rel="noreferrer">
                    {pageData.hint.storeWebLink}
                  </a>
                  <FnbTypography text={pageData.hint.storeWebExample} />
                  <div className="store-web-preview-image">
                    <Image style={styleImage} src={images.storeWebGuide} />
                  </div>
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      case Platform.StoreMobileApp.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="top"
              maxWidth="384px"
              variant="secondary"
              title={() => (
                <div className="store-app-guide-tooltip guide-text">
                  {
                    <span
                      className="storeApp-preview-text"
                      dangerouslySetInnerHTML={{ __html: pageData.hint.storeApp }}
                    />
                  }
                  <div className="storeApp-preview-image">
                    <Image style={styleImage} src={images.storeAppGuide} />
                  </div>
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      case Platform.POSServing.toLowerCase():
        return (
          <>
            <FnbTooltip
              autoAdjustOverflow
              placement="topRight"
              maxWidth="524px"
              variant="secondary"
              title={() => (
                <div className="guide-text">
                  {<span dangerouslySetInnerHTML={{ __html: pageData.hint.POSServing }}></span>}
                </div>
              )}
              arrowPointAtCenter={true}
            >
              <CustomInfoCircleIcon />
            </FnbTooltip>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <FnbHeadingPage
        title={titleName}
        activeStatus={{
          content:
            statusId === ProductStatus.Activate ? (
              <FnbBadge variant="success" text={pageData.active}></FnbBadge>
            ) : (
              statusId === ProductStatus.Deactivate && <FnbBadge variant="error" text={pageData.inactive}></FnbBadge>
            ),
        }}
        listButtons={[
          <FnbCancelButton showWarning={isChangeForm} onOk={() => onCompleted()} />,
          <FnbButton
            variant="tertiary"
            danger
            onClick={() => handleOpenDeletePopup()}
            permission={PermissionKeys.DELETE_PRODUCT}
            iconHeader={<CustomTrashIcon color="#DB1B1B" />}
          />,
          <FnbButton
            text={t(activate)}
            variant="secondary"
            permission={
              statusId === ProductStatus.Activate ? PermissionKeys.DEACTIVATE_PRODUCT : PermissionKeys.ACTIVATE_PRODUCT
            }
            onClick={() => onChangeStatus()}
          />,
          <FnbAddNewButton
            text={pageData.btnSave}
            onClick={() => {
              setIsLoading(true);
              onSubmitForm();
            }}
            permission={PermissionKeys.EDIT_PRODUCT}
            hideIcon={true}
            loading={isLoading}
          />,
        ]}
        suffix={
          !isTopping ? (
            <FnbSuffixHeadingGuideline purposeType={GuidancePurposeType.Product} visible={titleName !== ""} />
          ) : (
            <></>
          )
        }
      />

      <Form
        form={form}
        name="basic"
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        onSubmitCapture={() => formCreateProductSubmitCapture()}
      >
        <div className="col-input-full-width">
          <Row className="grid-container-edit-product">
            <Col span={24} className="left-create-product">
              <Card className="w-100 fnb-card h-auto">
                <Row>
                  <Col span={24}>
                    <h4 className="edit-text-title-group">{pageData.productInformation.title}</h4>
                    <div className="edit-text-title-group-underlined"></div>
                    <h4 className="fnb-form-label">
                      {isTopping
                        ? pageData.productInformation.name.toppingName
                        : pageData.productInformation.name.label}
                      <span className="text-danger">*</span>
                    </h4>
                    <Form.Item
                      name={["product", "name"]}
                      rules={[
                        {
                          required: pageData.generalInformation.name.required,
                          message: (
                            <InputValidateMessage
                              type={EnumInputValidateType.ERROR}
                              message={
                                isTopping
                                  ? pageData.productInformation.name.toppingValidateMessage
                                  : pageData.generalInformation.name.validateMessage
                              }
                            />
                          ),
                        },
                      ]}
                    >
                      <Input
                        showCount
                        className="fnb-input-with-count"
                        placeholder={
                          isTopping
                            ? pageData.productInformation.name.toppingNamePlaceholder
                            : pageData.productInformation.name.placeholder
                        }
                        maxLength={pageData.generalInformation.name.maxLength}
                        disabled={isBelongsActiveCombo}
                        id="product-name"
                        onChange={onNameTouched}
                      />
                    </Form.Item>

                    <h4 className="fnb-form-label">{pageData.productCategory.label}</h4>
                    <Form.Item name={["product", "productCategoryId"]}>
                      <SelectEditComponent
                        id="productCategory"
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showSearch
                        allowClear
                        functions={selectNewCategoryFuncs}
                        placeholder={pageData.productCategory.placeholder}
                        pleaseEnterName={pageData.productCategory.pleaseEnterNameCategory}
                        nameExisted={pageData.productCategory.nameCategoryExisted}
                        btnAddNew={pageData.btnAddNew}
                        listOption={listAllProductCategory}
                        onChangeOption={onChangeCategory}
                        onSearch={onCategoryNameChange}
                        addNewItem={showModalAddCategory}
                        isNameExisted={isCategoryNameExisted}
                        name={nameCategory}
                        isEditProduct={true}
                        category={pageData.productCategory.label}
                        isShowSearchIcon={true}
                      />
                    </Form.Item>
                    <h4 className="fnb-form-label">{pageData.generalInformation.description.label}</h4>
                    <Form.Item name={["product", "description"]} rules={[]}>
                      <FnbTextArea
                        placeholder={pageData.generalInformation.description.placeholder}
                        showCount
                        maxLength={pageData.generalInformation.description.maxLength}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        rows={5}
                      ></FnbTextArea>
                    </Form.Item>

                    <h4 className={`fnb-form-label form-group-label ${isTopping === true ? "d-none" : ""}`}>
                      {pageData.includeTopping}
                    </h4>
                    <div className={`product-check-box-select-all-topping ${isTopping === true ? "d-none" : ""}`}>
                      <FnbCheckBox onChange={(event) => onChangeToppingOption(event)} checked={toppingOptionValue}>
                        {pageData.allToppings}
                      </FnbCheckBox>
                    </div>
                    <Form.Item className={`select-topping ${isTopping === true ? "d-none" : ""}`}>
                      <Row>
                        <Col span={24}>
                          <FnbSelectAddNewItem
                            mode={"multiple"}
                            placeholder={pageData.toppingPlaceholder}
                            onSelect={(key) => onSelectProductTopping(key)}
                            onDeselect={(key) => onRemoveToppingChip(key)}
                            listHeight={400}
                            options={allproductToppings?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                              thumbnail: item?.thumbnail,
                            }))}
                            showThumbnail
                            isShowSearchIcon={true}
                            disabled={toppingOptionValue}
                            fieldItemName="Topping"
                            onAddNewItem={onAddNewItem}
                            onSearch={(e) => setSearchToppingValue(e)}
                            tagRender={() => null}
                            className="select-topping-product"
                            value={productToppingSelected?.map((item) => ({
                              value: item?.id,
                              label: item?.name,
                              thumbnail: item?.thumbnail,
                            }))}
                          />
                          {productToppingSelected.length > 0 && !toppingOptionValue && (
                            <Col className="topping-text">
                              <Row gutter={[12, 12]} className="list-topping-chip">
                                {productToppingSelected.map((item) => {
                                  return (
                                    <Col>
                                      <div className="w-100 container-toppings">
                                        <FnbChip
                                          id={item?.id}
                                          iconHeader={
                                            item?.thumbnail ? (
                                              <Image preview={false} src={item?.thumbnail} />
                                            ) : (
                                              <ImageMaterialDefault />
                                            )
                                          }
                                          isDisableToolTip={true}
                                          title={item.name}
                                          isRemoveChip={true}
                                          onRemoveChip={(id) => onRemoveToppingChip(id)}
                                        ></FnbChip>
                                      </div>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col span={24} className="price-product">
              <FnbCard
                title={isTopping ? pageData.pricing.productPrice : pageData.pricing.pricesAndVariations}
                underlineTitle
                className="w-100 mt-1 fnb-card h-auto"
                buttons={[
                  <FnbButton
                    onClick={handleShowGuideProductVariants}
                    text={pageData.hint.howToSetUpProductVariants}
                    variant="secondary-purple"
                    iconHeader={<MessageQuestionIcon />}
                    hiddenTextOnMobile
                    className={`${isTopping === true ? "hidden" : ""}`}
                  />,
                ]}
              >
                <Row>
                  <Col span={24}>{renderPrices()}</Col>
                </Row>
              </FnbCard>
            </Col>

            <Col span={24} className="recipe-product">
              <FnbCard
                title={pageData.unit.recipe}
                underlineTitle
                className="w-100 mt-1 fnb-card h-auto"
                buttons={[
                  <FnbButton
                    onClick={handleShowGuideProductRecipes}
                    text={isTopping ? pageData.hint.howToSetUpRecipes : pageData.hint.howToSetUpProductRecipes}
                    variant="secondary-purple"
                    iconHeader={<MessageQuestionIcon />}
                    hiddenTextOnMobile
                  />,
                ]}
              >
                <Row>
                  <Col span={24}>{renderInventoryTrackings()}</Col>
                  <Col span={24}>
                    {getFormSelectedMaterials()}
                    {!isSelectedMaterial && (
                      <div id="error-recipe-message" className="mt-1">
                        <InputValidateMessage
                          type={EnumInputValidateType.ERROR}
                          message={pageData.inventoryTracking.pleaseSetupRecipe}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className="mt-20">
                  <Col className="tip-product-receipt" xs={24} sm={24} md={24} lg={24} flex={1}>
                    <LampOn />
                    {pageData.inventoryTracking.recipeTip}
                  </Col>
                  {prices?.length > 0 && dataInventoryTable.length > 0 && (
                    <>
                      <Col className="total-cost-product-receipt" xs={24} sm={24} md={24} lg={24} flex={1}>
                        <FnbTypography
                          className="total-cost-product-receipt-title"
                          text={`${pageData.productInformation.totalProductCost}: `}
                        ></FnbTypography>
                        <div className="total-cost-product-receipt-value">
                          <FnbTypography text={totalCost ? formatNumberDecimalOrInteger(totalCost) : 0} />
                          <FnbTypography text={` ${getCurrency()}/ ${units.find((u) => u.id === unitId)?.name}`} />
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </FnbCard>
            </Col>

            <Col className="right-create-product" xs={24} sm={24} md={24} lg={24}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Card className="w-100 fnb-card h-auto upload-product-image">
                    <h4 className="title-image">{pageData.productImage.title}</h4>
                    <Row className={`non-image ${image !== null ? "have-image" : ""}`}>
                      <Col span={24} className={`image-product ${image !== null ? "justify-left" : ""}`}>
                        <Form.Item name={["product", "media"]}>
                          <FnbCustomUploadImageComponent
                            ref={fnbImageSelectRef}
                            iconUpload={<UploadLogoIcon />}
                            buttonText={pageData.generalInformation.uploadImage}
                            onChange={onChangeImage}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col className="mt-12" xs={24} sm={24} md={24} lg={24}>
                  <Card className={`w-100 mt-1 fnb-card h-auto ${isTopping === true ? "hidden" : ""}`}>
                    <Row className="option-preview">
                      <Col className="option-title-container">
                        <Row className="option-title-text">
                          <h4 className="title-option">{pageData.optionInformation.title}</h4>
                          <FnbTooltip
                            autoAdjustOverflow
                            placement="bottomRight"
                            maxWidth="370px"
                            variant="secondary"
                            title={() => renderOptionPreview()}
                            arrowPointAtCenter={true}
                          >
                            <CustomInfoCircleIcon />
                          </FnbTooltip>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-24">
                      <Col span={24}>
                        <FnbSelectOptions
                          placeholder={pageData.optionInformation.selectOption}
                          showSearch
                          options={options?.map((b) => ({
                            value: b?.id,
                            label: b?.name,
                          }))}
                          optionFilterProp="children"
                          value={null}
                          onChange={(value) => onSelectOption(value)}
                          name={newOptionName}
                          isEditProduct={true}
                          onChangeOption={onChangeOption}
                          onSearch={onOptionSearch}
                          addNewItem={onAddNewOption}
                          isNameExisted={isOptionNameExisted}
                          isNameSelected={isOptionNameSelected}
                          permission={PermissionKeys.CREATE_OPTION}
                        />
                      </Col>
                    </Row>
                    {renderOptions()}
                  </Card>
                </Col>
              </Row>

              <Row hidden={listPlatforms.length > 0 ? "" : "hidden"}>
                <Col className="mt-12" xs={24} sm={24} md={24} lg={24}>
                  <Card className="w-100 mt-1 fnb-card platform-card">
                    <h4 className="title-platform">{pageData.platform.title}</h4>
                    <div className="platform-group">
                      <Checkbox.Group onChange={onChangePlatform} value={valuePlatforms}>
                        {listPlatforms?.map((p, index) => {
                          return (
                            <div className={`platform-item ${index === 0 ? "mt-1" : "mt-10"}`}>
                              <FnbCheckBox value={p.id}>{p.name === "POS" ? "POS devices" : p.name}</FnbCheckBox>
                              {renderPlatformPreview(p.id)}
                            </div>
                          );
                        })}
                      </Checkbox.Group>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="mt-12" xs={24} sm={24} md={24} lg={24}>
                  <Card className="w-100 mt-1 fnb-card other-setting-card">
                    <h4 className="title-other-setting">{pageData.othersSetting.title}</h4>
                    <Form.Item name={["product", "isPrintStamp"]}>
                      <Row className="isPrintStamp-container">
                        <Col>
                          {isPrintStampValue !== undefined && (
                            <FnbSwitch
                              onChange={(value) => onChangePrintProduct(value)}
                              checked={isPrintStampValue}
                              className="isPrintStamp-switch"
                            />
                          )}
                        </Col>
                        <Col className="isPrintStamp-title-container">
                          <Row className="isPrintStamp-title">
                            {pageData.othersSetting.isPrintStampTitle}
                            <FnbTooltip maxWidth="392px" variant="secondary" title={() => renderStampPreview()}>
                              <CustomInfoCircleIcon />
                            </FnbTooltip>
                          </Row>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>

      <FnbHeadingPage
        title={""}
        listButtons={[
          <FnbCancelButton showWarning={isChangeForm} onOk={() => onCompleted()} />,
          <FnbAddNewButton
            text={pageData.btnSave}
            onClick={() => {
              setIsLoading(true);
              onSubmitForm();
            }}
            permission={PermissionKeys.EDIT_PRODUCT}
            hideIcon={true}
            loading={isLoading}
          />,
        ]}
      />

      <FnbModal
        width={"800px"}
        title={pageData.addTopping}
        handleCancel={() => {
          setIsVisibleProductToppingModal(false);
        }}
        onOk={handleOK}
        cancelText={pageData.btnCancel}
        okText={pageData.btnAddNew}
        visible={isVisibleProductToppingModal}
        content={renderModalContent()}
        okButtonProps={!productToppingSelected || productToppingSelected.length === 0 ? { disabled: true } : undefined}
      />
      <DeleteProductComponent
        isModalVisible={isModalVisible}
        preventDeleteProduct={preventDeleteProduct}
        titleModal={titleModal}
        handleCancel={() => setIsModalVisible(false)}
        onDelete={handleDeleteItem}
        handlePreventEditProduct={handlePreventEditProduct}
        isPreventEditProduct={isPreventEditProduct}
      />
      <EditProductInCombo
        isModalVisible={isModalComboVisible}
        combos={comboInfos}
        titleModal={pageData.notificationTitle}
        handleCancel={() => setIsModalComboVisible(false)}
        onDelete={() => {}}
      />
      <FnbNotifyDialog
        title={pageData.notificationTitle}
        open={isModalNotificationVisible}
        hideCancelButton={true}
        okText={pageData.buttonIGotIt}
        onOk={() => {
          setIsModalNotificationVisible(false);
        }}
        onCancel={() => {
          setIsModalNotificationVisible(false);
        }}
        className={"fnb-notify-dialog-product-dependencies"}
        content={() => {
          return (
            <>
              <div
                className="text-content-notification-product-dependencies"
                dangerouslySetInnerHTML={{
                  __html: notificationMessage,
                }}
              />
              <Table
                className="table-product-dependencies"
                columns={notificationTable}
                dataSource={notificationDatasource}
                pagination={false}
              />
            </>
          );
        }}
      />
      <ConfirmDialogComponent
        title={pageData.leaveDialog.confirmation}
        okText={pageData.btnContinueUpdate}
        cancelText={pageData.btnIgnore}
        onOk={onConfirmTouched}
        onCancel={onCancelTouched}
        visible={showConfirmTouched}
        type={ConfirmStyle.LEAVER}
        contentElement={renderUpdateDuplicateName()}
      />
      {isCategoryModalVisible && (
        <FnbModal
          title={pageData.addProductCategory}
          visible={isCategoryModalVisible}
          footer={(null, null)}
          width={"800px"}
          handleCancel={() => setIsCategoryModalVisible(false)}
          onOk={addNewCategory}
          cancelText={pageData.cancel}
          okText={pageData.createCategory}
          disabledBtnOk={false}
          content={
            <Form form={formAddCategory} name="basic" onFinish={addNewCategory} autoComplete="off">
              <Row>
                <div className="w-100">
                  <Card className="fnb-card">
                    <Row gutter={[24, 24]}>
                      <Col xs={24} sm={24} md={24} lg={12} span={12}>
                        <div className="d-flex">
                          <h3 className="fnb-form-label">
                            {pageData.categoryName}
                            <span className="text-danger">*</span>
                          </h3>
                        </div>
                        <Form.Item
                          name={["name"]}
                          rules={[
                            {
                              required: pageData.generalInformation.name.required,
                              message: pageData.generalInformation.name.validateMessage,
                            },
                          ]}
                        >
                          <FnbInput
                            className="fnb-input-with-count"
                            showCount
                            placeholder={pageData.generalInformation.name.placeholder}
                            maxLength={pageData.generalInformation.name.maxLength}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={12} span={12}>
                        <div className="d-flex">
                          <h3 className="fnb-form-label">
                            {pageData.priority.title}
                            <span className="text-danger">*</span>
                          </h3>
                          <div style={{ marginTop: "17px" }}>
                            <FnbTooltip placement="topLeft" title={pageData.priority.tooltip}>
                              <span className="ml-12">
                                <ExclamationIcon width={16} height={16} />
                              </span>
                            </FnbTooltip>
                          </div>
                        </div>
                        <Form.Item
                          name={["priority"]}
                          rules={[
                            {
                              required: true,
                              message: pageData.priority.validateMessage,
                            },
                          ]}
                        >
                          <FnbInputNumber
                            placeholder={pageData.priority.placeholder}
                            className="fnb-input-number w-100"
                            min={1}
                            max={1000000}
                            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[24, 24]} className="mt-16">
                      <Col xs={24} sm={24} md={24} lg={24} span={24}>
                        {renderSelectBranch()}
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Row>
            </Form>
          }
        />
      )}

      <CreateNewOptionManagement
        isModalVisible={showAddNewOptionForm}
        handleCancel={onCancelAddNewFormOption}
        onSuccess={onSuccessAddNewFormOption}
        nameOption={createOptionName}
      />
      <CreateNewTax
        t={t}
        isModalVisible={isTaxModalVisible}
        name={nameTax}
        handleCompleted={handleCompletedTaxModal}
        handleCancel={handleCancelTaxModal}
        disabledImportedTax={true}
      />
      <DialogHowToSetupProductVariants
        isShowGuideProductVariants={isShowGuideProductVariants}
        handleHiddenGuideProductVariants={handleHiddenGuideProductVariants}
      />
      <DialogHowToSetupProductRecipes
        isShowGuideProductRecipes={isShowGuideProductRecipes}
        handleHiddenGuideProductRecipes={handleHiddenGuideProductRecipes}
      />
      <FnbModalCreateTopping
        visible={isShowModalAddTopping}
        setVisible={setIsShowModalAddTopping}
        defaultNameTopping={searchToppingValue}
        initPlatforms={listPlatforms}
        initUnits={units}
        initMaterials={materials}
        toppings={allproductToppings}
        currencyCode={getCurrency()}
        setToppings={setAllProductToppings}
        stampConfigs={stampConfigs}
        stampData={stampDatas}
        taxes={listAllTax}
        setTaxes={setListAllTax}
        handleAddSuccess={handleAddSuccessTopping}
      />
      <IngredientSearchModal
        visible={isShowModalSearchMaterial}
        className="promotion-campaign-buy-x-get-y-modal"
        handleCancel={onCancelModalSearchMaterial}
        listSelectedMaterials={getDataSelected()}
        modalType={IngredientSearchModalType.TYPE_INGREDIENT}
        afterSelected={onAddSelectedMaterial}
        allowAddNewIngredient
        position={parseInt(activeKeyInventoryTracking)}
      />
    </>
  );
}
