import { Col, Form, Row } from "antd";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import { FnbTooltipVariant } from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { inputNumberRangeOneTo999999999 } from "constants/default.constants";
import { AddCircleOutlined, MessageQuestionIcon } from "constants/icons.constants";
import { MaxNumberInput } from "constants/string.constants";
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from "react";
import "./ingredient-price-unit-conversion.page.scss";
import { FnbButton } from "components/fnb-button/fnb-button";
import FnbInputGroup, { FnbInputGroupType } from "components/fnb-input-group/fnb-input-group";
import theme from "theme";
import { FnbDeleteIcon } from "components/fnb-delete-icon/fnb-delete-icon";
import SelectEditUnitsComponent from "components/select-edit-new-item-units/select-edit-new-item-units";
import { DialogHowtoSetupUnit } from "./DialogHowtoSetupUnit/index";
import { getCurrency } from "utils/helpers";

const setDefaultData = [
  {
    unitId: null,
    capacity: null,
    preUnitConversionId: null,
    capacityWithPreUnitConversion: null,
  },
];

export const PriceAndConversionMaterial = forwardRef(
  ({ t, form, units, fetchUnits, updateBaseUnit, unitConversions }, ref) => {
    const selectNewItemFuncs = React.useRef(null);
    const [baseUnit, setBaseUnit] = useState(null);
    const [listDataUnitConversion, setListDataUnitConversion] = useState([]);
    const [isShowGuideConvertUnitDialog, setIsShowGuideConvertUnitDialog] = useState(null);
    const [isDisabledBtnAddConversion, setIsDisabledBtnAddConversion] = useState(true);
    const currency = getCurrency();

    const pageData = {
      priceAndConversion: t("material.priceAndConversion"),
      currentCostPerUnit: t("material.currentCostPerUnit"),
      priceRangeUnitConversions: t("productManagement.pricing.priceRangeUnitConversions"),
      unitRequired: t("material.unitRequired"),
      tooltipCostPerUnit: t("material.tooltipCostPerUnit"),
      enterTheNumber: t("form.enterTheNumber"),
      UOM_label: t("material.UOM_label"),
      placeholderUOM: t("material.placeholderUOM"),
      placeholderCostPerUnit: t("material.placeholderCostPerUnit"),
      tooltipUOM: {
        top: t("material.tooltipUOM.top"),
        center: t("material.tooltipUOM.center"),
        bottom: t("material.tooltipUOM.bottom"),
        setup: t("material.tooltipUOM.setup"),
      },
      inventory: {
        costPerUnitPlaceholder: t("material.inventory.costPerUnitPlaceholder"),
        minQuantity: t("material.inventory.minQuantity"),
        quantityValidateMessageMinimumOne: t("material.inventory.quantityValidateMessageMinimumOne"),
        pleaseSelectBaseUnit: t("productManagement.unit.pleaseSelectBaseUnit"),
      },
      setUpUnit: t("material.setUpUnit"),
      baseConversionFromUOM: t("material.baseConversionFromUOM"),
      backToUOM: t("material.backToUOM"),
      baseConversionDescription: t("material.baseConversionDescription"),
      addNewConversion: t("material.addNewConversion"),
      buttonHowToSetUp: t("material.inventory.buttonHowToSetUp", "How to set up UOM correctly"),
      selectConversionUnit: t("material.selectConversionUnit"),
      enterCapacity: t("material.enterCapacity"),
      capacityRequired: t("material.capacityRequired"),
      UOM: t("material.UOM"),
      uOMRequired: t("material.uOMRequired"),
      miniumStockTooltip: {
        top: t("material.miniumStockTooltip.top"),
        bottom: t("material.miniumStockTooltip.bottom"),
      },
    };

    useEffect(() => {
      setBaseUnit(units?.find((u) => form?.getFieldValue("unitId") === u?.id));
      if (unitConversions?.length > 0) {
        setListDataUnitConversion(unitConversions);
        form?.setFieldValue("listDataUnitConversion", unitConversions);
      }
    }, [unitConversions]);

    useEffect(() => {
      checkDisabledBtnAddConversion();
      updateBaseUnit(baseUnit);
      form?.setFieldValue("unitId", baseUnit?.id);
    }, [baseUnit]);

    useEffect(() => {
      form.validateFields(["listDataUnitConversion"]);
    }, [listDataUnitConversion]);

    useImperativeHandle(ref, () => ({
      onUpdateListDataUnitConversion: (datas) => {
        setListDataUnitConversion(datas);
      },
    }));

    const renderToolTipCostPerUnit = () => {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: pageData.tooltipCostPerUnit,
          }}
        ></span>
      );
    };

    const onChangeOption = (id, fieldName, isBaseUnit) => {
      if (selectNewItemFuncs.current) {
        selectNewItemFuncs.current(id);
      }
      const unit = units.find((u) => u.id === id);
      if (fieldName === "unitId" || isBaseUnit) {
        setBaseUnit(unit);
      }
      if (typeof fieldName[1] === "number" && (fieldName[2] === "preUnitConversionId" || fieldName[2] === "unitId")) {
        const prev = form.getFieldValue("listDataUnitConversion");
        let newUnitConversions = prev;
        newUnitConversions[fieldName[1]][fieldName[2]] = id;
        if (fieldName[2] === "preUnitConversionId") {
          newUnitConversions = prev?.slice(0, fieldName[1] + 1);
          if(isBaseUnit && newUnitConversions?.[0]) {
            newUnitConversions[0].unitId = null;
          }
        } else if (fieldName[2] === "unitId") {
          let index = fieldName[1] + 1 > 0 ? fieldName[1] + 1 : 0;
          if (newUnitConversions[index]) {
            newUnitConversions[index].preUnitConversionId = id;
          }
        }
        setListDataUnitConversion(newUnitConversions);
        form.setFieldValue("listDataUnitConversion", newUnitConversions);
      } else {
        setListDataUnitConversion([]);
      }
      form.setFieldValue(fieldName, unit?.id);
    };

    const reCalculatorAll = () => {
      const listDataUnitConversion = form.getFieldValue("listDataUnitConversion");
      listDataUnitConversion.forEach((item, index) => onChangeCapacityWithPreUnitConversion(item.capacityWithPreUnitConversion,index))
    }

    const onChangeCapacityWithPreUnitConversion = (value, index) => {
      const prev = form.getFieldValue("listDataUnitConversion");
      if (prev[index]?.preUnitConversionId === baseUnit?.id) {
        prev[index].capacity = value;
      } else {
        prev[index].capacity =
          value * (index === 0 ? 1 : prev?.find((uC) => uC?.unitId === prev[index]?.preUnitConversionId).capacity);
      }
      prev[index].capacityWithPreUnitConversion = value;
      setListDataUnitConversion(prev);
      form.setFieldValue("listDataUnitConversion", prev);
    };
    const onAddNewUnitConversion = () => {
      const disable = checkDisabledBtnAddConversion();
      if (disable) {
        return;
      } else {
        const prev = form.getFieldValue("listDataUnitConversion") ?? [];
        const unitConversionItem = {
          unitId: null,
          capacity: null,
          preUnitConversionId:
            !prev || prev?.length === 0 ? baseUnit?.id : prev[prev?.length - 1]?.unitId ?? baseUnit?.id,
          capacityWithPreUnitConversion: 0,
        };
        const newUnitConversions = [...prev, unitConversionItem];
        form?.setFieldValue("listDataUnitConversion", newUnitConversions);
        setListDataUnitConversion(newUnitConversions);
      }
    };

    const loadUnitConversionOptions = (index) => {
      if (index > 0) {
        const listUC = form.getFieldValue("listDataUnitConversion")?.slice(0, index);
        let listRes = [];
        units
          ?.filter((u) => listUC.some((uC) => uC?.unitId === u?.id || uC?.preUnitConversionId === u?.id))
          ?.forEach((item) => {
            let uC = listUC?.find((uC) => uC.unitId === item?.id);
            let preUnitName = units.find((unit) => uC?.preUnitConversionId === unit?.id)?.name;
            let newName = item.name;
            if (uC && preUnitName) {
              newName = `${item?.name} (${uC?.capacityWithPreUnitConversion} ${preUnitName})`;
            }
            listRes.push({ name: newName, id: item?.id, code: item?.code });
          });

        return listRes;
      } else {
        return units;
      }
    };

    const loadApplicableUnitOptions = (index, currentUnitId) => {
      let res = units.filter((u) => u?.id !== baseUnit?.id);
      if (index > 0) {
        return res.filter(
          (u) =>
            !form.getFieldValue("listDataUnitConversion")?.some((uC) => uC?.preUnitConversionId === u?.id) ||
            currentUnitId === u?.id,
        );
      } else {
        return res;
      }
    };

    const onBackToUOM = () => {
      form.setFieldsValue({ listDataUnitConversion: [] });
      setListDataUnitConversion([]);
    };

    const checkDisabledBtnAddConversion = () => {
      setIsDisabledBtnAddConversion(!form.getFieldValue("unitId"));
    };

    const loadRemoveItemIds = (currentId) => {
      return listDataUnitConversion?.filter((value) => value?.unitId !== currentId)?.map((uC) => uC?.unitId);
    };

    const handleHiddenGuideConvertUnitDialog = () => setIsShowGuideConvertUnitDialog(false);
    const handleShowGuideConvertUnitDialog = () => setIsShowGuideConvertUnitDialog(true);

    return (
      <Fragment>
        <FnbCard
          title={pageData.priceAndConversion}
          underlineTitle
          buttons={[
            <FnbButton
              onClick={handleShowGuideConvertUnitDialog}
              text={pageData.setUpUnit}
              variant="secondary-purple"
              iconHeader={<MessageQuestionIcon />}
              hiddenTextOnMobile
            />,
          ]}
          className="mt-16 ingredient-price-unit-conversion"
        >
          <Row gutter={[28, 28]}>
            <Col span={24}>
              <FnbFormItem
                label={pageData.UOM_label}
                tooltip={
                  <div className="tooltip-UOM">
                    <span dangerouslySetInnerHTML={{ __html: pageData.tooltipUOM.top }}></span>
                    <span>
                      <b>{pageData.tooltipUOM.center}</b>
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: pageData.tooltipUOM.bottom }}></span>
                  </div>
                }
                tooltipVariant={FnbTooltipVariant.SECONDARY}
                className="mb-28"
                hidden={baseUnit && listDataUnitConversion && listDataUnitConversion.length > 0}
              >
                <SelectEditUnitsComponent
                  form={{
                    rules: [
                      {
                        required: true,
                        message: <InputValidateMessage message={pageData.uOMRequired} />,
                      },
                    ],
                    name: "unitId",
                  }}
                  selectNewItemFuncs={selectNewItemFuncs}
                  placeholder={pageData.placeholderUOM}
                  units={units}
                  onChangeOption={(id) => {
                    onChangeOption(id, "unitId");
                    checkDisabledBtnAddConversion();
                  }}
                  setNewUnitId={({id, name}) => {
                    form.setFieldValue("unitId", id);
                    setBaseUnit({ id, name });
                  }}
                  removeItemIds={listDataUnitConversion.filter((_, index) => index !== 0).map((uC) => uC?.unitId)}
                  fetchUnits={fetchUnits}
                />
              </FnbFormItem>
              {baseUnit && listDataUnitConversion && listDataUnitConversion.length > 0 && (
                <Form.List name="listDataUnitConversion" initialValue={setDefaultData}>
                  {(fields, { remove }) => (
                    <>
                      {fields.slice(0, 1).map((field, index) => (
                        <>
                          <div className="base-conversion-uom">
                            <div className="uom-title">
                              <FnbTypography variant="b1-medium" text={pageData.baseConversionFromUOM} />
                              <FnbButton
                                className="ml-auto"
                                text={pageData.backToUOM}
                                variant="secondary"
                                onClick={() => onBackToUOM()}
                              />
                            </div>
                            <FnbTypography
                              variant="b2-regular"
                              className="uom-description"
                              text={pageData.baseConversionDescription}
                            />
                            <div className="uom-conversion">
                              <FnbFormItem className="left">
                                <SelectEditUnitsComponent
                                  form={{
                                    name: [field.name, "unitId"],
                                    rules: [
                                      {
                                        required: true,
                                        message: <InputValidateMessage message={pageData.unitRequired} />,
                                      },
                                    ],
                                  }}
                                  selectNewItemFuncs={selectNewItemFuncs}
                                  placeholder={pageData.selectConversionUnit}
                                  units={loadApplicableUnitOptions(
                                    0,
                                    form.getFieldValue(["listDataUnitConversion", field.name, "unitId"]),
                                  )}
                                  onChangeOption={(id) => {
                                    onChangeOption(id, ["listDataUnitConversion", field.name, "unitId"]);
                                  }}
                                  fetchUnits={fetchUnits}
                                  setNewUnitId={({id}) =>
                                    form.setFieldValue(["listDataUnitConversion", field.name, "unitId"], id)
                                  }
                                  removeItemIds={loadRemoveItemIds(
                                    form.getFieldValue(["listDataUnitConversion", field.name, "unitId"]),
                                  )}
                                  value={form.getFieldValue(["listDataUnitConversion", field.name, "unitId"])}
                                />
                              </FnbFormItem>
                              <span className="center">=</span>
                              <FnbInputGroup
                                width={null}
                                className="right"
                                firstInput={{
                                  type: FnbInputGroupType.NUMBER_DECIMAL,
                                  placeholder: pageData.enterCapacity,
                                  form: {
                                    name: [field.name, "capacityWithPreUnitConversion"],
                                    rules: [
                                      {
                                        required: true,
                                        message: <InputValidateMessage message={pageData.capacityRequired} />,
                                      },
                                      () => ({
                                        validator(_, value) {
                                          if (value > MaxNumberInput || value <= 0) {
                                            return Promise.reject(
                                              <InputValidateMessage
                                                message={pageData.priceRangeUnitConversions}
                                              />,
                                            );
                                          }
                                          return Promise.resolve();
                                        },
                                      }),
                                    ],
                                  },
                                  onChange: (value) => {
                                    onChangeCapacityWithPreUnitConversion(value, field.name);
                                    reCalculatorAll();
                                  },
                                }}
                                secondInput={{
                                  type: FnbInputGroupType.SELECT_SINGLE,
                                  prefix: pageData.UOM,
                                  prefixColor: theme.colors.secondary[70],
                                  option: units.map((u) => ({
                                    name: u?.name,
                                    id: u?.id,
                                  })),
                                  form: {
                                    name: [field.name, "preUnitConversionId"]
                                  },
                                  onChange: (id) =>
                                    onChangeOption(
                                      id,
                                      ["listDataUnitConversion", field.name, "preUnitConversionId"],
                                      true,
                                    ),
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ))}
                      {fields?.length > 1 && (
                        <div className="conversion-uom-list mt-28">
                          {fields.slice(1, fields.length).map((field, index) => (
                            <div className="conversion-uom-list-item">
                              <div className="uom-conversion w-100">
                                <FnbFormItem className="left">
                                  <SelectEditUnitsComponent
                                    form={{
                                      rules: [
                                        {
                                          required: true,
                                          message: <InputValidateMessage message={pageData.unitRequired} />,
                                        },
                                      ],
                                      name: [field.name, "unitId"],
                                    }}
                                    selectNewItemFuncs={selectNewItemFuncs}
                                    placeholder={pageData.selectConversionUnit}
                                    units={loadApplicableUnitOptions(
                                      field.name,
                                      form.getFieldValue(["listDataUnitConversion", field.name, "unitId"]),
                                    )}
                                    onChangeOption={(id) =>
                                      onChangeOption(id, ["listDataUnitConversion", field.name, "unitId"])
                                    }
                                    fetchUnits={fetchUnits}
                                    setNewUnitId={({id}) =>
                                      form.setFieldValue(["listDataUnitConversion", field.name, "unitId"], id)
                                    }
                                    removeItemIds={loadRemoveItemIds(
                                      form.getFieldValue(["listDataUnitConversion", field.name, "unitId"]),
                                    )}
                                  />
                                </FnbFormItem>
                                <span className="center">=</span>
                                <FnbInputGroup
                                  width={null}
                                  className="right"
                                  firstInput={{
                                    type: FnbInputGroupType.NUMBER_DECIMAL,
                                    placeholder: pageData.enterCapacity,
                                    form: {
                                      name: [field.name, "capacityWithPreUnitConversion"],
                                      rules: [
                                        {
                                          required: true,
                                          message: <InputValidateMessage message={pageData.priceRangeUnitConversions} />,
                                        },
                                        () => ({
                                          validator(_, value) {
                                            if (value > MaxNumberInput || value <= 0) {
                                              return Promise.reject(
                                                <InputValidateMessage message={pageData.priceRangeUnitConversions} />,
                                              );
                                            }
                                            return Promise.resolve();
                                          },
                                        }),
                                      ],
                                    },
                                    onChange: (value) => {
                                      onChangeCapacityWithPreUnitConversion(value, field.name);
                                      reCalculatorAll();
                                    },
                                  }}
                                  secondInput={{
                                    type: FnbInputGroupType.SELECT_SINGLE,
                                    option: loadUnitConversionOptions(field.name).map((u) => ({
                                      name: u?.name,
                                      id: u?.id,
                                    })),
                                    form: {
                                      name: [field.name, "preUnitConversionId"],
                                    },
                                    onChange: (id) => {
                                      onChangeOption(id, ["listDataUnitConversion", field.name, "preUnitConversionId"]);
                                      reCalculatorAll();
                                    },
                                  }}
                                />
                              </div>
                              <FnbButton
                                className="btn-delete"
                                variant="secondary"
                                iconHeader={<FnbDeleteIcon color={theme.colors.primary[100]} width={20} height={20} />}
                                onClick={() => {
                                  remove(fields.slice(field?.name, fields?.length).map((field) => field.name));
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </Form.List>
              )}
              <FnbTypography.Link
                disable={isDisabledBtnAddConversion}
                className={`mt-28`}
                variant="b1-medium"
                iconHeader={<AddCircleOutlined width={20} height={20} />}
                underline
                onClick={onAddNewUnitConversion}
                text={pageData.addNewConversion}
              />
            </Col>
            <Col span={24} xl={12}>
              <FnbFormItem
                label={pageData.currentCostPerUnit}
                tooltip={renderToolTipCostPerUnit()}
                maxWidthTooltip={400}
                tooltipVariant={FnbTooltipVariant.SECONDARY}
                className="mb-16"
              >
                <FnbInputGroup
                  firstInput={{
                    type: FnbInputGroupType.NUMBER_DECIMAL,
                    placeholder: pageData.enterTheNumber,
                    suffix: currency,
                    form: {
                      name: "costPerUnit",
                      rules: [
                        () => ({
                          validator(_, value) {
                            if (value > MaxNumberInput || value < 0) {
                              return Promise.reject(pageData.priceRangeUnitConversions);
                            }
                            return Promise.resolve();
                          },
                        }),
                      ],
                    },
                  }}
                  secondInput={{
                    type: FnbInputGroupType.LABEL,
                    value: units.find((u) => u?.id === baseUnit?.id)?.name,
                  }}
                />
              </FnbFormItem>
            </Col>
            <Col span={24} xl={12}>
              <FnbFormItem
                label={pageData.inventory.minQuantity}
                tooltipVariant={FnbTooltipVariant.SECONDARY}
                tooltip={
                  <div className="tooltip-min-quantity">
                    <FnbTypography variant="b2-regular" text={pageData.miniumStockTooltip.top} />
                    <FnbTypography variant="b2-bold" text={pageData.miniumStockTooltip.bottom} />
                  </div>
                }
              >
                <FnbInputGroup
                  width={null}
                  className="right"
                  firstInput={{
                    type: FnbInputGroupType.NUMBER_INT,
                    placeholder: pageData.enterTheNumber,
                    form: {
                      name: ["minQuantity"],
                      rules: [
                        {
                          pattern: new RegExp(inputNumberRangeOneTo999999999.range),
                          message: (
                            <InputValidateMessage message={pageData.inventory.quantityValidateMessageMinimumOne} />
                          ),
                        },
                      ],
                    },
                  }}
                  secondInput={{
                    type: FnbInputGroupType.LABEL,
                    value: units.find((u) => u?.id === baseUnit?.id)?.name,
                  }}
                />
              </FnbFormItem>
            </Col>
          </Row>
        </FnbCard>
        <DialogHowtoSetupUnit
          isShowGuideConvertUnitDialog={isShowGuideConvertUnitDialog}
          handleHiddenGuideConvertUnitDialog={handleHiddenGuideConvertUnitDialog}
        ></DialogHowtoSetupUnit>
      </Fragment>
    );
  },
);
