import { Card, Col, Form, message, Row, Typography } from "antd";
import DeleteConfirmComponent from "components/delete-confirm/delete-confirm.component";
import { FnbDeleteIcon } from "components/fnb-delete-icon/fnb-delete-icon";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTable } from "components/fnb-table/fnb-table";
import { DELAYED_TIME, MaximumNumber } from "constants/default.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { currency } from "constants/string.constants";
import purchaseOrderDataService from "data-services/purchase-order/purchase-order-data.service";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  checkOnKeyPressValidation,
  formatCurrency,
  formatterDecimalNumber,
  getCurrency,
  isDecimalKey,
  parserDecimalNumber,
} from "utils/helpers";
import "../index.scss";
import "./new-purchase-order.scss";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { KEY_TAB_PANE } from "pages/inventory/material-control/table-inventory-control.component";
import { InputValidateMessage, EnumInputValidateType } from "components/input-validate-message/input-validate-message";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { SearchIcon } from "constants/icons.constants";
import { IngredientSearchModal, IngredientSearchModalType } from "components/ingredient-search-modal/ingredient-search-modal";

const { Text } = Typography;

export default function NewPurchaseOrderComponent(props) {
  const [t] = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [branches, setBranches] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [initDataMaterials, setInitDataMaterials] = useState([]);
  const [dataSelectedMaterial, setDataSelectedMaterial] = useState([]);
  const [isSelectedMaterial, setIsSelectedMaterial] = useState(true);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState();
  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    createPurchaseOrderSuccess: t("messages.createPurchaseOrderSuccess"),
    updatePurchaseOrderSuccess: t("messages.updatePurchaseOrderSuccess"),
    createPurchaseOrder: t("purchaseOrder.createPurchaseOrder"),
    updatePurchaseOrder: t("purchaseOrder.updatePurchaseOrder"),
    materialInformation: t("purchaseOrder.materialInformation"),
    searchMaterial: t("purchaseOrder.searchMaterial"),
    pleaseSelectMaterial: t("inventoryTracking.pleaseSelectMaterial"),
    note: t("form.note"),
    selectBranch: t("staffManagement.permission.selectBranch"),
    pleaseSelectBranch: t("staffManagement.permission.pleaseSelectBranch"),
    supplier: {
      title: t("supplier.title"),
      selectSupplier: t("supplier.selectSupplier"),
      pleaseSelectSupplier: t("supplier.pleaseSelectSupplier"),
    },
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      materialName: t("table.materialName"),
      material: t("material.title"),
      quantity: t("table.quantity"),
      importUnit: t("table.importUnit"),
      pleaseEnterNameUnit: t("productManagement.unit.pleaseSelectUnitMaterial"),
      unitPlaceholder: t("productManagement.unit.unitPlaceholder"),
      importPrice: t("table.importPrice"),
      total: t("table.total"),
      totalCost: t("table.totalCost"),
      action: t("table.action"),
      inventory: t("table.inventory"),
      branchName: t("table.branchName"),
    },
    btnCreate: t("button.create"),
    generalInformation: t("purchaseOrder.generalInformation"),
    goBackToPurchaseOrder: t("purchaseOrder.goBackTo"),
    pleaseEnterQuantity: t("purchaseOrder.pleaseEnterQuantity"),
    pleaseImportPrice: t("purchaseOrder.pleaseImportPrice"),
    confirmation: t("leaveDialog.confirmation"),
    confirmationOkText: t("button.confirmationOkText"),
    confirmationCancelText: t("button.confirmationCancelText"),
    confirmMessage: t("messages.confirmLeave"),
    freeTextArea: t("purchaseOrder.freeTextArea"),
    destination: t("purchaseOrder.destinationLabel"),
    validateMinQtyMessage: t("productManagement.pricing.priceRange"),
    pleaseEnterMax1e9: t("messages.valueShouldBeFrom0to999999999"),
    ingredientPlaceholder: t("productManagement.recipe.placeholder"),
  };
  const [visibleModalLeaveConfirm, setVisibleModelLeaveConfirm] = useState(false);
  const [showModalSearchIngredient, setShowModalSearchIngredient] = useState(false);

  useEffect(() => {
    fetchInitData();
  }, []);

  const fetchInitData = async () => {
    const response = await purchaseOrderDataService.getPurchaseOrderPrepareDataAsync("");
    const { suppliers, branches, materials } = response;
    setSuppliers(suppliers);
    setBranches(branches);
    setMaterials(materials);
    setInitDataMaterials(materials);
  };

  const onFinish = async (values) => {
    if (dataSelectedMaterial.length > 0) {
      const createPurchaseOrderRequestModel = {
        ...values.purchaseOrder,
        materials: dataSelectedMaterial,
      };

      /// Add purchase Order
      const res = await purchaseOrderDataService.createPurchaseOrderAsync(createPurchaseOrderRequestModel);
      if (res) {
        leaveOnOk();
        message.success(pageData.createPurchaseOrderSuccess);
      }
    } else {
      setIsSelectedMaterial(false);
      var element = document.getElementById("select-material-error");
      scrollingIntoErrorMessage(element);
    }
  };

  const scrollingIntoErrorMessage = (messageErrorElement) => {
    if (messageErrorElement) {
      messageErrorElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "left",
        width: "5rem",
        render: (_, record, index) => index + 1,
      },
      {
        title: pageData.table.sku,
        dataIndex: "sku",
        align: "left",
        width: "15%",
      },
      {
        title: pageData.table.materialName,
        dataIndex: "material",
        align: "left",
        width: "20%",
        render: (_, record) => {
          return (
            <Link to={`/inventory/material/detail/${record?.id}`} target="_blank">
              {record?.name}
            </Link>
          );
        },
      },
      {
        title: pageData.table.quantity,
        dataIndex: "quantity",
        align: "left",
        width: "15%",
        render: (_, record, index) => (
          <Row align="middle" justify="start">
            <Col span={24}>
              <Form.Item
                name={["purchaseOrder", "materials", record?.id, "quantity"]}
                rules={[
                  {
                    required: true,
                    message: pageData.pleaseEnterQuantity,
                  },
                  () => ({
                    validator(_, value) {
                      if (value > MaximumNumber || value <= 0) {
                        return Promise.reject(pageData.validateMinQtyMessage);
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FnbInputNumber
                  onChange={(value) => onChangeRecord(index, "quantity", value)}
                  defaultValue={record.quantity}
                  className="fnb-input quantity-material w-100"
                  formatter={(value) => formatterDecimalNumber(value)}
                  parser={(value) => parserDecimalNumber(value)}
                  onKeyPress={(event) => {
                    if (!isDecimalKey(event)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ),
      },
      {
        title: pageData.table.importUnit,
        dataIndex: "unitId",
        align: "left",
        width: "15%",
        render: (_, record, index) => {
          let mapUnitConversions = record?.unitConversion?.map((unit) => {
            return {
              id: unit.unitId,
              name: unit.unitName,
              capacity: unit.capacity,
            };
          });

          let unitConversion = [{ id: record?.unitId, name: record?.unitName }, ...mapUnitConversions];
          return (
            <Row align="middle" justify="start">
              <Col span={24}>
                <Form.Item
                  name={["purchaseOrder", "selectedMaterials", record?.id, "unit"]}
                  rules={[
                    {
                      required: true,
                      message: pageData.table.pleaseEnterNameUnit,
                    },
                  ]}
                >
                  <FnbSelectSingle
                    className="select-unit-material"
                    showSearch
                    placeholder={pageData.unitPlaceholder}
                    option={unitConversion?.map((item) => ({
                      id: item.id,
                      name: item.name,
                    }))}
                    onChange={(value) => onSelectUnit(value, index)}
                    value={record?.unitId}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
        },
      },
      {
        title: `${pageData.table.importPrice} (${getCurrency()})`,
        dataIndex: "price",
        align: "left",
        width: "15%",
        render: (_, record, index) => (
          <Row align="middle" justify="start">
            <Col span={24}>
              <Form.Item
                name={["purchaseOrder", "materials", record?.id, "price"]}
                rules={[
                  {
                    required: true,
                    message: pageData.pleaseImportPrice,
                  },
                  () => ({
                    validator(_, value) {
                      if (value && (value <= 0 || value >= 1e9)) {
                        return Promise.reject(
                          <InputValidateMessage
                            type={EnumInputValidateType.ERROR}
                            message={pageData.pleaseEnterMax1e9}
                          />,
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <FnbInputNumber
                  onChange={(value) => onChangeRecord(index, "price", value)}
                  style={{ width: "80%" }}
                  formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  defaultValue={record.price}
                  min={getCurrency() === currency.vnd ? 1 : 0}
                  precision={getCurrency() === currency.vnd ? 0 : 2}
                  className="fnb-input input-material-price"
                  id={`purchaseOrder-materials-${index}-price`}
                  onKeyPress={(event) => {
                    const checkStatus = checkOnKeyPressValidation(
                      event,
                      `purchaseOrder-materials-${index}-price`,
                      0,
                      null,
                      getCurrency() === currency.vnd ? 0 : 2,
                    );
                    if (!checkStatus) event.preventDefault();
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        ),
      },
      {
        title: `${pageData.table.total} (${getCurrency()})`,
        dataIndex: "total",
        align: "right",
        width: "10%",
        render: (_, record) => {
          return (
            <p style={{ textAlign: "right" }}>
              {record?.total?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
            </p>
          );
        },
      },
      {
        title: pageData.table.action,
        dataIndex: "action",
        align: "center",
        justify: "middle",
        width: "10%",
        render: (_, record) => (
          <Row align="center" justify="center">
            <FnbButton
              variant="tertiary"
              iconHeader={<FnbDeleteIcon />}
              onClick={() => onRemoveItemMaterial(record?.id)}
            />
          </Row>
        ),
      },
    ];
    return columns;
  };

  const handleSetDataSelectedMaterial = (listSelectedMaterials) => {
    let selectedIds = dataSelectedMaterial.map(z => z.materialId);
    let newMaterialIds = listSelectedMaterials.filter(item => !selectedIds.includes(item.materialId)).map(z => z.materialId);
    listSelectedMaterials.forEach(item => {
      if (newMaterialIds.includes(item.materialId)) {
        item.id = item.materialId;
        item.quantity = 0;
        item.price = 0;
        item.total = 0;
        form.setFieldValue(["purchaseOrder", "materials", item.id, "quantity"], 0);
        form.setFieldValue(["purchaseOrder", "selectedMaterials", item.id, "unit"], null);
        form.setFieldValue(["purchaseOrder", "materials", item.id, "price"], 0);
      }
    })
    setDataSelectedMaterial(listSelectedMaterials);
    setIsSelectedMaterial(true);
  }

  const onRemoveItemMaterial = (id) => {
    let restMaterials = dataSelectedMaterial.filter((o) => o.id !== id);
    setDataSelectedMaterial(restMaterials);

    let initDataMaterial = initDataMaterials.find((o) => o.id === id);
    materials.push(initDataMaterial);
    dataSelectedMaterial.length === 1 &&
      form.setFieldValue(["purchaseOrder", "selectedMaterials", 0, "unit"], undefined);

    if (restMaterials.length === 0) {
      setIsSelectedMaterial(false);
    }
  };

  const onChangeRecord = (index, column, value) => {
    const changeRecord = dataSelectedMaterial[index];
    const quantity = column === "quantity" ? value : changeRecord.quantity;
    const price = column === "price" ? value : changeRecord.price;
    dataSelectedMaterial?.map((item, i) => {
      if (i === index) {
        item.total = quantity * price;
        item.quantity = quantity;
        item.price = price;
      }
    });
    setDataSelectedMaterial([...dataSelectedMaterial]);
  };

  const onSelectUnit = (value, index) => {
    dataSelectedMaterial[index].unitId = value;
  };

  const onCancel = () => {
    if (isChangeForm) {
      setVisibleModelLeaveConfirm(true);
    } else {
      setVisibleModelLeaveConfirm(false);
      leaveOnOk();
    }
  };

  const changeForm = () => {
    setIsChangeForm(true);
  };

  const leaveOnOk = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push({
        pathname: "/inventory/transfer-control",
        search: `?tabActive=${KEY_TAB_PANE.VIEW_BY_PURCHASE_ORDER}`,
      });
    }, DELAYED_TIME);
  };

  const leaveOnCancel = () => {
    setVisibleModelLeaveConfirm(false);
  };

  const getTotalMaterials = () => {
    let total = dataSelectedMaterial.reduce((n, { total }) => n + total, 0).toFixed(2);
    return formatCurrency(total);
  };

  const onChangeWarehouse = async (warehouseId) => {
    const response = await purchaseOrderDataService.getPurchaseOrderPrepareDataAsync(warehouseId);
    const { materials } = response;
    setMaterials(materials);
    setInitDataMaterials(materials);
    setSelectedWarehouseId(warehouseId);
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 22,
        }}
        className="create-new-purchase-order-form"
      >
        <FnbHeadingPage
          title={pageData.createPurchaseOrder}
          typeBtnCreate="submit"
          permissionCreate={PermissionKeys.CREATE_PURCHASE}
          listButtons={[<FnbButton variant="secondary" text={pageData.btnCancel} onClick={() => onCancel()} />]}
        ></FnbHeadingPage>
        <div className="col-input-full-width">
          <Row align="middle">
            <Card className="w-100 mb-3 fnb-card h-auto">
              <Row gutter={16} align="middle">
                <Col span={24}>
                  <h4 className="title-group">{pageData.generalInformation}</h4>
                </Col>
                <Col className="gutter-row" span={12} md={12} sm={24} xs={24}>
                  <h4 className="fnb-form-label">
                    {pageData.supplier.title}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["purchaseOrder", "supplierId"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.supplier.pleaseSelectSupplier,
                      },
                    ]}
                  >
                    <FnbSelectSingle
                      placeholder={pageData.supplier.selectSupplier}
                      showSearch
                      option={suppliers?.map((b) => ({
                        id: b.id,
                        name: b.name,
                      }))}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12} md={12} sm={24} xl={12} xs={24}>
                  <h4 className="fnb-form-label">
                    {pageData.destination}
                    <span className="text-danger">*</span>
                  </h4>
                  <Form.Item
                    name={["purchaseOrder", "branchId"]}
                    rules={[
                      {
                        required: true,
                        message: pageData.pleaseSelectBranch,
                      },
                    ]}
                  >
                    <FnbSelectSingle
                      showSearch
                      placeholder={pageData.selectBranch}
                      option={branches?.map((item) => ({
                        id: item.id,
                        name: item.name,
                      }))}
                      onChange={(value) => onChangeWarehouse(value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col span={24}>
                  <h4 className="fnb-form-label">
                    {pageData.note}
                    <span className="text-danger"></span>
                  </h4>
                  <Form.Item name={["purchaseOrder", "note"]}>
                    <FnbTextArea
                      showCount
                      maxLength={255}
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      placeholder={pageData.freeTextArea}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Row>

          <Row className="display-contents">
            <Card className="mt-1 fnb-card h-auto">
              <Row>
                <Col span={24}>
                  <h4 className="title-group">{pageData.materialInformation}</h4>
                  <div onClick={() => {
                    if (form.getFieldValue(["purchaseOrder", "branchId"])) {
                      setShowModalSearchIngredient(true);
                    } else {
                      form.validateFields([["purchaseOrder", "branchId"]]);
                    }
                  }}>
                    <Form.Item className="w-100">
                      <FnbInput
                        id={"btn-search-ingredient"}
                        className="fnb-input"
                        size="large"
                        placeholder={pageData.ingredientPlaceholder}
                        prefix={<SearchIcon />}
                        type={TYPE_INPUT.BUTTON}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FnbTable
                    tableId="material-list"
                    className="mt-4"
                    columns={columnsMaterial()}
                    dataSource={dataSelectedMaterial}
                    total={dataSelectedMaterial.length}
                    scrollX={1400}
                  />
                </Col>
              </Row>
              <br />
              <Row justify="end" align="middle" className="h-bg-brad-class">
                <Col xs={0} sm={0} md={0} lg={14}></Col>
                <Col xs={24} sm={24} md={24} lg={10}>
                  <Row align="middle">
                    <Col span={24} className="pl20">
                      <Row gutter={16}>
                        <Col span={24} className="fz20 text-center">
                          <Text className="float-left">{pageData.table.quantity}</Text>
                          <span className="float-right pr10">
                            <span className="fwb mr10">{dataSelectedMaterial.length}</span>
                            {pageData.table.material}
                          </span>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col span={24} className="fz26 text-center">
                          <Text className="float-left" strong>
                            {pageData.table.totalCost}:
                          </Text>
                          <span className="float-right pr10">{getTotalMaterials()}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Row>
        </div>
        {!isSelectedMaterial && (
          <div
            id="select-material-error"
            className="ant-form-item-explain-error"
            ref={(errorDiv) => {
              scrollingIntoErrorMessage(errorDiv);
            }}
          >
            {pageData.pleaseSelectMaterial}
          </div>
        )}
      </Form>
      <DeleteConfirmComponent
        title={pageData.confirmation}
        content={pageData.leaveForm}
        visible={visibleModalLeaveConfirm}
        skipPermission={true}
        cancelText={pageData.confirmationCancelText}
        cancelButtonProps={{ className: "cancel-leave-button" }}
        okText={pageData.confirmationOkText}
        onCancel={leaveOnCancel}
        onOk={leaveOnOk}
        isChangeForm={isChangeForm}
      />
      <IngredientSearchModal
        visible={showModalSearchIngredient}
        handleCancel={() => setShowModalSearchIngredient(false)}
        listSelectedMaterials={dataSelectedMaterial}
        setListSelectedMaterials={handleSetDataSelectedMaterial}
        modalType={IngredientSearchModalType.TYPE_INGREDIENT}
        branchId={form.getFieldValue(["purchaseOrder", "branchId"])}
        loadAllData
      />
    </>
  );
}
