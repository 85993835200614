import { Card, Col, Image, Row, Typography } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import FnbWidget from "components/fnb-widget/fnb-widget.component";
import FnbWidgetJumbotron from "components/fnb-widget-jumbotron/fnb-widget-jumbotron.component";
import { CustomInfoCircleIcon, ExportOutlinedIcon, ReceiptShiftIcon } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { PromotionReportConstants, TYPE } from "constants/report.constants";
import { DateFormat } from "constants/string.constants";
import reportDataService from "data-services/report/report-data.service";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import languageService from "services/language/language.service";
import { languageCodeSelector } from "store/modules/session/session.reducers";
import {
  executeAfter,
  formatDate,
  formatNumberDecimalOrInteger,
  getCurrency,
  getUrlImageByLanguageCode,
  handleDownloadFile,
  hasPermission,
} from "utils/helpers";
import "./index.scss";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { sortConstant, tableSettings } from "constants/default.constants";
import { useRedirect } from "hooks/useRedirect";
import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { PromotionStatus, PromotionTabKey, ReportPromotionType } from "constants/promotion.constants";
import { SwapLeftOutlined } from "@ant-design/icons";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FilterPromotionReport from "./components/filter-promotion-report.component";
import { FnbFilter } from "components/fnb-filter/fnb-filter";
import { imagesWidgetType } from "constants/images.constants";
import CampaignDetailCustomerListComponent from "./components/campaign-usage-detail-customer.component";
import CampaignDetailOrderListComponent from "./components/campaign-usage-detail.component";

function PromotionReport() {
  const { t, ready } = useTranslation();
  const pageData = {
    no: t("table.no"),
    totalCampaigns: t("reportRevenue.totalCampaigns"),
    campaigns: t("reportRevenue.campaigns"),
    totalOrder: t("dashboard.totalOrder", "Total orders"),
    orders: t("reportRevenue.orders"),
    totalCustomers: t("reportRevenue.totalCustomers"),
    customers: t("reportRevenue.customers"),
    totalRevenue: t("reportRevenue.totalRevenue"),
    totalDiscount: t("reportRevenue.totalDiscount"),
    allBranch: t("dashboard.allBranch"),
    date: {
      yesterday: t("dashboard.compareDate.yesterday"),
      previousDay: t("dashboard.compareDate.previousDay"),
      lastWeek: t("dashboard.compareDate.lastWeek"),
      previousWeek: t("dashboard.compareDate.previousWeek"),
      lastMonth: t("dashboard.compareDate.lastMonth"),
      previousMonth: t("dashboard.compareDate.previousMonth"),
      lastYear: t("dashboard.compareDate.lastYear"),
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    btnExport: t("button.export"),
    promotion: {
      title: t("report.promotion.title"),
      campaignName: t("report.promotion.campaignName"),
      campaignTime: t("report.promotion.campaignTime"),
      campaignTimeStart: t("report.promotion.campaignTimeStart"),
      campaignTimeEnd: t("report.promotion.campaignTimeEnd"),
      campaignRevenue: t("report.promotion.campaignRevenue"),
      totalOrders: t("report.promotion.totalOrders"),
      totalCustomers: t("report.promotion.totalCustomers"),
      totalDiscounts: t("report.promotion.totalDiscounts"),
      status: t("report.promotion.status"),
      statusName: {
        scheduled: t("report.promotion.statusName.scheduled"),
        active: t("report.promotion.statusName.active"),
        finished: t("report.promotion.statusName.finished"),
      },
      placeholder: {
        campaignName: t("report.promotion.placeholder.campaignName"),
      },
      hint: {
        revenue: t("report.promotion.hint.revenue"),
        order: t("report.promotion.hint.order"),
      },
    },
    wiget: {
      totalOrder: t("wiget.totalOrderPromotion"),
      totalDiscount: t("wiget.totalDiscount"),
      totalRevenue: t("wiget.totalRevenue"),
      totalCampaign: t("wiget.totalCampaign"),
      totalCustomer: t("wiget.totalCustomer"),
    },
  };
  const filterPromotionReportRef = React.useRef(null);
  const [pageSize, setPageSize] = useState(tableSettings.pageSize);
  const [currentPageNumber, setCurrentPageNumber] = useState(tableSettings.page);
  const [promotionStatus, setPromotionStatus] = useState(0);
  const [promotionReportType, setPromotionReportType] = useState(0);
  const [keySearch, setKeySearch] = useState("");
  const [sortCampaignName, setSortCampaignName] = useState("");
  const [sortCampaignRevenue, setSortCampaignRevenue] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [sortCustomer, setSortCustomer] = useState("");
  const [sortTotalDiscount, setSortTotalDiscount] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [countFilter, setCountFilter] = useState(0);
  const { handleRedirect, target } = useRedirect();

  const [initData, setInitData] = useState({});
  const [branchId, setBranchId] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });

  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);
  const languageSession = useSelector((state) => state.session?.languageSession);

  const campaignDetailCustomerListRef = useRef(null);
  const [isShowCampaignDetailCustomerList, setIsShowCampaignDetailCustomerList] = useState(false);
  const campaignDetailOrderListRef = useRef(null);
  const [isShowCampaignDetailOrderList, setIsShowCampaignDetailOrderList] = useState(false);

  useEffect(() => {
    getInitialData(branchId, typeOptionDate);
  }, []);

  const getInitialData = (branchId, typeOptionDate) => {
    setTypeOptionDate(OptionDateTime.today);
    getDataInfoByFilter(
      branchId,
      selectedDate,
      typeOptionDate,
      currentPageNumber,
      pageSize,
      null,
      promotionStatus,
      promotionReportType,
      keySearch,
    );
  };

  const getDataInfoByFilter = async (
    branchId,
    date,
    typeOptionDate,
    pageNumber = 1,
    pageSize = 20,
    sortTable = null,
    promotionStatus = 0,
    promotionReportType = 0,
    keySearch = "",
  ) => {
    let startDate = moment(date?.startDate);
    let endDate = moment(date?.endDate);

    // Parse local time frome client to UTC time before comparation
    var fromDate = moment.utc(startDate).local().format(DateFormat.YYYY_MM_DD_HH_MM_SS_2);
    var toDate = moment.utc(endDate).local().format(DateFormat.YYYY_MM_DD_HH_MM_SS_2);
    let req = {
      branchId: branchId ?? "",
      startDate: fromDate,
      endDate: toDate,
      businessSummaryWidgetFilter: typeOptionDate,
      pageNumber: pageNumber,
      pageSize: pageSize,
      promotionStatus: promotionStatus,
      promotionReportType: promotionReportType,
      keySearch: keySearch,
      sortCampaignName: sortTable?.sortCampaignName || "",
      sortCampaignRevenue: sortTable?.sortCampaignRevenue || "",
      sortOrder: sortTable?.sortOrder || "",
      sortCustomer: sortTable?.sortCustomer || "",
      sortTotalDiscount: sortTable?.sortTotalDiscount || "",
    };

    var result = await reportDataService.promotionReportAsync(req);
    setInitData(result);
    if (sortTable === undefined || sortTable === null) {
      resetSort();
    }
  };

  //Handle for Hint settings
  const renderHintTotalCampaign = () => {
    return (
      <p className="hintTotalOrder">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalCampaign }} />
      </p>
    );
  };

  const renderHintTotalOrder = () => {
    return (
      <p className="hintTotalOrder">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalOrder }} />
      </p>
    );
  };

  const renderHintTotalCustomer = () => {
    return (
      <p className="hintTotalOrder">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalCustomer }} />
      </p>
    );
  };

  const renderHintTotalDiscount = () => {
    return (
      <p className="hintTotalDiscount">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalDiscount }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalDiscount)} />
        </div>
      </p>
    );
  };

  const renderHintTotalRevenue = () => {
    return (
      <p className="hintTotalRevenue">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalRevenue }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalRevenue)} />
        </div>
      </p>
    );
  };

  const renderWidgetJumbotronSummary = () => {
    let listBusiness = [
      {
        icon: <ReceiptShiftIcon width={38} height={38} />,
        title: pageData.totalCampaigns,
        suffix: pageData.campaigns,
        summaryItem: {
          total: initData?.widgetPromotionReport?.totalCampaigns,
          average: initData?.widgetPromotionReport?.totalCampaigns,
          groupItems: [
            {
              key: "totalOrder",
              title: pageData.totalOrder,
              suffix: pageData.orders,
              total: initData?.widgetPromotionReport?.totalOrders, 
              isShowHint: true,
              informationHint: renderHintTotalOrder(),
            },
            {
              key: "totalCustomers",
              title: pageData.totalCustomers,
              suffix: pageData.customers,
              total: initData?.widgetPromotionReport?.totalCustomers,
              isShowHint: true,
              informationHint: renderHintTotalCustomer(),
            },
            {
              key: "totalRevenue",
              title: pageData.totalRevenue,
              suffix: getCurrency(),
              total: initData?.widgetPromotionReport?.totalRevenues,
              isShowHint: true,
              informationHint: renderHintTotalRevenue(),
            },
            {
              key: "totalDiscount",
              title: pageData.totalDiscount,
              suffix: getCurrency(),
              total: initData?.widgetPromotionReport?.totalDiscounts,
              isShowHint: true,
              informationHint: renderHintTotalDiscount(),
            },
          ]
        },
        isShowHint: true,
        informationHint: renderHintTotalCampaign(),
      },
    ];

    const widgetSummary = listBusiness?.map((item, index) => {
      return (
        <Col key={index} span={24} className="ct-widget-jumbotron">
          <FnbWidgetJumbotron
            summaryItem={item.summaryItem}
            titleTotal={item.title}
            icon={item.icon}
            suffixData={item.suffix}
            isShowHint={item.isShowHint}
            informationHint={item.informationHint}
            colorHint={"#50429B"}
          />
        </Col>
      );
    });
    return <>{widgetSummary}</>;
  };

  // Reset all sort states to default (empty)
  const resetSort = () => {
    const sortSetters = [
      setSortCampaignName,
      setSortCampaignRevenue,
      setSortTotalDiscount,
      setSortCustomer,
      setSortOrder,
    ];

    sortSetters.forEach((setSort) => setSort(""));
  };

  // Main function to handle table sorting
  const handleSortTable = (sortColumn) => {
    // Reset sort states before applying the new one
    resetSort();

    // Initialize sort table with default values, use current state
    const sortTable = {
      sortCampaignName: sortCampaignName,
      sortCampaignRevenue: sortCampaignRevenue,
      sortOrder: sortOrder,
      sortCustomer: sortCustomer,
      sortTotalDiscount: sortTotalDiscount,
    };

    const setSortStateAndFetchData = (setSortState, sortKey) => {
      if (sortTable[sortKey] === sortConstant.ASC) {
        setSortState(sortConstant.DESC);
        sortTable[sortKey] = sortConstant.DESC;
      } else if (sortTable[sortKey] === sortConstant.DESC) {
        setSortState("");
        sortTable[sortKey] = "";
      } else {
        setSortState(sortConstant.ASC);
        sortTable[sortKey] = sortConstant.ASC;
      }

      setCurrentPageNumber(tableSettings.page);
      setPageSize(tableSettings.pageSize);
      getDataInfoByFilter(
        branchId,
        selectedDate,
        typeOptionDate,
        tableSettings.page,
        tableSettings.pageSize,
        sortTable,
        promotionStatus,
        promotionReportType,
        keySearch,
      );
    };

    // Mapping of sort column constants to actions
    const sortActions = {
      [PromotionReportConstants.CAMPAIGNNAME]: () => setSortStateAndFetchData(setSortCampaignName, "sortCampaignName"),
      [PromotionReportConstants.CAMPAIGNREVENUE]: () =>
        setSortStateAndFetchData(setSortCampaignRevenue, "sortCampaignRevenue"),
      [PromotionReportConstants.TOTALORDERS]: () => setSortStateAndFetchData(setSortOrder, "sortOrder"),
      [PromotionReportConstants.TOTALCUSTOMERS]: () => setSortStateAndFetchData(setSortCustomer, "sortCustomer"),
      [PromotionReportConstants.TOTALDISCOUNTS]: () =>
        setSortStateAndFetchData(setSortTotalDiscount, "sortTotalDiscount"),
    };

    // Execute the corresponding action if the sort column is valid
    if (sortActions[sortColumn]) {
      sortActions[sortColumn]();
    } else {
      console.warn(`Invalid sort column: ${sortColumn}`);
    }
  };

  const SortIcon = ({ direction, active, onClick }) => (
    <SwapLeftOutlined
      style={{
        marginLeft: direction === sortConstant.ASC ? "-10px" : "8px",
        color: active ? undefined : "#AA9AFF",
        fontSize: 18,
      }}
      rotate={direction === sortConstant.ASC ? 90 : 270}
      onClick={onClick}
    />
  );

  const setColumnTable = [
    {
      title: pageData.no,
      key: "index",
      width: "5rem",
      align: "left",
      dataIndex: "index",
      render: (val) => val + 1,
    },
    {
      title: (
        <>
          {pageData.promotion.campaignName}
          <div className="sort-icon">
            <SortIcon
              direction={sortConstant.DESC}
              active={sortCampaignName === sortConstant.DESC}
              onClick={() => handleSortTable(PromotionReportConstants.CAMPAIGNNAME)}
            />
            <SortIcon
              direction={sortConstant.ASC}
              active={sortCampaignName === sortConstant.ASC}
              onClick={() => handleSortTable(PromotionReportConstants.CAMPAIGNNAME)}
            />
          </div>
        </>
      ),
      dataIndex: "name",
      key: "name",
      width: "14rem",
      align: "left",
      ellipsis: true,
      render: (_, record) => {
        return (
          <FnbTooltip title={record?.name} onlyShowWhenEllipsis maxWidthContent="95%">
            <FnbTypography.Link
              onClick={() => onHandleSelectedCampaignName(record, "")}
              text={record?.name}
              boxShadow={"0 1px"}
            />
          </FnbTooltip>
        );
      },
    },
    {
      title: (
        <>
          <div className="title-right">{pageData.promotion.campaignTime}</div>
        </>
      ),
      dataIndex: "time",
      align: "right",
      className: "grid-time-column",
      width: "15rem",
      render: (_, record) => {
        return (
          <div className="date-container">
            <Typography className="start-date">
              <span className="start-text">{pageData.promotion.campaignTimeStart}: </span>
              {formatDate(record.startDate, DateFormat.DD_MMM_YYYY_HH_MM)}
            </Typography>
            {record.endDate && (
              <Typography className="end-date">
                <span className="end-text">{pageData.promotion.campaignTimeEnd}: </span>
                {formatDate(record.endDate, DateFormat.DD_MMM_YYYY_HH_MM)}
              </Typography>
            )}
          </div>
        );
      },
    },
    {
      title: (
        <>
          <div className="title-hint">
            {pageData.promotion.campaignRevenue}
            <div className="hint-icon">
              <FnbTooltip maxWidthContent="95%" title={() => pageData.promotion.hint.revenue}>
                <CustomInfoCircleIcon color={"#50429B"} />
              </FnbTooltip>
            </div>
          </div>

          <div className="sort-icon">
            <SortIcon
              direction={sortConstant.DESC}
              active={sortCampaignRevenue === sortConstant.DESC}
              onClick={() => handleSortTable(PromotionReportConstants.CAMPAIGNREVENUE)}
            />
            <SortIcon
              direction={sortConstant.ASC}
              active={sortCampaignRevenue === sortConstant.ASC}
              onClick={() => handleSortTable(PromotionReportConstants.CAMPAIGNREVENUE)}
            />
          </div>
        </>
      ),
      dataIndex: "totalRevenues",
      align: "left",
      width: "15rem",
      render: (_, record) => {
        return (
          <>
            <div className="cell-right">
              <FnbTypography text={formatNumberDecimalOrInteger(record?.totalRevenues)} />
            </div>
          </>
        );
      },
    },
    {
      title: (
        <>
          <div className="title-hint">
            {pageData.promotion.totalOrders}
            <div className="hint-icon">
              <FnbTooltip maxWidthContent="95%" title={() => pageData.promotion.hint.order}>
                <CustomInfoCircleIcon color={"#50429B"} />
              </FnbTooltip>
            </div>
          </div>

          <div className="sort-icon">
            <SortIcon
              direction={sortConstant.DESC}
              active={sortOrder === sortConstant.DESC}
              onClick={() => handleSortTable(PromotionReportConstants.TOTALORDERS)}
            />
            <SortIcon
              direction={sortConstant.ASC}
              active={sortOrder === sortConstant.ASC}
              onClick={() => handleSortTable(PromotionReportConstants.TOTALORDERS)}
            />
          </div>
        </>
      ),
      dataIndex: "totalOrders",
      key: "totalOrders",
      width: "12rem",
      align: "left",
      render: (_, record) => {
        return (
          <>
            <div className="cell-right">
              <FnbTypography.Link
                onClick={() => onHandleSelectedCampaignName(record, "")}
                text={formatNumberDecimalOrInteger(record?.totalOrders)}
                boxShadow={"0 1px"}
              />
            </div>
          </>
        );
      },
    },
    {
      title: (
        <>
          {pageData.promotion.totalCustomers}
          <div className="sort-icon">
            <SortIcon
              direction={sortConstant.DESC}
              active={sortCustomer === sortConstant.DESC}
              onClick={() => handleSortTable(PromotionReportConstants.TOTALCUSTOMERS)}
            />
            <SortIcon
              direction={sortConstant.ASC}
              active={sortCustomer === sortConstant.ASC}
              onClick={() => handleSortTable(PromotionReportConstants.TOTALCUSTOMERS)}
            />
          </div>
        </>
      ),
      dataIndex: "totalCustomers",
      key: "totalCustomers",
      width: "11rem",
      align: "left",
      render: (_, record) => {
        return (
          <>
            <div className="cell-right">
              <FnbTypography.Link
                onClick={() => onHandleSelectedCustomer(record, "")}
                text={formatNumberDecimalOrInteger(record?.totalCustomers)}
                boxShadow={"0 1px"}
              />
            </div>
          </>
        );
      },
    },
    {
      title: (
        <>
          {pageData.promotion.totalDiscounts}
          <div className="sort-icon">
            <SortIcon
              direction={sortConstant.DESC}
              active={sortTotalDiscount === sortConstant.DESC}
              onClick={() => handleSortTable(PromotionReportConstants.TOTALDISCOUNTS)}
            />
            <SortIcon
              direction={sortConstant.ASC}
              active={sortTotalDiscount === sortConstant.ASC}
              onClick={() => handleSortTable(PromotionReportConstants.TOTALDISCOUNTS)}
            />
          </div>
        </>
      ),
      dataIndex: "totalDiscounts",
      align: "left",
      width: "11rem",
      render: (_, record) => {
        return (
          <>
            <div className="cell-right">
              <FnbTypography text={formatNumberDecimalOrInteger(record?.totalDiscounts)} />
            </div>
          </>
        );
      },
    },
    {
      title: (
        <>
          <div className="title-center">{pageData.promotion.status}</div>
        </>
      ),
      dataIndex: "promotionStatus",
      key: "promotionStatus",
      align: "center",
      width: "10rem",
      render: (_, { promotionStatus }) => <Row justify={"center"}>{renderPromotionStatus(promotionStatus)}</Row>,
    },
  ];

  const renderPromotionStatus = (statusId) => {
    switch (statusId) {
      case PromotionStatus.Schedule:
        return <FnbBadge variant="warning" text={pageData.promotion.statusName.scheduled} />;
      case PromotionStatus.Active:
        return <FnbBadge variant="success" text={pageData.promotion.statusName.active} />;
      default:
        return <FnbBadge variant="error" text={pageData.promotion.statusName.finished} />;
    }
  };

  const onHandleSelectedCampaignName = async (item, keySearch) => {
    try {
      var promotionType = item?.promotionReportType;
      switch (promotionType) {
        case ReportPromotionType.AutomaticPromotion:
          campaignDetailOrderListRef.current.initData(item?.id, "", "", branchId);
          break;
        case ReportPromotionType.FlashSale:
          campaignDetailOrderListRef.current.initData("", "", item?.id, branchId);
          break;
        case ReportPromotionType.PromotionCode:
          campaignDetailOrderListRef.current.initData("", item?.id, "", branchId);
          break;
        default:
          break;
      }
      setIsShowCampaignDetailOrderList(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleSelectedCustomer = async (item, keySearch) => {
    try {
      var promotionType = item?.promotionReportType;
      switch (promotionType) {
        case ReportPromotionType.AutomaticPromotion:
          campaignDetailCustomerListRef.current.initData(item?.id, "", "", branchId);
          break;
        case ReportPromotionType.FlashSale:
          campaignDetailCustomerListRef.current.initData("", "", item?.id, branchId);
          break;
        case ReportPromotionType.PromotionCode:
          campaignDetailCustomerListRef.current.initData("", item?.id, "", branchId);
          break;
        default:
          break;
      }
      setIsShowCampaignDetailCustomerList(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandlePromotionStatusSubmit = async (value) => {
    try {
      setCurrentPageNumber(tableSettings.page);
      getDataInfoByFilter(
        branchId,
        selectedDate,
        typeOptionDate,
        tableSettings.page,
        tableSettings.pageSize,
        null,
        value,
        promotionReportType,
        keySearch,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onHandlePromotionTypeSubmit = async (value) => {
    try {
      setCurrentPageNumber(tableSettings.page);
      getDataInfoByFilter(
        branchId,
        selectedDate,
        typeOptionDate,
        tableSettings.page,
        tableSettings.pageSize,
        null,
        promotionStatus,
        value,
        keySearch,
      );
    } catch (error) {
      console.log(error);
    }
  };

  const filterComponent = () => {
    return (
      <FilterPromotionReport
        ref={filterPromotionReportRef}
        setPromotionReportType={setPromotionReportType}
        setPromotionStatus={setPromotionStatus}
        handlePromotionStatusSubmit={onHandlePromotionStatusSubmit}
        handlePromotionTypeSubmit={onHandlePromotionTypeSubmit}
        setCountFilter={setCountFilter}
      />
    );
  };

  const onClearFilter = (e) => {
    if (filterPromotionReportRef && filterPromotionReportRef.current) {
      filterPromotionReportRef.current.clear();
      getDataInfoByFilter(
        branchId,
        selectedDate,
        typeOptionDate,
        tableSettings.page,
        tableSettings.pageSize,
        null,
        0,
        0,
        keySearch,
      );
    }
  };

  const handleChangeBranch = (branchId) => {
    setBranchId(branchId);
    setCurrentPageNumber(tableSettings.page);
    getDataInfoByFilter(
      branchId,
      selectedDate,
      typeOptionDate,
      tableSettings.page,
      tableSettings.pageSize,
      null,
      promotionStatus,
      promotionReportType,
      keySearch,
    );
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    setTypeOptionDate(typeOptionDate);
    setCurrentPageNumber(tableSettings.page);
    getDataInfoByFilter(
      branchId,
      date,
      typeOptionDate,
      tableSettings.page,
      tableSettings.pageSize,
      null,
      promotionStatus,
      promotionReportType,
      keySearch,
    );
  };

  const exportPromotionReportAsync = async () => {
    //TODO  User Story 79628: [Admin] Promotion Report - Download promotion report)
    let languageCode = languageService.getLang();
    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);

    let param = {
      branchId: branchId ?? "",
      startDate: startDate,
      endDate: endDate,
      businessSummaryWidgetFilter: typeOptionDate,
      promotionStatus: promotionStatus,
      promotionReportType: promotionReportType,
      keySearch: keySearch,
      languageCode: languageCode,
    };

    try {
      var response = await reportDataService.exportPromotionReportAsync(param);
      handleDownloadFile(response);
    } catch (error) {
      const { statusText } = error;
      // message.error(statusText);
    }
  };

  const handleChangePage = async (pageNumber, pageSize) => {
    setCurrentPageNumber(pageNumber);
    setPageSize(pageSize);
    getDataInfoByFilter(
      branchId,
      selectedDate,
      typeOptionDate,
      pageNumber,
      pageSize,
      null,
      promotionStatus,
      promotionReportType,
      keySearch,
    );
  };

  const handleSearch = async (keySearch = "") => {
    executeAfter(500, async () => {
      setCurrentPageNumber(tableSettings.page);
      setKeySearch(keySearch);
      getDataInfoByFilter(
        branchId,
        selectedDate,
        typeOptionDate,
        tableSettings.page,
        tableSettings.pageSize,
        null,
        promotionStatus,
        promotionReportType,
        keySearch,
      );
    });
  };

  const handleOnRowClick = ({ id }) => {
    if (!id) return;
    if (hasPermission(PermissionKeys.VIEW_ORDER_REPORT)) {
      //TODO User Story 59213: [Admin] Promotion Report - Campaign usage detail (List orders of the campaign)
    }
  };

  return (
    <>
      <Row className="fnb-form-title" gutter={[0, 16]}>
        <Col span={24}>
          <FnbHeadingPage
            title={pageData.promotion.title}
            isCompactButtons={false}
            listButtons={[
              <FnbFilter
                filter={{
                  onClearFilter: onClearFilter,
                  buttonTitle: pageData.filter,
                  component: filterComponent(),
                  totalFilterSelected: countFilter,
                }}
              />,
              <FnbListBranches onChangeEvent={handleChangeBranch} />,
              <FnbDatePicker
                selectedDate={selectedDate}
                setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
              />,
              <FnbButton
                permission={PermissionKeys.EXPORT_PROMOTION_REPORT}
                onClick={() => exportPromotionReportAsync()}
                text={pageData.btnExport}
                iconHeader={<ExportOutlinedIcon />}
                variant="secondary"
              />,
            ]}
          />
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]}>{renderWidgetJumbotronSummary()}</Row>
        </Col>
      </Row>

      <Row className="mt-3">
        <Card className="fnb-card-custom w-100">
          <FnbTable
            className="promotion-report"
            columns={setColumnTable}
            isSortAPI={true}
            pageSize={pageSize}
            dataSource={initData?.promotionsReport}
            total={initData?.totalCampaings}
            currentPageNumber={currentPageNumber}
            onChangePage={handleChangePage}
            search={{
              placeholder: pageData.promotion.placeholder.campaignName,
              onChange: handleSearch,
            }}
            onRowClick={handleOnRowClick}
          />
        </Card>
      </Row>
      <CampaignDetailCustomerListComponent
        ref={campaignDetailCustomerListRef}
        t={t}
        isShowCampaignDetailCustomerList={isShowCampaignDetailCustomerList}
        handleCancel={() => setIsShowCampaignDetailCustomerList(false)}
      />
      <CampaignDetailOrderListComponent
        ref={campaignDetailOrderListRef}
        t={t}
        isShowCampaignDetailOrderList={isShowCampaignDetailOrderList}
        handleCancel={() => setIsShowCampaignDetailOrderList(false)}
      />
    </>
  );
}

export default PromotionReport;
