export const PromotionType = {
  /// <summary>
  /// DiscountTotal
  /// </summary>
  DiscountTotal: 0,

  /// <summary>
  /// DiscountProduct
  /// </summary>
  DiscountProduct: 1,

  /// <summary>
  /// In DiscountProductCategory
  /// </summary>
  DiscountProductCategory: 2,
};

export const ListPromotionType = [
  {
    key: 0,
    name: "promotion.discount.total",
  },
  {
    key: 1,
    name: "promotion.discount.product",
  },
  {
    key: 2,
    name: "promotion.discount.productCategory",
  },
];

export const PromotionStatus = {
  All: 0,
  Schedule: 1,
  Active: 2,
  Finish: 3,
};

export const PromotionTabLabel = {
  Discount: "discount",
  FlashSale: "flashsale",
  DiscountCode: "discountcode",
  BuyXGetY: "buyxgety",
};

export const PromotionTabKey = {
  Discount: "1",
  FlashSale: "2",
  DiscountCode: "3",
  BuyXGetY: "4",
};

export const ReportPromotionType = {
    All:0 ,
    AutomaticPromotion: 1,
    FlashSale: 2,
    PromotionCode: 3,
};
