import { Col, Row } from "antd";
import { CustomInfoCircleIcon } from "constants/icons.constants";
import React from "react";
import { useTranslation } from "react-i18next";
import { getShortValue, getSuffixShortValue, isDecimalNumber } from "utils/helpers";
import "./fnb-widget-jumbotron.component.scss";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";

function FnbWidgetJumbotron(props) {
  const [t] = useTranslation();
  const {
    summaryItem,
    titleTotal,
    icon,
    isShowHint = false,
    informationHint = "",
    colorHint = "#FFFFFF",
  } = props;

  const formatTextNumberWidget = (number) => {
    if (isNaN(number) || number === 0) {
      return number;
    }

    return isDecimalNumber(number)
      ? `${number.toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ""
      : `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "";
  };

  const renderGroupItems = (data) => {
    const size = 24 / data?.length;
    const groupItems = data?.map((item) => {
      const total = item?.total;
      return <Col span={24} sm={24} md={24} lg={size} xl={size} xxl={size} key={item?.key}>
        <Row className="group-title">
          <Col className="title">
            <FnbTypography variant={theme.typography["b1-bold"]} text={item?.title} color={theme.colors.primary[100]} />
          </Col>
          <Col className="group-hint">
            {item?.isShowHint &&
              <FnbTooltip
                autoAdjustOverflow
                placement="bottom"
                maxWidth="480px"
                variant="secondary"
                title={() => item?.informationHint}
              >
                <CustomInfoCircleIcon color={theme.colors.primary[100]} />
              </FnbTooltip>
            }
          </Col>
        </Row>
        <Row className="group-total">
          <Col>
            <FnbTypography variant={theme.typography["h1-bold"]} text={getShortValue(total)} color={theme.colors.primary[100]} />
            <FnbTypography variant={theme.typography["b1-bold"]} text={getSuffixShortValue(total)} color={theme.colors.primary[100]} className="ml-2" />
          </Col>
        </Row>
        <Row className="group-suffix">
          <FnbTypography variant={theme.typography["b2"]} color={theme.colors.primary[100]}>{formatTextNumberWidget(total)} {item?.suffix} </FnbTypography>
        </Row>
      </Col>
    });
    return <>{groupItems}</>;
  };

  return (
    <div className={`summary-card-average-jumbotron`}>
      <Row className="group-main">
        <Row className="main-icon">
          <div className="hexagon">{icon}</div>
        </Row>
        <Row className="main-total-title">
          <Col className="title">
            {titleTotal}
          </Col>
          <Col className="summary-hint">
            {isShowHint &&
              <FnbTooltip
                autoAdjustOverflow
                placement="bottom"
                maxWidth="480px"
                variant="secondary"
                title={() => informationHint}
              >
                <CustomInfoCircleIcon color={colorHint} />
              </FnbTooltip>
            }
          </Col>
        </Row>
        <Row>
          <Col className="main-value">
            <FnbTypography variant={theme.typography["d1-bold"]} text={getShortValue(summaryItem?.total)} />
          </Col>
        </Row>
      </Row>
      <Row className="group-items">
        {summaryItem?.groupItems?.length > 0 && renderGroupItems(summaryItem?.groupItems)}
      </Row>
    </div>
  );
}

export default FnbWidgetJumbotron;
