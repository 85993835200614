import { Col, Row, Image } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import FnbWidget from "components/fnb-widget/fnb-widget.component";
import { RevenueLineChartComponent } from "components/line-chart/line-chart.component";
import RevenuePieChart from "components/revenue-pie-chart";
import { ExportOutlinedIcon, ReceiptShiftIcon } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import { TYPE } from "constants/report.constants";
import { DateFormat } from "constants/string.constants";
import orderDataService from "data-services/order/order-data.service";
import reportDataService from "data-services/report/report-data.service";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import languageService from "services/language/language.service";
import { languageCodeSelector } from "store/modules/session/session.reducers";
import { getColorForChart, getCurrency, handleDownloadFile, getUrlImageByLanguageCode } from "utils/helpers";
import "./revenue.page.scss";
import { orderPaymentMethodLocalization, paymentMethod } from "constants/payment-method.constants";
import { OrderServiceTypeLocalization } from "constants/order-type-status.constants";
import { imagesWidgetType } from "constants/images.constants";

function Revenue() {
  const { t, ready } = useTranslation();
  const pageData = {
    title: t("report.summary"),
    totalCost: t("reportRevenue.totalCost"),
    totalDiscount: t("reportRevenue.totalDiscount"),
    shippingFee: t("reportRevenue.shippingFee"),
    extraFee: t("reportRevenue.extraFee"),
    totalRevenue: t("reportRevenue.totalRevenue"),
    profit: t("reportRevenue.profit"),
    paid: t("reportRevenue.paid"),
    received: t("reportRevenue.received"),
    unpaid: t("reportRevenue.unpaid"),
    txt_reduce: t("dashboard.txt_reduce"),
    txt_increase: t("dashboard.txt_increase"),
    allBranch: t("dashboard.allBranch"),
    date: {
      yesterday: t("dashboard.compareDate.yesterday"),
      previousDay: t("dashboard.compareDate.previousDay"),
      lastWeek: t("dashboard.compareDate.lastWeek"),
      previousWeek: t("dashboard.compareDate.previousWeek"),
      lastMonth: t("dashboard.compareDate.lastMonth"),
      previousMonth: t("dashboard.compareDate.previousMonth"),
      lastYear: t("dashboard.compareDate.lastYear"),
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    platform: t("platform.title"),
    paymentMethod: t("payment.paymentMethod"),
    serviceType: t("reportRevenue.serviceType"),
    revenuePaymentMethod: t("reportRevenue.revenuePaymentMethod"),
    revenuePlatform: t("reportRevenue.revenuePlatform"),
    revenueServiceType: t("reportRevenue.revenueServiceType"),
    totalTax: t("reportRevenue.totalTax"),
    btnExport: t("button.export"),
    orders: t("report.shift.orders", "Orders"),
    totalOrder: t("dashboard.totalOrder", "Total order"),
    totalProfit: t("reportRevenue.totalProfit", "Total Profit"),
    avgRevenue: t("reportRevenue.avgRevenue", "Average Revenue"),
    avgProfit: t("reportRevenue.avgProfit", "Average Profit"),
    avgTotalCost: t("reportRevenue.avgTotalCost", "Average Cost"),
    avgDiscount: t("reportRevenue.avgDiscount", "Average Discount"),
    avgShippingFee: t("reportRevenue.avgShippingFee", "Average Shipping Fee"),
    avgExtraFee: t("reportRevenue.avgExtraFee", "Average Extra Fee"),
    avgTax: t("reportRevenue.avgTax", "Average Tax"),
    wiget: {
      totalOrder: t("wiget.totalOrder"),
      totalCost: t("wiget.totalCost"),
      totalDiscount: t("wiget.totalDiscount"),
      totalExtraFee: t("wiget.totalExtraFee"),
      totalTax: t("wiget.totalTax"),
      totalRevenue: t("wiget.totalRevenue"),
      totalProfit: t("wiget.totalProfit"),
    },
  };

  const [initData, setInitData] = useState({});
  const [branchId, setBranchId] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });

  const [titleConditionCompare, setTitleConditionCompare] = useState(pageData.date.yesterday);
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);
  const [revenueByPlatform, setRevenueByPlatform] = useState([]);
  const [revenueByPaymentMethod, setRevenueByPaymentMethod] = useState([]);
  const [revenueByServiceType, setRevenueByServiceType] = useState([]);
  const revenueChartReportRef = React.useRef(null);
  const averageRevenueChartReportRef = React.useRef(null);

  const languageCode = useSelector(languageCodeSelector);
  const languageSession = useSelector((state) => state.session?.languageSession);

  useEffect(() => {
    getInitialData(branchId, selectedDate, typeOptionDate);
    getOrderRevenuePieChartByFilter(branchId, selectedDate, typeOptionDate);
  }, []);

  useEffect(() => {
    switch (typeOptionDate) {
      case OptionDateTime.today:
        setTitleConditionCompare(pageData.date.yesterday);
        break;
      case OptionDateTime.yesterday:
        setTitleConditionCompare(pageData.date.previousDay);
        break;
      case OptionDateTime.thisWeek:
        setTitleConditionCompare(pageData.date.lastWeek);
        break;
      case OptionDateTime.lastWeek:
        setTitleConditionCompare(pageData.date.previousWeek);
        break;
      case OptionDateTime.thisMonth:
        setTitleConditionCompare(pageData.date.lastMonth);
        break;
      case OptionDateTime.lastMonth:
        setTitleConditionCompare(pageData.date.previousMonth);
        break;
      case OptionDateTime.thisYear:
        setTitleConditionCompare(pageData.date.lastYear);
        break;
      default:
        setTitleConditionCompare(pageData.date.previousSession);
        break;
    }
    if (ready) getDataForRevenueLineChart(branchId, selectedDate, typeOptionDate);
  }, [languageSession, typeOptionDate, ready]);

  const getInitialData = (branchId, date, typeOptionDate) => {
    getOrderInfoByFilter(branchId, date, typeOptionDate);
    setTypeOptionDate(OptionDateTime.today);
  };

  const getOrderInfoByFilter = async (branchId, date, typeOptionDate) => {
    let startDate = moment(date?.startDate);
    let endDate = moment(date?.endDate);

    // Parse local time frome client to UTC time before comparation
    var fromDate = moment.utc(startDate).local().format(DateFormat.YYYY_MM_DD_HH_MM_SS_2);
    var toDate = moment.utc(endDate).local().format(DateFormat.YYYY_MM_DD_HH_MM_SS_2);

    let req = {
      branchId: branchId ?? "",
      startDate: fromDate,
      endDate: toDate,
      typeOptionDate: typeOptionDate,
    };

    const result = await orderDataService.getOrderBusinessRevenueWidgetAsync(req);

    setInitData(result);
  };

  //Handle for Hint settings
  const renderHintTotalOrder = () => {
    return (
      <p className="hintTotalOrder">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalOrder }} />
      </p>
    );
  };

  const renderHintTotalCost = () => {
    return (
      <p className="hintTotalCost">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalCost }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalCost)} />
        </div>
      </p>
    );
  };

  const renderHintTotalDiscount = () => {
    return (
      <p className="hintTotalDiscount">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalDiscount }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalDiscount)} />
        </div>
      </p>
    );
  };

  const renderHintTotalExtraFee = () => {
    return (
      <p className="hintTotalExtrafee">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalExtraFee }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalExtrafee)} />
        </div>
      </p>
    );
  };

  const renderHintTotalTax = () => {
    return (
      <p className="hintTotalTax">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalTax }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalTax)} />
        </div>
      </p>
    );
  };

  const renderHintTotalRevenue = () => {
    return (
      <p className="hintTotalRevenue">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalRevenue }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalRevenue)} />
        </div>
      </p>
    );
  };

  const renderHintTotalProfit = () => {
    return (
      <p className="hintTotalProfit">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalProfit }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalProfit)} />
        </div>
      </p>
    );
  };


  const renderWidgetSummary = () => {
    let listBusiness = [
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalOrder,
        suffix: pageData.orders,
        summaryItem: {
          percent: initData?.totalOrderPercent,
          total: initData?.totalOrder,
          average: initData?.averageRevenue,
        },
        titleAverage: pageData.avgRevenue,
        isShowHint: true,
        informationHint: renderHintTotalOrder()
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalCost,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentCost,
          total: initData?.totalCost,
          average: initData?.averageCost,
        },
        titleAverage: pageData.avgTotalCost,
        isShowHint: true,
        informationHint: renderHintTotalCost()
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalDiscount,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentDiscount,
          total: initData?.totalDiscount,
          average: initData?.averageDiscount,
        },
        titleAverage: pageData.avgDiscount,
        isShowHint: true,
        informationHint: renderHintTotalDiscount()
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.shippingFee,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentShippingFee,
          total: initData?.totalShippingFee,
          average: initData?.averageShippingFee,
        },
        titleAverage: pageData.avgShippingFee,
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.extraFee,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentExtraFee,
          total: initData?.totalExtraFee,
          average: initData?.averageExtraFee,
        },
        titleAverage: pageData.avgExtraFee,
        isShowHint: true,
        informationHint: renderHintTotalExtraFee()
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalTax,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentTax,
          total: initData?.totalTax,
          average: initData?.averageTax,
        },
        titleAverage: pageData.avgTax,
        isShowHint: true,
        informationHint: renderHintTotalTax()
      },
    ];

    const widgetSummary = listBusiness?.map((item, index) => {
      return (
        <Col key={index} xl={8} span={24} className="ct-widget">
          <FnbWidget
            isShowAverageSection={true}
            isShowAverageOnly={true}
            summaryItem={item.summaryItem}
            titleTotal={item.title}
            icon={item.icon}
            suffixData={item.suffix}
            titleAverage={item.titleAverage}
            styles={{
              backgroundTotalColor: "#FFFFFF",
              sectionTotalTextColor: "#50429B",
              sectionTotalTextCurrencyColor: "#7E66FF",
              sectionAvgBackgroundColor: "#E1DBFF",
              sectionAvgTextColor: "#50429B",
              sectionAvgCurrencyTextColor: "#7E66FF",
            }}
            isShowHint={item.isShowHint}
            informationHint={item.informationHint}
            colorHint={"#50429B"}
          />
        </Col>
      );
    });
    return <>{widgetSummary}</>;
  };

  const renderWidgetRevenue = () => {
    let listBusiness = [
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalRevenue,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentRevenue,
          total: initData?.totalRevenue,
          average: initData?.averageRevenue,
          percentAverage: initData?.percentAverageRevenue,
          paid: initData?.totalRevenuePaid,
          unpaid: initData?.totalRevenueUnpaid,
        },
        titleAverage: pageData.avgRevenue,
        isShowHint: true,
        informationHint: renderHintTotalRevenue()
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalProfit,
        suffix: getCurrency(),
        summaryItem: {
          percent: initData?.percentProfit,
          total: initData?.totalProfit,
          average: initData?.averageProfit,
          percentAverage: initData?.percentAverageProfit,
          paid: initData?.totalProfitPaid,
          unpaid: initData?.totalProfitUnpaid,
        },
        titleAverage: pageData.avgProfit,
        isShowHint: true,
        informationHint: renderHintTotalProfit()
      },
    ];

    const widgetRevenue = listBusiness?.map((item, index) => {
      return (
        <Col key={index} xl={12} lg={24} span={24}>
          <FnbWidget
            isShowAverageSection={true}
            isShowAverageOnly={true}
            isShowPaidAndUnpaid={true}
            summaryItem={item.summaryItem}
            titleTotal={item.title}
            icon={item.icon}
            suffixData={item.suffix}
            titleAverage={item.titleAverage}
            styles={{
              sectionAvgBackgroundColor: "#F7F5FF",
              sectionAvgTextColor: "#50429B",
              sectionAvgCurrencyTextColor: "#7E66FF",
            }}
            isShowHint={item.isShowHint}
            informationHint={item.informationHint}
          />
        </Col>
      );
    });

    return <>{widgetRevenue}</>;
  };

  const handleChangeBranch = (branchId) => {
    setBranchId(branchId);
    getOrderInfoByFilter(branchId, selectedDate, typeOptionDate);
    getOrderRevenuePieChartByFilter(branchId, selectedDate, typeOptionDate);
    getDataForRevenueLineChart(branchId, selectedDate, typeOptionDate);
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    setTypeOptionDate(typeOptionDate);
    getOrderInfoByFilter(branchId, date, typeOptionDate);
    getOrderRevenuePieChartByFilter(branchId, date, typeOptionDate);
    getDataForRevenueLineChart(branchId, date, typeOptionDate);
  };

  /* Revenue by Pie Chart */
  const getOrderRevenuePieChartByFilter = (branchId, date, typeOptionDate) => {
    let startDate = moment(date?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(date?.endDate).format(DateFormat.MM_DD_YYYY);
    let req = {
      branchId: branchId ?? "",
      startDate: startDate,
      endDate: endDate,
      typeOptionDate: typeOptionDate,
      type: TYPE.REVENUE,
    };
    orderDataService.getRevenueByTypeAsync(req).then((res) => {
      if (res) {
        setRevenueByPlatform(res.revenueByPlatforms);
        onSetDataForPaymentMethodChart(res.revenueByPaymentMethods);
        onSetDataForServiceTypeChart(res.revenueByServiceTypes);
      }
    });
  };

  const onSetDataForPaymentMethodChart = (response) => {
    setRevenueByPaymentMethod(
      [...response].map((item) => {
        return {
          ...item,
          name:
            item.paymentMethodType === paymentMethod.Personal
              ? item.name
              : orderPaymentMethodLocalization[item?.paymentMethodType] ?? item.name,
        };
      }),
    );
  };

  const onSetDataForServiceTypeChart = (response) => {
    setRevenueByServiceType(
      [...response].map((item) => {
        return {
          ...item,
          name: OrderServiceTypeLocalization[item?.id],
        };
      }),
    );
  };

  const getDataForRevenueLineChart = (branchId, selectedDateTime, typeOptionDate) => {
    const req = {
      branchId: branchId ?? "",
      startDate: selectedDateTime?.startDate,
      endDate: selectedDateTime?.endDate,
      segmentTimeOption: typeOptionDate,
    };
    orderDataService.calculateStatisticalDataAsync(req).then((res) => {
      if (res) {
        let legendContainerId = "legendContainerId";
        if (revenueChartReportRef && revenueChartReportRef.current) {
          revenueChartReportRef.current.fillData(selectedDateTime, typeOptionDate, res, false, legendContainerId);
        }
        setTimeout(() => {
          legendContainerId = legendContainerId + 2;
          if (averageRevenueChartReportRef && averageRevenueChartReportRef.current) {
            averageRevenueChartReportRef.current.fillData(
              selectedDateTime,
              typeOptionDate,
              res,
              true,
              legendContainerId,
            );
          }
        }, 1000);
      }
    });
  };

  const getDataPlatformForPieChart = () => {
    const platformColor = getColorForChart(249, 100, 24, revenueByPlatform?.length);
    return revenueByPlatform?.map((item, index) => {
      return {
        label: item?.name,
        value: item?.totalAmount,
        color: platformColor[index],
        totalAmount: item?.totalAmount,
      };
    });
  };

  const getDataPaymentMethodForPieChart = () => {
    const paymentMethodColor = getColorForChart(29, 100, 50, revenueByPaymentMethod?.length);
    return revenueByPaymentMethod?.map((item, index) => {
      return {
        label: item?.name,
        value: item?.totalAmount,
        color: paymentMethodColor[index],
        totalAmount: item?.totalAmount,
      };
    });
  };

  const getDataServiceTypeForPieChart = () => {
    const serviceTypeColor = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 206, 86, 0.2)",
      "rgba(155, 216, 76, 0.2)",
      "rgba(175, 292, 92, 0.2)",
      "rgba(253, 102, 255, 0.2)",
    ];
    return revenueByServiceType?.map((item, index) => {
      return {
        label: item?.name,
        value: item?.totalAmount,
        color: serviceTypeColor[index],
        totalAmount: item?.totalAmount,
      };
    });
  };

  const exportSoldProductsAsync = async () => {
    let languageCode = languageService.getLang();
    var timeZone = new Date().getTimezoneOffset() / 60;

    let startDate = moment(selectedDate?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(selectedDate?.endDate).format(DateFormat.MM_DD_YYYY);

    let req = {
      branchId: branchId ?? "",
      typeOptionDate: typeOptionDate,
      startDate: startDate,
      endDate: endDate,
      segmentTimeOption: typeOptionDate,
      type: TYPE.REVENUE,
      languageCode: languageCode,
      timeZone: timeZone,
    };
    try {
      var response = await reportDataService.exportRevenueAsync(req);
      handleDownloadFile(response);
    } catch (ex) {}
  };

  return (
    <>
      <Row className="fnb-form-title" gutter={[0, 16]}>
        <Col span={24}>
          <FnbHeadingPage
            title={pageData.title}
            isCompactButtons={false}
            listButtons={[
              <FnbButton
                permission={PermissionKeys.EXPORT_REVENUE_REPORT}
                onClick={() => exportSoldProductsAsync()}
                text={pageData.btnExport}
                iconHeader={<ExportOutlinedIcon />}
                variant="secondary"
              />,
              <FnbListBranches onChangeEvent={handleChangeBranch} />,
              <FnbDatePicker
                selectedDate={selectedDate}
                setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
              />,
            ]}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>{renderWidgetSummary()}</Row>
        </Col>
        <Col span={24}>
          <Row gutter={[16, 16]}>{renderWidgetRevenue()}</Row>
        </Col>
      </Row>
      <Row gutter={[16, 30]}>
        <Col span={24} style={{ marginTop: 30 }}>
          <RevenueLineChartComponent ref={revenueChartReportRef} />
        </Col>
        <Col span={24}>
          <RevenueLineChartComponent chartTitle={t("chartAverageRevenue.title")} ref={averageRevenueChartReportRef} />
        </Col>
      </Row>
      <div className="c-revenue-pie-chart">
        {/* revenueByPlatform */}
        <div className="rpc-element">
          <div className="rpc-element-wrapper">
            <RevenuePieChart
              key={`${languageCode}-revenueByPlatform`}
              dataSourceRevenue={revenueByPlatform}
              getDataForPieChart={getDataPlatformForPieChart()}
              titleBack={pageData.platform.toUpperCase()}
              chartName={pageData.revenuePlatform}
            />
          </div>
        </div>

        {/* revenueByPaymentMethod */}
        <div className="rpc-element">
          <div className="rpc-element-wrapper">
            <RevenuePieChart
              key={`${languageCode}-revenueByPaymentMethod`}
              dataSourceRevenue={revenueByPaymentMethod}
              getDataForPieChart={getDataPaymentMethodForPieChart()}
              titleBack={pageData.paymentMethod.toUpperCase()}
              chartName={pageData.revenuePaymentMethod}
            />
          </div>
        </div>

        {/* revenueByServiceType */}
        <div className="rpc-element">
          <div className="rpc-element-wrapper">
            <RevenuePieChart
              key={`${languageCode}-revenueByServiceType`}
              dataSourceRevenue={revenueByServiceType}
              getDataForPieChart={getDataServiceTypeForPieChart()}
              titleBack={pageData.serviceType.toUpperCase()}
              chartName={pageData.revenueServiceType}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Revenue;
