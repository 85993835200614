import { FnbButton } from "components/fnb-button/fnb-button";
import { PlusCircleIcon } from "constants/icons.constants";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetterEachWord, hasPermission } from "utils/helpers";
import "./fnb-add-new-button.scss";

export function FnbAddNewButton({
  className = "",
  onClick,
  text,
  htmlType,
  permission,
  disabled,
  hideIcon,
  idControl = "btn-add-new",
  customIcon,
  loading = false,
}) {
  const [t] = useTranslation();
  const renderButton = () => {
    const titleFormatted = text ? capitalizeFirstLetterEachWord(text) : t("button.addNew");
    if (hasPermission(permission)) {
      return (
        <FnbButton
          iconHeader={hideIcon ? <></> : customIcon ? customIcon : <PlusCircleIcon />}
          className={`${className}`}
          onClick={onClick}
          type={htmlType}
          disabled={disabled || loading}
          id={idControl}
          text={titleFormatted}
          loading={loading}
        />
      );
    }

    if (!permission) {
      return (
        <FnbButton
          iconHeader={hideIcon ? <></> : customIcon ? customIcon : <PlusCircleIcon />}
          className={`${className}`}
          onClick={onClick}
          type={htmlType}
          disabled={disabled || loading}
          id={idControl}
          text={titleFormatted}
          loading={loading}
        />
      );
    }
  };
  return <>{renderButton()}</>;
}
