import { Card, Col, Row, Image } from "antd";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbListBranches } from "components/fnb-list-branches/fnb-list-branches";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbWidget from "components/fnb-widget/fnb-widget.component";
import { RevenueLineChartComponent } from "components/line-chart/line-chart.component";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { ReceiptShiftIcon, WalletIcon } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { TRANSACTION_TABPANE_KEY } from "constants/report.constants";
import { DateFormat } from "constants/string.constants";
import { target, useRedirect } from "hooks/useRedirect";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { formatNumberDecimalOrInteger, getCurrency, tokenExpired } from "utils/helpers";
import { getStorage, localStorageKeys } from "utils/localStorage.helpers";
import productImageDefault from "../../assets/images/product-img-default.png";
import StaffActivitiesComponent from "./components/staff-activities.component";
import { authUserSelector, storeInfoSelector, currentUserSelector } from "store/modules/session/session.reducers";
import "./index.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { Link } from "react-router-dom";
import SystemGuideline from "./components/system-guideline/system-guideline.component";
import TrialPackageExpiredComponent from "./components/trial-package-expired"
import { isStoreInternational, getUrlImageByLanguageCode } from "utils/helpers";
import SocialContact from "components/social-contact";
import storeDataService from "data-services/store/store-data.service";
import { getUserInfo } from "services/auth.service";
import { imagesWidgetType } from "constants/images.constants";

function Home(props) {
  const { orderDataService } = props;
  const [t, ready] = useTranslation();
  const dispatch = useDispatch();
  const currency = getCurrency();
  const { handleRedirect } = useRedirect();
  const pageData = {
    title: t("dashboard.title"),
    order: t("dashboard.order", "Order"),
    revenue: t("dashboard.revenue"),
    cost: t("dashboard.cost"),
    txt_reduce: t("dashboard.txt_reduce"),
    txt_increase: t("dashboard.txt_increase"),
    allBranch: t("dashboard.allBranch"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    noDataFound: t("table.noDataFound"),
    topSellingProductTitle: t("dashboard.topSellingProduct.title"),
    topSellingProductSeemore: t("dashboard.topSellingProduct.seemore"),
    topSellingProductViewMore: t("dashboard.topSellingProduct.viewMore"),
    topSellingProductItems: t("dashboard.topSellingProduct.productItems"),
    topCustomerTitle: t("dashboard.topCustomer.title"),
    recentlyActivitiesText: t("homePage.recentlyActivitiesText"),
    orders: t("report.shift.orders", "Orders"),
    totalOrder: t("dashboard.totalOrder", "Total order"),
    totalRevenue: t("dashboard.totalRevenue", "Total revenue"),
    totalCost: t("dashboard.totalCost", "Total cost"),
    wiget: {
      totalOrder: t("wiget.totalOrder"),
      totalCost: t("wiget.totalCost"),
      totalRevenue: t("wiget.totalRevenue"),
    },
  };
  const isMobileDevice = useMediaQuery({ maxWidth: 575 });
  const [initData, setInitData] = useState({});
  const [branchId, setBranchId] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.today);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  const tableLineChartRef = React.useRef(null);
  const [dataChart, setDataChart] = useState();
  const languageSession = useSelector((state) => state.session?.languageSession);
  const authUser = useSelector(authUserSelector);
  const { isTrial } = useSelector(storeInfoSelector);
  const currentUser = useSelector(currentUserSelector);
  const isInternational = isStoreInternational();
  const [contactConfigs, setContactConfigs] = useState([]);
  const loggedUserInfo = getUserInfo();

  useEffect(() => {
    if (authUser?.isExpired) {
      props.history.push("/my-account");
    } else {
      getInfoData(branchId, selectedDate, typeOptionDate);
      if (ready) getStatisticalData(branchId, selectedDate, typeOptionDate);
    }

    //set default scrollTop
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 100);
  }, [authUser?.isExpired, languageSession]);

  //Customize className for right content main body 
  useEffect(() => {
    const mainContentElement = document.getElementById("main-body-id");
    mainContentElement.classList.add("main-body__homepage");
    return () => {
      mainContentElement.classList.remove("main-body__homepage");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTokenExpired = () => {
    let isTokenExpired = true;
    let token = getStorage(localStorageKeys.TOKEN);
    if (token || token !== null) {
      isTokenExpired = tokenExpired(token);
    }
    return isTokenExpired;
  };

  const getStoreConfigSocial = async () => {
    try {
      const storeSocialsSettings = await storeDataService.getStoreSocialsSettings();
      if (storeSocialsSettings) {
        const { contacts } = storeSocialsSettings;
        if (contacts && contacts.length > 0) {
          setContactConfigs(contacts);
        }
      }
    } catch (error) {
      console.log("Error getStoreConfigSocial", error);
    }
  };

  const getInfoData = (branchId, date, typeOptionDate) => {
    getOrderInfoByFilter(branchId, date, typeOptionDate);
    getStoreConfigSocial();
  };

  const getStatisticalData = (branchId, date, typeOptionDate) => {
    let req = {
      branchId: branchId ?? "",
      startDate: date?.startDate,
      endDate: date?.endDate,
      segmentTimeOption: typeOptionDate,
    };

    orderDataService
      .calculateStatisticalDataAsync(req)
      .then((res) => {
        if (tableLineChartRef && tableLineChartRef.current) {
          tableLineChartRef.current.fillData(date, typeOptionDate, res);
        }
        setDataChart(res);
      })
      .catch((errors) => {
        if (tableLineChartRef && tableLineChartRef.current) {
          tableLineChartRef.current.fillData(date, typeOptionDate, { orderData: [] });
        }
        setDataChart({ orderData: [] });
      });
  };

  const getOrderInfoByFilter = async (branchId, date, typeOptionDate) => {
    // startDate and endDate are local time from client
    let startDate = moment(date?.startDate).format(DateFormat.MM_DD_YYYY);
    let endDate = moment(date?.endDate).format(DateFormat.MM_DD_YYYY);
    let req = {
      branchId: branchId ?? "",
      startDate: startDate,
      endDate: endDate,
      typeOptionDate: typeOptionDate,
    };
    const resultSummaryWidget = await orderDataService.getOrderBusinessSummaryWidgetAsync({
      ...req,
      startDate: date?.startDate,
      endDate: date?.endDate,
    });
    const resultTopSellingProduct = await orderDataService.getOrderTopSellingProductAsync(req);

    setInitData(resultSummaryWidget);
    setTopSellingProducts(resultTopSellingProduct.listTopSellingProduct);
    setTopCustomers(resultTopSellingProduct.listTopCustomer);
  };

  //Handle for Hint settings
  const renderHintTotalOrder = () => {
    return (
      <p className="hintTotalOrder">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalOrder }} />
      </p>
    );
  };

  const renderHintTotalCost = () => {
    return (
      <p className="hintTotalCost">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalCost }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalCost)} />
        </div>
      </p>
    );
  };

  const renderHintTotalRevenue = () => {
    return (
      <p className="hintTotalRevenue">
        <ul dangerouslySetInnerHTML={{ __html: pageData.wiget.totalRevenue }} />
        <div>
          <Image src={getUrlImageByLanguageCode(imagesWidgetType.TotalRevenue)} />
        </div>
      </p>
    );
  };

  const renderWidgets = () => {
    let listBusiness = [
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalOrder,
        suffix: pageData.orders,
        summaryItem: {
          percent: initData?.percentOrder,
          total: initData?.totalOrder,
          average: initData?.averageTotalRevenue,
          percentAverage: initData?.percentAverageRevenue,
        },
        isShowHint: true,
        informationHint: renderHintTotalOrder()
      },
      {
        icon: <WalletIcon />,
        title: pageData.totalRevenue,
        suffix: currency,
        summaryItem: {
          percent: initData?.percentRevenue,
          total: initData?.totalRevenue,
          average: initData?.averageTotalRevenue,
          percentAverage: initData?.percentAverageRevenue,
        },
        isShowHint: true,
        informationHint: renderHintTotalRevenue()
      },
      {
        icon: <ReceiptShiftIcon />,
        title: pageData.totalCost,
        suffix: currency,
        summaryItem: {
          percent: initData?.percentCost,
          total: initData?.totalCost,
          average: initData?.averageTotalCost,
          percentAverage: initData?.percentAverageCost,
        },
        isShowHint: true,
        informationHint: renderHintTotalCost()
      },
    ];
    const widgets = listBusiness?.map((item, index) => {
      return (
        <Col key={index} xl={8} span={24} className="col-group-business col-with-auto">
          <FnbWidget
            isShowAverageSection={true}
            isShowAverageOnly={false}
            summaryItem={item.summaryItem}
            titleTotal={item.title}
            icon={item.icon}
            suffixData={item.suffix}
            isShowHint={item.isShowHint}
            informationHint={item.informationHint}
          />
        </Col>
      );
    });

    return <>{widgets}</>;
  };

  const handleChangeBranch = (branchId) => {
    setBranchId(branchId);
    getOrderInfoByFilter(branchId, selectedDate, typeOptionDate);
    getStatisticalData(branchId, selectedDate, typeOptionDate);
  };

  const onSelectedDatePicker = (date, typeOptionDate) => {
    setSelectedDate(date);
    setTypeOptionDate(typeOptionDate);
    getOrderInfoByFilter(branchId, date, typeOptionDate);
    getStatisticalData(branchId, date, typeOptionDate);
  };

  const handleRedirectCustomer = (route) => {
    handleRedirect(route, target.blank);
  };

  const tableTopSellingProductSettings = {
    pageSize: 20,
    columns: [
      {
        align: "center",
        width: "2rem",
        className: "col-order",
        render: (value, { no }) => {
          return <span>{no}</span>;
        },
      },
      {
        align: "center",
        className: "col-thumbnail",
        render: (value, { thumbnail }) => {
          return <Thumbnail src={thumbnail} imageDefault={productImageDefault} />;
        },
      },
      {
        align: "left",
        ellipsis: true,
        width: "40%",
        className: "col-name",
        render: (_, { priceName, productName, productId }) => {
          return (
            <Link to={`/product/details/${productId}`} target="_blank">
              <FnbTooltip title={productName} placement="top" onlyShowWhenEllipsis>
                <FnbTypography
                  text={productName}
                  color={theme.colors.default.black}
                  variant={theme.typography["b1-bold"]}
                />
              </FnbTooltip>
              <FnbTypography text={priceName} variant={theme.typography["b1-regular"]} color={theme.colors.gray[70]} />
            </Link>
          );
        },
      },
      {
        align: "right",
        width: "35%",
        className: "col-item",
        render: (value, { quantity, totalCost }) => {
          return (
            <div className="description">
              <span className="description__quantity">{`${quantity} ${pageData.topSellingProductItems}`}</span>
              <span className="description__price">{`${formatNumberDecimalOrInteger(totalCost)} ${currency}`}</span>
            </div>
          );
        },
      },
    ],
  };

  const tableTopCustomerSettings = {
    pageSize: 20,
    columns: [
      {
        align: "center",
        width: "2rem",
        className: "col-order",
        render: (value, { no }) => {
          return <span>{no}</span>;
        },
      },
      {
        align: "center",
        className: "col-thumbnail",
        render: (value, { thumbnail }) => {
          return <Thumbnail src={thumbnail} imageDefault={productImageDefault} />;
        },
      },
      {
        align: "left",
        ellipsis: true,
        width: "40%",
        className: "col-customer",
        render: (value, { customerName, id }) => {
          return (
            <FnbTooltip title={customerName} placement="top" onlyShowWhenEllipsis>
              <span onClick={() => handleRedirectCustomer(`/customer/detail/${id}`)} className="customer-name">
                {customerName}
              </span>
            </FnbTooltip>
          );
        },
      },
      {
        dataIndex: "cost",
        width: "35%",
        align: "right",
        className: "col-price",
        render: (value) => {
          return <span className="price">{`${formatNumberDecimalOrInteger(value)} ${currency}`}</span>;
        },
      },
    ],
  };

  const renderTopSellingProductAndCustomer = () => {
    return (
      <Row className="mt-5 row__widget-selling" gutter={[12, 0]}>
        <Col flex={1} className="col__selling-product" span={12}>
          <Card>
            <Row className="row__title">
              <span className="span__title">{pageData.topSellingProductTitle}</span>
              <span
                className="span__see-more"
                onClick={() => {
                  props.history.push(`/report/transaction/${TRANSACTION_TABPANE_KEY.PRODUCT}`);
                }}
              >
                {pageData.topSellingProductViewMore}
              </span>
            </Row>
            <FnbTable
              className="best-sell-product"
              columns={tableTopSellingProductSettings.columns}
              dataSource={topSellingProducts}
              showHeader={false}
              scrollX={isMobileDevice ? 'auto' : 0}
            />
          </Card>
        </Col>
        <Col flex={1} className="col__top-customer" span={12}>
          <Card>
            <Row className="row__title">
              <span className="span__title">{pageData.topCustomerTitle}</span>
              <span
                className="span__see-more"
                onClick={() => {
                  props.history.push("/report/customer");
                }}
              >
                {pageData.topSellingProductSeemore}
              </span>
            </Row>
            <FnbTable
              className="top-customer"
              columns={tableTopCustomerSettings.columns}
              showHeader={false}
              dataSource={topCustomers}
              scrollX={isMobileDevice ? 'auto' : 0}
            />
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <div className="wrapper-home-page">
      <TrialPackageExpiredComponent isTrial={isTrial} trialExpired={currentUser?.trialPackageExpired} />
      <SystemGuideline />
      <Row className="home-page fnb-form-title" gutter={[0, 0]}>
        <Col span={24}>
          <FnbHeadingPage
            title={pageData.title}
            isCompactButtons={false}
            listButtons={[
              <FnbListBranches onChangeEvent={handleChangeBranch} />,
              <FnbDatePicker
                selectedDate={selectedDate}
                setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
              />,
            ]}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[12, 12]} className="row-width-auto">
            {renderWidgets()}
          </Row>
          <Row className="mt-4" gutter={[12, 0]}>
            <Col flex={1} span={24} lg={16}>
              <Row>
                <RevenueLineChartComponent ref={tableLineChartRef} />
              </Row>
              {renderTopSellingProductAndCustomer()}
            </Col>
            <Col flex={1} span={24} lg={8}>
              <StaffActivitiesComponent />
            </Col>
          </Row>
        </Col>
      </Row>
      {isInternational && <SocialContact configs={contactConfigs} extra={[]} />}
    </div>
  );
}

export default Home;
