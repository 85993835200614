import { Form, Radio } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbSelectMultiple } from "components/fnb-select-multiple/fnb-select-multiple";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./filter-promotion-report.style.scss";
import React from "react";
import { PromotionStatus, ReportPromotionType } from "constants/promotion.constants";

const FilterPromotionReport = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [countType, setCountType] = useState(0);
  const [countStatus, setCountStatus] = useState(0);
  const { setPromotionReportType, setPromotionStatus, handlePromotionStatusSubmit, handlePromotionTypeSubmit, setCountFilter } = props;

  const pageData = {
    filter: {
      status: t("report.promotion.status"),
      promotionType: t("report.promotion.type"),
    },
  };

  const statusOptions = [
    { name: t("report.promotion.statusName.all"), id: PromotionStatus.All },
    { name: t("report.promotion.statusName.scheduled"), id: PromotionStatus.Schedule },
    { name: t("report.promotion.statusName.active"), id: PromotionStatus.Active },
    { name: t("report.promotion.statusName.finished"), id: PromotionStatus.Finish },
  ];

  const stypeOptions = [
    { name: t("report.promotion.typeReport.all"), id: ReportPromotionType.All },
    { name: t("report.promotion.typeReport.automatic"), id: ReportPromotionType.AutomaticPromotion },
    { name: t("report.promotion.typeReport.flashsale"), id: ReportPromotionType.FlashSale },
    { name: t("report.promotion.typeReport.discountCode"), id: ReportPromotionType.PromotionCode },
  ];

  const updateCounts = (increment) => {
    setCount((prevCount) => Math.max(0, prevCount + increment));
    setCountFilter((prevFilter) => Math.max(0, prevFilter + increment));
  };

  const onChangeStatus = (value) => {
    setPromotionStatus(value);
    handlePromotionStatusSubmit(value);

    if (value !== 0) {
      if (countStatus === 0) {
        updateCounts(1);
      }
      setCountStatus(1);
    } else {
      if (countStatus !== 0) {
        updateCounts(-1);
      }
      setCountStatus(0);
    }
  };

  const onChangeType = (value) => {
    setPromotionReportType(value);
    handlePromotionTypeSubmit(value);

    if (value !== 0) {
      if (countType === 0) {
        updateCounts(1);
      }
      setCountType(1);
    } else {
      if (countType !== 0) {
        updateCounts(-1);
      }
      setCountType(0);
    }
  };

  React.useImperativeHandle(ref, () => ({
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    form.resetFields();
    setPromotionReportType(0);
    setPromotionStatus(0);
    setCountFilter(0);
    setCount(0);
    setCountType(0);
    setCountStatus(0);
  };

  return (
    <Form layout="vertical" className="filter-promotion-report" form={form}>
      <Form.Item label={pageData.filter.status} name="promotion-status" initialValue={statusOptions[0].name}>
        <FnbSelectSingle
          onChange={onChangeStatus}
          className="form-select"
          showSearch={false}
          option={statusOptions}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.promotionType} name="promotion-type" initialValue={stypeOptions[0].name}>
        <FnbSelectSingle
          onChange={onChangeType}
          className="form-select"
          showSearch={false}
          option={stypeOptions}
        />
      </Form.Item>
    </Form>
  );
});

export default FilterPromotionReport;
