import { Col, Row, Typography } from "antd";
import FnbCheckBox from "components/fnb-checkbox/fnb-checkbox";
import { FnbChip } from "components/fnb-chip/fnb-chip.component";
import { FnbInput } from "components/fnb-input/fnb-input.component";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { NoDataFoundComponent } from "components/no-data-found/no-data-found.component";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { AddCircleTableIcon, ImageMaterialDefault, SearchIcon } from "constants/icons.constants";
import { MaterialType, MaterialTypeLocalization } from "constants/pre-process.constants";
import ingredientRemoveRequestDataService from "data-services/material-control/ingredient-remove-request-data.service";
import materialDataService from "data-services/material/material-data.service";
import useDebounce from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency, formatNumberDecimalOrInteger } from "utils/helpers";
import "./ingredient-search-modal.scss";
import { useRef } from "react";
export const IngredientSearchModalType = {
  COST_BASE_UNIT: 0,
  TYPE_INGREDIENT: 1,
};
export function IngredientSearchModal({
  visible,
  className,
  handleCancel,
  branchId,
  listSelectedMaterials,
  setListSelectedMaterials,
  modalType = IngredientSearchModalType.COST_BASE_UNIT,
  afterSelected,
  allowAddNewIngredient = true,
  position,
  loadAllData = false,
  excludedId,
}) {
  const { t } = useTranslation();
  const pageData = {
    searchByNameSku: t("materialControl.createRemoveRequest.searchByNameSku"),
    text1: t("materialControl.createRemoveRequest.addIngredientsText1"),
    text2: t("materialControl.createRemoveRequest.addIngredientsText2"),
    ingredientSearch: t("materialControl.createRemoveRequest.ingredientSearch"),
    inventory: t("material.inventory.title"),
  };

  const [materials, setMaterials] = useState([]);
  const [searchMaterials, setSearchMaterials] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState(listSelectedMaterials ?? []);
  const [keyword, setKeyword] = useState("");
  const ref = useRef(null);

  const renderListIngredients = () => {
    return searchMaterials.map((material, index) => {
      const id = material.materialId ?? material.id;
      return (
        <Row
          id={`content-view-${index}`}
          style={{
            display: "flex",
            gap: 16,
            flexDirection: "row",
            padding: 12,
            borderBottomWidth: index === searchMaterials?.length - 1 ? 0 : 1,
            borderBottomColor: "#E1DBFF",
            borderBottomStyle: "solid",
          }}
        >
          <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <FnbCheckBox
              defaultChecked={selectedMaterials?.findIndex((x) => (x.materialId ?? x.key) === id) !== -1}
              checked={selectedMaterials?.findIndex((x) => (x.materialId ?? x.key) === id) !== -1}
              value={material.materialId ?? material.id}
              onChange={(e) => handleCheckMaterial(e, material)}
            />
          </Col>
          <Col style={{ flex: 1 }}>
            <Row style={{ gap: 12 }} align={"middle"}>
              <Col>
                {material?.thumbnail ? (
                  <Thumbnail width={64} height={64} src={material?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={64} height={64} />
                )}
              </Col>
              <Col style={{ alignSelf: "center", flex: 1, gap: 6 }}>
                <Row>
                  <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>{material?.name}</Typography>
                </Row>
                {material?.sku && (
                  <Row>
                    <Typography style={{ fontWeight: 400, fontSize: 14, color: "#8B8899" }}>
                      SKU: {material?.sku}
                    </Typography>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          {modalType === IngredientSearchModalType.COST_BASE_UNIT && (
            <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>
                {formatCurrency(material?.price ?? material?.costPerUnit)}/{material?.unitName}
              </Typography>
            </Col>
          )}
          {modalType === IngredientSearchModalType.TYPE_INGREDIENT && (
            <Col
              style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column" }}
            >
              <Row style={{ gap: 8, justifyContent: "space-between", width: "100%" }}>
                <Typography style={{ fontSize: 16, fontWeight: 400, color: "#8B8899" }}>
                  {t("materialControl.preprocess.type")}:
                </Typography>
                <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>
                  {material?.materialType === MaterialType.SemiFinished
                    ? t(MaterialTypeLocalization[MaterialType.SemiFinished])
                    : t(MaterialTypeLocalization[MaterialType.Raw])}
                </Typography>
              </Row>
              <Row style={{ justifyContent: "space-between", width: "100%" }}>
                <Typography style={{ fontSize: 16, fontWeight: 400, color: "#8B8899" }}>
                  {pageData.inventory}:
                </Typography>
                <Typography style={{ fontWeight: 400, fontSize: 16, color: "#2f2d39" }}>
                  {formatNumberDecimalOrInteger(material?.inventoryQuantity ?? (material?.quantity ?? 0))} {material?.unitName}
                </Typography>
              </Row>
            </Col>
          )}
        </Row>
      );
    });
  };

  const handleGetIngredients = async () => {
    if (branchId) {
      let data =
        modalType === IngredientSearchModalType.TYPE_INGREDIENT && !loadAllData
          ? await materialDataService.getMaterialsByBranchIdAsync(branchId, 2)
          : await ingredientRemoveRequestDataService.getAllIngredientByBranchId(branchId);
      if (data) {
        setMaterials(data?.ingredients);
        setSearchMaterials(data?.ingredients);
      }
    } else {
      const res = await materialDataService.getAllMaterialManagementsAsync(excludedId ?? '');
      if (res) {
        const materials = res?.materials;
        if (materials) {
          setMaterials(materials);
          setSearchMaterials(materials);
        }
      }
    }
  };

  const handleCheckMaterial = (e, material) => {
    if (!material.materialId)
      material.materialId = material.id;
    if (e.target.checked) {
      let newListMaterials = [...selectedMaterials, material];
      setSelectedMaterials(newListMaterials);
    } else {
      let newListMaterials = selectedMaterials?.filter((x) => (x.materialId ?? x.key)!== material.materialId);
      setSelectedMaterials(newListMaterials);
    }
    setKeyword("");
  };

  useDebounce(
    () => {
      if (keyword?.length === 0) {
        setSearchMaterials(materials);
      } else {
        let newMaterials = materials.filter((x) => {
          return (
            x?.name?.removeVietnamese().toLowerCase().includes(keyword?.removeVietnamese().toLowerCase()) ||
            x?.sku?.removeVietnamese().toLowerCase().includes(keyword?.removeVietnamese().toLowerCase())
          );
        });
        setSearchMaterials(newMaterials);
      }
    },
    [keyword],
    500,
  );

  const handleSubmitFilter = () => {
    setListSelectedMaterials && setListSelectedMaterials(selectedMaterials);
    afterSelected && afterSelected(selectedMaterials, position);
    handleCancel();
  };

  useEffect(() => {
    if (visible) {
      handleGetIngredients();
      setSelectedMaterials([...listSelectedMaterials]);
      if (ref && ref.current) {
        setTimeout(() => ref.current.focus(), 500);
      }
    }
    setKeyword("");
  }, [visible]);

  useEffect(() => {
    setListSelectedMaterials && setListSelectedMaterials([]);
    setSelectedMaterials([]);
    setMaterials([]);
    setSearchMaterials([]);
  }, [branchId]);

  useEffect(() => {
    if (searchMaterials?.length > 0) document.getElementById("content-view-0")?.scrollIntoView();
  }, [searchMaterials]);

  const redirectUrl = () => {
    handleCancel();
    setTimeout(() => {
      let baseUrl = "/material/add-new";
      if (keyword) {
        const data = {
          name: keyword,
        };
        const queryString = new URLSearchParams(data).toString();
        baseUrl = `${baseUrl}?` + queryString;
      }
      window.open(baseUrl, "_blank");
    }, 500);
  };

  return (
    <FnbModal
      visible={visible}
      className={`ingredient-search-modal ${className}`}
      handleCancel={handleCancel}
      onOk={handleSubmitFilter}
      title={pageData.ingredientSearch}
      width={800}
      closeIcon
      okText={`${pageData.text1} ${selectedMaterials?.length > 0 ? `(${selectedMaterials?.length})` : ""} ${
        pageData.text2
      }`}
      content={
        <div className="content-inner">
          <FnbInput
            ref={ref}
            value={keyword}
            prefix={
              <div id='chip-container'>
                {selectedMaterials?.length === 0 && <SearchIcon />}
                {selectedMaterials?.map((material) => {
                  const id = material.materialId ?? material.key;
                  let selectedMaterial = materials.find((x) => (x.materialId ?? x.id) === id);
                  return (
                    <FnbChip
                      className={"ingredient-chip"}
                      id={selectedMaterial?.id}
                      isDisableToolTip={true}
                      title={selectedMaterial?.name}
                      isRemoveChip={true}
                      onRemoveChip={() => {
                        let newListMaterials = selectedMaterials?.filter((x) => (x.materialId ?? x.key) !== id);
                        setSelectedMaterials(newListMaterials);
                      }}
                    />
                  );
                })}
              </div>
            }
            placeholder={selectedMaterials?.length === 0 ? pageData.searchByNameSku : undefined}
            onChange={(e) => setKeyword(e.target.value)}
            className="ingredient-input"
            maxLength={100}
            allowClear
            autoFocus
          />
          <div className="ingredient-modal-content">
            <div className="ingredient-modal-content-list" style={searchMaterials?.length === 0 ? {minWidth: '100%'} : {}}>
              {searchMaterials?.length > 0 ? (
                renderListIngredients()
              ) : (
                <div className="ingredient-search-no-data">
                  <NoDataFoundComponent />
                  {allowAddNewIngredient && (
                    <div className="text-add-new-data" onClick={() => redirectUrl()}>
                      <AddCircleTableIcon />
                      <span>
                        {t("material.addNewItemIngredient", {
                          item: `${keyword ?? ""}`,
                        })}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      }
      disabledBtnOk={selectedMaterials?.length === 0}
    />
  );
}
