import { Drawer, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Platform } from "../../../../constants/platform.constants";
import { store } from "../../../../modules/index";
import { setToastMessageUpdateToCart } from "../../../../modules/toast-message/toast-message.actions";
import posCartService from "../../../../services/pos/pos-cart.services";
import shoppingCartService from "../../../../services/shopping-cart/shopping-cart.service";
import {
  calculatePercentageFlashSale,
  formatTextCurrency,
  formatTextNumber,
  getCurrencySymbol,
} from "../../../../utils/helpers";
import { CheckoutDeleteIcon, MinusQuantityIcon, PlusQuantityIcon } from "../../../assets/icons.constants";
import cartNote from "../../../assets/icons/cart-note.svg";
import productImageDefault from "../../../assets/images/product-default-img.jpg";
import EditOrderItem from "../../../components/edit-order-item/edit-order-item.component";
import FnbDisplayImageComponent from "../../../components/fnb-display-image/fnb-display-image.component";
import PageType from "../../../constants/page-type.constants";
import { backgroundTypeEnum } from "../../../constants/store-web-page.constants";
import "./checkout-order-items.scss";
export default function CheckoutOrderItems(props) {
  const {
    cartItem,
    currentIndex,
    onDeleteProduct,
    onUpdateCartQuantity,
    setCurrentCartItems,
    index,
    storageConfig,
    initDataShoppingCart,
    calculateShoppingCart,
    isCart,
    isPos,
    handleOnclickViewEditPos,
  } = props;
  const [isShowCombo, setIsShowCombo] = useState(false);
  const [visibleEditProduct, setVisibleEditProduct] = useState(false);
  const [styleBody, setStyleBody] = useState({});
  const [productSelectEdit, setProductSelectEdit] = useState(null);
  const [indexDefault, setIndexDefault] = useState(0);
  const currencySymbol = getCurrencySymbol();

  const isMobile = useMediaQuery({ maxWidth: 740 });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (!cartItem) return <></>;
  const isCombo = cartItem?.isCombo;
  /**
   * Total Topping price per unit
   */
  const toppingPrice = isCombo ? cartItem?.totalOfToppingPrice : cartItem?.productPrice?.totalOfToppingPrice;

  const toppingOriginalPrice = isCombo
    ? cartItem?.totalOfToppingPrice
    : cartItem?.productPrice?.totalOfToppingOriginalPrice;
  const originalPrice = isCombo ? cartItem?.originalPrice : cartItem?.productPrice?.originalPrice;
  const priceWithDiscount = isCombo ? cartItem?.sellingPrice : cartItem?.productPrice?.priceValue;
  const originalPriceIncludeTopping = originalPrice + toppingOriginalPrice;
  const priceWithDiscountIncludeTopping = isCombo ? cartItem?.sellingPrice : cartItem?.productPrice?.priceValue;

  const thumbnail = cartItem.thumbnail;
  const total = (priceWithDiscount ?? 0) * cartItem?.quantity;
  const handleClose = () => {
    initDataShoppingCart();
    setVisibleEditProduct(false);
    callApiValidateCartItems(false);
  };

  const callApiValidateCartItems = async (isCheckChangedData, cartItems, callBack) => {
    let isChangedProductPrice = true;
    if (!cartItems || cartItems?.length === 0) {
      const reduxState = store.getState();
      const session = reduxState?.session;
      cartItems = (!isPos ? session?.cartItems : session?.posCartItems) ?? [];
    }
    // Get data from redux to verify. Done then save to local storage and redux.
    const dataRequest = {
      cartItems: cartItems,
      isCheckChangedData: isCheckChangedData,
    };
    isChangedProductPrice = !isPos
      ? shoppingCartService.verifyAndUpdateCart(dataRequest)
      : posCartService.verifyAndUpdateCart(cartItems, isCheckChangedData);
    return isChangedProductPrice;
  };

  const onClickEditOrderItem = (item, index) => {
    setProductSelectEdit(item);
    setIndexDefault(index);
    if (isPos) {
      handleOnclickViewEditPos(item, index);
      return;
    }
    initBackgroundBody();
    setVisibleEditProduct(true);
  };

  //Config Edit order item === Config ProductDetail || ComboDetail
  const initBackgroundBody = () => {
    const { pages } = storageConfig;
    let configDetail = pages?.find((x) => x.id === PageType.PRODUCT_DETAIL)?.config?.productDetail;
    let style = {};
    if (configDetail?.backgroundType === backgroundTypeEnum.Color) {
      style = {
        backgroundColor: configDetail?.backgroundColor,
      };
    } else {
      style = {
        backgroundImage: `url(${configDetail?.backgroundImage})`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `center`,
        backgroundSize: `100% 100%`,
        backgroundAttachment: "initial",
        borderRadius: "8px",
      };
    }
    setStyleBody({ ...style, marginTop: "32px" });
  };
  //Config Edit order item

  const showCombo = () => {
    setIsShowCombo(!isShowCombo);
  };

  const onShowToastMessage = () => {
    dispatch(setToastMessageUpdateToCart(true));
    setTimeout(() => {
      dispatch(setToastMessageUpdateToCart(false));
    }, 3000);
  };

  const renderComboItem = () => {
    return (
      cartItem?.isCombo && (
        <div className="box-combo">
          {cartItem?.products?.map((product) => {
            const productOptionsNotDefault = product?.options?.filter((itemOption) => !itemOption?.isSetDefault);
            const productOptions = productOptionsNotDefault?.map((itemOption) => {
              return `${itemOption?.name} (${itemOption?.optionLevelName})`;
            });
            const renderOption = productOptions?.join(" , ");
            return (
              <>
                <div className="box-combo-item">
                  <div className="product-name text-line-clamp-1">{product.name}</div>
                  {renderOption !== "" && (
                    <div className="product-option text-line-clamp-1">{productOptions?.join(" , ")}</div>
                  )}
                  {product?.toppings?.reduce((total, topping) => {
                    return total + topping.quantity;
                  }, 0) > 0 && (
                    <div className="product-topping">
                      <>
                        {product?.toppings?.map((itemTopping) => {
                          return (
                            itemTopping?.quantity > 0 && (
                              <div className="topping-item">
                                <div className="name text-line-clamp-1">{itemTopping?.name}</div>
                                <div className="quantity">x {itemTopping?.quantity}</div>
                                <div className="price-value">
                                  {formatTextNumber(itemTopping?.priceValue)}
                                  {currencySymbol}
                                </div>
                              </div>
                            )
                          );
                        })}
                      </>
                    </div>
                  )}
                </div>
              </>
            );
          })}
        </div>
      )
    );
  };

  const renderCheckoutItemQuantity = () => {
    return (
      <>
        <MinusQuantityIcon
          className="quantity-icon"
          onClick={() => {
            if (cartItem?.quantity > 1) {
              isCart
                ? onUpdateCartQuantity(cartItem?.isCombo, cartItem?.quantity - 1, index, false)
                : onUpdateCartQuantity(cartItem?.id, -1, index);
            } else {
              onUpdateCartQuantity(cartItem?.isCombo, cartItem?.quantity - 1, index, false);
            }
          }}
        />
        <div className="quantity">{cartItem?.quantity}</div>
        <PlusQuantityIcon
          className="quantity-icon"
          onClick={() =>
            cartItem?.isOutOfStock === true
              ? null
              : isCart
              ? onUpdateCartQuantity(cartItem?.isCombo, cartItem?.quantity + 1, index, true)
              : onUpdateCartQuantity(cartItem?.id, 1, index)
          }
        />
      </>
    );
  };

  const renderItemOptions = () => {
    const productOptionsNotDefault = cartItem?.options?.filter((itemOption) => !itemOption?.isSetDefault);
    const options = productOptionsNotDefault?.map((itemOption) => {
      return `${itemOption?.name} (${itemOption?.optionLevelName})`;
    });
    return options?.join(" , ");
  };

  const renderItemToppings = () => {
    return (
      cartItem?.toppings?.reduce((total, topping) => {
        return total + topping.quantity;
      }, 0) > 0 && (
        <div className="toppings">
          {cartItem?.toppings?.map((itemTopping) => {
            return (
              itemTopping?.quantity > 0 && (
                <div className="topping-item">
                  <div className="name text-line-clamp-1">{itemTopping?.name}</div>
                  <div className="quantity">x {itemTopping?.quantity}</div>
                  <div className="price-value">
                    <div className="topping-price-col">
                      <span>
                        {formatTextNumber(itemTopping?.priceValue)}
                        {currencySymbol}
                      </span>
                      {itemTopping?.priceValue < itemTopping?.originalPrice && (
                        <span className="topping-original-price">
                          {formatTextNumber(itemTopping?.originalPrice)}
                          {currencySymbol}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
            );
          })}
        </div>
      )
    );
  };
  const renderItemPrice = () => {
    return (
      <div className="order-item-price">
        <div className="price-after-discount">{formatTextCurrency(priceWithDiscountIncludeTopping)}</div>
        {!isCombo && !cartItem?.isFlashSale && originalPrice !== 0 && originalPrice > priceWithDiscount && (
          <div className="price">
            {formatTextNumber(originalPriceIncludeTopping)}
            {currencySymbol}
          </div>
        )}
      </div>
    );
  };

  const renderFlashSaleTag = () => {
    if (cartItem?.isFlashSale) {
      return (
        <div className="flashsale-tag">
          {calculatePercentageFlashSale(
            cartItem?.productPrice?.priceValue - cartItem?.productPrice?.totalOfToppingPrice,
            cartItem?.productPrice?.originalPrice,
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isMobile ? (
        <>
          <div className={`checkout-order-item-theme-2 ${cartItem?.isOutOfStock === true && "out-of-stock-opacity"}`}>
            <div className="order-item-image" onClick={() => onClickEditOrderItem(cartItem, index)}>
              <FnbDisplayImageComponent
                src={thumbnail}
                srcFallback={productImageDefault}
                isFlashSale={Boolean(cartItem?.productPrice?.flashSaleId)}
                isOutOfStock={cartItem?.isOutOfStock}
                nameComponent={"is-cart-item"}
              />
            </div>
            <div className="order-item">
              <div className="order-item-info">
                <div className="name text-line-clamp-2" onClick={() => onClickEditOrderItem(cartItem, index)}>
                  {cartItem?.name || cartItem?.dataDetails?.comboPricingName}{" "}
                  {cartItem?.productPrice?.priceName && `(${cartItem?.productPrice?.priceName})`}
                </div>
                <CheckoutDeleteIcon className="delete-icon" onClick={() => onDeleteProduct(cartItem.id, index)} />
              </div>
              {renderFlashSaleTag()}
              {renderItemPrice()}
              <div className="options">{renderItemOptions()}</div>
              {renderItemToppings()}
              {renderComboItem()}
              <div className="order-item-quantity-total">
                <div className="order-item-quantity">{renderCheckoutItemQuantity()}</div>
                <div className="order-item-total">
                  <div className="total-amount">{formatTextCurrency(total)}</div>
                </div>
              </div>
              {cartItem?.notes && (
                <div className="cartMessageCheckout">
                  <img className="messageIconCheckout" src={cartNote} alt="cart note" />
                  <div className="messageNoteCheckout">{cartItem?.notes}</div>
                </div>
              )}
            </div>
          </div>
          <Drawer
            className="drawer-product-cart-detail"
            placement="bottom"
            open={visibleEditProduct}
            onClose={handleClose}
            destroyOnClose={true}
            closable={false}
            style={styleBody}
            height={"85%"}
          >
            <EditOrderItem
              dataEdit={productSelectEdit}
              indexDefault={indexDefault}
              onCancel={handleClose}
              stateConfig={storageConfig}
              calculateShoppingCart={calculateShoppingCart}
              platformId={Platform.StoreWebsite}
            />
          </Drawer>
        </>
      ) : (
        <>
          <div className={`checkout-order-item-theme-2 ${cartItem?.isOutOfStock === true && "out-of-stock-opacity"}`}>
            <div className="order-item">
              <div className="order-item-img">
                <div className="order-item-image" onClick={() => onClickEditOrderItem(cartItem, index)}>
                  <FnbDisplayImageComponent
                    src={thumbnail}
                    srcFallback={productImageDefault}
                    isFlashSale={!isPos ? Boolean(cartItem?.isFlashSale) : false}
                    isOutOfStock={cartItem?.isOutOfStock}
                    nameComponent={"is-cart-item"}
                  />
                </div>
              </div>
              <div className="order-item-detail">
                <div className="order-item-infomation">
                  <div className="order-item-info">
                    <div className="name-info">
                      <div className="name text-line-clamp-2" onClick={() => onClickEditOrderItem(cartItem, index)}>
                        {cartItem?.name || cartItem?.dataDetails?.comboPricingName}{" "}
                        {cartItem?.productPrice?.priceName && `(${cartItem?.productPrice?.priceName})`}
                      </div>
                      {renderFlashSaleTag()}
                      <div className="options">{renderItemOptions()}</div>
                    </div>
                  </div>
                  {!isPos && renderItemPrice()}
                  <div className="order-item-quantity">{renderCheckoutItemQuantity()}</div>
                  <div className="order-item-total">
                    <div className="total-amount">{formatTextCurrency(total)}</div>
                    <CheckoutDeleteIcon className="delete-icon" onClick={() => onDeleteProduct(cartItem.id, index)} />
                  </div>
                </div>
                <div>
                  {renderItemToppings()}
                  {renderComboItem()}
                  {cartItem?.notes && (
                    <div className="cartMessageCheckout">
                      <img className="messageIconCheckout" src={cartNote} alt="cart note" />
                      <div className="messageNoteCheckout">{cartItem?.notes}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Modal
            className="modal-product-cart-detail"
            open={visibleEditProduct}
            onOk={handleClose}
            onCancel={handleClose}
            footer={(null, null)}
            centered
            destroyOnClose={true}
            width={"80%"}
            style={styleBody}
          >
            <EditOrderItem
              dataEdit={productSelectEdit}
              indexDefault={indexDefault}
              onCancel={handleClose}
              stateConfig={storageConfig}
              calculateShoppingCart={calculateShoppingCart}
              platformId={Platform.StoreWebsite}
            />
          </Modal>
        </>
      )}
    </>
  );
}
