import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Input, Pagination, Popover, Row, Table } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import {
  AddCircleTableIcon,
  ArrowDownIcon,
  CalendarNewIcon,
  DeleteOutlined,
  EditOutlined,
  ExportOutlinedIcon,
  FilterIcon,
  FilterSelected,
  FolderIcon,
  SearchIcon,
  SortOrderOutlined,
  SortUpIcon,
} from "constants/icons.constants";
import _ from "lodash";
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { hasPermission } from "utils/helpers";
import "./fnb-table.scss";
import $ from "jquery";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { CloseIcon } from "themes/theme-2-new/assets/icons.constants";
import theme from "theme";

const DEFAULT_ROW_KEY = "selectionRowKey";

export function FnbTable(props) {
  const [t] = useTranslation();
  const {
    classNameComponent,
    columns, // define columns
    dataSource, // define dataSource
    currentPageNumber, // number of current page
    pageSize, // number of record per page
    total, // total number of record
    onChangePage,
    hideTableRowSelection,
    rowSelection,
    description,
    className,
    editPermission,
    deletePermission,
    tableId,
    bordered,
    search,
    filter,
    filterPlacement = "bottomRight",
    filterComponent,
    sort,
    scrollX,
    scrollY,
    scroll,
    summary,
    footerMessage,
    calendarFilter,
    loading,
    calendarComponent,
    onScroll,
    expandable,
    rowKey,
    emptyText,
    autoFocus,
    exportExcel,
    onRowClick,
    isRenderTableController = true,
    isCompactButtons = true, // compact button to arrow popover in mobile mode
    listButtons = [],
    components,
    onScrollTable,
    setHeaderCollapse,
    isSetHeaderCollapse = false,
    showHeader = true,
    showDetailDataFound = false,
    itemDataFound,
    redirectUrl,
    isRenderExtendHeaderInformation = false,
    headerExtendInformation,
    contenRedirect = t("material.addNewItemIngredient", {
      item: `${itemDataFound ?? ""}`,
    }),
    customEmptyData,
  } = props;
  const isMax768 = useMediaQuery({ maxWidth: 768 });
  const defaultScrollX = 900;
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dataOriginal, setDataOriginal] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    keySort: "",
    sortOrder: "",
  });
  const [openPopOver, setOpenPopOver] = useState(false);
  const [valueSearch, setValueSearch] = useState(null);
  const [enableClick, setEnableClick] = useState(false);
  const timeOutClick = useRef(null);

  const pageData = {
    titleFilter: t("table.titleFilter"),
    noDataFound: t("table.noDataFound"),
    filterButtonTitle: t("button.filter"),
    selectedItems: t("messages.selectedItems"),
    exportButton: t("button.export"),
    selectRowsActions: t("table.selectRowsActions"),
    selectedRows: t("table.selectedRows"),
    allSelected: t("table.allSelected"),
    buttonResetFilter: t("button.resetData"),
    filtersApplied: t("report.staff.filtersApplied"),
    editButton: t("button.edit"),
    deleteButton: t("button.delete"),
    addNewIngredient: t("material.addNewIngredient"),
  };

  let moved = false;

  const downListener = () => {
    moved = false;
  };

  const moveListener = () => {
    moved = true;
  };

  const upListener = () => {
    if (moved) {
      setEnableClick(false);
    } else {
      setEnableClick(true);
    }
  };

  useEffect(() => {
    if (!onScrollTable || Object.keys(onScrollTable)?.length === 0) return;
    const elements = document.querySelectorAll(`.${onScrollTable.classNameTable} .ant-table-container .ant-table-body`);

    if (onScrollTable) {
      if (elements?.length) elements?.[0]?.addEventListener("scroll", onScrollTable.onScroll);
    }

    return () => elements?.[0]?.removeEventListener("scroll", onScrollTable.onScroll);
  }, [onScrollTable]);

  useEffect(() => {
    const tableElements = document.querySelectorAll(".fnb-table");
    const hasRowConfig = onRowClick && tableElements?.length > 0;
    if (hasRowConfig) {
      tableElements?.forEach((element) => {
        element.addEventListener("mousedown", downListener);
        element.addEventListener("mousemove", moveListener);
        element.addEventListener("mouseup", upListener);
      });
    }
    return () => {
      setSelectedRows([]);
      setDataOriginal([]);
      setDataTable([]);
      setEnableClick(false);
      if (hasRowConfig) {
        tableElements?.forEach((element) => {
          element.removeEventListener("mousedown", downListener);
          element.removeEventListener("mousemove", moveListener);
          element.removeEventListener("mouseup", upListener);
        });
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (_.isArray(dataSource)) {
      const dataRecord = dataSource?.map((record, index) => ({
        ...record,
        [rowKey || DEFAULT_ROW_KEY]: rowKey ? record[rowKey] : getRowIndex(index),
      }));
      setDataOriginal(dataRecord || []);
      setDataTable(dataRecord || []);
      calculatorWidthSearch();
    }
  }, [dataSource]);

  // register grabbing scroll table
  useEffect(() => {
    const elements = document.querySelectorAll(".ant-table-content, .ant-table-body");
    if (elements?.length > 0) {
      let pos = { top: 0, left: 0, x: 0, y: 0 };
      elements?.forEach((item) => {
        const mouseMoveHandler = function (e) {
          // How far the mouse has been moved
          const dx = e.clientX - pos.x;
          const dy = e.clientY - pos.y;

          // Scroll the element
          item.scrollTop = pos.top - dy;
          item.scrollLeft = pos.left - dx;
        };

        const mouseUpHandler = function (e) {
          item.removeEventListener("mousemove", mouseMoveHandler);
          item.removeEventListener("mouseup", mouseUpHandler);
          item.style.removeProperty("user-select");
          item.style.removeProperty("cursor");
        };

        const mouseDownHandler = function (e) {
          pos = {
            // The current scroll
            left: item.scrollLeft,
            top: item.scrollTop,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
          };

          item.style.userSelect = "none";

          if (item.scrollWidth > item.clientWidth) {
            item.style.cursor = "grab";
          }
          item.addEventListener("mousemove", mouseMoveHandler);
          item.addEventListener("mouseup", mouseUpHandler);
        };
        item.removeEventListener("mousedown", mouseDownHandler);
        item.addEventListener("mousedown", mouseDownHandler);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    const elements = document.querySelectorAll(".ant-table-container .ant-table-body");

    if (onScroll && elements?.length > 0) {
      elements?.forEach((item) => {
        item.addEventListener("scroll", onScroll);
      });
    }

    return () => {
      if (elements?.length > 0) {
        elements?.forEach((item) => {
          item.removeEventListener("scroll", onScroll);
        });
      }
    };
  }, [onScroll]);

  useEffect(() => {
    if (rowSelection?.selectedRowKeys) {
      setSelectedRows((state) =>
        state?.filter((selectedRow) => rowSelection?.selectedRowKeys?.includes(selectedRow[rowKey || DEFAULT_ROW_KEY])),
      );
    }
  }, [rowSelection?.selectedRowKeys]);

  const getSortActiveClass = (sortOrder) => {
    switch (sortOrder) {
      case "ascend":
        return "sort-order sort-ascend";
      case "descend":
        return "sort-order sort-descend";
      default:
        return "sort-order";
    }
  };

  const handleCustomizeColumn = (columns) => {
    const { keySort, sortOrder } = sortConfig;

    return columns?.map((column) => {
      if (column?.sorter) {
        const { title, sorter, ...rest } = column;
        const newSortOrder = keySort === column?.dataIndex ? sortOrder : "";
        return {
          ...rest,
          title: () => (
            <div className="table-column-sorter">
              <span className="table-column-title">{title}</span>
              <span className="table-column-sorter-full">
                <SortOrderOutlined className={getSortActiveClass(newSortOrder)} />
              </span>
            </div>
          ),
          onHeaderCell: (column) => ({
            onClick: () => handleSortOrder(column?.dataIndex, column?.sort),
          }),
          sort: sorter,
          sortOrder: newSortOrder,
        };
      } else if (column?.tooltip) {
        const { title, contentTooltip, ...rest } = column;
        return {
          ...rest,
          title: () => (
            <div style={{ display: "flex", justifyContent: "start" }}>
              <span className="table-column-title">{title}</span>
              <span style={{ paddingLeft: 4 }}>
                <FnbTooltip title={contentTooltip} placement="top" overlayClassName="hover-table" maxWidth={350}>
                  <InfoCircleOutlined
                    style={{
                      color: "#50429B",
                      width: 16,
                      height: 16,
                    }}
                  />
                </FnbTooltip>
              </span>
            </div>
          ),
        };
      } else {
        const { title, contentTooltip, ...rest } = column;
        return {
          ...rest,
          title: () => (
            <FnbTooltip title={title} onlyShowWhenEllipsis={true}>
              <span className="table-column-title">{title}</span>
            </FnbTooltip>
          ),
        };
      }
    });
  };

  const handleSortOrder = async (dataIndex, sort) => {
    const { keySort, sortOrder } = sortConfig;
    if (keySort === dataIndex) {
      if (sortOrder === "ascend") {
        setSortConfig({
          keySort: dataIndex,
          sortOrder: "descend",
        });
        setDataTable(dataTable?.slice()?.sort(sort)?.reverse());
      }
      if (sortOrder === "descend") {
        setSortConfig({
          keySort: "",
          sortOrder: "",
        });
        setDataTable(dataOriginal);
      }
    } else {
      setSortConfig({
        keySort: dataIndex,
        sortOrder: "ascend",
      });
      setDataTable(dataTable?.slice()?.sort(sort));
    }
  };

  const getTableColumns = () => {
    // If not define permission to edit or delete, return default column
    if (!editPermission || !deletePermission) {
      return handleCustomizeColumn(columns);
    }

    // If user has permission to edit or delete, return default column
    if (hasPermission(editPermission) || hasPermission(deletePermission)) {
      return handleCustomizeColumn(columns);
    } else {
      // If user has no permission to edit or delete, then remove action column
      return handleCustomizeColumn(columns.filter((c) => c.key !== "action"));
    }
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const getRowIndex = (index) => {
    return _.isNumber(currentPageNumber) && _.isNumber(pageSize) ? (currentPageNumber - 1) * pageSize + index : index;
  };

  const onSelectedRowKeys = (selectedRowKeys, selectedRows, info) => {
    setSelectedRows(selectedRows);
    rowSelection?.onChange && rowSelection?.onChange(selectedRowKeys, selectedRows, info);
  };

  const onEditRowKeys = () => {
    rowSelection?.onEdit &&
      rowSelection?.onEdit(
        selectedRows?.map((selectedRow) => selectedRow[rowKey || DEFAULT_ROW_KEY]),
        selectedRows,
      );
  };

  const onDeletedRowKeys = () => {
    rowSelection?.onDelete &&
      rowSelection?.onDelete(
        selectedRows?.map((selectedRow) => selectedRow[rowKey || DEFAULT_ROW_KEY]),
        selectedRows,
      );
  };

  const onClickDebounce = (record, rowIndex) => {
    if (timeOutClick.current) {
      clearTimeout(timeOutClick.current);
    }
    timeOutClick.current = setTimeout(() => {
      onRowClick && onRowClick(record, rowIndex);
    }, 300);
  };

  const handleChangePage = (page, pageSize) => {
    setSelectedRows([]);
    rowSelection?.onChange && rowSelection?.onChange([], [], { type: "single" });
    onChangePage && onChangePage(page, pageSize);
  };

  const renderPagination = () => {
    const hasPagination = total > pageSize;
    const currentView = dataSource?.length;

    if (hasPagination) {
      let showingMessage = t("table.showingRecordMessage", {
        showingRecords: currentView,
        totalRecords: total,
      });
      if (footerMessage) {
        showingMessage = t(footerMessage, {
          showingRecords: currentView,
          totalRecords: total,
        });
      }
      return (
        <div className="fnb-tbl-pagination">
          <div className="info-pagination">
            <div className="table-text-footer" dangerouslySetInnerHTML={{ __html: showingMessage }}></div>
          </div>
          <div className="fnb-pagination">
            <Pagination
              current={currentPageNumber ?? 1}
              total={total}
              defaultPageSize={pageSize}
              onChange={handleChangePage}
            />
          </div>
        </div>
      );
    }
  };

  const renderSelectRows = () => {
    if (rowSelection && !hideTableRowSelection) {
      const { onEdit, onDelete, renderActions } = rowSelection;
      // Some actions need to be checked for display such as Stop/Approved/Reject should use renderActions
      // There are only 2 default methods for table components: Edit and delete
      if (!onEdit && !onDelete && !renderActions) return;
      const enableEdit = onEdit && (!editPermission || hasPermission(editPermission));
      const enableDelete = onDelete && (!deletePermission || hasPermission(deletePermission));
      let selectedRowsContent = pageData.selectRowsActions;
      const selectedRowsTotal = selectedRows?.length || 0;
      const multipleSelectedRows = selectedRowsTotal > 1;
      const hasSelectedRows = selectedRowsTotal > 0;
      const selectedAllRows = selectedRowsTotal === pageSize;

      if (hasSelectedRows) {
        selectedRowsContent = t(pageData.selectedRows, {
          selectedRows: selectedRowsTotal,
        });
      }
      if (selectedAllRows) {
        selectedRowsContent = pageData.allSelected;
      }

      return (
        <div className="selected-rows-container">
          <div className="selected-rows-description">{selectedRowsContent}</div>
          {hasSelectedRows && (
            <div className="selected-rows-action">
              {!multipleSelectedRows && enableEdit && (
                <FnbTooltip placement="top" title={pageData.editButton} zIndex={100}>
                  <FnbButton
                    variant="text"
                    className="row-edit-button"
                    iconHeader={<EditOutlined />}
                    onClick={onEditRowKeys}
                    disabled={multipleSelectedRows}
                  />
                </FnbTooltip>
              )}
              {enableDelete && (
                <FnbTooltip placement="top" title={pageData.deleteButton} zIndex={100}>
                  <FnbButton
                    variant="text"
                    className="row-delete-button"
                    iconHeader={<DeleteOutlined />}
                    onClick={onDeletedRowKeys}
                    disabled={multipleSelectedRows}
                  />
                </FnbTooltip>
              )}
              {renderActions &&
                renderActions(
                  selectedRows?.map((selectedRow) => selectedRow[rowKey || DEFAULT_ROW_KEY]),
                  selectedRows,
                )}
            </div>
          )}
        </div>
      );
    }

    return <></>;
  };

  const renderDescription = () => {
    if (description) {
      return description;
    }

    return <></>;
  };

  const renderSearch = () => {
    if (search) {
      const { placeholder, onChange, maxLength, isSearchWithEnterKey } = search;
      return (
        <div className="search-bar-wrapper" id="search-bar-input">
          <div className="search-bar" id="search-bar-input-content">
            <Input
              autoFocus={autoFocus}
              maxLength={maxLength ?? 100}
              onChange={(e) => {
                setValueSearch(e.target.value);
                onChange(encodeURIComponent(e.target.value));
              }}
              className={`fnb-search-input ${valueSearch ? "ant-input-affix-wrapper-focused" : ""}`}
              allowClear
              size="large"
              placeholder={placeholder}
              prefix={<SearchIcon width={24} height={24} />}
              onKeyDown={(event) => {
                if (isSearchWithEnterKey && event.key === "Enter") {
                  onChange(encodeURIComponent(event.target.value));
                }
              }}
            />
          </div>
        </div>
      );
    }

    return <></>;
  };

  const calculatorWidthSearch = () => {
    let searchPosition = document.getElementById("search-bar-input")?.getBoundingClientRect();
    let actionGroupPosition = document.getElementById("table-actions-group-row")?.getBoundingClientRect();
    if (searchPosition && actionGroupPosition) {
      let searchInputWidth = searchPosition.right - actionGroupPosition.left - 16;
      let searchInput = document.querySelectorAll("#search-bar-input-content span")[0];
      if (searchInput) {
        searchInput.style.maxWidth = `${searchInputWidth}px`;
      }
    }
  };

  const renderSortButton = () => {
    if (sort) {
      const { buttonTitle, onClick } = sort;
      return (
        <Button className="action-button" type="primary" icon={<SortUpIcon />} onClick={onClick}>
          <span className="button-title">{buttonTitle}</span>
        </Button>
      );
    }

    return <></>;
  };

  const renderCalendarButton = () => {
    if (calendarFilter) {
      const { buttonTitle, onClick } = calendarFilter;
      return (
        <Button
          className="action-button action-button-calendar"
          type="primary"
          icon={<CalendarNewIcon />}
          onClick={onClick}
        >
          <span className="button-title-calendar">{buttonTitle}</span>
        </Button>
      );
    }

    return <></>;
  };

  const renderFilterButton = () => {
    if (filterComponent) {
      return <>{filterComponent}</>;
    }
    if (filter) {
      const { component, onClearFilter, totalFilterSelected, onClickFilterButton, filterClassName, isShowTitle } =
        filter;
      const numberTotalFilterSelected = parseInt(totalFilterSelected) || 0;
      return (
        <Popover
          id="table-filter-popover"
          placement={filterPlacement}
          content={
            <>
              <div className="content-filter" id="table-filter-popover-content">
                {component}
              </div>
              <div className="footer-filter" id="table-filter-popover-footer">
                {totalFilterSelected > 0 && (
                  <span>{t(pageData.filtersApplied, { filter_count: totalFilterSelected })}</span>
                )}
                <FnbButton
                  id="table-filter-popover-reset-filter"
                  onClick={onClearFilter}
                  disabled={numberTotalFilterSelected <= 0}
                  variant="tertiary"
                  text={pageData.buttonResetFilter}
                />
              </div>
            </>
          }
          title={
            isShowTitle && (
              <div className="filter-title">
                <FnbTypography variant="b1-bold" text={pageData.titleFilter} color={theme.colors.primary[100]} />
                <CloseIcon width={13} height={13} onClick={onCloseFilter} />
              </div>
            )
          }
          trigger="click"
          open={visible}
          onOpenChange={handleVisibleChange}
          getPopupContainer={(trigger) => trigger.parentElement}
          overlayClassName={`filter-component custom-form ${filterClassName ?? ""}`}
          onClick={onClickFilterButton}
        >
          <Badge className="badge-counter" size="small" count={numberTotalFilterSelected} color="#FF8C24">
            {visible ? (
              <FilterSelected className={numberTotalFilterSelected > 0 ? "filter-count" : "filter-empty"} />
            ) : (
              <FilterIcon className={numberTotalFilterSelected > 0 ? "filter-count" : "filter-empty"} />
            )}
          </Badge>
        </Popover>
      );
    }
    return <></>;
  };

  const onCloseFilter = () => {
    setVisible(false);
  };

  const renderTableControlAction = () => {
    const isDisplayActionsGroup =
      rowSelection ||
      description ||
      listButtons?.length > 0 ||
      exportExcel ||
      sort ||
      calendarFilter ||
      calendarComponent ||
      filterComponent ||
      filter ||
      search;

    return (
      <>
        {isDisplayActionsGroup && (
          <div className="table-actions-group-row" id="table-actions-group-row">
            {rowSelection && (
              <Fragment>
                <div className="selection-actions-group">
                  {renderSelectRows()}
                  {renderDescription()}
                </div>
              </Fragment>
            )}
            <div className="action-group">
              {renderCustomButtons()}
              {renderExportExcel()}
              {renderSortButton()}
              {renderCalendarButton()}
              {renderCalendarFilterButton()}
              {renderFilterButton()}
              {renderSearch()}
            </div>
          </div>
        )}
      </>
    );
  };

  const getHeaderCollapse = () => {
    const isDisplayActionsGroup =
      rowSelection ||
      description ||
      listButtons?.length > 0 ||
      exportExcel ||
      sort ||
      calendarFilter ||
      calendarComponent ||
      filterComponent ||
      filter ||
      search;

    setHeaderCollapse(
      <>
        {isDisplayActionsGroup && (
          <div className="table-actions-group-row">
            <div className="selection-actions-group">
              {renderSelectRows()}
              {renderDescription()}
            </div>
            <div className="action-group">
              {renderCustomButtons()}
              {renderExportExcel()}
              {renderSortButton()}
              {renderCalendarButton()}
              {renderCalendarFilterButton()}
              {renderFilterButton()}
              {renderSearch()}
            </div>
          </div>
        )}
      </>,
    );
  };

  const renderCalendarFilterButton = () => {
    if (calendarComponent) {
      const { selectedDate, orderTypeFilterTime, onSelectedDatePicker, onConditionCompare, containAllTime } =
        calendarComponent;
      return (
        <div className="action-button-calendar-component">
          <FnbDatePicker
            selectedDate={selectedDate}
            orderTypeFilterTime={orderTypeFilterTime}
            setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
            setConditionCompare={onConditionCompare}
            containAllTime={containAllTime}
          />
        </div>
      );
    }

    return <></>;
  };

  const renderExportExcel = () => {
    if (exportExcel) {
      const { onClick, icon } = exportExcel;
      return (
        <FnbButton
          variant="secondary"
          text={pageData.exportButton}
          onClick={onClick}
          iconHeader={icon || <ExportOutlinedIcon />}
        ></FnbButton>
      );
    }

    return <></>;
  };

  const renderCustomButtons = () => {
    if (isMax768 && isCompactButtons && listButtons?.length > 0) {
      return (
        <Popover
          content={
            <>
              {listButtons.map((button) => {
                return React.cloneElement(button, {
                  variant: "tertiary",
                });
              })}
            </>
          }
          trigger="click"
          placement="bottomRight"
          open={openPopOver}
          onOpenChange={setOpenPopOver}
          overlayClassName="fnb-heading-page__popover-overlay"
        >
          <FnbButton variant="tertiary" text={<ArrowDownIcon />} />
        </Popover>
      );
    }
    return [...listButtons];
  };

  const renderExtendHeaderInformation = () => {
    return <div className="header-extend-information">{headerExtendInformation}</div>;
  };

  const renderAddNewData = () => {
    return (
      <div className="text-add-new-data" onClick={() => redirectUrl()}>
        <AddCircleTableIcon />
        <span>{contenRedirect}</span>
      </div>
    );
  };

  return (
    <div className={`fnb-table-wrapper hide-pagination-options ${classNameComponent}`}>
      {isRenderTableController && renderTableControlAction()}
      {isSetHeaderCollapse && getHeaderCollapse()}
      {isRenderExtendHeaderInformation && renderExtendHeaderInformation()}
      <Row className="fnb-table-wrapper__row">
        <Col span={24}>
          <Table
            showSorterTooltip={false}
            loading={{
              spinning: loading || loading === true,
              indicator: <LoadingOutlined />,
            }}
            locale={{
              emptyText: customEmptyData ? customEmptyData : (
                <div className="no-data-found">
                  <p className="text-center" style={{ marginBottom: "12px", marginTop: "105px" }}>
                    <FolderIcon />
                  </p>
                  <p className="text-center body-2 text-no-data" style={{ marginBottom: "181px" }}>
                    <div className="empty-text-table">
                      <p className="text-center body-2 mb-2">{pageData.noDataFound}</p>
                      {emptyText && emptyText}
                    </div>
                    {showDetailDataFound && renderAddNewData()}
                  </p>
                </div>
              ),
            }}
            scroll={scroll ? scroll : { x: scrollX ?? defaultScrollX, y: scrollY }}
            className={`fnb-table form-table ${className}`}
            columns={getTableColumns()}
            dataSource={dataTable?.map((record, index) => ({
              ...record,
              index: getRowIndex(index),
            }))}
            rowSelection={
              rowSelection && {
                type: "checkbox",
                columnWidth: 56,
                ...rowSelection,
                onChange: onSelectedRowKeys,
              }
            }
            pagination={false}
            bordered={bordered}
            id={tableId}
            expandable={expandable}
            rowKey={rowKey ?? DEFAULT_ROW_KEY}
            summary={summary}
            onRow={(record, index) => {
              return {
                style: onRowClick && { cursor: "pointer" },
                onClick: () => enableClick && onClickDebounce(record, index),
              };
            }}
            components={components}
            showHeader={showHeader}
            rowClassName={(record) => Boolean(record.disabled) && "disabled-row"}
          />
          {renderPagination()}
        </Col>
      </Row>
    </div>
  );
}
