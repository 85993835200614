import { Col, Form, Row } from "antd";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbFormItem } from "components/fnb-form-item/fnb-form-item.component";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbSelect } from "components/fnb-select/fnb-select";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip, { FnbTooltipVariant } from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { EnumInputValidateType, InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { MaximumNumber, MinimumNumber } from "constants/default.constants";
import { CustomTrashIcon, ExclamationIcon, ImageMaterialDefault, MessageQuestionIcon } from "constants/icons.constants";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import theme from "theme";
import { formatNumberDecimalOrInteger, getCurrency, isDecimalKey, parserDecimalNumber, roundNumber } from "utils/helpers";
import "./image-other-setting.page.scss";
import "./ingredient-recipe.page.scss";
import { useParams } from "react-router-dom";
import { IngredientSearchModal, IngredientSearchModalType } from "components/ingredient-search-modal/ingredient-search-modal";

export const IngredientRecipe = (props) => {
  const {
    t,
    form,
    isCreatePage = true,
    units,
    listSelectedMaterials,
    setListSelectedMaterials,
    baseUnit,
    preProcessUnitId = '',
    preProcessUnitName = '',
  } = props;
  const param = useParams();
  const currency = getCurrency();
  const pageData = {
    unitForPreProcessing: t("material.unitForPreProcessing"),
    unitForPreProcessingToolTip: t("material.unitForPreProcessingToolTip"),
    placeholderUOM: t("material.placeholderUOM"),
    unit: {
      recipe: t("productManagement.unit.recipe"),
    },
    hint: {
      howToSetUpRecipes: t("productManagement.hint.howToSetUpRecipes"),
    },
    searchMaterial: t("material.searchMaterialPlaceholder"),
    selectPreProcessUnitValidation: t("material.selectPreProcessUnitValidation"),
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      consumableIngredients: t("material.consumableIngredients"),
      quantityUnit: t("material.quantityUnit"),
      cost: t("material.unitCost"),
      totalCost: t("material.totalCost"),
      pleaseEnterQuantityUsed: t("material.pleaseEnterQuantityUsed"),
      valueMustBeGreaterThanZero: t("material.valueMustBeGreaterThanZero"),
    },
    delete: t("button.delete"),
    pleaseSetupRecipe: t("inventoryTracking.pleaseSetupRecipe"),
  };
  const basePreProcessUnitId = useRef(preProcessUnitId);
  const isMaxWidth1600 = useMediaQuery({ maxWidth: 1600 });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const unitConversionData = Form.useWatch('listDataUnitConversion', form);

  const unitConversionOptions = useMemo(() => {
    if (!baseUnit || baseUnit?.id?.length <= 0) {
      return [];
    }

    let listUnitOptions = [{...baseUnit, capacity: 1}];
    if (!unitConversionData || unitConversionData?.length <= 0) {
      return listUnitOptions;
    }

    const filteredUnits = units?.filter((u) =>
      unitConversionData?.some((uC) => uC?.unitId === u?.id)
    );
    filteredUnits?.forEach((item) => {
      const unitConversion = unitConversionData?.find((uC) => uC.unitId === item?.id);
      listUnitOptions.push({
        name: item?.name,
        id: item?.id,
        capacity: unitConversion?.capacity,
      });
    });

    return listUnitOptions;
  }, [baseUnit?.id, units, unitConversionData]);


  useEffect(() => {
    if (isCreatePage) {
      const formPreProcessUnitId = form?.getFieldValue('preProcessUnitId');
      const isExist = unitConversionOptions?.some((u) => u?.id === formPreProcessUnitId);
      if(unitConversionOptions?.length > 0 && !isExist){
        form?.setFieldValue('preProcessUnitId', undefined);
      }
    }
  }, [unitConversionOptions, isCreatePage])

  useEffect(() => {
    if (listSelectedMaterials?.length > 0) {
      form?.setFields([
        {
          name: ["selectMaterial"],
          errors: [],
        },
      ]);
    }
  }, [listSelectedMaterials]);

  const renderToolTipPreProcessing = () => {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: pageData.unitForPreProcessingToolTip,
        }}
      ></span>
    );
  };

  const handleChangeQuantity = (value, index) => {
    listSelectedMaterials[index].quantity = value ?? "";
    setListSelectedMaterials([...listSelectedMaterials]);
  };

  const handleChangeUnit = (unitId, material, index) => {
    if (unitId !== material?.unitId) {
      const selectedUnitConversion = material?.unitConversion?.find((u) => u?.unitId === unitId);
      if (selectedUnitConversion) {
        listSelectedMaterials[index].unitName = selectedUnitConversion?.unitName;
        listSelectedMaterials[index].unitId = unitId;
        listSelectedMaterials[index].newCostPerUnit = material?.costPerUnit * selectedUnitConversion?.capacity;
      } else {
        listSelectedMaterials[index].unitName = material?.unit?.name;
        listSelectedMaterials[index].unitId = material?.unit?.id;
        listSelectedMaterials[index].newCostPerUnit = material?.costPerUnit;
      }
      setListSelectedMaterials([...listSelectedMaterials]);
    }
  };

  const onRemoveMaterial = (materialId, index) => {
    let newListSelected = listSelectedMaterials?.filter((m) => m?.id !== materialId);
    setListSelectedMaterials([...newListSelected]);

    form?.setFieldValue(["listSelectedMaterials"], newListSelected);
    if (newListSelected?.length === 0) {
      form?.setFields([
        {
          name: ["selectMaterial"],
          errors: [<InputValidateMessage type={EnumInputValidateType.ERROR} message={pageData.pleaseSetupRecipe} />],
        },
      ]);
    }
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.consumableIngredients,
        dataIndex: "consumableIngredients",
        width: "27%",
        align: "left",
        render: (_, record, index) => {
          return (
            <div className={"material-name-container"}>
              <div className={"material-name-left-column"}>
                {record?.thumbnail ? (
                  <Thumbnail width={52} height={52} src={record?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={52} height={52} />
                )}
                <div className={"material-name-sku"}>
                  <FnbTooltip onlyShowWhenEllipsis title={record?.name} width={350}>
                    <FnbTypography
                      variant={theme.typography["b1-regular"]}
                      text={record?.name}
                      color={"#2F2D39"}
                    />
                  </FnbTooltip>
                  {record?.sku && (
                    <FnbTooltip onlyShowWhenEllipsis title={record?.sku} width={350}>
                      <FnbTypography
                        text={`SKU: ${record?.sku}`}
                        style={{ fontWeight: 400, fontSize: 14 }}
                        color={theme.colors.gray[70]}
                      />
                    </FnbTooltip>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: pageData.table.quantityUnit,
        dataIndex: "quantityUnit",
        align: "left",
        width: "25%",
        render: (_, record, index) => {
          const mapUnitConversions = record?.unitConversion?.map((unit) => {
            return {
              id: unit?.unitId,
              name: unit?.unitName,
              capacity: unit?.capacity,
            };
          });
          const unitConversion = [
            { id: record?.unit?.id, name: record?.unit?.name, capacity: 1 },
            ...mapUnitConversions
          ];
          return (
            <Row id={`used-quantity-${index}`} className={"error-select-quantity"}>
              {isCreatePage ? (
                <>
                  <Col span={10} className="pr-1">
                    <Form.Item
                      name={["listSelectedMaterials", index, "quantity"]}
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (value === undefined || value === null || value === "") {
                              return Promise.reject(
                                <InputValidateMessage
                                  className={"used-quantity-input"}
                                  message={pageData.table.pleaseEnterQuantityUsed}
                                />
                              );
                            }
                            if (value <= 0) {
                              return Promise.reject(
                                <InputValidateMessage
                                  className={"used-quantity-input"}
                                  message={pageData.table.valueMustBeGreaterThanZero}
                                />
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <FnbInputNumber
                        min={MinimumNumber}
                        max={MaximumNumber}
                        value={record?.quantity}
                        defaultValue={MinimumNumber}
                        onChange={(value) => {
                          handleChangeQuantity(value, index);
                        }}
                        showWrap={true}
                        formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value) => parserDecimalNumber(value, true)}
                        onKeyPress={(event) => {
                          if (!isDecimalKey(event)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={14} className="pl-1">
                    <Form.Item name={["listSelectedMaterials", index, "unitId"]}>
                      <FnbSelectSingle
                        getPopupContainer={() => document.getElementById(`used-quantity-${index}`)?.parentNode?.parentNode?.parentNode}
                        defaultValue={record?.unitId}
                        value={record?.unitId}
                        option={unitConversion}
                        onChange={(unitId) => {
                          handleChangeUnit(unitId, record, index);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <Col>{formatNumberDecimalOrInteger(record?.quantity)} ({record?.unitName})</Col>
              )}
            </Row>
          );
        },
      },
      {
        title: pageData.table.cost,
        dataIndex: "cost",
        align: "left",
        width: "18%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col>{formatNumberDecimalOrInteger(record?.newCostPerUnit ?? record?.costPerUnit)} ({currency}/{record?.unitName})</Col>
            </Row>
          );
        },
      },
      {
        title: pageData.table.totalCost,
        dataIndex: "totalCost",
        align: "left",
        width: "18%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col>{formatNumberDecimalOrInteger((record?.newCostPerUnit ?? record?.costPerUnit) * record?.quantity)} ({currency})</Col>
            </Row>
          );
        },
      },
    ];

    if (isCreatePage === true) {
      columns.push({
        title: "",
        dataIndex: "action",
        align: "center",
        width: "5%",
        render: (_, record, index) => (
          <div
            className="icon-delete-price pt-3"
            onClick={() => {
              onRemoveMaterial(record?.id, index);
            }}
          >
            <FnbTooltip className="delete-material-icon" title={pageData.delete}>
              <CustomTrashIcon color={theme.colors.primary[100]} />
            </FnbTooltip>
          </div>
        ),
      });
    }

    return columns;
  };

  const handleChangePreProcessUnitId = (unitId) => {
    if (!basePreProcessUnitId || !basePreProcessUnitId?.current) {
      basePreProcessUnitId.current = unitId;
      return;
    }
    const listDataUnitConversion = form?.getFieldValue("listDataUnitConversion");
    const dictDataUnitConversion = listDataUnitConversion.reduce((r, e) => {
      r[e?.unitId] = e;
      return r;
    }, {});

    const newUnitConversion = dictDataUnitConversion[unitId];
    const oldUnitConversion = dictDataUnitConversion[basePreProcessUnitId?.current];
    const converseRatio = (newUnitConversion?.capacity ?? 1) / (oldUnitConversion?.capacity ?? 1);

    const updatedMaterials = listSelectedMaterials.map((item, index) => {
      const baseQuantity = item?.baseQuantity || item?.quantity || 0;
      const quantity = item?.quantity || 0;
      const conversedQuantity = roundNumber(Number(quantity) * converseRatio, 2);
      form?.setFieldValue(["listSelectedMaterials", index, "quantity"], conversedQuantity);
      return {
        ...item,
        baseQuantity: baseQuantity,
        quantity: conversedQuantity,
      };
    });

    basePreProcessUnitId.current = unitId;
    setListSelectedMaterials(updatedMaterials);
  };

  const handleSetListSelectedMaterials = (listSelected) => {
    if (listSelected?.length === 0)
      return;
    setListSelectedMaterials(prev => {
      const initListSelected = listSelected?.map((item) => {
        const prevItem = prev?.find((x) => x?.id === item?.id);
        return {
          ...item,
          quantity: prevItem?.quantity ?? 0,
        };
      }) ?? [];

      return initListSelected;
    });
  }

  return (
    <FnbCard
      title={pageData.unit.recipe}
      underlineTitle
      className="w-100 mt-12 fnb-card fnb-card-material-recipe h-auto"
      buttons={[]}
    >
      <Row>
        <Col span={24}>
          {isCreatePage ? (
            <Row>
              <FnbFormItem
                className="mb-28 pre-process"
                name="preProcessUnitId"
                label={pageData.unitForPreProcessing}
                rules={[
                  {
                    required: true,
                    message: <InputValidateMessage message={pageData.selectPreProcessUnitValidation} />,
                  },
                ]}
                tooltip={renderToolTipPreProcessing()}
                tooltipVariant={FnbTooltipVariant.SECONDARY}
              >
                <FnbSelectSingle
                  onClick={() => {
                    if (!baseUnit || baseUnit?.id?.length <= 0) {
                      form?.validateFields(['unitId']);
                    }
                  }}
                  placeholder={pageData.placeholderUOM}
                  option={unitConversionOptions}
                  onChange={(id) => {
                    handleChangePreProcessUnitId(id);
                  }}
                />
              </FnbFormItem>
            </Row>
          ) : (
            <>
              <Row>
                <h4 className="fnb-form-label material-view-lable-text-color">{pageData.unitForPreProcessing}</h4>
                <FnbTooltip
                  className="fnb-form-label-tooltip"
                  placement="top"
                  title={() => renderToolTipPreProcessing()}
                  variant="secondary"
                  width={420}
                >
                  <ExclamationIcon width={14} height={14} />
                </FnbTooltip>
              </Row>
              <Row>
                <FnbTooltip onlyShowWhenEllipsis>
                  <FnbTypography text={preProcessUnitName} />
                </FnbTooltip>
              </Row>
            </>
          )}
        </Col>
        {isCreatePage ? (
          <Col span={24}>
            <Row>
              <Col span={24}>
                <FnbFormItem name="selectMaterial">
                  <FnbSelect
                    onChange={(e) => {}}
                    popupClassName="fnb-select-multiple-dropdown"
                    placeholder={pageData.searchMaterial}
                    onClick={() => setIsOpenModal(true)}
                    fixed={true}
                    multiLine={false}
                    open={false}
                    showSearch={false}
                    isShowSearchIcon={true}
                    suffixIcon={<></>}
                  />
                </FnbFormItem>
              </Col>
            </Row>
          </Col>
        ) : null}
        <Col span={24}>
          <Row className="mt-3">
            {listSelectedMaterials?.length > 0 && (
              <Col span={24}>
                <FnbTable
                  columns={columnsMaterial()}
                  dataSource={listSelectedMaterials}
                  total={listSelectedMaterials?.length}
                  numberRecordCurrent={listSelectedMaterials?.length}
                  showPaging={false}
                  scroll={isCreatePage && (
                    listSelectedMaterials?.length > 5
                      ? { x: isMaxWidth1600 ? 850 : 1200, y: 86 * 5 }
                      : { x: isMaxWidth1600 ? 850 : 1200 }
                  )}
                  className={"material-recipe-table"}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <IngredientSearchModal
        excludedId={param?.id}
        visible={isOpenModal}
        handleCancel={() => setIsOpenModal(false)}
        listSelectedMaterials={listSelectedMaterials}
        setListSelectedMaterials={handleSetListSelectedMaterials}
        modalType={IngredientSearchModalType.TYPE_INGREDIENT}
      />
    </FnbCard>
  );
};
