export const REPORT = {
  TOPSELLINGPRODUCT: 1,
  WORSTSELLINGPRODUCT: 2,
};

export const TYPE = {
  REVENUE: 1,
  TRANSACTION: 2
};

export const TRANSACTION_TABPANE_KEY = {
  ORDER: 1,
  RESERVATION: 2,
  SHIFT: 3,
  PRODUCT: 4,
  COMBO: 5
};

export const ColumnNameTableSoldProduct = {
  PRODUCT: 'PRODUCT',
  CATEGORY: 'CATEGORY',
  QUANTITY: 'QUANTITY',
  AMOUNT: 'AMOUNT',
  COST: 'COST',
};

export const PromotionReportConstants = {
  CAMPAIGNNAME: 'CAMPAIGNNAME',
  CAMPAIGNREVENUE: 'CAMPAIGNREVENUE',
  TOTALORDERS: 'TOTALORDERS',
  TOTALCUSTOMERS: 'TOTALCUSTOMERS',
  TOTALDISCOUNTS: 'TOTALDISCOUNTS',
};