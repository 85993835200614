import { Badge, Popover } from "antd";
import { FnbButton } from "components/fnb-button/fnb-button";
import {
  FilterIcon,
  FilterSelected,
} from "constants/icons.constants";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./fnb-filter.scss";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { CloseIcon } from "themes/theme-2-new/assets/icons.constants";
import theme from "theme";

export function FnbFilter(props) {
  const [t] = useTranslation();
  const {
    filter,
    filterComponent,
  } = props;


  const [visible, setVisible] = useState(false);

  const pageData = {
    titleFilter: t("table.titleFilter"),
    noDataFound: t("table.noDataFound"),
    filterButtonTitle: t("button.filter"),
    buttonResetFilter: t("button.resetData"),
    filtersApplied: t("report.staff.filtersApplied"),
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const renderFilterButton = () => {
    if (filterComponent) {
      return <>{filterComponent}</>;
    }
    if (filter) {
      const { component, onClearFilter, totalFilterSelected, onClickFilterButton, filterClassName, isShowTitle } = filter;
      const numberTotalFilterSelected = parseInt(totalFilterSelected) || 0;
      return (
        <Popover
          id="table-filter-popover"
          placement="bottomRight"
          content={
            <>
              <div className="content-filter" id="table-filter-popover-content">
                {component}
              </div>
              <div className="footer-filter" id="table-filter-popover-footer">
                {totalFilterSelected > 0 && (
                  <span>{t(pageData.filtersApplied, { filter_count: totalFilterSelected })}</span>
                )}
                <FnbButton
                  id="table-filter-popover-reset-filter"
                  onClick={onClearFilter}
                  disabled={numberTotalFilterSelected <= 0}
                  variant="tertiary"
                  text={pageData.buttonResetFilter}
                />
              </div>
            </>
          }
          title={isShowTitle && <div className="filter-title">
            <FnbTypography variant="b1-bold" text={pageData.titleFilter} color={theme.colors.primary[100]} />
            <CloseIcon width={13} height={13} onClick={onCloseFilter} />
          </div>}
          trigger="click"
          open={visible}
          onOpenChange={handleVisibleChange}
          getPopupContainer={(trigger) => trigger.parentElement}
          overlayClassName={`filter-component custom-form ${filterClassName ?? ""}`}
          onClick={onClickFilterButton}
        >
          <Badge className="badge-counter" size="small" count={numberTotalFilterSelected} color="#FF8C24">
            {visible ? (
              <FilterSelected className={numberTotalFilterSelected > 0 ? "filter-count" : "filter-empty"} />
            ) : (
              <FilterIcon className={numberTotalFilterSelected > 0 ? "filter-count" : "filter-empty"} />
            )}
          </Badge>
        </Popover>
      );
    }
    return <></>;
  };

  const onCloseFilter = () => {
    setVisible(false);
  };

  return (
    renderFilterButton()
  );
}
