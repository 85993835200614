import { FnbBadge } from "components/fnb-badge/fnb-badge";
import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { InfoCircleBasicIcon } from "constants/icons.constants";
import { OptionDateTime } from "constants/option-date.constants";
import { orderStatusBadgeVariant, orderStatusLocalization } from "constants/order-status.constants";
import { DateFormat, guidIdEmptyValue } from "constants/string.constants";
import promotionCampaignDataService from "data-services/promotion-campaign/promotion-campaign.service";
import moment from "moment/moment";
import { forwardRef, useImperativeHandle, useState } from "react";
import languageService from "services/language/language.service";
import {
  executeAfter,
  formatCurrencyWithoutSymbol,
  formatDate,
  formatNumberDecimalOrInteger,
  getCurrency,
} from "utils/helpers";
import "./campaign-usage-detail.component.scss";

const CampaignDetailOrderListComponent = forwardRef((props, ref) => {
  const DEFAULT_PAGE_NUMBER = 1;
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_DATE = moment().toDate().toLocaleDateString("en-US");
  const DEFAULT_END_DATE = moment().toDate().toLocaleDateString("en-US");
  const { t, isShowCampaignDetailOrderList, handleCancel } = props;
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.allTime);
  const [keySearch, setKeySearch] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const [promotionCampaignName, setPromotionCampaignName] = useState("-");
  const [totalOrders, setTotalOrders] = useState("-");
  const [totalRevenue, setTotalRevenue] = useState("-");
  const [totalCampaignDiscount, setTotalCampaignDiscount] = useState("-");
  const [currentPageNumber, setCurrentPageNumber] = useState(DEFAULT_PAGE_NUMBER);

  const [promotionCampaignId, setPromotionCampaignId] = useState("");
  const [discountCodeId, setDiscountCodeId] = useState("");
  const [flashSaleId, setFlashSaleId] = useState("");
  const [branchId, setBranchId] = useState();

  const pageData = {
    campaignDetailTitle: t("report.promotion.campaignDetailTitle"),
    createdTime: t("report.promotion.createdTime"),
    orderId: t("report.promotion.orderId"),
    detail: t("report.promotion.detail"),
    total: t("report.promotion.total"),
    campaignDiscount: t("report.promotion.campaignDiscount"),
    customer: t("report.promotion.customer"),
    placeholderSearch: t("report.promotion.placeholderSearchByOrderId"),
    campaign: t("report.promotion.campaign"),
    totalOrder: t("report.promotion.totalOrder"),
    totalRevenue: t("report.promotion.totalRevenue"),
    totalCampaignDiscount: t("report.promotion.totalCampaignDiscount"),
    subTotal: t("report.promotion.subTotal"),
    totalDiscount: t("report.promotion.totalDiscount"),
    taxAndFee: t("report.promotion.taxAndFee"),
    status: t("table.status"),
    amountDiscountOnCampaign: t("report.promotion.amountDiscountOnCampaign"),
  };

  useImperativeHandle(ref, () => ({
    initData: (promotionCampaignId, discountCodeId, flashSaleId, reportBranchId) => {
      setTypeOptionDate(OptionDateTime.allTime);
      setPromotionCampaignId(promotionCampaignId);
      setDiscountCodeId(discountCodeId);
      setFlashSaleId(flashSaleId);
      setBranchId(reportBranchId ?? guidIdEmptyValue);
    },
  }));

  const getComboDataTable = async (
    languageCode,
    pageNumber,
    pageSize,
    inputKeySearch,
    selectedTypeOptionDate,
    startDate,
    endDate,
    reportPromotionCampaignId,
    reportDiscountCodeId,
    reportFlashSaleId,
    reportBranchId,
  ) => {
    await promotionCampaignDataService
      .getPromotionCampaignDetailOrderListAsync(
        languageCode,
        pageNumber,
        pageSize,
        inputKeySearch ?? keySearch,
        reportPromotionCampaignId ?? promotionCampaignId ?? "",
        reportFlashSaleId ?? flashSaleId ?? "",
        reportDiscountCodeId ?? discountCodeId ?? "",
        startDate && startDate !== "" && startDate !== undefined
          ? startDate
          : selectedDate.startDate ?? DEFAULT_START_DATE,
        endDate && endDate !== "" && endDate !== undefined ? endDate : selectedDate.endDate ?? DEFAULT_END_DATE,
        selectedTypeOptionDate !== "" && selectedTypeOptionDate !== undefined ? selectedTypeOptionDate : typeOptionDate,
        reportBranchId ?? branchId ?? guidIdEmptyValue,
      )
      .then((res) => {
        if (res) {
          setDataSource(res.promotionCampaignDetailOrderLists);
          setPromotionCampaignName(res.promotionCampaignName);
          setTotalOrders(res.totalOrders);
          setTotalRevenue(formatNumberDecimalOrInteger(res.totalRevenue));
          setTotalCampaignDiscount(formatNumberDecimalOrInteger(res.totalCampaignDiscount));
        }
      });
  };

  const onSearch = async (keySearch = "") => {
    let decodedKeySearch = decodeURIComponent(keySearch);
    let sanitizedKeySearch = decodedKeySearch.trim().toUpperCase();
    let languageCode = languageService.getLang();
    executeAfter(500, async () => {
      setKeySearch(keySearch);
      setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
      getComboDataTable(languageCode, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, sanitizedKeySearch, typeOptionDate);
    });
  };

  const onChangePage = async (pageNumber, pageSize) => {
    let languageCode = languageService.getLang();
    getComboDataTable(languageCode, pageNumber, pageSize);
    setCurrentPageNumber(pageNumber);
  };

  const onSelectedDatePicker = (date, selectedTypeOptionDate) => {
    setSelectedDate({
      startDate: date.startDate === "" ? DEFAULT_START_DATE : date.startDate,
      endDate: date.endDate === "" ? DEFAULT_END_DATE : date.endDate,
    });
    setTypeOptionDate(selectedTypeOptionDate);
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
    let languageCode = languageService.getLang();
    getComboDataTable(
      languageCode,
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE,
      keySearch,
      selectedTypeOptionDate ?? typeOptionDate,
      date.startDate === "" ? DEFAULT_START_DATE : date.startDate,
      date.endDate === "" ? DEFAULT_END_DATE : date.endDate,
    );
  };

  const tableConfigs = {
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    columns: [
      {
        title: pageData.createdTime,
        dataIndex: "createdTime",
        width: "15%",
        align: "right",
        render: (value) => <FnbTypography text={formatDate(value, DateFormat.DD_MMM_YYYY_HH_MM)} />,
      },
      {
        title: pageData.orderId,
        dataIndex: "stringCode",
        width: "8%",
        align: "left",
        render: (_, record) => (
          <FnbTypography.Link
            to={`/report/order/detail/${record?.orderId}`}
            target="_blank"
            underline={true}
            text={record.stringCode}
          />
        ),
      },
      {
        title: `${pageData.detail} (${getCurrency()})`,
        dataIndex: "detail",
        align: "center",
        width: "18%",
        render: (_, record) => (
          <>
            <div className="detail-info">
              <FnbTypography text={pageData.subTotal + ":"} />
              <FnbTypography text={formatCurrencyWithoutSymbol(record.originalPrice)} />
            </div>
            <div className="detail-info">
              <FnbTypography text={pageData.totalDiscount + ":"} />
              <FnbTypography text={formatCurrencyWithoutSymbol(record.discountAmount)} />
            </div>
            <div className="detail-info">
              <FnbTypography text={pageData.taxAndFee + ":"} />
              <FnbTypography text={formatCurrencyWithoutSymbol(record.taxAndFeeAmount)} />
            </div>
          </>
        ),
      },
      {
        title: `${pageData.total} (${getCurrency()})`,
        dataIndex: "totalAmount",
        align: "right",
        width: "13%",
        render: (_, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FnbTypography className="total-amount" text={formatCurrencyWithoutSymbol(record.totalAmount)} />
            <FnbTypography text={record.paymentMethodName} />
          </div>
        ),
      },
      {
        title: (
          <div className="custom-table-header">
            {`${pageData.campaignDiscount} (${getCurrency()})`}
            <FnbTooltip variant="secondary" placement="bottom" title={pageData.amountDiscountOnCampaign}>
              <InfoCircleBasicIcon />
            </FnbTooltip>
          </div>
        ),
        dataIndex: "campaignDiscountAmount",
        align: "right",
        width: "22%",
        render: (value) => <FnbTypography text={formatCurrencyWithoutSymbol(value)} />,
      },
      {
        title: pageData.customer,
        dataIndex: "customer",
        align: "left",
        width: "14%",
        render: (_, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <FnbTypography.Link
              to={`/customer/detail/${record.customerId}`}
              target="_blank"
              underline={true}
              text={record.customerName ?? ""}
            />
            <FnbTypography text={record.customerPhone ?? ""} />
          </div>
        ),
        defaultSortOrder: "descend",
      },
      {
        title: pageData.status,
        dataIndex: "orderStatus",
        align: "left",
        render: (value) => {
          return (
            <div className="column-product-status">
              <FnbBadge variant={orderStatusBadgeVariant[value]} text={t(orderStatusLocalization[value])} />
            </div>
          );
        },
      },
    ],
    onSearch,
    onChangePage,
  };

  const renderHeaderExtendInformation = () => {
    return (
      <>
        <div className="extend-information">
          <div className="campaign-name">
            <FnbTypography text={pageData.campaign} />:{" "}
            <FnbTypography className="highlight-info" text={promotionCampaignName} />
          </div>
          <div className="other-information">
            <div>
              <FnbTypography text={pageData.totalOrder} />:{" "}
              <FnbTypography className="highlight-info" text={totalOrders} />
            </div>
            <div>
              <FnbTypography text={pageData.totalRevenue} />:{" "}
              <FnbTypography className="highlight-info" text={`${totalRevenue} ${getCurrency()}`} />
            </div>
            <div>
              <FnbTypography text={pageData.totalCampaignDiscount} />:{" "}
              <FnbTypography className="highlight-info" text={`${totalCampaignDiscount} ${getCurrency()}`} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <FnbTable
          dataSource={dataSource}
          className="campaign-detail-order-list-table"
          columns={tableConfigs.columns}
          pageSize={tableConfigs.pageSize}
          currentPageNumber={currentPageNumber}
          onChangePage={tableConfigs.onChangePage}
          search={{
            maxLength: 100,
            placeholder: pageData.placeholderSearch,
            onChange: tableConfigs.onSearch,
          }}
          calendarComponent={{
            onSelectedDatePicker: onSelectedDatePicker,
            selectedDate: selectedDate,
            orderTypeFilterTime: typeOptionDate,
            containAllTime: true,
          }}
          isRenderExtendHeaderInformation={true}
          headerExtendInformation={renderHeaderExtendInformation()}
          scrollX={1300}
          scrollY={484}
          total={totalOrders}
        />
      </>
    );
  };

  return (
    <FnbModal
      width={"1402px"}
      className="campaign-detail-order-list"
      title={pageData.campaignDetailTitle}
      visible={isShowCampaignDetailOrderList}
      handleCancel={() => {
        handleCancel();
        setCurrentPageNumber(1);
        setTypeOptionDate(OptionDateTime.allTime);
        setDataSource([]);
        setPromotionCampaignName("-");
        setTotalOrders("-");
        setTotalRevenue("-");
        setTotalCampaignDiscount("-");
      }}
      content={renderContent()}
      footer={""}
      closable={true}
      isDestroyOnClose={true}
    />
  );
});
export default CampaignDetailOrderListComponent;
