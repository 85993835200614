import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { ImageMaterialDefault } from "constants/icons.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import FnbTypography from "components/fnb-typography/fnb-typography";
import theme from "theme";
import { formatterDecimalNumber } from "utils/helpers";
import { Typography } from "antd";
import { KEY_TAB_PANE } from "../table-inventory-control.component";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import FnbCard from "components/fnb-card/fnb-card.component";
import { FnbTable } from "components/fnb-table/fnb-table";
import "./preprocess-material-detail.page.scss";
import preprocessMaterialDataService from "data-services/material-control/preprocess-material-data.service";
import { FnbButton } from "components/fnb-button/fnb-button";

const { Text } = Typography;
const urlBack = `/inventory/transfer-control?tabActive=${KEY_TAB_PANE.VIEW_BY_PREPROCESS_MATERIAL}`;

const PreProcessMaterialDetailPage = (props) => {
  const { match, history } = props;
  const { t } = useTranslation();
  const [preProcessDetail, setPreProcessDetail] = useState(null);

  const pageData = {
    branch: t("materialControl.preProcessMaterial.branch"),
    preProcessMaterial: t("menu.inventoryManagement.preProcessMaterial"),
    generalInformation: t("material.generalInformation"),
    sku: t("material.inventory.sku"),
    type: t("materialControl.preProcessMaterial.type"),
    unit: t("materialControl.preProcessMaterial.unit"),
    materialToPreProcess: t("materialControl.preProcessMaterial.materialToPreProcess"),
    quantityToPreProcess: t("materialControl.preProcessMaterial.quantityToPreProcess"),
    consumableMaterialAreUsed: t("materialControl.preProcessMaterial.consumableMaterialAreUsed"),
    consumableMaterial: t("materialControl.preProcessMaterial.consumableMaterial"),
    usedQuantity: t("materialControl.preProcessMaterial.usedQuantity"),
    availableQuantity: t("materialControl.preProcessMaterial.availableQuantity"),
    remainQuantity: t("materialControl.preProcessMaterial.remainQuantity"),
    btnLeave: t("button.leave"),
  };

  useEffect(() => {
    void fetchData();
  }, []);

  const fetchData = async () => {
    await preprocessMaterialDataService.getPreProcessByIdRequestAsync(match?.params?.id)
      .then((res) => {
        if (res) {
          setPreProcessDetail(res);
        }
      });
  };

  const columnMaterialToPreProcess = () => {
    return [
      {
        title: pageData.materialToPreProcess,
        dataIndex: 'materialName',
        align: "left",
        width: "35%",
        render: (_, record) => {
          return (
            <div className={"material-name-container"}>
              <div className={"material-name-left-column"}>
                {record?.thumbnail ? (
                  <Thumbnail src={record?.thumbnail} width={56} height={56} />
                ) : (
                  <ImageMaterialDefault width={56} height={56} />
                )}
                <div style={{maxWidth: '70%'}}>
                  <FnbTooltip
                    onlyShowWhenEllipsis
                    title={record?.materialName}
                  >
                    <FnbTypography
                      variant={theme.typography["b1-regular"]}
                      text={record?.materialName}
                      color={"#2F2D39"}
                    />
                  </FnbTooltip>
                  <FnbTooltip
                    onlyShowWhenEllipsis
                    title={record?.sku}
                  >
                    <FnbTypography
                      variant={theme.typography["b2-regular"]}
                      text={record?.sku ? `${pageData.sku}: ${record?.sku}` : null}
                      color={"#8B8899"}
                    />
                  </FnbTooltip>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: pageData.type,
        dataIndex: "typeName",
        width: "25%",
        align: "center",
        render: (_, record) => {
          return <>{t(record?.materialTypeName)}</>;
        },
      },
      {
        title: pageData.quantityToPreProcess,
        dataIndex: "quantity",
        width: "28%",
        align: "center",
        render: (_, record) => {
          return <>{formatterDecimalNumber(record?.quantity)}</>;
        },
      },
      {
        title: pageData.unit,
        dataIndex: "unit",
        align: "center",
        width: "12%",
        render: (_, record) => {
          return <FnbTooltip title={record?.unitName} onlyShowWhenEllipsis />;
        },
      },
    ];
  };

  const columnConsumableMaterialAreUsed = () => {
    return [
      {
        title: pageData.consumableMaterial,
        dataIndex: 'materialName',
        align: "left",
        width: "33%",
        render: (_, record) => {
          return (
            <div className={"material-name-container"}>
              <div className={"material-name-left-column"}>
                {record?.thumbnail ? (
                  <Thumbnail src={record?.thumbnail} width={56} height={56} />
                ) : (
                  <ImageMaterialDefault width={56} height={56} />
                )}
                <div style={{maxWidth: '70%'}}>
                  <FnbTooltip
                    onlyShowWhenEllipsis
                    title={record?.materialName}
                  >
                    <FnbTypography
                      variant={theme.typography["b1-regular"]}
                      text={record?.materialName}
                      color={record?.remainQuantity < 0 ? "#E28B08" : "#2F2D39"}
                    />
                  </FnbTooltip>
                  <FnbTooltip
                    onlyShowWhenEllipsis
                    title={record?.sku}
                  >
                    <FnbTypography
                      variant={theme.typography["b2-regular"]}
                      text={record?.sku ? `${pageData.sku}: ${record?.sku}` : null}
                      color={record?.remainQuantity < 0 ? "#E28B08" : "#8B8899"}
                    />
                  </FnbTooltip>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: pageData.type,
        dataIndex: "typeName",
        width: "28%",
        align: "center",
        render: (_, record) => {
          return (
            <div className={record?.remainQuantity < 0 ? "warning" : ""}>
              {t(record?.materialTypeName)}
            </div>
          );
        },
      },
      {
        title: pageData.usedQuantity,
        dataIndex: "usedQuantity",
        width: "22%",
        align: "center",
        render: (_, record) => {
          return (
            <div className={record?.remainQuantity < 0 ? "warning" : ""}>
              <FnbTooltip
                title={`${formatterDecimalNumber(record?.usedQuantity)} (${record?.unitName})`}
                onlyShowWhenEllipsis
              />
            </div>
          );
        },
      },
      {
        title: pageData.availableQuantity,
        dataIndex: "availableQuantity",
        width: "15%",
        align: "center",
        render: (_, record) => {
          return (
            <div className={record?.remainQuantity < 0 ? "warning" : ""}>
              <FnbTooltip
                title={`${formatterDecimalNumber(record?.availableQuantity)} (${record?.unitName})`}
                onlyShowWhenEllipsis
              />
            </div>
          );
        },
      },
      {
        title: pageData.remainQuantity,
        dataIndex: "remainQuantity",
        width: "15%",
        align: "center",
        render: (_, record) => {
          return (
            <div className={record?.remainQuantity < 0 ? "warning" : ""}>
              <FnbTooltip
                title={`${formatterDecimalNumber(record?.remainQuantity < 0 ? 0 : record?.remainQuantity)} (${
                  record?.unitName
                })`}
                onlyShowWhenEllipsis
              />
            </div>
          );
        },
      },
    ];
  };

  const handleBack = () => {
    history.push(urlBack);
  };

  return (
    <div className="pre-process-material-detail">
      <FnbHeadingPage
        itemsBreadcrumb={[
          {
            href: urlBack,
            title: pageData.preProcessMaterial,
          },
          {
            title: (
              <Text className="pre-process-material-code">{`#${preProcessDetail?.stringCode ?? ''}`}</Text>
            ),
          },
        ]}
        listButtons={[
          <FnbButton text={pageData.btnLeave} variant="tertiary" onClick={handleBack} />,
        ]}
        isCompactButtons={false}
      />
      <div className="card-parent">
        <FnbCard className="card-general-info card-table mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.generalInformation} />
            <hr className="title-hr" />
            <div className="label-item">
              <div className="label">
                <FnbTypography text={`${pageData.branch}:`} />
              </div>
              <div className="content">
                <FnbTypography text={preProcessDetail?.branchName ?? ''} />
              </div>
            </div>
          </div>
          <FnbTable
            className="mt-4"
            dataSource={preProcessDetail?.materials}
            columns={columnMaterialToPreProcess()}
          />
        </FnbCard>
        <FnbCard className="card-general-info card-table mt-3">
          <div className="general-info">
            <FnbTypography className="title" text={pageData.consumableMaterialAreUsed} />
            <hr className="title-hr" />
          </div>
          <FnbTable
            className="mt-4"
            dataSource={preProcessDetail?.consumableMaterials}
            columns={columnConsumableMaterialAreUsed()}
          />
        </FnbCard>
      </div>
    </div>
  );
};

export default PreProcessMaterialDetailPage;
