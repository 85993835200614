import React, { useEffect, useState } from "react";
import { Radio, Form } from "antd";
import { useTranslation } from "react-i18next";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import "./filter-popover.component.scss";
import FnbRadioButton from "components/fnb-radio-button";

export const FilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { categories, branches, units, isShowBranchFilter = true, typeIngredient } = props;

  const [selectedBranchId, setSelectedBranchId] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedUnitId, setSelectedUnitId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const defaultValue = "";
  const filterTypes = {
    branch: "branchId",
    category: "materialCategoryId",
    unit: "unitId",
    status: "isActive",
    type: "type",
  };

  useEffect(() => {
    let countBranch = selectedBranchId !== "" ? 1 : 0;
    let countCategory = selectedCategoryId !== "" ? 1 : 0;
    let countUnit = selectedUnitId !== "" ? 1 : 0;
    let countStatus = selectedStatus !== "" ? 1 : 0;
    let countType = selectedType !== "" ? 1 : 0;

    const filterOptions = {
      unitId: selectedUnitId,
      branchId: selectedBranchId,
      materialCategoryId: selectedCategoryId,
      isActive: selectedStatus,
      count: countUnit + countBranch + countCategory + countStatus + countType,
      type: selectedType,
    };
    props.fetchDataMaterials(filterOptions);
  }, [selectedBranchId, selectedCategoryId, selectedUnitId, selectedStatus, selectedType]);

  //#region PageData
  const pageData = {
    filter: {
      branch: {
        title: t("material.filter.branch.title"),
        all: t("material.filter.branch.all"),
        placeholder: t("material.filter.branch.placeholder"),
        specialOptionKey: null,
      },
      category: {
        title: t("material.filter.category.title"),
        all: t("material.filter.category.all"),
        placeholder: t("material.filter.category.placeholder"),
        specialOptionKey: null,
      },
      unit: {
        title: t("material.filter.unit.title"),
        all: t("material.filter.unit.all"),
        placeholder: t("material.filter.unit.placeholder"),
        specialOptionKey: null,
      },
      status: {
        title: t("material.filter.status.title"),
        all: t("material.filter.status.all"),
        active: t("material.filter.status.active"),
        inactive: t("material.filter.status.inactive"),
        specialOptionKey: null,
      },
      ingredientType: t("material.filter.type.ingredientType"),
    },
  };
  //#endregion

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter();
      props.fetchDataMaterials({
        unitId: "",
        branchId: "",
        materialCategoryId: "",
        isActive: "",
        count: 0,
      });
    },
  }));

  const clearFilter = () => {
    setSelectedBranchId(defaultValue);
    setSelectedCategoryId(defaultValue);
    setSelectedUnitId(defaultValue);
    setSelectedStatus(defaultValue);
    setSelectedType(defaultValue);
  };

  const onFilterMaterial = (id, filterName) => {
    switch (filterName) {
      case filterTypes.branch:
        setSelectedBranchId(id);
        break;
      case filterTypes.category:
        setSelectedCategoryId(id);
        break;
      case filterTypes.unit:
        setSelectedUnitId(id);
        break;
      case filterTypes.type:
        setSelectedType(id);
        break;
      default: //status
        setSelectedStatus(id);
        break;
    }
  };

  return (
    <Form layout="vertical">
      {isShowBranchFilter && (
        <Form.Item label={pageData.filter.branch.title}>
          <FnbSelectSingle
            className="form-select"
            showSearch
            onChange={(value) => onFilterMaterial(value, filterTypes.branch)}
            value={selectedBranchId}
            defaultValue={defaultValue}
            option={branches}
          />
        </Form.Item>
      )}
      <Form.Item label={pageData.filter.category.title}>
        <FnbSelectSingle
          className="form-select"
          showSearch
          onChange={(value) => onFilterMaterial(value, filterTypes.category)}
          value={selectedCategoryId}
          defaultValue={defaultValue}
          option={categories}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.unit.title}>
        <FnbSelectSingle
          fixed
          className="form-select"
          showSearch
          onChange={(value) => onFilterMaterial(value, filterTypes.unit)}
          value={selectedUnitId}
          defaultValue={defaultValue}
          option={units}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.ingredientType}>
        <FnbSelectSingle
          fixed
          className="form-select"
          showSearch
          onChange={(value) => onFilterMaterial(value, filterTypes.type)}
          value={selectedType}
          defaultValue={defaultValue}
          option={typeIngredient}
        />
      </Form.Item>
      <Form.Item label={pageData.filter.status.title}>
        <Radio.Group
          value={selectedStatus}
          defaultValue={defaultValue}
          buttonStyle="solid"
          onChange={(e) => onFilterMaterial(e.target.value, filterTypes.status)}
        >
          <FnbRadioButton value={defaultValue} isChecked={selectedStatus === ""}>
            {pageData.filter.status.all}
          </FnbRadioButton>
          <FnbRadioButton value={true} isChecked={selectedStatus === true}>
            {pageData.filter.status.active}
          </FnbRadioButton>
          <FnbRadioButton value={false} isChecked={selectedStatus === false}>
            {pageData.filter.status.inactive}
          </FnbRadioButton>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
});
