export const PermissionKeys = {
  ONLINE_STORE_MENU: "6C626154-5065-7265-6D69-7373000008D0",
  STORE_APP_PACKAGE: "6C626154-5065-7265-6D69-7373000009D0",
  ADMIN: "6C626154-5065-7265-6D69-7373000007D0",
  SUPER_ADMIN: "6C626154-5065-7265-6D69-7373000007D1",
  VIEW_PRODUCT: "6C626154-5065-7265-6D69-737300000001",
  CREATE_PRODUCT: "6C626154-5065-7265-6D69-737300000002",
  EDIT_PRODUCT: "6C626154-5065-7265-6D69-737300000003",
  DELETE_PRODUCT: "6C626154-5065-7265-6D69-737300000004",
  ACTIVATE_PRODUCT: "6C626154-5065-7265-6D69-737300000005",
  DEACTIVATE_PRODUCT: "6C626154-5065-7265-6D69-737300000006",
  IMPORT_PRODUCT: "6C626154-5065-7265-6D69-737300000007",
  EXPORT_PRODUCT: "6C626154-5065-7265-6D69-737300000008",
  VIEW_OPTION: "6C626154-5065-7265-6D69-737300000009",
  CREATE_OPTION: "6C626154-5065-7265-6D69-73730000000A",
  EDIT_OPTION: "6C626154-5065-7265-6D69-73730000000B",
  DELETE_OPTION: "6C626154-5065-7265-6D69-73730000000C",
  VIEW_COMBO: "6C626154-5065-7265-6D69-73730000000D",
  CREATE_COMBO: "6C626154-5065-7265-6D69-73730000000E",
  EDIT_COMBO: "6C626154-5065-7265-6D69-73730000000F",
  DELETE_COMBO: "6C626154-5065-7265-6D69-737300000010",
  VIEW_MATERIAL: "6C626154-5065-7265-6D69-737300000011",
  CREATE_MATERIAL: "6C626154-5065-7265-6D69-737300000012",
  EDIT_MATERIAL: "6C626154-5065-7265-6D69-737300000013",
  DELETE_MATERIAL: "6C626154-5065-7265-6D69-737300000014",
  ACTIVATE_MATERIAL: "6C626154-5065-7265-6D69-737300000015",
  DEACTIVATE_MATERIAL: "6C626154-5065-7265-6D69-737300000016",
  IMPORT_MATERIAL: "6C626154-5065-7265-6D69-737300000017",
  EXPORT_MATERIAL: "6C626154-5065-7265-6D69-737300000018",
  VIEW_PRODUCT_CATEGORY: "6C626154-5065-7265-6D69-737300000019",
  CREATE_PRODUCT_CATEGORY: "6C626154-5065-7265-6D69-73730000001A",
  EDIT_PRODUCT_CATEGORY: "6C626154-5065-7265-6D69-73730000001B",
  DELETE_PRODUCT_CATEGORY: "6C626154-5065-7265-6D69-73730000001C",
  VIEW_MATERIAL_CATEGORY: "6C626154-5065-7265-6D69-73730000001D",
  CREATE_MATERIAL_CATEGORY: "6C626154-5065-7265-6D69-73730000001E",
  EDIT_MATERIAL_CATEGORY: "6C626154-5065-7265-6D69-73730000001F",
  DELETE_MATERIAL_CATEGORY: "6C626154-5065-7265-6D69-737300000020",
  VIEW_SUPPLIER: "6C626154-5065-7265-6D69-737300000021",
  CREATE_SUPPLIER: "6C626154-5065-7265-6D69-737300000022",
  EDIT_SUPPLIER: "6C626154-5065-7265-6D69-737300000023",
  DELETE_SUPPLIER: "6C626154-5065-7265-6D69-737300000024",
  VIEW_PURCHASE: "6C626154-5065-7265-6D69-737300000025",
  CREATE_PURCHASE: "6C626154-5065-7265-6D69-737300000026",
  EDIT_PURCHASE: "6C626154-5065-7265-6D69-737300000027",
  CANCEL_PURCHASE: "6C626154-5065-7265-6D69-737300000028",
  APPROVE_PURCHASE: "6C626154-5065-7265-6D69-737300000029",
  IMPORT_GOODS: "6C626154-5065-7265-6D69-73730000002A",
  VIEW_TRANSFER: "6C626154-5065-7265-6D69-73730000002B",
  CREATE_TRANSFER: "6C626154-5065-7265-6D69-73730000002C",
  EDIT_TRANSFER: "6C626154-5065-7265-6D69-73730000002D",
  CANCEL_TRANSFER: "6C626154-5065-7265-6D69-73730000002E",
  SHIP_GOODS: "6C626154-5065-7265-6D69-73730000002F",
  RECEIVE_GOODS: "6C626154-5065-7265-6D69-737300000030",
  VIEW_CUSTOMER: "6C626154-5065-7265-6D69-737300000031",
  CREATE_CUSTOMER: "6C626154-5065-7265-6D69-737300000032",
  EDIT_CUSTOMER: "6C626154-5065-7265-6D69-737300000033",
  DELETE_CUSTOMER: "6C626154-5065-7265-6D69-737300000034",
  VIEW_SEGMENT: "6C626154-5065-7265-6D69-737300000035",
  CREATE_SEGMENT: "6C626154-5065-7265-6D69-737300000036",
  EDIT_SEGMENT: "6C626154-5065-7265-6D69-737300000037",
  DELETE_SEGMENT: "6C626154-5065-7265-6D69-737300000038",
  VIEW_PROMOTION: "6C626154-5065-7265-6D69-737300000039",
  CREATE_PROMOTION: "6C626154-5065-7265-6D69-73730000003A",
  EDIT_PROMOTION: "6C626154-5065-7265-6D69-73730000003B",
  DELETE_PROMOTION: "6C626154-5065-7265-6D69-73730000003C",
  STOP_PROMOTION: "6C626154-5065-7265-6D69-73730000003D",
  VIEW_AREA_TABLE: "6C626154-5065-7265-6D69-73730000003E",
  CREATE_AREA_TABLE: "6C626154-5065-7265-6D69-73730000003F",
  EDIT_AREA_TABLE: "6C626154-5065-7265-6D69-737300000040",
  DELETE_AREA_TABLE: "6C626154-5065-7265-6D69-737300000041",
  VIEW_MEMBERSHIP_LEVEL: "6C626154-5065-7265-6D69-737300000042",
  CREATE_MEMBERSHIP_LEVEL: "6C626154-5065-7265-6D69-737300000043",
  EDIT_MEMBERSHIP_LEVEL: "6C626154-5065-7265-6D69-737300000044",
  DELETE_MEMBERSHIP_LEVEL: "6C626154-5065-7265-6D69-737300000045",
  VIEW_FEE: "6C626154-5065-7265-6D69-737300000046",
  CREATE_FEE: "6C626154-5065-7265-6D69-737300000047",
  DELETE_FEE: "6C626154-5065-7265-6D69-737300000048",
  VIEW_TAX: "6C626154-5065-7265-6D69-737300000049",
  CREATE_TAX: "6C626154-5065-7265-6D69-73730000004A",
  DELETE_TAX: "6C626154-5065-7265-6D69-73730000004B",
  VIEW_SHIFT: "6C626154-5065-7265-6D69-73730000004C",
  VIEW_ORDER: "6C626154-5065-7265-6D69-73730000004D",
  CREATE_ORDER: "6C626154-5065-7265-6D69-73730000004E",
  EDIT_ORDER: "6C626154-5065-7265-6D69-73730000004F",
  DELETE_ORDER: "6C626154-5065-7265-6D69-737300000050",
  CANCEL_ORDER: "6C626154-5065-7265-6D69-737300000051",
  CASHIER: "6C626154-5065-7265-6D69-737300000052",
  SERVICE: "6C626154-5065-7265-6D69-737300000053",
  KITCHEN: "6C626154-5065-7265-6D69-737300000054",
  VIEW_LOYALTY_POINT: "6C626154-5065-7265-6D69-737300000055",
  STOP_FEE: "6C626154-5065-7265-6D69-737300000056",
  STOP_COMBO: "6C626154-5065-7265-6D69-737300000057",
  VIEW_THEME_STORE: "6C626154-5065-7265-6D69-737300000058",
  EDIT_THEME: "6C626154-5065-7265-6D69-737300000059",
  REMOVE_THEME: "6C626154-5065-7265-6D69-73730000005A",
  VIEW_EMAIL_CAMPAIGN: "6C626154-5065-7265-6D69-73730000005B",
  CREATE_EMAIL_CAMPAIGN: "6C626154-5065-7265-6D69-73730000005C",
  EDIT_EMAIL_CAMPAIGN: "6C626154-5065-7265-6D69-73730000005D",
  DELETE_EMAIL_CAMPAIGN: "6C626154-5065-7265-6D69-73730000005E",
  STOP_EMAIL_CAMPAIGN: "6C626154-5065-7265-6D69-73730000005F",
  VIEW_QR_CODE: "6C626154-5065-7265-6D69-737300000060",
  CREATE_QR_CODE: "6C626154-5065-7265-6D69-737300000061",
  EDIT_QR_CODE: "6C626154-5065-7265-6D69-737300000062",
  DELETE_QR_CODE: "6C626154-5065-7265-6D69-737300000063",
  STOP_QR_CODE: "6C626154-5065-7265-6D69-737300000064",
  VIEW_INVENTORY_HISTORY: "6C626154-5065-7265-6D69-737300000065",
  VIEW_CUSTOMER_REPORT: "6C626154-5065-7265-6D69-737300000066",
  VIEW_PAGE: "6C626154-5065-7265-6D69-737300000067",
  CREATE_PAGE: "6C626154-5065-7265-6D69-737300000068",
  EDIT_PAGE: "6C626154-5065-7265-6D69-737300000069",
  DELETE_PAGE: "6C626154-5065-7265-6D69-73730000006A",
  VIEW_MENU_MANAGEMENT: "6C626154-5065-7265-6D69-73730000006B",
  CREATE_MENU_MANAGEMENT: "6C626154-5065-7265-6D69-73730000006C",
  EDIT_MENU_MANAGEMENT: "6C626154-5065-7265-6D69-73730000006D",
  DELETE_MENU_MANAGEMENT: "6C626154-5065-7265-6D69-73730000006E",
  VIEW_FLASH_SALE: "6C626154-5065-7265-6D69-73730000006F",
  CREATE_FLASH_SALE: "6C626154-5065-7265-6D69-737300000070",
  EDIT_FLASH_SALE: "6C626154-5065-7265-6D69-737300000071",
  DELETE_FLASH_SALE: "6C626154-5065-7265-6D69-737300000072",
  STOP_FLASH_SALE: "6C626154-5065-7265-6D69-737300000073",
  VIEW_DISCOUNT_CODE: "6C626154-5065-7265-6D69-737300000074",
  CREATE_DISCOUNT_CODE: "6C626154-5065-7265-6D69-737300000075",
  EDIT_DISCOUNT_CODE: "6C626154-5065-7265-6D69-737300000076",
  DELETE_DISCOUNT_CODE: "6C626154-5065-7265-6D69-737300000077",
  STOP_DISCOUNT_CODE: "6C626154-5065-7265-6D69-737300000078",
  VIEW_NOTIFICATION_CAMPAIGN: "6C626154-5065-7265-6D69-737300000079",
  CREATE_NOTIFICATION_CAMPAIGN: "6C626154-5065-7265-6D69-73730000007A",
  EDIT_NOTIFICATION_CAMPAIGN: "6C626154-5065-7265-6D69-73730000007B",
  DELETE_NOTIFICATION_CAMPAIGN: "6C626154-5065-7265-6D69-73730000007C",
  STOP_NOTIFICATION_CAMPAIGN: "6C626154-5065-7265-6D69-73730000007D",

  VIEW_ADVANCED_SETTINGS: "public",
  EDIT_ADVANCED_SETTINGS: "public",

  VIEW_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF391",
  CREATE_NEW_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF392",
  EDIT_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF393",
  CANCEL_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF394",
  APPROVE_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF395",

  DELIVER_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF396",
  COMPLETE_TRANSFER_MATERIAL: "A57D562E-E625-EE11-9A18-9C2F9D4DF397",

  VIEW_BLOG: "6C626154-5065-7265-6D69-73730000015A",
  CREATE_BLOG: "6C626154-5065-7265-6D69-73730000015B",
  EDIT_BLOG: "6C626154-5065-7265-6D69-73730000015C",
  DELETE_BLOG: "6C626154-5065-7265-6D69-73730000015D",

  VIEW_REVENUE_REPORT: "6C626154-5065-7265-6D69-73730000008D",
  EXPORT_REVENUE_REPORT: "6C626154-5065-7265-6D69-73730000008E",
  VIEW_ORDER_REPORT: "6C626154-5065-7265-6D69-73730000008F",
  EXPORT_ORDER_REPORT: "6C626154-5065-7265-6D69-737300000090",
  VIEW_SHIFT_REPORT: "6C626154-5065-7265-6D69-737300000091",
  EXPORT_SHIFT_REPORT: "6C626154-5065-7265-6D69-737300000092",
  VIEW_SOLD_PRODUCT_REPORT: "6C626154-5065-7265-6D69-737300000093",
  EXPORT_SOLD_PRODUCT_REPORT: "6C626154-5065-7265-6D69-737300000094",
  EXPORT_TOP_CUSTOMER_REPORT: "6C626154-5065-7265-6D69-737300000095",
  VIEW_RESERVATION_REPORT: "6C626154-5065-7265-6D69-737300000097",
  EXPORT_RESERVATION_REPORT: "6C626154-5065-7265-6D69-737300000098",
  VIEW_STAFF_REPORT: "6C626154-5065-7265-6D69-737300000099",
  EXPORT_STAFF_REPORT: "6C626154-5065-7265-6D69-737300000100",

  VIEW_RESERVATION: "6C626154-5065-7265-6D69-73730000016A",
  CREATE_RESERVATION: "6C626154-5065-7265-6D69-73730000016B",
  EDIT_RESERVATION: "6C626154-5065-7265-6D69-73730000016C",
  CANCEL_RESERVATION: "6C626154-5065-7265-6D69-73730000016D",

  CREATE_BUY_X_GET_Y: "6C626154-5065-7265-6D69-73730000009C",
  VIEW_BUY_X_GET_Y: "6C626154-5065-7265-6D69-73730000009D",
  STOP_BUY_X_GET_Y: "6C626154-5065-7265-6D69-73730000009E",
  EDIT_BUY_X_GET_Y: "6C626154-5065-7265-6D69-73730000009F",
  DELETE_BUY_X_GET_Y: "6C626154-5065-7265-6D69-7373000000A0",

  SOCIAL_TIKTOK: "6C626154-5065-7265-6D69-737300000101",

  VIEW_INGREDIENT_REMOVE_REQUEST: "6C626154-5065-7265-6D69-737300000102",
  CREATE_INGREDIENT_REMOVE_REQUEST: "6C626154-5065-7265-6D69-737300000103",
  EDIT_INGREDIENT_REMOVE_REQUEST: "6C626154-5065-7265-6D69-737300000104",
  REJECT_INGREDIENT_REMOVE_REQUEST: "6C626154-5065-7265-6D69-737300000105",
  APPROVE_INGREDIENT_REMOVE_REQUEST: "6C626154-5065-7265-6D69-737300000106",
  IMPORT_INGREDIENT_REMOVE_REQUEST: "6C626154-5065-7265-6D69-737300000107",

  VIEW_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000108",
  CREATE_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000109",
  EDIT_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000110",
  DELETE_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000111",
  REJECT_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000112",
  APPROVE_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000113",
  IMPORT_STOCK_UPDATE_REQUEST: "6C626154-5065-7265-6D69-737300000114",

  VIEW_KITCHEN: "6C626154-5065-7265-6D69-7373000000AF",
  CREATE_KITCHEN: "6C626154-5065-7265-6D69-7373000000B0",
  EDIT_KITCHEN: "6C626154-5065-7265-6D69-7373000000B1",
  DELETE_KITCHEN: "6C626154-5065-7265-6D69-7373000000B2",

  VIEW_REVIEW: "6C626154-5065-7265-6D69-7373000000F8",
  SHOW_HIDE_REVIEW: "6C626154-5065-7265-6D69-7373000000F9",

  VIEW_PREPROCESS_MATERIAL: "6C626154-5065-7265-6D69-7373000000CF",
  PREPROCESS_MATERIAL: "6C626154-5065-7265-6D69-7373000000D0",

  VIEW_PROMOTION_REPORT: "6C626154-5065-7265-6D69-73730000016E",
  EXPORT_PROMOTION_REPORT: "6C626154-5065-7265-6D69-73730000016F",
};
