import { Form } from "antd";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import React from "react";
import { useTranslation } from "react-i18next";

export const PreprocessFilterPopover = React.forwardRef((props, ref) => {
  const [t] = useTranslation();
  const { branches, fetchDataPopover = () => {} } = props;

  const [form] = Form.useForm();
  const DEFAULT_VALUE = "";

  const allBranchOption = {
    id: "",
    name: t("material.filter.branch.all"),
  };

  React.useImperativeHandle(ref, () => ({
    /// Export this function to props with name exportFilter and param: data
    clear() {
      clearFilter();
    },
  }));

  const clearFilter = () => {
    form.resetFields();
    fetchDataPopover({});
  };

  const handleOnValueChange = (changedValues, allValues) => {
    fetchDataPopover(allValues);
  };

  return (
    <Form layout="vertical" onValuesChange={handleOnValueChange} form={form}>
      <Form.Item label={t("purchaseOrder.branch")} name="branchId">
        <FnbSelectSingle
          className="form-select"
          showSearch
          defaultValue={DEFAULT_VALUE}
          option={[allBranchOption, ...branches]}
        />
      </Form.Item>
    </Form>
  );
});
