import http, { downloadAsync } from "../../utils/http-common";

const controller = "purchaseOrder";

const createPurchaseOrderAsync = (data) => {
  return http.post(`/${controller}/create-purchase-order`, data);
};

const updatePurchaseOrderByIdAsync = (data) => {
  return http.put(`/${controller}/update-purchase-order-by-id`, data);
};

const cancelPurchaseOrderStatusByIdAsync = (data) => {
  return http.put(`/${controller}/cancel-purchase-order-by-id`, data);
};

const approvePurchaseOrderStatusByIdAsync = (data) => {
  return http.put(`/${controller}/approve-purchase-order-by-id`, data);
};

const orderPurchaseOrderStatusByIdAsync = (data) => {
  return http.put(`/${controller}/order-purchase-order-by-id`, data);
};

const completePurchaseOrderStatusByIdAsync = (data) => {
  return http.put(`/${controller}/complete-purchase-order-by-id`, data);
};

const getAllPurchaseOrderAsync = (pageNumber, pageSize, keySearch, fromDate, toDate, branchId, supplierId, status) => {
  let url = `/${controller}/get-all-purchase-order?pageNumber=${pageNumber}&pageSize=${pageSize}&keySearch=${keySearch}`;
  if (fromDate && toDate) url += `&fromDate=${fromDate}&toDate=${toDate}`;
  if (branchId) url += `&branchId=${branchId}`;
  if (supplierId) url += `&supplierId=${supplierId}`;
  if (status != undefined) url += `&status=${status}`;

  return http.get(url);
};

const getPurchaseOrderByIdAsync = (id) => {
  return http.get(`/${controller}/get-purchase-order-by-id/${id}`);
};

const getPurchaseOrderByMaterialIdAsync = (materialId) => {
  return http.get(`/${controller}/get-purchase-order-by-material-id/${materialId}`);
};

const getPurchaseOrderByBranchIdAsync = (branchId) => {
  return http.get(`/${controller}/get-purchase-order-by-branch-id/${branchId}`);
};

const getPurchaseOrderBySupplierIdAsync = (supplierId) => {
  return http.get(`/${controller}/get-purchase-order-by-supplier-id/${supplierId}`);
};

const getPurchaseOrderPrepareDataAsync = (wareHouseId) => {
  return http.get(`/${controller}/get-purchase-order-prepare-data?wareHouseId=${wareHouseId}`);
};

const downloadImportTemplateAsync = (languageCode) => {
  return downloadAsync(`/${controller}/download-template?languageCode=${languageCode}`);
};

const importFileAsync = (data) => {
  return http.post(`/${controller}/import`, data);
};

const purchaseOrderDataService = {
  createPurchaseOrderAsync,
  getAllPurchaseOrderAsync,
  getPurchaseOrderByIdAsync,
  updatePurchaseOrderByIdAsync,
  getPurchaseOrderByMaterialIdAsync,
  getPurchaseOrderBySupplierIdAsync,
  getPurchaseOrderPrepareDataAsync,
  cancelPurchaseOrderStatusByIdAsync,
  approvePurchaseOrderStatusByIdAsync,
  orderPurchaseOrderStatusByIdAsync,
  completePurchaseOrderStatusByIdAsync,
  getPurchaseOrderByBranchIdAsync,
  downloadImportTemplateAsync,
  importFileAsync,
};
export default purchaseOrderDataService;
