import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { executeAfter, hasPermission, formatDate } from "utils/helpers";
import { DateFormat } from "constants/string.constants";
import branchDataService from "data-services/branch/branch-data.service";
import { PreprocessFilterPopover } from "./components/preprocess-filter-popover.component";
import { Card, Form, Row } from "antd";
import { FnbTable } from "components/fnb-table/fnb-table";
import { PermissionKeys } from "constants/permission-key.constants";
import { FnbButton } from "components/fnb-button/fnb-button";
import { PreProcessIcon } from "constants/icons.constants";
import { FnbDatePicker } from "components/fnb-date-picker/fnb-data-picker";
import theme from "theme";
import defaultUserIcon from "assets/images/default-user.png";
import preprocessMaterialDataService from "data-services/material-control/preprocess-material-data.service";
import './preprocess-material.page.scss';

const TablePreProcessMaterial = () => {
  const history = useHistory();
  const [t] = useTranslation();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [numberRecordCurrent, setNumberRecordCurrent] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const filterPopoverRef = useRef();
  const [branches, setBranches] = useState([]);
  const [dataFilterAndSearch, setDataFilterAndSearch] = useState({
    keySearch: "",
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
    businessSummaryWidgetFilter: 0,
    branchId: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const pageData = {
    btnFilter: t("button.filter"),
    btnPreProcess: t("button.preProcess"),
    table: {
      id: t("table.id"),
      branch: t("purchaseOrder.branch"),
      materials: t("materialControl.preProcessMaterial.numberOfMaterial"),
      createdBy: t("table.createdBy"),
      createdTime: t("menu.inventoryManagement.createdTime"),
    },
    today: t("optionDatetime.today"),
    date: {
      yesterday: "dashboard.compareDate.yesterday",
      previousDay: "dashboard.compareDate.previousDay",
      lastWeek: "dashboard.compareDate.lastWeek",
      previousWeek: "dashboard.compareDate.previousWeek",
      lastMonth: "dashboard.compareDate.lastMonth",
      previousMonth: "dashboard.compareDate.previousMonth",
      lastYear: "dashboard.compareDate.lastYear",
      previousSession: t("dashboard.compareDate.previousSession"),
    },
    yesterday: "dashboard.compareDate.yesterday",
    searchPlaceholder: t("materialControl.preProcessMaterial.searchPlaceholder"),
    ignore: t("button.deletePageIgnore"),
    toCreateData: t("area.toCreateData"),
  };

  const tableSettings = {
    pageSize: 20,
    columns: [
      {
        title: pageData.table.id,
        key: "code",
        width: "9rem",
        align: "left",
        render: (_, record) => {
          return <FnbTooltip onlyShowWhenEllipsis={true}>{record?.code}</FnbTooltip>;
        },
      },
      {
        title: pageData.table.branch,
        key: "branch",
        width: "36%",
        className: "table-text-branch-overflow",
        ellipsis: true,
        align: "left",
        render: (_, record) => {
          return (
            <FnbTooltip onlyShowWhenEllipsis={true} title={record?.branchName}>
              <span className={'branch-name'}>{record?.branchName}</span><br/>
              <span className={'branch-address'}>{record?.branchAddress}</span>
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.materials,
        key: "materials",
        width: "15%",
        align: "center",
        render: (_, record) => {
          return (
            <FnbTooltip
              variant="secondary"
              title={
                <ul style={{ paddingLeft: 14, margin: "auto" }}>
                  {record?.materials?.map((item) => {
                    return <li>{item?.materialName}</li>;
                  })}
                </ul>
              }
              maxWidth={'400'}
              hideTooltip={!record?.materials?.length || record?.materials?.length <= 0}
            >
              <FnbTypography
                color={theme.colors.primary[100]}
                text={record?.materials?.length ?? 0}
                variant={theme.typography["b1-bold"]}
                textDecoration={'underline'}
              />
            </FnbTooltip>
          );
        },
      },
      {
        title: pageData.table.createdBy,
        key: "createdBy",
        width: "15%",
        ellipsis: true,
        align: "left",
        className: "table-text-createdBy-overflow",
        render: (_, record) => {
          return (
            <div className="column-product-name">
              <img src={record?.thumbnail || defaultUserIcon} alt={record?.createdBy} />
              <FnbTooltip title={record?.createdBy} onlyShowWhenEllipsis maxWidthContent="95%">
                <span>{record?.createdBy}</span>
              </FnbTooltip>
            </div>
          );
        },
      },
      {
        title: pageData.table.createdTime,
        key: "createdTime",
        width: "15%",
        align: "left",
        render: (_, record) => {
          return formatDate(record?.createdTime, DateFormat.HH_mm_DD_MMM_YYYY);
        },
      },
    ],
    onChangePage: async (page, pageSize) => {
      await fetchDatableAsync({
        pageNumber: page,
        pageSize: pageSize,
        keySearch: dataFilterAndSearch.keySearch ?? "",
        startDate: dataFilterAndSearch.startDate,
        endDate: dataFilterAndSearch.endDate,
        businessSummaryWidgetFilter: dataFilterAndSearch.businessSummaryWidgetFilter,
        branchId: dataFilterAndSearch.branchId,
      });
    },
    onSearch: async (keySearch) => {
      await executeAfter(500, async () => {
        setDataFilterAndSearch({ ...dataFilterAndSearch, keySearch: keySearch });
        await fetchDatableAsync({
          pageNumber: 1,
          pageSize: tableSettings.pageSize,
          keySearch: keySearch,
          startDate: dataFilterAndSearch.startDate,
          endDate: dataFilterAndSearch.endDate,
          businessSummaryWidgetFilter: dataFilterAndSearch.businessSummaryWidgetFilter,
          branchId: dataFilterAndSearch.branchId,
        });
      });
    },
  };

  useEffect(() => {
    void fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: "",
      startDate: moment().toDate().toLocaleDateString("en-US"),
      endDate: moment().toDate().toLocaleDateString("en-US"),
      businessSummaryWidgetFilter: 0,
    });
    void initBranchDataFilter();
  }, []);

  const initBranchDataFilter = async () => {
    const resBranch = await branchDataService.getAllBranchsAsync();
    if (resBranch) {
      setBranches(resBranch?.branchs);
    }
  };

  const fetchDatableAsync = async (fetchingProps) => {
    const { pageNumber, pageSize, keySearch, startDate, endDate, businessSummaryWidgetFilter, branchId } = fetchingProps;
    const response = await preprocessMaterialDataService.getAllPreprocessRequestAsync(
      pageNumber,
      pageSize,
      keySearch,
      startDate,
      endDate,
      businessSummaryWidgetFilter,
      branchId,
    );
    if (response) {
      const data = response?.preProcessMaterials?.map(s => mappingRecordToColumns(s));
      setTotalRecords(response?.total);
      setCurrentPageNumber(response?.pageNumber);
      setDataSource(data ?? []);

      let numberRecordCurrent = pageNumber * pageSize;
      if (numberRecordCurrent > response?.total) {
        numberRecordCurrent = response?.total;
      }
      setNumberRecordCurrent(numberRecordCurrent);
    }
  };

  const mappingRecordToColumns = (item) => {
    return {
      id: item?.id,
      code: item?.stringCode,
      branchName: item?.branchName,
      branchAddress: item?.branchAddress,
      materials: item?.materials,
      createdTime: item?.createdTime,
      createdBy: item?.createdBy,
      thumbnail: item?.thumbnail,
    };
  };

  const getTableColumns = () => {
    return tableSettings.columns;
  };

  const onClearFilter = (e) => {
    if (filterPopoverRef && filterPopoverRef.current) {
      filterPopoverRef.current?.clear();
    }
    setCountFilter(0);
    setDataFilterAndSearch({
      ...dataFilterAndSearch,
      businessSummaryWidgetFilter: 0,
      branchId: "",
    });
  };

  const handleFilter = (data, isReloadCountFilter = true) => {
    if (data === null || data === undefined) return;

    setCurrentPageNumber(1);
    if (isReloadCountFilter) {
      const countFilter = Object.values(data).reduce((accumulator, currentValue) => {
        // Count except for string undefined, '' or status = 0
        if (currentValue || currentValue === 0) return ++accumulator;
        return accumulator;
      }, 0);
      setCountFilter(countFilter);
    }
    setDataFilterAndSearch({ ...dataFilterAndSearch, ...data });
    void fetchDatableAsync({
      pageNumber: 1,
      pageSize: tableSettings.pageSize,
      keySearch: dataFilterAndSearch.keySearch || "",
      startDate: dataFilterAndSearch.startDate,
      endDate: dataFilterAndSearch.endDate,
      businessSummaryWidgetFilter: dataFilterAndSearch.businessSummaryWidgetFilter,
      branchId: data.branchId,
    });
  };

  const filterComponent = () => {
    return <PreprocessFilterPopover fetchDataPopover={handleFilter} ref={filterPopoverRef} branches={branches} />;
  };

  const onRowClick = (record, _index) => {
    history.push(`/inventory/material-control/pre-process-material/${record?.id}`);
  };

  const onPreProcess = () => {
    history.push('./material-control/pre-process-ingredients');
  };

  const onSelectedRowKeysChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const onSelectedDatePicker = async (date, typeOptionDate) => {
    const newDataFilter = {
      ...dataFilterAndSearch,
      startDate: date?.startDate,
      endDate: date?.endDate,
      businessSummaryWidgetFilter: typeOptionDate,
    };
    setDataFilterAndSearch(newDataFilter);
    void fetchDatableAsync({
      pageNumber: currentPageNumber,
      pageSize: tableSettings.pageSize,
      keySearch: dataFilterAndSearch.keySearch || "",
      startDate: date?.startDate,
      endDate: date?.endDate,
      businessSummaryWidgetFilter: typeOptionDate,
      branchId: dataFilterAndSearch.branchId,
    });
  };

  return (
    <>
      <Form className="form-staff">
        <Card className="fnb-card-custom w-100">
          <Row className="form-staff">
            <FnbTable
              className="table-striped-rows table-preprocess-material"
              dataSource={dataSource}
              columns={getTableColumns()}
              pageSize={tableSettings.pageSize}
              currentPageNumber={currentPageNumber}
              total={totalRecords}
              onChangePage={tableSettings.onChangePage}
              numberRecordCurrent={numberRecordCurrent}
              scrollX={1500}
              search={{
                placeholder: pageData.searchPlaceholder,
                onChange: tableSettings.onSearch,
                maxLength: 100,
              }}
              rowSelection={{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectedRowKeysChange,
                renderActions: (_selectedRowKeys, selectedRows) => <></>,
              }}
              filter={{
                totalFilterSelected: countFilter,
                onClearFilter: onClearFilter,
                buttonTitle: pageData.btnFilter,
                component: filterComponent(),
              }}
              filterPlacement={"bottomLeft"}
              onRowClick={onRowClick}
              listButtons={[
                <FnbButton
                  permission={PermissionKeys.PREPROCESS_MATERIAL}
                  onClick={onPreProcess}
                  text={pageData.btnPreProcess}
                  iconHeader={<PreProcessIcon />}
                  className="fnb-heading-page__button-create"
                />,
                <FnbDatePicker
                  selectedDate={dataFilterAndSearch}
                  setSelectedDate={(date, typeOptionDate) => onSelectedDatePicker(date, typeOptionDate)}
                  customeStyle={{ border: '1px solid #D8DAE1' }}
                />,
              ]}
              emptyText={
                hasPermission(PermissionKeys.PREPROCESS_MATERIAL) && (
                  <div className="content-collapse-empty-text">
                    <FnbTypography.Link
                      variant={theme.typography["b1-bold-underlined"]}
                      underline={true}
                      text={pageData.btnPreProcess}
                      onClick={onPreProcess}
                    />
                    {pageData.toCreateData}
                  </div>
                )
              }
            />
          </Row>
        </Card>
      </Form>
    </>
  );
};

export default TablePreProcessMaterial;