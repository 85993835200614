import { FnbModal } from "components/fnb-modal/fnb-modal-component";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTypography from "components/fnb-typography/fnb-typography";
import { OptionDateTime } from "constants/option-date.constants";
import promotionCampaignDataService from "data-services/promotion-campaign/promotion-campaign.service";
import moment from "moment/moment";
import { forwardRef, useImperativeHandle, useState } from "react";
import { executeAfter, formatNumberDecimalOrInteger, getCurrency } from "utils/helpers";
import "./campaign-usage-detail-customer.component.scss";
import { images } from "constants/images.constants";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { InfoCircleBasicIcon } from "constants/icons.constants";
import { guidIdEmptyValue } from "constants/string.constants";

const CampaignDetailCustomerListComponent = forwardRef((props, ref) => {
  const DEFAULT_PAGE_NUMBER = 1;
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_DATE = moment().toDate().toLocaleDateString("en-US");
  const DEFAULT_END_DATE = moment().toDate().toLocaleDateString("en-US");
  const { t, isShowCampaignDetailCustomerList, handleCancel } = props;
  const [typeOptionDate, setTypeOptionDate] = useState(OptionDateTime.allTime);
  const [keySearch, setKeySearch] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    startDate: moment().toDate().toLocaleDateString("en-US"),
    endDate: moment().toDate().toLocaleDateString("en-US"),
  });
  const [promotionCampaignName, setPromotionCampaignName] = useState("-");
  const [totalCustomers, setTotalCustomers] = useState("-");
  const [totalRevenue, setTotalRevenue] = useState("-");
  const [totalCampaignDiscount, setTotalCampaignDiscount] = useState("-");
  const [currentPageNumber, setCurrentPageNumber] = useState(DEFAULT_PAGE_NUMBER);

  const [promotionCampaignId, setPromotionCampaignId] = useState("");
  const [discountCodeId, setDiscountCodeId] = useState("");
  const [flashSaleId, setFlashSaleId] = useState("");
  const [branchId, setBranchId] = useState();

  const pageData = {
    campaignDetailTitle: t("report.promotion.campaignDetailTitleCustomer"),
    createdTime: t("report.promotion.createdTime"),
    orderId: t("report.promotion.orderId"),
    detail: t("report.promotion.detail"),
    total: t("report.promotion.total"),
    campaignDiscount: t("report.promotion.campaignDiscount"),
    customer: t("report.promotion.customer"),
    placeholderSearch: t("report.promotion.searchCustomerNamePhone"),
    campaign: t("report.promotion.campaign"),
    totalCustomers: t("report.promotion.totalCustomersInDetail"),
    totalRevenue: t("report.promotion.totalRevenue"),
    totalDiscount: t("report.promotion.totalDiscount"),
    subTotal: t("report.promotion.subTotal"),
    discount: t("report.promotion.discount"),
    taxAndFee: t("report.promotion.taxAndFee"),
    status: t("table.status"),
    customerName: t("order.customerName"),
    rank: t("order.rank"),
    orders: t("order.orders"),
    spendAmount: t("report.promotion.spendAmount"),
    ordersGuide: t("report.promotion.ordersGuide"),
    spendAmountGuide: t("report.promotion.spendAmountGuide"),
    campaignDiscountGuide: t("report.promotion.campaignDiscountGuide"),
    totalOrder: t("report.promotion.customerTotalOrder"),
  };

  useImperativeHandle(ref, () => ({
    initData: (promotionCampaignId, discountCodeId, flashSaleId, reportBranchId) => {
      setTypeOptionDate(OptionDateTime.allTime);
      setPromotionCampaignId(promotionCampaignId);
      setDiscountCodeId(discountCodeId);
      setFlashSaleId(flashSaleId);
      setBranchId(reportBranchId ?? guidIdEmptyValue);
    },
  }));
  const getCampaignDetailCustomerData = async (
    pageNumber,
    pageSize,
    inputKeySearch,
    selectedTypeOptionDate,
    startDate,
    endDate,
    reportPromotionCampaignId,
    reportDiscountCodeId,
    reportFlashSaleId,
    reportBranchId,
  ) => {
    await promotionCampaignDataService
      .getPromotionCampaignDetailCustomerListAsync(
        pageNumber,
        pageSize,
        inputKeySearch ?? keySearch,
        reportPromotionCampaignId ?? promotionCampaignId ?? "",
        reportFlashSaleId ?? flashSaleId ?? "",
        reportDiscountCodeId ?? discountCodeId ?? "",
        startDate && startDate !== "" && startDate !== undefined
          ? startDate
          : selectedDate.startDate ?? DEFAULT_START_DATE,
        endDate && endDate !== "" && endDate !== undefined ? endDate : selectedDate.endDate ?? DEFAULT_END_DATE,
        selectedTypeOptionDate !== "" && selectedTypeOptionDate !== undefined ? selectedTypeOptionDate : typeOptionDate,
        reportBranchId ?? branchId ?? guidIdEmptyValue,
      )
      .then((res) => {
        if (res) {
          setDataSource(res.promotionCampaignDetailCustomerLists);
          setPromotionCampaignName(res.promotionCampaignName);
          setTotalCustomers(res.totalCustomers);
          setTotalRevenue(formatNumberDecimalOrInteger(res.totalRevenue));
          setTotalCampaignDiscount(formatNumberDecimalOrInteger(res.totalCampaignDiscount));
        }
      });
  };

  const onSearch = async (keySearch = "") => {
    executeAfter(500, async () => {
      setKeySearch(keySearch);
      setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
      getCampaignDetailCustomerData(DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, keySearch, typeOptionDate);
    });
  };

  const onChangePage = async (pageNumber, pageSize) => {
    getCampaignDetailCustomerData(pageNumber, pageSize);
    setCurrentPageNumber(pageNumber);
  };

  const onSelectedDatePicker = (date, selectedTypeOptionDate) => {
    setSelectedDate({
      startDate: date.startDate === "" ? DEFAULT_START_DATE : date.startDate,
      endDate: date.endDate === "" ? DEFAULT_END_DATE : date.endDate,
    });
    setTypeOptionDate(selectedTypeOptionDate);
    setCurrentPageNumber(DEFAULT_PAGE_NUMBER);
    getCampaignDetailCustomerData(
      DEFAULT_PAGE_NUMBER,
      DEFAULT_PAGE_SIZE,
      keySearch,
      selectedTypeOptionDate ?? typeOptionDate,
      date.startDate === "" ? DEFAULT_START_DATE : date.startDate,
      date.endDate === "" ? DEFAULT_END_DATE : date.endDate,
    );
  };

  const tableConfigs = {
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_PAGE_NUMBER,
    columns: [
      {
        title: pageData.customer,
        dataIndex: "customerName",
        width: "30%",
        align: "left",
        render: (_, record) => (
          <div className="customer-info">
            <img src={record?.customerThumbnail || images.imgDefault} />
            <div>
              <FnbTypography.Link
                to={`/customer/detail/${record?.customerId}`}
                target="_blank"
                underline={true}
                text={record.customerName}
              />
              <FnbTypography text={record?.customerPhone} />
            </div>
          </div>
        ),
      },
      {
        title: pageData.rank,
        dataIndex: "customerRank",
        width: "18%",
        align: "left",
      },
      {
        title: (
          <div className="custom-table-header">
            {pageData.totalOrder}
            <FnbTooltip maxWidth={"321px"} variant="secondary" placement="bottom" title={pageData.ordersGuide}>
              <InfoCircleBasicIcon />
            </FnbTooltip>
          </div>
        ),
        dataIndex: "totalOrders",
        align: "right",
        width: "12%",
      },
      {
        title: (
          <div className="custom-table-header">
            {`${pageData.spendAmount} (${getCurrency()})`}
            <FnbTooltip maxWidth={"321px"} variant="secondary" placement="bottom" title={pageData.spendAmountGuide}>
              <InfoCircleBasicIcon />
            </FnbTooltip>
          </div>
        ),
        dataIndex: "spendAmount",
        align: "right",
        width: "18%",
        render: (value) => <FnbTypography text={formatNumberDecimalOrInteger(value)} />,
      },
      {
        title: (
          <div className="custom-table-header">
            {`${pageData.campaignDiscount} (${getCurrency()})`}
            <FnbTooltip
              maxWidth={"321px"}
              variant="secondary"
              placement="bottom"
              title={pageData.campaignDiscountGuide}
            >
              <InfoCircleBasicIcon />
            </FnbTooltip>
          </div>
        ),
        sorter: (pre, current) => parseInt(pre?.spendAmount) - parseInt(current?.spendAmount),
        dataIndex: "campaignDiscount",
        align: "right",
        render: (value) => <FnbTypography text={formatNumberDecimalOrInteger(value)} />,
      },
    ],
    onSearch,
    onChangePage,
  };

  const renderHeaderExtendInformation = () => {
    return (
      <>
        <div className="extend-information">
          <div className="campaign-name">
            <FnbTypography text={pageData.campaign} />:{" "}
            <FnbTypography className="highlight-info" text={promotionCampaignName} />
          </div>
          <div className="other-information">
            <div>
              <FnbTypography text={pageData.totalCustomers} />:{" "}
              <FnbTypography className="highlight-info" text={totalCustomers} />
            </div>
            <div>
              <FnbTypography text={pageData.totalRevenue} />:{" "}
              <FnbTypography className="highlight-info" text={`${totalRevenue} ${getCurrency()}`} />
            </div>
            <div>
              <FnbTypography text={pageData.totalDiscount} />:{" "}
              <FnbTypography className="highlight-info" text={`${totalCampaignDiscount} ${getCurrency()}`} />
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <FnbTable
          dataSource={dataSource}
          className="campaign-detail-customer-list-table"
          columns={tableConfigs.columns}
          pageSize={tableConfigs.pageSize}
          currentPageNumber={currentPageNumber}
          onChangePage={tableConfigs.onChangePage}
          search={{
            maxLength: 100,
            placeholder: pageData.placeholderSearch,
            onChange: tableConfigs.onSearch,
          }}
          calendarComponent={{
            onSelectedDatePicker: onSelectedDatePicker,
            selectedDate: selectedDate,
            orderTypeFilterTime: typeOptionDate,
            containAllTime: true,
          }}
          isRenderExtendHeaderInformation={true}
          headerExtendInformation={renderHeaderExtendInformation()}
          scrollX={1200}
          scrollY={484}
          total={totalCustomers}
        />
      </>
    );
  };

  return (
    <FnbModal
      width={"1402px"}
      className="campaign-detail-customer-list"
      title={pageData.campaignDetailTitle}
      visible={isShowCampaignDetailCustomerList}
      handleCancel={() => {
        handleCancel();
        setCurrentPageNumber(1);
        setTypeOptionDate(OptionDateTime.allTime);
        setDataSource([]);
        setPromotionCampaignName("-");
        setTotalCustomers("-");
        setTotalRevenue("-");
        setTotalCampaignDiscount("-");
      }}
      content={renderContent()}
      footer={""}
      closable={true}
      isDestroyOnClose={true}
    />
  );
});
export default CampaignDetailCustomerListComponent;
