import { Col, Form, message, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { FnbAddNewButton } from "components/fnb-add-new-button/fnb-add-new-button";
import FnbCard from "components/fnb-card/fnb-card.component";
import ConfirmDialogComponent from "components/fnb-confirm-dialog/confirm-dialog.component";
import { FnbInput, TYPE_INPUT } from "components/fnb-input/fnb-input.component";
import { FnbSelectSingle } from "components/fnb-select-single/fnb-select-single";
import { FnbTable } from "components/fnb-table/fnb-table";
import FnbTooltip from "components/fnb-tooltip/fnb-tooltip";
import { InputValidateMessage } from "components/input-validate-message/input-validate-message";
import { Thumbnail } from "components/thumbnail/thumbnail";
import { CustomTrashIcon, ImageMaterialDefault, SearchIcon } from "constants/icons.constants";
import { PermissionKeys } from "constants/permission-key.constants";
import branchDataService from "data-services/branch/branch-data.service";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  capitalizeFirstLetterEachWord,
  formatNumberDecimalOrInteger,
  isDecimalKey,
  parserDecimalNumber,
  roundNumber,
} from "utils/helpers";
import { KEY_TAB_PANE } from "../../table-inventory-control.component";
import { IngredientSearchModal } from "../../../../../components/ingredient-search-modal/ingredient-search-modal";
import FnbHeadingPage from "components/fnb-heading-page/fnb-heading-page";
import { FnbCancelButton } from "components/cancel-button";
import i18n from "utils/i18n";
import theme from "theme";
import FnbTypography from "components/fnb-typography/fnb-typography";
import FnbInputNumber from "components/fnb-input/fnb-input-number";
import ingredientUpdateStockRequestDataService from "data-services/material-control/ingredient-update-stock-request.service";
import { MaximumNumber, MinimumNumber } from "constants/default.constants";
import { FnbTextArea } from "components/fnb-text-area/fnb-text-area.component";
import { EnumUpdateStockReasonType } from "constants/update-stock-request.constants";
import { useMediaQuery } from "react-responsive";
import "./create-update-stock-request.scss";
import { useModifiedBranchLocalStorage } from "hooks/useModifiedDataLocalStorage";
import FnbSelectAddNewItem from "components/fnb-select/fnb-select-add-new-item.component";

export default function CreateUpdateStockRequest(props) {
  const { t } = useTranslation();
  const { needToReloadBranches, updateLastTimeGetBranches } = useModifiedBranchLocalStorage();

  const pageData = {
    btnCancel: t("button.cancel"),
    btnSave: t("button.save"),
    okText: t("button.ok"),
    leaveForm: t("messages.leaveForm"),
    createTransferMaterialSuccess: t("messages.createTransferMaterialSuccess"),
    updateTransferMaterialSuccess: t("messages.updateTransferMaterialSuccess"),
    updateStockRequest: t("materialControl.updateStockRequest.createRequest.requestUpdate"),
    searchByNameSku: t("materialControl.updateStockRequest.createRequest.searchByNameSku"),
    branchOrWarehouse: t("materialControl.updateStockRequest.createRequest.branch"),
    placeholderBranch: t("materialControl.updateStockRequest.createRequest.pleaseSelectBranch"),
    reasonForRequest: t("materialControl.updateStockRequest.createRequest.reasonForRequest"),
    ingredientInformation: t("materialControl.updateStockRequest.createRequest.ingredientInfo"),
    selectApplicableReason: t("materialControl.updateStockRequest.createRequest.selectApplicableReason"),
    pleaseSelectReason: t("materialControl.updateStockRequest.createRequest.pleaseSelectReason"),
    placeholderDescription: t("materialControl.updateStockRequest.createRequest.placeholderDescription"),
    description: t("materialControl.updateStockRequest.createRequest.description"),
    selectBranch: t("materialControl.updateStockRequest.createRequest.selectBranch"),
    pleaseSelectBranch: t("materialControl.updateStockRequest.createRequest.pleaseSelectBranch"),
    supplier: {
      title: t("supplier.title"),
      selectSupplier: t("supplier.selectSupplier"),
      pleaseSelectSupplier: t("supplier.pleaseSelectSupplier"),
    },
    table: {
      no: t("table.no"),
      sku: t("table.sku"),
      ingredients: t("materialControl.updateStockRequest.createRequest.ingredients"),
      inventoryQuantity: t("materialControl.updateStockRequest.createRequest.inventoryQuantity"),
      realityQuantity: t("materialControl.updateStockRequest.createRequest.realityQuantity"),
      reason: t("materialControl.updateStockRequest.createRequest.reason"),
      available: t("materialControl.updateStockRequest.createRequest.available"),
    },
    btnCreate: t("button.create"),
    btnConfirmLeave: t("button.confirmLeave"),
    leaveWarningMessage: t("messages.leaveForm"),
    messages: {
      createdSuccess: t("combo.messages.createdSuccess"),
    },
    discardBtn: t("button.discard"),
    leaveDialog: {
      confirmation: t("leaveDialog.confirmation"),
      content: t("messages.leaveForm"),
    },
    generalInformation: t("purchaseOrder.generalInformation"),
    createdSuccessfully: t("materialControl.updateStockRequest.createRequest.createdSuccessfully"),
    errQuantity: t("materialControl.updateStockRequest.createRequest.errQuantity"),
    errIngredient: t("materialControl.updateStockRequest.createRequest.errIngredient"),
    hoverTotal: t("materialControl.updateStockRequest.createRequest.hoverTotal"),
    hoverRealityQuantity: t("materialControl.updateStockRequest.createRequest.hoverRealityQuantity"),
    createRequest: t("materialControl.updateStockRequest.createRequest.createRequest"),
  };

  const optionsReasonForRequest = [
    {
      id: EnumUpdateStockReasonType.SHRINKAGE,
      name: t("materialControl.updateStockRequest.createRequest.shrinkage"),
    },
    {
      id: EnumUpdateStockReasonType.MISPLACEMENT,
      name: t("materialControl.updateStockRequest.createRequest.misplacement"),
    },
    {
      id: EnumUpdateStockReasonType.BACKFLUSHING,
      name: t("materialControl.updateStockRequest.createRequest.backflushing"),
    },
    {
      id: EnumUpdateStockReasonType.OTHERS,
      name: t("materialControl.updateStockRequest.createRequest.others"),
    },
  ];

  const [form] = useForm();
  const history = useHistory();
  const [visibleModalLeaveConfirm, setVisibleModelLeaveConfirm] = useState(false);
  const [branches, setBranches] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [listSelectedMaterials, setListSelectedMaterials] = useState([]);
  const [isChangeForm, setIsChangeForm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const isMaxWidth1600 = useMediaQuery({ maxWidth: 1600 });

  const getBranches = async () => {
    const branchesResponse = await branchDataService.getAllBranchsAsync();
    if (branchesResponse) {
      setBranches(branchesResponse?.branchs);
      updateLastTimeGetBranches();
    }
  };

  useEffect(() => {
    if (needToReloadBranches) {
      getBranches();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReloadBranches]);

  const onAddNewBranch = (label) => {
    window.open(`/branch/create-new?name=${label}`, "_blank");
  };

  const leaveOnCancel = () => {
    setVisibleModelLeaveConfirm(false);
  };

  const leaveOnOk = () => {};

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    const errorFields = form
      .getFieldsError()
      // eslint-disable-next-line no-sequences
      .reduce((arr, field) => (field?.errors?.length && arr?.push(field?.name), arr), []);
    form.validateFields(errorFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const element = document.querySelector("#main-body-id");
    if (element) element.style.paddingTop = "0";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const handleChangeRealityQuantity = (value, index) => {
    listSelectedMaterials[index].realityQuantity = value ?? "";
    setListSelectedMaterials([...listSelectedMaterials]);

    let formValue = form.getFieldValue();
    let selectedMaterials = [];
    listSelectedMaterials?.forEach((material) => {
      selectedMaterials.push({ realityQuantity: material?.realityQuantity });
    });
    formValue.updateRequest.selectedMaterials = selectedMaterials;
    form.validateFields([["updateRequest", "selectedMaterials", index, "realityQuantity"]]);
  };

  const handleChangeBranch = (value) => {
    form.setFieldValue("branchId", value);
    form.resetFields([["updateRequest", "selectedMaterials"]]);
    setListSelectedMaterials([]);
  };

  const handleChangeUnit = (unitId, material, listUnit, index) => {
    if (unitId === material?.unitId) {
      listSelectedMaterials[index].currentUnitName = null;
      listSelectedMaterials[index].currentUnitId = unitId;
      listSelectedMaterials[index].quantity = material?.inventoryQuantity ?? 0;
    } else {
      let unitConversion = listUnit?.find((u) => u?.id === unitId);
      listSelectedMaterials[index].currentUnitName = unitConversion?.name;
      listSelectedMaterials[index].currentUnitId = unitId;
      if (unitConversion?.capacity && unitConversion?.capacity !== 0) {
        listSelectedMaterials[index].quantity = (material?.inventoryQuantity ?? 0) / unitConversion?.capacity;
      } else {
        listSelectedMaterials[index].quantity = 0;
      }
    }
    setListSelectedMaterials([...listSelectedMaterials]);
  };

  const onRemoveMaterial = (materialId, index) => {
    let newListSelected = listSelectedMaterials?.filter((m) => m?.materialId !== materialId);
    setListSelectedMaterials([...newListSelected]);

    let formValue = form.getFieldValue();
    let selectedMaterials = [];
    newListSelected?.forEach((material) => {
      selectedMaterials.push({ realityQuantity: material?.realityQuantity });
    });
    formValue.updateRequest.selectedMaterials = selectedMaterials;
    form.validateFields([["updateRequest", "selectedMaterials", index, "realityQuantity"]]);
  };

  const handleChangeReasonType = (option) => {
    form.setFieldValue("reasonType", option);
  };

  const changeForm = (e) => {
    if (e?.length > 0) {
      setIsChangeForm(true);
      setIsDisabled(false);
    }
  };

  const navigateToManagementPage = () => {
    setIsChangeForm(false);
    setTimeout(() => {
      history.push({
        pathname: "/inventory/transfer-control",
        search: `?tabActive=${KEY_TAB_PANE.VIEW_BY_UPDATE_STOCK}`,
      });
    }, 200);
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(async () => {
        if (isSubmit) return;
        setIsSubmit(true);
        setIsChangeForm(true);
        setIsDisabled(true);
        if (listSelectedMaterials?.length === 0) return;
        let listIngredients = listSelectedMaterials.map((material) => {
          return {
            materialId: material?.materialId,
            unitId: material?.currentUnitId ?? material?.unitId,
            inventoryQuantity: roundNumber(material?.quantity, 2),
            quantity: roundNumber(material?.realityQuantity, 2),
          };
        });
        let request = {
          branchId: form.getFieldValue("branchId"),
          reasonType: form.getFieldValue("reasonType"),
          description: form.getFieldValue("description") || "",
          ingredients: listIngredients,
        };
        await ingredientUpdateStockRequestDataService
          .createUpdateStockRequest(request)
          .then((response) => {
            if (response?.isSuccess) {
              message.success(pageData.createdSuccessfully);
              navigateToManagementPage();
            }
          })
          .catch((ex) => {
            setIsChangeForm(false);
            setIsSubmit(false);
          });
      })
      .catch(async ({ errorFields }) => {
        if (errorFields && errorFields?.length > 0) {
          form.scrollToField(errorFields?.[0]?.name, { behavior: "smooth", block: "center" });
        }
      });
  };

  const columnsMaterial = () => {
    let columns = [
      {
        title: pageData.table.no,
        dataIndex: "no",
        align: "center",
        width: "5%",
        ellipsis: true,
        render: (_, record, index) => <FnbTypography style={{ textAlign: "center" }} text={index + 1}></FnbTypography>,
      },
      {
        title: pageData.table.ingredients,
        dataIndex: "ingredients",
        width: "27%",
        align: "left",
        editable: true,
        render: (_, record, index) => {
          return (
            <Col style={{ flex: 1 }}>
              <Row style={{ gap: 12 }}>
                {record?.thumbnail ? (
                  <Thumbnail width={52} height={52} src={record?.thumbnail} />
                ) : (
                  <ImageMaterialDefault width={52} height={52} />
                )}
                <Col style={{ alignSelf: "center", flex: 1, gap: 6, maxWidth: "50%" }}>
                  <FnbTooltip title={record?.name} onlyShowWhenEllipsis></FnbTooltip>
                  {record?.sku && (
                    <Row>
                      <FnbTypography
                        text={`SKU: ${record?.sku}`}
                        style={{ fontWeight: 400, fontSize: 14 }}
                        color={theme.colors.gray[70]}
                      />
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          );
        },
      },
      {
        title: capitalizeFirstLetterEachWord(pageData.table.inventoryQuantity),
        dataIndex: "inventoryQuantity",
        align: "left",
        width: "18%",
        render: (_, record, index) => {
          return (
            <Row>
              <Col>{`${formatNumberDecimalOrInteger(roundNumber(record?.quantity, 2))} ${
                record?.currentUnitName ? record?.currentUnitName : record?.unitName
              }`}</Col>
            </Row>
          );
        },
        tooltip: true,
        contentTooltip: parse(pageData.hoverTotal),
      },
      {
        title: capitalizeFirstLetterEachWord(pageData.table.realityQuantity),
        dataIndex: "realityQuantity",
        align: "left",
        width: "25%",
        render: (_, record, index) => {
          let mapUnitConversions = record?.unitConversion?.map((unit) => {
            return {
              id: unit?.unitId,
              name: unit?.unitName,
              capacity: unit?.capacity,
            };
          });

          let unitConversion = [{ id: record?.unitId, name: record?.unitName }, ...mapUnitConversions];

          return (
            <div className="error-select-quantity">
              <Row>
                <Col span={10} className="pr-1">
                  <Form.Item
                    rules={[
                      {
                        required:
                          record?.realityQuantity === undefined ||
                          record?.realityQuantity === 0 ||
                          record?.realityQuantity === "",
                        message: <InputValidateMessage id={"please-select-input"} message={pageData.errQuantity} />,
                      },
                    ]}
                    name={["updateRequest", "selectedMaterials", index, "realityQuantity"]}
                  >
                    <FnbInputNumber
                      min={MinimumNumber}
                      max={MaximumNumber}
                      value={record?.realityQuantity}
                      onChange={(value) => {
                        handleChangeRealityQuantity(value, index);
                      }}
                      showWrap={true}
                      formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      parser={(value) => parserDecimalNumber(value, true)}
                      onKeyPress={(event) => {
                        if (!isDecimalKey(event)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={14} className="pl-1">
                  <FnbSelectSingle
                    value={record?.currentUnitId ?? unitConversion[0].id}
                    option={unitConversion}
                    onChange={(unitId) => {
                      handleChangeUnit(unitId, record, unitConversion, index);
                    }}
                  />
                </Col>
              </Row>
            </div>
          );
        },
        tooltip: true,
        contentTooltip: parse(pageData.hoverRealityQuantity),
      },
      {
        title: "",
        dataIndex: "action",
        align: "center",
        width: "5%",
        render: (_, record, index) => (
          <div
            className="icon-delete-price pt-3"
            onClick={() => {
              onRemoveMaterial(record?.materialId, index);
            }}
          >
            <CustomTrashIcon color={theme.colors.primary[100]} />
          </div>
        ),
      },
    ];
    return columns;
  };

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFieldsChange={(e) => changeForm(e)}
        autoComplete="off"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 22,
        }}
        className="create-update-stock-request-form custom-form"
        layout="vertical"
      >
        <FnbHeadingPage
          title={pageData.updateStockRequest}
          isCompactButtons={false}
          listButtons={[
            <FnbCancelButton showWarning={isChangeForm} onOk={navigateToManagementPage} />,
            <FnbAddNewButton
              onClick={(e) => onFinish(e)}
              className="float-right"
              type="primary"
              text={pageData.createRequest}
              hideIcon={true}
              permission={PermissionKeys.CREATE_STOCK_UPDATE_REQUEST}
              disabled={isDisabled}
            />,
          ]}
        />
        <div className="col-input-full-width">
          <Row align="middle">
            <FnbCard className="w-100" title={pageData.generalInformation} underlineTitle>
              <Row gutter={16} align="middle">
                <Col className="gutter-row mb-28" span={24}>
                  <Form.Item
                    name={["updateRequest", "branchId"]}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage id={"please-select-brach"} message={pageData.pleaseSelectBranch} />
                        ),
                      },
                    ]}
                    label={pageData.branchOrWarehouse}
                  >
                    <FnbSelectAddNewItem
                      mode="single"
                      placeholder={pageData.placeholderBranch}
                      options={branches?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                      showSearch
                      allowClear
                      fieldItemName={pageData.branchOrWarehouse.toLowerCase()}
                      onAddNewItem={onAddNewBranch}
                      onChange={handleChangeBranch}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16} align="middle">
                <Col className="gutter-row mb-28" span={24}>
                  <Form.Item
                    name={["updateRequest", "reasonType"]}
                    rules={[
                      {
                        required: true,
                        message: (
                          <InputValidateMessage
                            id={"please-select-transfer-material-from"}
                            message={pageData.pleaseSelectReason}
                          />
                        ),
                      },
                    ]}
                    label={pageData.reasonForRequest}
                  >
                    <FnbSelectSingle
                      placeholder={pageData.selectApplicableReason}
                      option={optionsReasonForRequest}
                      onChange={(option) => {
                        handleChangeReasonType(option);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col span={24}>
                  <Form.Item name={["updateRequest", "description"]} label={pageData.description}>
                    <FnbTextArea
                      showCount
                      maxLength={1000}
                      rows={6}
                      placeholder={pageData.placeholderDescription}
                      onChange={(e) => {
                        form.setFieldValue("description", e?.target?.value);
                      }}
                    ></FnbTextArea>
                  </Form.Item>
                </Col>
              </Row>
            </FnbCard>
          </Row>
          <Row className="display-contents">
            <FnbCard className="mt-3" title={pageData.ingredientInformation} underlineTitle>
              <Row>
                <Col span={24} className="button-show-search-modal">
                  <div
                    onClick={() => {
                      if (form.getFieldValue("branchId")) {
                        setIsOpenModal(true);
                      } else {
                        form.validateFields([["updateRequest", "branchId"]]);
                      }
                    }}
                  >
                    <Form.Item
                      className="w-100"
                      name={["updateRequest", listSelectedMaterials]}
                      rules={[
                        {
                          required: listSelectedMaterials?.length <= 0,
                          message: (
                            <InputValidateMessage id={"please-select-materials"} message={pageData.errIngredient} />
                          ),
                        },
                      ]}
                    >
                      <FnbInput
                        id={"btn-search-ingredient"}
                        className="fnb-input"
                        size="large"
                        placeholder={pageData.searchByNameSku}
                        prefix={<SearchIcon />}
                        type={TYPE_INPUT.BUTTON}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                {listSelectedMaterials?.length > 0 && (
                  <Col span={24}>
                    <FnbTable
                      columns={columnsMaterial()}
                      dataSource={listSelectedMaterials}
                      total={listSelectedMaterials?.length}
                      numberRecordCurrent={listSelectedMaterials?.length}
                      showPaging={false}
                      scroll={
                        listSelectedMaterials?.length > 5
                          ? { x: isMaxWidth1600 ? 850 : 1200, y: 86 * 5 }
                          : { x: isMaxWidth1600 ? 850 : 1200 }
                      }
                    />
                  </Col>
                )}
              </Row>
            </FnbCard>
          </Row>
        </div>
      </Form>
      <ConfirmDialogComponent visible={visibleModalLeaveConfirm} onCancel={leaveOnCancel} onOk={leaveOnOk} />
      <IngredientSearchModal
        visible={isOpenModal}
        handleCancel={() => setIsOpenModal(false)}
        listSelectedMaterials={listSelectedMaterials}
        setListSelectedMaterials={setListSelectedMaterials}
        branchId={form.getFieldValue("branchId")}
      />
    </>
  );
}
