import { Button, Input, Tooltip, message } from "antd";
import jwt_decode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { EnumOrderType } from "../../../../constants/enums";
import { PaymentMethodId } from "../../../../constants/payment-method.constants";
import { Platform } from "../../../../constants/platform.constants";
import branchDataService from "../../../../data-services/branch-data.services";
import customerDataService from "../../../../data-services/customer-data.service";
import deliveryDataService from "../../../../data-services/delivery-data.service";
import orderDataService from "../../../../data-services/order-data.service";
import paymentDataService from "../../../../data-services/payment-data.service";
import paymentMethodDataService from "../../../../data-services/paymentMethod-data.service";
import { store } from "../../../../modules/index";
import {
  setCartItems,
  setDeliveryAddress,
  setDeliveryMethods,
  setDiscountCodes,
  setNotificationDialog,
  setOrderInfo,
  setPaymentMethods,
} from "../../../../modules/session/session.actions";
import { setMoMoPaymentResponse } from "../../../../modules/third-party-response/third-party-response.actions";
import { setToastMessageMaxDiscount } from "../../../../modules/toast-message/toast-message.actions";
import { useAppCtx } from "../../../../providers/app.provider";
import { checkOutOfStockAllProductWhenUpdateCart } from "../../../../services/material/check-out-of-stock.service";
import maxDiscountService from "../../../../services/max-discount.services";
import shoppingCartService from "../../../../services/shopping-cart/shopping-cart.service";
import {
  addMinutes,
  areArraysEqual,
  convertLocalTime,
  getStoreConfig,
  isNonEmptyArray,
} from "../../../../utils/helpers";
import { HttpStatusCode } from "../../../../utils/http-common";
import { getStorage, localStorageKeys, setStorage } from "../../../../utils/localStorage.helpers";
import { CheckoutAddIcon } from "../../../assets/icons.constants";
import { ReactComponent as OrderNote } from "../../../assets/icons/note-icon.svg";
import noProductInCart from "../../../assets/images/no-product-in-cart.png";
import BtnSelectDiscountCode from "../../../components/btn-select-discount-code/btn-select-discount-code";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog.component";
import CustomizeDialog from "../../../components/customize-dialog/customize-dialog.component";
import NotificationDialog from "../../../components/notification-dialog/notification-dialog.component";
import OverlayLoadingFullScreenComponent from "../../../components/overlay-loading-full-screen/OverlayLoadingFullScreenComponent";
import PromotionListComponent from "../../../components/promotion-list/promotion-list.component";
import { EnumDeliveryMethod, EnumPromotion, enumOrderType } from "../../../constants/enum";
import { EnumDayOfWeek, EnumNextTimeOpenType } from "../../../constants/enums";
import { defaultCookingTime } from "../../../constants/number.constants";
import { PaymentMethodType } from "../../../constants/payment-method.constants";
import { theme2ElementCustomize } from "../../../constants/store-web-page.constants";
import { DateFormat, PHONE_NUMBER_REGEX, ValidTimeRegex } from "../../../constants/string.constant";
import { creditDedbitCard, pathOrderDetail, visa } from "../../../constants/visa-constants";
import { mockupPaymentMethod } from "../components/checkout-payment-method/mockup-const/checkout-payment-mockup";
import { mockupDelivery } from "../components/delivery-method/mockup-const/delivery-method-mockup";
import UseAvailablePoint from "./available-point/available-point.component";
import "./checkout-detail.style.scss";
import { CheckoutMomoDialog } from "./checkout-momo-dialog/checkout-momo-dialog";
import CheckoutOrderItems from "./checkout-order-items";
import CheckoutPaymentMethod from "./checkout-payment-method/checkout-payment-method";
import { CheckoutSummary } from "./checkout-summary/checkout-summary.component";
import PaymentStatusDialog from "./create-order-status-dialog/create-order-status-dialog.component";
import { CheckoutDeliveryInfo } from "./delivery-info/delivery-info.component";
import { CheckoutDeliveryMethod } from "./delivery-method/delivery-method.component";
import { ReactComponent as NoteIcon } from "./note-icon.svg";
import { guidIdEmptyValue } from "../../../../constants/combo.constants";

/// Releated to backend enum
const PaymentMethod = {
  MOMO: 0,
  ZALO_PAY: 1,
  CREDIT_DEBIT_CARD: 2,
  CASH: 3,
  VNPAY: 4,
  COD: 5,
  BANK_TRANSFER: 6,
  PAYPAL: 9,
};

const DEPAUSE_METHOD_TIME = 100; // miliseconds

const CheckOutDetail = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const checkoutMomoDialogRef = React.useRef();
  const storeConfig = getStoreConfig();
  const deliveryAddress = useSelector((state) => state?.session?.deliveryAddress);
  const branchAddress = useSelector((state) => state?.session?.deliveryAddress?.branchAddress);
  const currentOrderInfo = store.getState().session?.orderInfo;
  const location = useLocation();
  const paymentMethodId = location?.state?.paymentMethodId;
  const paymentMethodEnum = location?.state?.paymentMethod;
  const deliveryMethodId = useSelector((state) => state?.session?.orderInfo?.deliveryMethod?.deliveryMethodId);
  const currentCartItem = useSelector((state) => state?.session?.cartItems);
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const translateData = {
    addMoreProducts: t("checkOutPage.addMoreProducts", "Add more products"),
    changeAddress: t("checkOutPage.changeAddress", "Change another address"),
    configureAddress: t("checkOutPage.configureAddress", "Configure address"),
    noAddressMessage: t("checkOutPage.noAddressMessage", "You have no shipping address. Please configure one"),
    complete: t("checkOutPage.complete", "Complete"),
    pay: t("checkOutPage.pay", "Pay"),
    deliveryTo: t("checkOutPage.deliveryTo", "Delivery to"),
    discount: t("checkOutPage.discount", "Discount"),
    feeAndTax: t("checkOutPage.feeAndTax", "Fee & Tax"),
    shippingFee: t("checkOutPage.shippingFee", "Shipping Fee"),
    paymentMethod: t("checkOutPage.paymentMethod", "Payment method"),
    products: t("checkOutPage.products", "Products"),
    shippingMethod: t("checkOutPage.shippingMethod", "Shipping method"),
    shoppingCart: t("checkOutPage.shoppingCart", "Shopping Cart"),
    summary: t("checkOutPage.summary", "Summary"),
    subTotal: t("checkOutPage.subTotal", "Subtotal"),
    total: t("checkOutPage.total", "Total"),
    product: t("checkOutPage.product", "Product"),
    vnd: t("checkOutPage.vnd", "VNĐ"),
    placeHolderName: t("checkOutPage.placeHolderName", "Full name"),
    placeHolderPhone: t("checkOutPage.placeHolderPhone", "Phone number"),
    placeHolderShippingAddress: t("checkOutPage.placeHolderShippingAddress", "Shipping Address"),
    missingCustomerNameMessage: t("checkOutPage.missingCustomerNameMessage", "Please enter name"),
    missingPhoneMessage: t("checkOutPage.missingPhoneMessage", "Please enter phone"),
    cartProduct: t("checkOutPage.cartProduct", "Product"),
    cartPrice: t("checkOutPage.cartPrice", "Price"),
    cartQuantity: t("checkOutPage.cartQuantity", "Quantity"),
    cartTotal: t("checkOutPage.cartTotal", "Total"),
    placeHolderNote: t("checkOutPage.laceHolderNote", "Note"),
    yourCart: t("checkOutPage.yourCart", "Your cart"),
    items: t("checkOutPage.items", "items"),
    gotIt: t("storeWebPage.generalUse.gotIt", "Got it!"),
    selectAddressSuccessful: t("checkOutPage.selectAddressSuccessful", "Select address successful"),
    momoMinimumAmount: t("momo.minimumAmount", "The minimum payment on order is 1000đ via MoMo"),
    orderNote: t("storeWebPage.editOrderItem.noteAMessageForTheStore", "Nhập ghi chú"),
    promotion: t("checkOutPage.promotion", "Khuyến mãi"),
    useDiscountMessage: t("checkOutPage.useDiscountMessage", "Sử dụng giảm giá"),
    discountHasBeenApplied: t("promotion.discountCode.description.success", "Đã áp dụng giảm giá"),
    usePoint: ("checkOutPage.usePoint", "Dùng điểm"),
    titleNotificationSwitch: t("loginPage.notification", "Thông báo"),
    flashSaleEndNotification: t("storeWebPage.flashSale.flashSaleEndNotification"),
    productPriceHasBeenChanged: t(
      "messages.productPriceHasBeenChanged",
      "Giá sản phẩm đã bị thay đổi, vui lòng tải lại để xem giá hoặc khuyến mãi mới nhất.",
    ),
    pointConfigurationHasBeenChanged: t(
      "text.pointConfigurationHasBeenChanged",
      "Cấu hình điểm đã được thay đổi, vui lòng kiểm tra lại đơn hàng.",
    ),
    contentZeroPoint: t(
      "checkOutPage.availablePoint.contentZeroPoint",
      "Không thể quy đổi điểm cho đơn hàng. Vui lòng kiểm tra lại.",
    ),
    contentUsePointNotMatchTime: t(
      "checkOutPage.availablePoint.contentUsePointNotMatchTime",
      "Số điểm sử dụng đã hết hạn hoặc có thay đổi, vui lòng kiểm tra lại đơn hàng.",
    ),
    contentUsePointHasChange: t(
      "checkOutPage.availablePoint.contentUsePointHasChange",
      "Cấu hình điểm đã được thay đổi, vui lòng kiểm tra lại đơn hàng.",
    ),
    iGotIt: t("checkOutPage.availablePoint.iGotIt", "Đã hiểu"),
    verifyCustomerRank: t(
      "checkOutPage.verifyCustomerRank",
      "Cấu hình hạng thành viên đã có thay đổi, vui lòng kiểm tra lại đơn hàng.",
    ),
    notification: t("storeWebPage.generalUse.notification", "Thông báo"),
    earnPointMessage: t(
      "checkOutPage.earnPointMessage",
      "Bạn sẽ kiếm được <span class = {{earn_points}}>{{earnPoints}}</span> điểm khi hoàn thành đơn hàng.",
    ),
    emptyCart: t("checkOutPage.emptyCart", "Không có gì trong giỏ hàng của bạn"),
    soSorryNotificationWorkingHour: t(
      "storeBranch.soSorryNotificationWorkingHour",
      "Rất xin lỗi! Hiện tại không phải thời gian làm việc của cửa hàng. Vui lòng quay lại vào lúc <strong>{{timeWorkingHour}} {{dayOfWeek}}</strong>",
    ),
    productPriceChange: t(
      "checkOutPage.productPriceChange",
      "Giá sản phẩm đã được thay đổi, vui lòng tải lại để xem giá hoặc khuyến mãi mới nhất.",
    ),
    okay: t("storeWebPage.generalUse.okay", "Okay"),
    textOutOfStock: t("storeWebPage.productDetailPage.textOutOfStock", "Rất tiếc! Sản phẩm không còn đủ hàng"),
    someProductOutOfStock: t(
      "storeWebPage.productDetailPage.someProductOutOfStock",
      "Rất tiếc! Một số sản phẩm đã hết hàng.",
    ),
    willBeRemoveFromCart: t("storeWebPage.productDetailPage.willBeRemoveFromCart", "Chúng sẽ bị xóa khỏi giỏ hàng!"),
    cannotCreateOrder: t(
      "messages.cannotCreateOrder",
      "Chúng sẽ bị xóa khỏi giỏ hàng!",
      "Không thể tạo đơn, vui lòng kiểm tra lại",
    ),
    createOrderPaymentMomoErrorMessage: t(
      "checkOutPage.createOrderPaymentMomoErrorMessage",
      "Yêu cầu bị từ chối vì số tiền giao dịch nhỏ hơn số tiền tối thiểu cho phép là 1000 VND hoặc lớn hơn số tiền tối đa cho phép là 50000000 VND",
    ),
    checkDeliveryEstimateTime: t(
      "checkOutPage.checkDeliveryEstimateTime",
      "Thời gian giao hàng không phù hợp, vui lòng chọn lại!",
    ),
    checkPickupEstimateTime: t(
      "checkOutPage.checkPickupEstimateTime",
      "Thời gian lấy hàng không phù hợp, vui lòng chọn lại!",
    ),
    deliveryTimeChanged: t("checkOutPage.deliveryTimeChanged", "Thời gian dự kiến giao hàng đã thay đổi."),
    pickupTimeChanged: t("checkOutPage.pickupTimeChanged", "Thời gian dự kiến lấy hàng đã thay đổi."),
    newDeliveryTime: t("checkOutPage.newDeliveryTime", "Dự kiến giao"),
    newPickupTime: t("checkOutPage.newPickupTime", "Dự kiến lấy hàng"),
    pleaseChooseDeliveryMethod: t("checkOutPage.pleaseChooseDeliveryMethod", "Vui lòng chọn phương thức vận chuyển!"),
    pleaseChoosePaymentMethod: t("checkOutPage.pleaseChoosePaymentMethod", "Vui lòng chọn phương thức thanh toán!"),
    paymentFailed: t("checkOutPage.paymentFail", "Thanh toán không thành công!"),
    contentDisableCreateOrderByGrabDelivery: t("checkOutPage.contentDisableCreateOrderByGrabDelivery"),
    paymentMoMoErrorMessage: t("checkOutPage.paymentMoMoErrorMessage", "Rất tiếc, Không tạo được đơn hàng do có vấn đề xảy ra với tài khoản MoMo của chủ cửa hàng. Bạn vui lòng liên hệ với cửa hàng để nhận hỗ trợ! ")

  };

  const { TextArea } = Input;

  const { configuration, colorGroups, clickToFocusCustomize, isCustomize, isDefault, config } = props;
  const colorGroupId = configuration?.colorGroupId ? configuration?.colorGroupId : config?.checkout?.colorGroupId;
  const colorGroup = colorGroups?.find((c) => c?.id === colorGroupId) ?? colorGroups?.[0];
  const storageConfig = JSON.parse(getStorage("config"));
  const mockupCustomize = storageConfig.customizeTheme;
  const [disablePayButton, setDisablePayButton] = useState(true);
  const [isReloadSelectTime, setIsReloadSelectTime] = useState(false);
  const [isCreateOrderProcessing, setIsCreateOrderProcessing] = useState(false);
  const [isHiddenPromotion, setIsHiddenPromotion] = useState(false);
  const [isOpenCheckoutMomoDialog, setIsOpenCheckoutMomoDialog] = useState(false);
  const [isOpenCreateOrderStatusDialog, setIsOpenCreateOrderStatusDialog] = useState(false);
  const [currentCheckoutInfo, setCurrentCheckoutInfo] = useState(null);
  const [currentDeliveryMethodSelected, setCurrentDeliveryMethodSelected] = useState(null);
  const [currentPaymentMethodSelected, setCurrentPaymentMethodSelected] = useState(null);
  const [orderResponseData, setOrderResponseData] = useState(null);
  const [isShowNotifyDialog, setIsShowNotifyDialog] = useState(false);
  const [isShowNotifyWorkingHoursDialog, setIsShowNotifyWorkingHoursDialog] = useState(false);
  const [timeWorkingHour, setTimeWorkingHour] = useState(null);
  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [contentNotifyDialog, setContentNotifyDialog] = useState("");
  const [isShowVerifyProductPriceDialog, setIsShowVerifyProductPriceDialog] = useState(false);
  const [isShowVerifyMembershipDiscount, setIsShowVerifyMembershipDiscount] = useState(false);
  const [isShowCheckEstimateTime, setIsShowCheckEstimateTime] = useState(false);
  const [responseDataMomo, setResponseDataMomo] = useState(null);
  const [responseDataVisaMomo, setResponseDataVisaMomo] = useState(null);
  const [isShowDiscountCodeDialog, setIsShowDiscountCodeDialog] = useState(false);
  const [showConfirmCheckLoyaltyPoint, setShowConfirmCheckLoyaltyPoint] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const calculateCustomerLoyaltyPoint = useSelector(
    (state) => state?.session?.orderInfo?.cartValidated?.calculateCustomerLoyaltyPoint,
  );
  const isChangedProductPriceVar = useSelector(
    (state) => state?.session?.orderInfo?.cartValidated?.isChangedProductPrice,
  );
  const [currentAvailablePoint, setCurrentAvailablePoint] = useState(0);
  const [isShowUsePointContent, setIsShowUsePointContent] = useState(false);
  const [verifyUsePointDialogMessage, setVerifyUsePointDialogMessage] = useState("");
  const [verifyCartChangeMessage, setVerifyCartChangeMessage] = useState("");
  const [isUsePoint, setIsUsePoint] = useState(false);
  const [isLoadingSwitchExchangePoint, setIsLoadingSwitchExchangePoint] = useState(false);
  const [isEditReceiver, setIsEditReceiver] = useState(false);
  const [receiverInfo, setReceiverInfo] = useState();
  const [cookingTime, setCookingTime] = useState(defaultCookingTime);

  /// Order info
  const [orderNotes, setOrderNotes] = useState("");
  const [shoppingCart, setShoppingCart] = useState([]);
  const [titleBtnSelectDiscountCode, setTitleBtnSelectDiscountCode] = useState(t(translateData.useDiscountMessage));
  const [isAllowShowSelectAddressSuccessful, setIsAllowShowSelectAddressSuccessful] = useState(false);
  const [isShowDialogOutOfStock, setIsShowDialogOutOfStock] = useState(false);
  const [isShowDialogRemoveFromCart, setIsShowDialogRemoveFromCart] = useState(false);
  const [earnPoint, setEarnPoint] = useState(0);
  const customerId = useSelector((state) => state?.session?.orderInfo?.cartValidated?.customerId);
  const momoMinimumAmount = 1000;

  const cartItemsInRedux = useSelector((state) => state.session.cartItems);
  const discountCodesInRedux = useSelector((state) => state.session?.discountCodes);
  const appliedDiscountCodes = useSelector((state) => state?.session?.appliedDiscountCodes ?? {});
  const paymentMethodsInRedux = useSelector((state) => state.session.paymentMethods ?? []);
  const [paymentWindow, setPaymentWindow] = useState(null);

  const [isApplyDiscount, setIsApplyDiscount] = useState(false);
  const { Toast } = useAppCtx();
  const [currentTime, setCurrentTime] = useState(moment());
  const TIME_DELIVERY_SHOW_POPUP = 10;
  var currentUrl = window.location.href;
  var urlRedirect = new URL(currentUrl)?.origin + "/" + pathOrderDetail;

  useEffect(() => {
    if (deliveryAddress?.orderType === enumOrderType.DELIVERY && !deliveryAddress?.receiverAddress) {
      if (!isCustomize && !isDefault) {
        setTimeout(() => {
          const chooseAddressModal = document.getElementsByClassName("receiver-address-select-button-from-checkout")[0];
          return chooseAddressModal?.click();
        }, 800);
      }
    }
    calculateShoppingCart();
    initDataPaymentMethods();
    setCurrentTime(moment());
    // Fix auto select delivery method on the first load data after update shopping cart
  }, []);

  /// When change service type remove all discount code apply
  useEffect(() => {
    calculateShoppingCart();
  }, [deliveryAddress?.orderType]);

  useEffect(() => {
    if (!isCustomize) {
      const isEqual = areArraysEqual(appliedDiscountCodes?.discountCodes ?? [], discountCodesInRedux ?? []);
      if (!isEqual) {
        setIsLoading(true);

        const numberOfDiscountCodeApplied = appliedDiscountCodes?.discountCodes?.length ?? 0;
        const numberOfDiscountCode = discountCodesInRedux?.length ?? 0;

        //numberOfDiscountCode > numberOfDiscountCodeApplied that is, apply additional discount codes
        const isShowToastMessageDiscountCode = numberOfDiscountCode > numberOfDiscountCodeApplied;

        calculateShoppingCart(null, isShowToastMessageDiscountCode);
      }

      if (isNonEmptyArray(discountCodesInRedux)) {
        setTitleBtnSelectDiscountCode(translateData.discountHasBeenApplied);
        setIsApplyDiscount(true);
      } else {
        setTitleBtnSelectDiscountCode(translateData.useDiscountMessage);
        setIsApplyDiscount(false);
      }
    }
  }, [discountCodesInRedux]);

  useEffect(() => {
    if (!isCustomize) {
      dispatch(setDiscountCodes(appliedDiscountCodes?.discountCodes ?? []));
    }
  }, [appliedDiscountCodes]);

  useEffect(() => {
    if (calculateCustomerLoyaltyPoint) {
      const { isShowUsePoint, availablePoint, pointUsed, earnPoint } = calculateCustomerLoyaltyPoint;

      const cartChangeMessage =
        isShowUsePoint !== isShowUsePointContent
          ? translateData.pointConfigurationHasBeenChanged
          : translateData.productPriceHasBeenChanged;
      setVerifyCartChangeMessage(cartChangeMessage);

      setIsShowUsePointContent(isShowUsePoint);
      setCurrentAvailablePoint(availablePoint);
      setEarnPoint(earnPoint);

      if (isUsePoint && isShowUsePoint && availablePoint === 0 && availablePoint === pointUsed) {
        setVerifyUsePointDialogMessage(t("loyaltyPoint.message.pointCannotRedeem"));
        setShowConfirmCheckLoyaltyPoint(true);
      }
    }
  }, [calculateCustomerLoyaltyPoint]);

  useEffect(() => {
    setDisablePayButton(false);
  }, [shoppingCart]);

  useEffect(() => {
    calculateShoppingCart(null);
  }, [deliveryMethodId]);

  useEffect(() => {
    const fetchData = async () => {
      const isBranchClosed = await checkIfBranchIsClosed();
      if (isBranchClosed === true) {
        setIsShowNotifyWorkingHoursDialog(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setShoppingCart(cartItemsInRedux);

    const reduxState = store.getState();
    const deliveryMethods = reduxState?.session?.deliveryMethods ?? [];
    if (deliveryMethodId != undefined && deliveryMethodId != null) {
      const deliveryMethod = deliveryMethods?.find((x) => x?.deliveryMethodId == deliveryMethodId);
      setCurrentDeliveryMethodSelected(deliveryMethod);
    } else if (deliveryMethods && deliveryMethods?.length > 0 && currentDeliveryMethodSelected == null) {
      const minPricingMethod = deliveryMethods?.reduce(
        (min, current) => (current?.feeValue < min?.feeValue ? current : min),
        deliveryMethods[0],
      );
      if (minPricingMethod) {
        setCurrentDeliveryMethodSelected(minPricingMethod);
      } else {
        //Sort to select cheapest shipping fee
        const sortedByPriceDeliveryMethod = deliveryMethods.sort((a, b) => a.feeValue - b.feeValue);
        // auto select first delivery method
        setCurrentDeliveryMethodSelected(sortedByPriceDeliveryMethod[0]);
      }
    }
    if (isDefault) {
      setCurrentDeliveryMethodSelected(mockupDelivery[0]);
    }
  }, [cartItemsInRedux]);

  useEffect(() => {
    if (deliveryAddress?.receiverAddress && isAllowShowSelectAddressSuccessful) {
      message.info(translateData.selectAddressSuccessful);
    }
  }, [deliveryAddress?.receiverAddress]);

  useEffect(() => {
    if (!isCustomize) {
      // Set default orderType is DELIVERY if user not select any
      if (
        deliveryAddress?.orderType === undefined ||
        deliveryAddress?.orderType === null ||
        deliveryAddress?.orderType === EnumOrderType.Online
      ) {
        dispatch(
          setDeliveryAddress({
            ...deliveryAddress,
            orderType: enumOrderType.DELIVERY,
          }),
        );
      }
      getDeliveryInfoFromLoginSession();

      if (deliveryAddress && currentCartItem) {
        depauseMethod("initDataDeliveryMethods", DEPAUSE_METHOD_TIME, () => {
          initDataDeliveryMethods(deliveryAddress, currentCartItem);
        });
      }
    }
  }, [deliveryAddress?.orderType, deliveryAddress?.branchAddress, deliveryAddress?.receiverAddress]);

  useEffect(() => {
    calculateShoppingCart();
    initDataPaymentMethods();
  }, [branchAddress]);

  useEffect(() => {
      const newOrderInfo = {
        ...getOrderInfo(),
        shippingFee:
          deliveryAddress?.orderType === enumOrderType.DELIVERY ? currentDeliveryMethodSelected?.feeValue ?? 0 : 0,
        deliveryMethod: currentDeliveryMethodSelected,
      };
      dispatch(setOrderInfo(newOrderInfo));

      // Grab only support order has payment by Cash
      if (currentDeliveryMethodSelected?.enumDeliveryMethod === EnumDeliveryMethod.GrabExpress) {
        const cashPaymentMethod = paymentMethodsInRedux?.find((e) => e.paymentMethodEnumId === PaymentMethodType.Cash);
        if (cashPaymentMethod) {
          setCurrentPaymentMethodSelected(cashPaymentMethod);
        }
      }
  }, [currentDeliveryMethodSelected]);

  useEffect(() => {
    if (currentPaymentMethodSelected) {
      const newOrderInfo = {
        ...getOrderInfo(),
        paymentMethod: currentPaymentMethodSelected,
      };
      dispatch(setOrderInfo(newOrderInfo));
    }
  }, [currentPaymentMethodSelected?.paymentMethodId, currentPaymentMethodSelected?.paymentMethodEnumId]);

  useEffect(() => {
    if (responseDataMomo) {
      let intervalGetPaymentStatusID = setInterval(() => {
        const { requestId, orderId, amount } = responseDataMomo.paymentInfo;
        if (!requestId) {
          return;
        }

        const { pointUsed, redeemPointExchangeValue } = calculateCustomerLoyaltyPoint;
        paymentDataService
          .updateStoreWebOrderMomoPaymentWithPoint(
            requestId,
            orderId,
            amount,
            isUsePoint,
            pointUsed,
            redeemPointExchangeValue,
          )
          .then((responseData) => {
            if (responseData?.data?.isSuccess) {
              var result = {
                result: responseData?.data?.isSuccess,
                message: responseData?.data?.message,
              };
              dispatch(setMoMoPaymentResponse(result));
              clearInterval(intervalGetPaymentStatusID);
              //reset cart
              if (responseData?.data?.isSuccess == true) {
                handlePaymentCompleted(true);
              }
            }
          });
      }, 5000);

      return () => clearInterval(intervalGetPaymentStatusID);
    }
  }, [responseDataMomo]);

  useEffect(() => {
    if (paymentWindow === null) {
      return;
    }
    const interval = setInterval(async () => {
      try {
        if (paymentWindow.closed) {
          clearInterval(interval);
          setPaymentWindow(null);
          setIsLoading(false);
          updateVisaPaymentResult();
        }
      } catch (error) {
        message.error(translateData.paymentFailed);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [paymentWindow]);

  useEffect(() => {
    if (isChangedProductPriceVar) {
      const orderInfo = getOrderInfo();
      const hasOutOfStockItem = orderInfo?.cartValidated?.cartItems?.some((item) => item.isOutOfStock === true);
      if (hasOutOfStockItem) {
        setIsShowDialogRemoveFromCart(true);
      } else {
        setIsShowVerifyProductPriceDialog(true);
      }
      setIsCreateOrderProcessing(false);
      window.isCreateOrderCheckoutProcessing = false;
    }
  }, [isChangedProductPriceVar]);

  const updateVisaPaymentResult = async () => {
    try {
      paymentDataService
        .updateVisaPaymentResult(responseDataVisaMomo, calculateCustomerLoyaltyPoint, isUsePoint)
        .then((responseData) => {
          if (responseData?.data?.isSuccess === true) {
            setIsCreateOrderProcessing(false);
            window.isCreateOrderCheckoutProcessing = false;
            setIsOpenCreateOrderStatusDialog(true);
            // reset cart
            handlePaymentCompleted(true);
          }
        });
    } catch (error) {
      message.error("");
    }
  };

  const createPackagesDelivery = (cartItem) => {
    if (cartItem?.isCombo) {
      return {
        name: cartItem?.name,
        description: cartItem?.name,
        quantity: cartItem?.quantity,
        price: cartItem?.sellingPrice,
      };
    } else {
      return {
        name: cartItem?.name,
        description: cartItem?.name,
        quantity: cartItem?.quantity,
        price: cartItem?.productPrice?.priceValue,
      };
    }
  };

  const calculateShoppingCart = (cartItems, isShowToastMessageDiscountCode = false, isChangeQuantity = false) => {
    depauseMethod("callApiValidateCartItems", DEPAUSE_METHOD_TIME, () => {
      if (!isCustomize) {
        callApiValidateCartItems(false, cartItems, isUsePoint, null, isShowToastMessageDiscountCode, isChangeQuantity);
      }
    });
  };

  const initDataShoppingCart = () => {
    const store_cart = JSON.parse(localStorage.getItem(localStorageKeys.STORE_CART));
    setShoppingCart(store_cart);
  };

  const getDeliveryInfoFromLoginSession = () => {
    const token = getStorage(localStorageKeys.TOKEN);
    const loginData = JSON.parse(getStorage(localStorageKeys.LOGIN));
    const customerInfo = JSON.parse(getStorage(localStorageKeys.CUSTOMER_INFO));
    const decoded_token = token && jwt_decode(token);
    const customerId = decoded_token?.ID;
    const accountId = decoded_token?.ACCOUNT_ID;
    const customerName = customerInfo?.fullName;
    const customerPhone = loginData?.phone;

    // Save delivery info to redux
    const orderInfo = {
      ...currentOrderInfo,
      deliveryInfo: {
        customerId: customerId,
        accountId: accountId,
        phoneNumber: customerPhone,
        addressId: deliveryAddress?.receiverAddress?.id,
        address: deliveryAddress?.receiverAddress?.addressDetail,
        lat: deliveryAddress?.receiverAddress?.lat,
        lng: deliveryAddress?.receiverAddress?.lng,
        receiverName: customerName,
      },
    };

    dispatch(setOrderInfo(orderInfo));
  };

  const initDataPaymentMethods = async () => {
    const res = await paymentMethodDataService.getPaymentMethods(storeConfig?.storeId, branchAddress?.id);
    if (res) {
      let paymentMethods = res?.data?.sort((a, b) => (a.paymentMethodName > b.paymentMethodName ? 1 : -1));

      paymentMethods = paymentMethods.map((paymentMethod) => {
        if (paymentMethod.paymentMethodName === creditDedbitCard) {
          return { ...paymentMethod, paymentMethodName: visa };
        } else {
          return paymentMethod;
        }
      });

      const indexPaymentMethodBankTransfer = paymentMethods?.findIndex(
        (p) => p?.paymentMethodEnumId === PaymentMethodType.BankTransfer,
      );
      if (indexPaymentMethodBankTransfer !== -1) {
        paymentMethods.push(paymentMethods.splice(indexPaymentMethodBankTransfer, 1)[0]);
      }
      dispatch(setPaymentMethods(paymentMethods));
      setCurrentPaymentMethodSelected(paymentMethods?.[0]); // auto select first payment method
    }
    if (isDefault) {
      setCurrentPaymentMethodSelected(mockupPaymentMethod[0]);
    }
    if (paymentMethodId !== undefined && paymentMethodId != null && res) {
      const paymentMethods = res?.data?.sort((a, b) => (a.paymentMethodName > b.paymentMethodName ? 1 : -1));
      let changePaymentMethod = paymentMethodId;
      //Cash === COD bug #61588
      if (paymentMethodEnum === PaymentMethodType.COD) {
        const cash = res?.data?.find((p) => p?.paymentMethodEnumId === PaymentMethodType.Cash);
        if (cash) {
          changePaymentMethod = cash.paymentMethodId;
        }
      }
      const indexPaymentMethodReOrder = paymentMethods?.findIndex(
        (method) => method.paymentMethodId === changePaymentMethod,
      );
      setCurrentPaymentMethodSelected(paymentMethods[indexPaymentMethodReOrder]);
    }
  };

  const initDataDeliveryMethods = async (deliveryAddress, cartItems) => {
    const { branchAddress, receiverAddress } = deliveryAddress;
    const request = {
      storeBranchAddress: {
        address: branchAddress?.addressDetail,
        lat: branchAddress?.lat,
        lng: branchAddress?.lng,
      },
      receiverAddress: {
        address: receiverAddress?.addressDetail,
        lat: receiverAddress?.lat,
        lng: receiverAddress?.lng,
      },
      packages: cartItems?.map((item) => createPackagesDelivery(item)) || [],
    };
    const res = await deliveryDataService.calculateDeliveryFee(request);
    if (res) {
      //Set cooking time
      const cookingTime = res?.data?.deliveryPricings?.find((item) => item.enumId === EnumDeliveryMethod.SELF_DELIVERY);
      setCookingTime(cookingTime?.estimateTime);
      //Sort to select cheapest shipping fee
      const deliveryMethods = res?.data?.deliveryPricings?.sort((a, b) => (a.feeValue > b.feeValue ? 1 : -1));
      if (deliveryMethods) {
        const minPricingMethod = deliveryMethods?.reduce(
          (min, current) => (current?.feeValue < min?.feeValue ? current : min),
          deliveryMethods[0],
        );
        if (minPricingMethod && paymentMethodId != PaymentMethodId.PayPal) {
          setCurrentDeliveryMethodSelected(minPricingMethod);
        } else {
          const deliveryMethod = deliveryMethods.find((x) => x.deliveryMethodId === deliveryMethodId);
          const currentDeliveryMethod = deliveryMethod ?? deliveryMethods[0];
          // auto select first delivery method on the first init delivery methods list
          setCurrentDeliveryMethodSelected(currentDeliveryMethod);
        }
        dispatch(setDeliveryMethods(deliveryMethods));
      }
    }
  };

  const depauseMethod = (methodName, timeout, callBack) => {
    if (window[methodName]) {
      clearTimeout(window[methodName]);
    }

    window[methodName] = setTimeout(() => {
      if (callBack) {
        callBack();
      }
    }, timeout);
  };

  const onChangeDeliveryMethod = (deliveryMethod) => {
    if (deliveryMethod) {
      setCurrentDeliveryMethodSelected(deliveryMethod);
    }
  };

  const onChangePaymentMethod = (paymentMethod) => {
    if (paymentMethod) {
      setCurrentPaymentMethodSelected(paymentMethod);
    }
  };
  //#endregion

  const calculateDiscount = (price, promotion, currentDiscountValue = null) => {
    if (promotion && promotion?.isPercentDiscount) {
      let discountValue = (price * promotion?.percentNumber) / 100;
      if (promotion?.maximumDiscountAmount == 0) {
        return discountValue;
      }

      if (currentDiscountValue) {
        if (currentDiscountValue == promotion?.maximumDiscountAmount) {
          return 0;
        }

        return discountValue >= promotion?.maximumDiscountAmount
          ? promotion?.maximumDiscountAmount - currentDiscountValue
          : discountValue - currentDiscountValue;
      }
      if (promotion?.maximumDiscountAmount > 0) {
        return discountValue >= promotion?.maximumDiscountAmount ? promotion?.maximumDiscountAmount : discountValue;
      }

      return discountValue;
    } else {
      if (currentDiscountValue && currentDiscountValue <= promotion?.maximumDiscountAmount) {
        return promotion?.maximumDiscountAmount - currentDiscountValue;
      }

      return promotion.maximumDiscountAmount;
    }
  };

  const FindMaxPromotion = (promotions, price) => {
    let maxPromotion = null;
    let discountValue = 0;
    for (let i = 0; i < promotions.length; i++) {
      let promotion = promotions[i];
      let maxDiscount = calculateDiscount(price, promotion);
      if (maxDiscount >= discountValue) {
        discountValue = maxDiscount;
        maxPromotion = promotion;
      }
    }
    // Promotion value cannot be greater than product value
    if (discountValue > price) {
      discountValue = price;
    }

    return { maxPromotion, discountValue };
  };

  //Update shoppingcart when user click + or - button
  const onUpdateCartQuantity = async (id, quantity, cartIndex) => {
    if (!shoppingCart || shoppingCart.length === 0) return;
    let data = shoppingCart;
    //Check out of stock
    const cartData = [...data];
    const outOfStockIndices = cartData?.reduce((acc, item, index) => {
      if (item.isOutOfStock) {
        acc.push(index);
      }
      return acc;
    }, []);
    const verifyOutOfStock = await checkOutOfStockAllProductWhenUpdateCart(
      branchAddress?.id,
      cartData,
      cartIndex,
      quantity,
      outOfStockIndices,
    );
    if (verifyOutOfStock && quantity > 0) {
      setIsShowDialogOutOfStock(true);
      data = data.map((cart, index) =>
        cart.id === id && index === cartIndex ? { ...cart, quantity: Math.max(cart.quantity, 0) } : cart,
      );
      return;
    }

    data = data.map((cart, index) =>
      cart.id === id && index === cartIndex ? { ...cart, quantity: Math.max(cart.quantity + quantity, 0) } : cart,
    );

    // Calculate cart items and show toast message when change quantity
    calculateShoppingCart(data, false, true);

    /// Handle calculation max discount
    let maximumDiscountAmount = data[cartIndex]?.productPrice?.maximumDiscountAmount;
    let totalPriceValue = data[cartIndex]?.quantity * data[cartIndex]?.productPrice?.priceValue;
    let isIncludedTopping = data[cartIndex]?.productPrice?.isIncludedTopping;
    // Discount total bill
    if (data[cartIndex]?.isFlashSale === false && data[cartIndex]?.isPromotionTotalBill) {
      var totalAmountOriginalPrice = data
        ?.filter((cart) => cart.isCombo === false)
        ?.reduce((amount, cartList) => {
          return (
            amount +
            (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
            (cartList?.sellingPrice || 0) * cartList?.quantity
          );
        }, 0);
      const promotions = currentOrderInfo?.cartValidated?.promotions.filter(
        (p) => p.promotionType === EnumPromotion.DiscountTotal,
      );
      const { maxPromotion } = FindMaxPromotion(promotions, totalAmountOriginalPrice);
      maximumDiscountAmount = maxPromotion?.maximumDiscountAmount;
      isIncludedTopping = maxPromotion?.isIncludedTopping;
      // IsIncludedTopping
      if (maxPromotion?.isIncludedTopping === true) {
        totalAmountOriginalPrice = data
          ?.filter((cart) => cart.isCombo === false)
          ?.reduce((amount, cartList) => {
            return (
              amount +
              (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
              (cartList?.sellingPrice || 0) * cartList?.quantity +
              (cartList?.productPrice?.totalOfToppingPrice || 0) * cartList?.quantity +
              (cartList?.totalOfToppingPrice || 0) * cartList?.quantity
            );
          }, 0);
      }
      //Total amount
      totalPriceValue = (totalAmountOriginalPrice * maxPromotion?.percentNumber) / 100;
    } else if (data[cartIndex]?.isPromotionProductCategory) {
      // Discount product category
      const productCategoryId = data[cartIndex]?.dataDetails?.product?.productDetail?.productCategoryId;
      let newCartItemsCategory = data?.filter(
        (item) =>
          item?.dataDetails?.product?.productDetail?.productCategoryId === productCategoryId && item?.isCombo === false,
      );
      totalAmountOriginalPrice = newCartItemsCategory?.reduce((amount, cartList) => {
        return (
          amount +
          (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
          (cartList?.sellingPrice || 0) * cartList?.quantity
        );
      }, 0);

      const promotionCategories = currentOrderInfo?.cartValidated?.promotions.filter(
        (p) =>
          p.promotionType === EnumPromotion.DiscountProductCategory &&
          p.listPromotionProductCategory.find((cate) => cate === productCategoryId),
      );
      const { maxPromotion } = FindMaxPromotion(promotionCategories, totalAmountOriginalPrice);
      maximumDiscountAmount = maxPromotion?.maximumDiscountAmount;
      isIncludedTopping = maxPromotion?.isIncludedTopping;
      // IsIncludedTopping
      if (maxPromotion?.isIncludedTopping === true) {
        totalAmountOriginalPrice = newCartItemsCategory?.reduce((amount, cartList) => {
          return (
            amount +
            (cartList?.productPrice?.originalPrice || 0) * cartList?.quantity +
            (cartList?.sellingPrice || 0) * cartList?.quantity +
            (cartList?.productPrice?.totalOfToppingPrice || 0) * cartList?.quantity +
            (cartList?.totalOfToppingPrice || 0) * cartList?.quantity
          );
        }, 0);
      }
      //Total amount
      totalPriceValue = (totalAmountOriginalPrice * maxPromotion?.percentNumber) / 100;
    }
    const dataDiscount = {
      isFlashSale: data.some((item) => Boolean(item?.productPrice) && item?.productPrice["flashSaleId"]) ?? false, //Check null for productPrice
      isApplyPromotion: data[cartIndex]?.productPrice?.isApplyPromotion,
      isIncludedTopping: isIncludedTopping,
      isDiscountTotal: currentOrderInfo?.cartValidated?.isDiscountOnTotal,
      totalPriceValue: totalPriceValue,
      maximumDiscountAmount: maximumDiscountAmount,
    };
    maxDiscountService.calculationMaxDiscountService(
      dataDiscount,
      () => {
        dispatch(setToastMessageMaxDiscount(true));
      },
      () => {
        dispatch(setToastMessageMaxDiscount(false));
      },
    );
  };

  async function handleConfirmNotifyOutOfStock() {
    setIsShowDialogRemoveFromCart(false);
    let newCartItems = [...shoppingCart];
    const newCarts = shoppingCartService.removeOutOfStockCartItem(newCartItems);
    dispatch(setCartItems(newCarts));
    setShoppingCart(newCarts);
    calculateShoppingCart(newCarts);
  }

  const onDeleteProduct = (id, cartIndex) => {
    if (!shoppingCart || shoppingCart.length === 0) return;
    let data = [...shoppingCart];
    data.splice(cartIndex, 1);
    dispatch(setCartItems(data));
    setShoppingCart(data);

    // Calculate cart items and show toast message when delete product
    calculateShoppingCart(data, false, true);
  };

  const addMoreProducts = () => {
    history.push("/product-list");
  };

  const mappingOrderCartItem = (cartItem) => {
    return {
      orderItemId: null,
      dataDetails: cartItem?.dataDetails,
      productPriceId: cartItem?.productPrice?.id,
      flashSaleId: cartItem?.productPrice?.flashSaleId,
      quantity: cartItem?.quantity,
      notes: cartItem?.notes ?? "",
      options: cartItem?.options?.map((o) => {
        return {
          optionId: o.id,
          optionLevelId: o.optionLevelId,
        };
      }),
      toppings: cartItem?.toppings?.map((t) => {
        return {
          toppingId: t.id,
          quantity: t.quantity,
        };
      }),
      isCombo: cartItem?.isCombo ?? false,
      combo: cartItem?.isCombo
        ? {
            comboId: cartItem?.isCombo ? cartItem?.id : null,
            comboPricingId: cartItem?.comboPricingId,
            comboName: cartItem?.name,
            itemName: cartItem?.comboPricingName,
            thumbnail: cartItem?.thumbnail,
            originalPrice: cartItem?.originalPrice,
            sellingPrice: cartItem?.sellingPrice,
            sellingPriceAfterDiscount: cartItem?.sellingPrice,
            quantity: cartItem?.quantity,
            notes: cartItem?.notes,
            customName: cartItem?.comboPricingName,
            comboItems: cartItem?.products?.map((product) => {
              return {
                productId: product?.id,
                productPriceId: product?.productPrice?.id,
                itemName: product?.name,
                thumbnail: product?.thumbnail,
                quantity: product?.quantity ?? 1,
                note: product?.note,
                options: product?.options?.map((option) => {
                  return {
                    optionId: option?.id,
                    optionLevelId: option?.optionLevelId,
                  };
                }),
                toppings: product?.toppings?.map((topping) => {
                  return {
                    toppingId: topping?.id,
                    quantity: topping?.quantity,
                    priceValue: topping?.priceValue,
                  };
                }),
              };
            }),
          }
        : null,
      productId: !cartItem?.isCombo ? cartItem?.id : null,
    };
  };

  const callApiValidateCartItems = async (
    isCheckChangedData,
    cartItems,
    isActiveUsedPoint,
    isOrderCreating = false,
    isShowToastMessageDiscountCode = false,
    isChangeQuantity = false,
  ) => {
    let isChangedProductPrice = false;
    if (!cartItems || cartItems?.length === 0) {
      const reduxState = store.getState();
      const session = reduxState?.session;
      cartItems = session?.cartItems ?? [];
    }

    const orderInfo = getOrderInfo();

    var deliveryFees = 0;
    if (orderInfo?.shippingFee) {
      deliveryFees = orderInfo?.shippingFee;
    }

    setDisablePayButton(false);
    setIsAllowShowSelectAddressSuccessful(true);

    // Get data from redux to verify. Done then save to local storage and redux.
    const dataRequest = {
      cartItems: cartItems,
      isCheckChangedData: isCheckChangedData,
      isActiveUsedPoint: isActiveUsedPoint,
      deliveryFee: deliveryFees,
      isCustomize: isCustomize,
      callBackCheckFlashSale: callBackCheckFlashSale,
      isOrderCreating: isOrderCreating,
      isShowToastMessageDiscountCode: isShowToastMessageDiscountCode,
      isChangeQuantity: isChangeQuantity,
    };
    isChangedProductPrice = await shoppingCartService.verifyAndUpdateCart(dataRequest);
    setIsLoading(false);
    return isChangedProductPrice;
  };

  const callBackCheckFlashSale = (message) => {
    setContentNotifyDialog(message);
    setIsShowNotifyDialog(true);
  };

  const getOrderInfo = () => {
    const reduxState = store.getState();
    const session = reduxState?.session;
    const requestCartItems = session?.cartItems?.map((item) => mappingOrderCartItem(item));
    const orderInfo = {
      ...session?.orderInfo,
      cartItems: requestCartItems ?? [],
      orderNotes: orderNotes,
      deliveryAddress: { ...session?.deliveryAddress },
    };

    return orderInfo;
  };

  const getLoginUserInfo = () => {
    const customerInfoJsonString = getStorage(localStorageKeys.CUSTOMER_INFO);
    const customerInfo = JSON.parse(customerInfoJsonString);

    return customerInfo;
  };

  async function checkIfBranchIsClosed() {
    let isClosed = false;
    const workingHour = await branchDataService.getWorkingHourByBranchIdAsync(branchAddress?.id ?? null);
    const workingHourResult = workingHour?.data;
    if (workingHourResult?.isClosed === true) {
      setTimeWorkingHour(workingHourResult?.workingHour?.openTime);
      if (workingHourResult?.workingHour?.nextTimeOpen === EnumNextTimeOpenType[1].key) {
        setDayOfWeek(EnumNextTimeOpenType[workingHourResult?.workingHour?.nextTimeOpen - 1].name);
      } else if (workingHourResult?.workingHour?.nextTimeOpen === EnumNextTimeOpenType[2].key) {
        setDayOfWeek(EnumDayOfWeek[workingHourResult?.workingHour?.dayOfWeek].name);
      }
      isClosed = workingHourResult?.isClosed;
    }
    return isClosed;
  }

  const onCompleteCheckOut = async (isPreventShowPopupCheckTime) => {
    ///Handle check working hours when not select delivery time
    const isBranchClosed = await checkIfBranchIsClosed();
    if (isBranchClosed === true) {
      setIsShowNotifyWorkingHoursDialog(true);
      return;
    }

    //Check estimate time compare to now
    if (!isPreventShowPopupCheckTime) {
      if (showPopupCheckTimePlaceOrder(timeSlot, deliveryDate)) {
        setIsShowCheckEstimateTime(true);
        return;
      }
    }
    //Check address before pay order
    if (!deliveryAddress?.receiverAddress && deliveryAddress?.orderType === enumOrderType.DELIVERY) {
      const chooseAddressModal = document.querySelector(".receiver-address-select-button");
      return chooseAddressModal?.click();
    }

    const cartItems = [...shoppingCart];
    const indexCart = 0;
    const verifyOutOfStock = await checkOutOfStockAllProductWhenUpdateCart(
      branchAddress?.id,
      cartItems,
      indexCart,
      cartItems[indexCart]?.quantity,
      undefined,
      true,
    );
    if (verifyOutOfStock) {
      await callApiValidateCartItems(false, null, isUsePoint);
      setIsShowDialogRemoveFromCart(true);
      return;
    }

    const reduxState = store.getState();
    const session = reduxState?.session;
    const storeConfig = getStoreConfig();

    //Check valid receiver info
    const isValidReceiver = checkValidReceiverInfo();
    if (!isValidReceiver) return;

    //Check Change Membership Rank
    let isChangeMembershipLevel = false;
    const customerId = session?.userInfo?.customerId;
    if (customerId) {
      const membershipLevelInfo = await customerDataService.getMembershipLevelInformation(
        customerId,
        storeConfig?.storeId,
      );
      if (membershipLevelInfo?.data && membershipLevelInfo.data?.customerId !== undefined) {
        isChangeMembershipLevel = checkChangeDiscountMembershipLevel(
          session.orderInfo.cartValidated,
          membershipLevelInfo.data,
        );
      }
    }
    if (isChangeMembershipLevel) {
      setIsShowVerifyMembershipDiscount(true);
    } else {
      const { availablePoint, redeemPointExchangeValue } = calculateCustomerLoyaltyPoint;
      let isUsePointFailed = false;
      if (isUsePoint && availablePoint >= 0 && redeemPointExchangeValue >= 0) {
        isUsePointFailed = await handleVerifyCustomerLoyaltyPoint();
      }
      if (isUsePointFailed === true) {
        setIsCreateOrderProcessing(false);
        window.isCreateOrderCheckoutProcessing = false;
      } else {
        const isChangedProductPrice = await callApiValidateCartItems(true, null, isUsePoint, true);
        if (isChangedProductPrice === true) {
          ///Call dialog and refresh
          return;
        } else {
          await submitCreateOrder();
        }
      }
    }
  };

  const checkChangeDiscountMembershipLevel = (orderInfor, membershipDiscount) => {
    if (
      orderInfor?.customerId === membershipDiscount?.customerId &&
      orderInfor?.customerMemberShipLevel === membershipDiscount?.customerMemberShipLevel &&
      orderInfor?.customerMemberShipDiscount === membershipDiscount?.customerMemberShipDiscount &&
      orderInfor?.customerMemberShipMaxDiscount === membershipDiscount?.customerMemberShipMaxDiscount
    ) {
      return false;
    } else {
      return true;
    }
  };

  function isTimeValid(timeSlot) {
    // Biểu thức chính quy kiểm tra định dạng giờ HH:mm
    const timePattern = ValidTimeRegex;

    // Kiểm tra nếu timeSlot là chuỗi và phù hợp với định dạng giờ hoặc là "Sớm nhất"
    if (typeof timeSlot === "string" && timePattern.test(timeSlot)) {
      return true;
    } else {
      return false;
    }
  }

  const handleChangeReceiver = (values) => {
    setReceiverInfo(values);
  };

  const checkValidReceiverInfo = () => {
    if (isEditReceiver) {
      const { receiverName, receiverPhone } = receiverInfo;
      const isValidName = receiverName;
      const isValidPhone = receiverPhone && PHONE_NUMBER_REGEX.test(receiverPhone);
      return isValidName && isValidPhone;
    }
    return true;
  };

  const getReceiverRemarks = (isEditReceiver, receiverInfo, deliveryInfo) => {
    if (isEditReceiver && receiverInfo?.receiverPhone !== deliveryInfo?.phoneNumber) {
      return `Backup: ${deliveryInfo?.receiverName} - ${deliveryInfo?.phoneNumber}`;
    }
    return "";
  };

  const submitCreateOrder = async () => {
    if (isCreateOrderProcessing) return;
    //TODO: show valid old valid  checkout info data response from api
    const reduxState = store.getState();
    const orderInfo = getOrderInfo();
    const { cartValidated, deliveryInfo, deliveryMethod, paymentMethod, deliveryAddress } = orderInfo;
    const branchAddress = reduxState?.session?.deliveryAddress?.branchAddress;
    const loginUserInfo = getLoginUserInfo();
    const createOrderRequest = {
      accountId: loginUserInfo?.accountId,
      branchId: branchAddress?.id ?? null,
      customerId: loginUserInfo.customerId ?? null,
      enumOrderTypeId:
        deliveryAddress?.orderType === enumOrderType.DELIVERY ? enumOrderType.DELIVERY : enumOrderType.PICK_UP, // ORDER TYPE DELIVERY
      enumPaymentMethodId: currentPaymentMethodSelected?.paymentMethodEnumId ?? paymentMethod?.paymentMethodEnumId,
      isDeliveryOrder: true,
      deliveryMethodId: deliveryMethod?.deliveryMethodId ?? currentDeliveryMethodSelected?.deliveryMethodId,
      deliveryMethod: deliveryMethod?.enumId ?? currentDeliveryMethodSelected?.enumId,
      cartItems: cartValidated?.cartItems,
      totalTax: cartValidated?.totalTax,
      deliveryFee:
        deliveryAddress?.OrderType === enumOrderType.DELIVERY || deliveryAddress?.OrderType == undefined
          ? orderInfo?.shippingFee
          : 0,
      receiverName: isEditReceiver ? receiverInfo?.receiverName : deliveryInfo?.receiverName,
      receiverPhone: isEditReceiver ? receiverInfo?.receiverPhone : deliveryInfo?.phoneNumber,
      receiverAddress: {
        address: deliveryAddress?.receiverAddress?.address,
        lat: deliveryInfo?.lat,
        lng: deliveryInfo?.lng,
      },
      receiverRemarks: getReceiverRemarks(isEditReceiver, receiverInfo, deliveryInfo),
      note: orderNotes,
      orderCartInfo: cartValidated,
      userPhoneNumber: loginUserInfo?.phoneNumber,
      userFullName: loginUserInfo?.fullName,
      userPhoneCode: loginUserInfo?.phoneCode,
      discountCodes: Array.isArray(discountCodesInRedux) ? discountCodesInRedux : [],
      isActiveUsedPoint: isUsePoint,
      scheduledTime: deliveryDate && isTimeValid(timeSlot) ? deliveryDate + " " + timeSlot : null,
      platformId: Platform.StoreWebsite,
      isOrderCreating: true,
      orderType: deliveryAddress?.orderType,
      urlRedirect: urlRedirect,
    };

    var scheduledTime = moment(createOrderRequest.scheduledTime, DateFormat.YYYY_MM_DD_HH_MM);

    if (scheduledTime.isBefore(moment())) {
      createOrderRequest.scheduledTime = moment().format(DateFormat.YYYY_MM_DD_HH_MM);
    }

    // Validate delivery method if the order not is PICKUP and DOES NOT CHOOSE delivery method
    if (createOrderRequest.orderType !== EnumOrderType.PickUp && !Boolean(createOrderRequest.deliveryMethod)) {
      message.warning(t(translateData.pleaseChooseDeliveryMethod));
      return;
    }

    if (createOrderRequest.enumPaymentMethodId === undefined) {
      message.warning(t(translateData.pleaseChoosePaymentMethod));
      return;
    }

    if (
      paymentMethod?.paymentMethodEnumId === PaymentMethodType.MoMo &&
      shoppingCart?.length > 0 &&
      (orderInfo?.cartValidated?.totalPriceAfterDiscount ?? 0) + (orderInfo?.shippingFee ?? 0) < momoMinimumAmount
    ) {
      message.info(translateData.momoMinimumAmount);
      return;
    }

    // pay button start loading
    setIsCreateOrderProcessing(true);
    window.isCreateOrderCheckoutProcessing = true;

    try {
      const response = await orderDataService.createStoreWebOrderAsync(createOrderRequest);
      if (response.status === HttpStatusCode.Ok) {
        const responseData = response.data;        
        const resultCode = responseData?.paymentInfo?.resultCode ?? 0;
        if (responseData.paymentMethod === PaymentMethod.MOMO && resultCode > 0) {
          Toast.error({
            message: translateData.paymentMoMoErrorMessage,
            placement: "top",
          });
        } else if (responseData.paymentMethod == PaymentMethod.PAYPAL) {
          const IsFailPaymentByPayPal = responseData?.IsFailPaymentByPayPal;
          if (IsFailPaymentByPayPal) {
            Toast.error({
              message: translateData.createOrderPaymentMomoErrorMessage,
              placement: "top",
            });
          } else {
            handlePaymentCompleted(true);
            window.open(responseData?.paymentInfo?.paymentLink, "_self");
          }
        } else {
          setOrderResponseData(responseData);
          gotoPaymentStep(responseData);
          dispatch(setDiscountCodes([]));
        }

        if (responseData?.customerInfo != null && responseData?.customerInfo?.id !== guidIdEmptyValue) {
          loginUserInfo.id = responseData?.customerInfo?.id;
          loginUserInfo.customerId = responseData?.customerInfo?.customerId;
          loginUserInfo.customerCode = responseData?.customerInfo?.customerCode;
          loginUserInfo.customerBarcode = responseData?.customerInfo?.customerBarcode;
          loginUserInfo.customerRankId = responseData?.customerInfo?.customerRankId;
          loginUserInfo.rank = responseData?.customerInfo?.rank;
          setStorage(localStorageKeys.CUSTOMER_INFO, JSON.stringify(loginUserInfo));
        }

      } else {
        Toast.error({
          message: translateData.cannotCreateOrder,
          placement: "top",
        });
      }
    } catch (err) {
      console.error(err);
    }

    // pay button stop loading
    setIsCreateOrderProcessing(false);
    window.isCreateOrderCheckoutProcessing = false;
  };

  const getRemainingTime = (paymentInfoData) => {
    const responseTime = paymentInfoData?.paymentInfo?.responseTime;
    let expiredTime = 0;
    if (responseTime) {
      let responseDate = new Date(responseTime);
      expiredTime = addMinutes(responseDate, 10).getTime(); // expired after 10 mins, MOMO payment config
      const timeNow = new Date().getTime();
      const remainTime = expiredTime - timeNow;

      return remainTime;
    }
  };

  const gotoPaymentStep = (responseData) => {
    if (responseData) {
      const { paymentMethod } = responseData;
      switch (paymentMethod) {
        case PaymentMethod.MOMO:
          if (checkoutMomoDialogRef && checkoutMomoDialogRef.current) {
            checkoutMomoDialogRef.current.showPaymentInfo(responseData);
            const remainTime = getRemainingTime(responseData);
            if (remainTime > 0) {
              setCurrentCheckoutInfo(responseData);
              setIsOpenCheckoutMomoDialog(true);
              setResponseDataMomo(responseData);
            } else {
              setIsCreateOrderProcessing(false);
              window.isCreateOrderCheckoutProcessing = false;
              setCurrentCheckoutInfo(null);
            }
          }
          break;
        case PaymentMethod.CREDIT_DEBIT_CARD:
          if (responseData?.paymentInfo?.payUrl) {
            handlePaymentCompleted(true);
            window.open(responseData?.paymentInfo?.payUrl, "_self");
          } else {
            const notificationDialog = {
              isShow: true,
              content: t("messages.errorOccurredDuringProcessing"),
            };
            dispatch(setNotificationDialog(notificationDialog));
          }
          break;
        case PaymentMethod.CASH:
        default:
          setIsCreateOrderProcessing(false);
          window.isCreateOrderCheckoutProcessing = false;
          setIsOpenCreateOrderStatusDialog(true);

          // reset cart
          handlePaymentCompleted(true);
          break;
      }
    }
  };

  const handlePaymentCompleted = (isSuccess) => {
    if (isSuccess) {
      // Set empty cart
      const emptyCart = [];

      shoppingCartService.setStoreCartLocalStorage(emptyCart);
      dispatch(setCartItems(emptyCart));
      setShoppingCart([]);
      calculateShoppingCart();
    }
  };

  const handleConfirmNotify = () => {
    setIsShowNotifyDialog(false);
    callApiValidateCartItems(false, null, isUsePoint);
  };

  const handleOkayVerifyProductPrice = () => {
    setIsShowVerifyProductPriceDialog(false);
    callApiValidateCartItems(false, null, isUsePoint);
  };

  const handleOkayVerifyMembershipDiscount = () => {
    setIsShowVerifyMembershipDiscount(false);
    callApiValidateCartItems(false, null, isUsePoint);
  };

  const handleOkayCheckEstimateTime = () => {
    setIsShowCheckEstimateTime(false);
    setIsReloadSelectTime(!isReloadSelectTime);
  };

  const getTotalIemsInShoppingCart = (shoppingCart) => {
    let totalItems = 0;
    if (shoppingCart) {
      shoppingCart.forEach((cartItem) => {
        totalItems += cartItem.quantity;
      });
    }

    return totalItems;
  };

  const CreateOrderButton = ({ style }) => {
    const isDisabledByGrabDelivery =
      deliveryAddress?.orderType === enumOrderType.DELIVERY &&
      currentDeliveryMethodSelected?.enumId === EnumDeliveryMethod.GrabExpress &&
      currentPaymentMethodSelected?.paymentMethodEnumId !== PaymentMethodType.Cash;
    const titleTooltip = isDisabledByGrabDelivery ? translateData.contentDisableCreateOrderByGrabDelivery : "";

    return (
      <div className="button-create-order-and-payment" style={{ ...style }}>
        {isDisabledByGrabDelivery ? (
          //Because when using Tooltip, the css of the button will be affected. So I separate
          <Tooltip title={titleTooltip} color="#50429B">
            <div
              className="pay-button btn-in-tooltip"
              style={{
                backgroundColor: colorGroup?.buttonBackgroundColor,
              }}
            >
              {translateData.pay}
            </div>
          </Tooltip>
        ) : (
          <Button
            loading={isCreateOrderProcessing}
            className="pay-button"
            onClick={() => onCompleteCheckOut()}
            disabled={disablePayButton || isCreateOrderProcessing}
            style={{
              backgroundColor: colorGroup?.buttonBackgroundColor,
            }}
          >
            {translateData.pay}
          </Button>
        )}
      </div>
    );
  };

  const AddItemButton = ({ style }) => {
    return (
      <div className="button-create-order-and-payment" style={{ ...style }}>
        <Button
          loading={isCreateOrderProcessing}
          className="add-item-button"
          onClick={() => addMoreProducts()}
          disabled={disablePayButton || isCreateOrderProcessing}
          style={{
            backgroundColor: "transparent",
            borderColor: colorGroup?.buttonBackgroundColor,
            color: colorGroup?.buttonBackgroundColor,
          }}
        >
          {translateData.addMoreProducts}
        </Button>
      </div>
    );
  };

  function hiddenPromotion(value) {
    setIsHiddenPromotion(value);
  }

  const changeDiscountCodeBeingApplied = (code) => {
    const reduxState = store.getState();
    const session = reduxState?.session;
    const discountCodesAreBeingApplied = Array.isArray(session?.discountCodes) ? session?.discountCodes : [];
    const discountCodesAreBeingAppliedNew = [...discountCodesAreBeingApplied];
    const indexExist = discountCodesAreBeingApplied?.findIndex((discountCode) => discountCode === code);
    if (indexExist === -1) {
      discountCodesAreBeingAppliedNew.push(code);
      dispatch(setDiscountCodes(discountCodesAreBeingAppliedNew));
    } else {
      discountCodesAreBeingAppliedNew.splice(indexExist, 1);
      dispatch(setDiscountCodes(discountCodesAreBeingAppliedNew));
    }
  };

  const handleCloseDiscountCodeDialog = () => {
    setIsShowDiscountCodeDialog(false);
  };

  const onConfirmCheckLoyaltyPoint = () => {
    setIsLoadingSwitchExchangePoint(false);
    setIsUsePoint(false);
    window.isUsePoint = false;
    setShowConfirmCheckLoyaltyPoint(false);
    window.location.reload();
  };

  const handleVerifyCustomerLoyaltyPoint = async () => {
    setIsLoadingSwitchExchangePoint(true);
    let isVerifyFailed = false;
    const { availablePoint, redeemPointExchangeValue } = calculateCustomerLoyaltyPoint;
    if (availablePoint >= 0 && redeemPointExchangeValue >= 0) {
      const res = await customerDataService.verifyCustomerLoyaltyPointAsync(availablePoint, redeemPointExchangeValue);
      if (res) {
        const { isUsePointFailed, errorMessage } = res?.data?.response;
        if (isUsePointFailed === true) {
          setVerifyUsePointDialogMessage(t(errorMessage));
          setShowConfirmCheckLoyaltyPoint(true);
          isVerifyFailed = true;
        }
      }
    }
    setIsLoadingSwitchExchangePoint(false);
    return isVerifyFailed;
  };

  const handleIsActiveAvailablePoint = (checked) => {
    setIsUsePoint(checked);
    window.isUsePoint = checked;
    if (checked) {
      //Verify before calculate
      handleVerifyCustomerLoyaltyPoint(customerId);
      callApiValidateCartItems(false, null, true);
    } else {
      callApiValidateCartItems(false, null, false);
    }
  };

  function showPopupCheckTimePlaceOrder(time, date) {
    let isShowCheckEstimateTime = false;
    const now = moment();
    const minutesDifference = now.diff(currentTime, "minutes");
    let currentEstimateTime = null;
    if (time && date) {
      currentEstimateTime = convertLocalTime(time, date);
    }

    ///US 37508
    const isInvalidDateTime = currentEstimateTime && now.isAfter(currentEstimateTime);
    if (isInvalidDateTime || minutesDifference >= TIME_DELIVERY_SHOW_POPUP) {
      isShowCheckEstimateTime = true;
      const estimateTime =
        deliveryAddress?.orderType === enumOrderType.DELIVERY
          ? currentDeliveryMethodSelected?.estimateTime
          : cookingTime;
      const nowWithExtraTime = now.clone().add(estimateTime, "minutes");
      const newDeliveryDate = nowWithExtraTime.format(DateFormat.YYYY_MM_DD);
      const newTimeSlot = roundedFormattedTime(nowWithExtraTime.format(DateFormat.HH_MM));
      setDeliveryDate(newDeliveryDate);
      setTimeSlot(newTimeSlot);
    }

    return isShowCheckEstimateTime;
  }

  const [deliveryDate, setDeliveryDate] = useState(null);
  const [timeSlot, setTimeSlot] = useState(null);
  const PromotionsDialogComponent = useMemo(
    () => (
      <CustomizeDialog
        {...props}
        className="discount-code-dialog"
        title={translateData.promotion}
        open={isShowDiscountCodeDialog && !isHiddenPromotion}
        closable={true}
        content={() => (
          <PromotionListComponent
            colorConfig={colorGroup}
            hiddenPromotion={hiddenPromotion}
            callBack={changeDiscountCodeBeingApplied}
            isShowInputDiscountCode={false}
          />
        )}
        onCancel={() => {
          handleCloseDiscountCodeDialog();
        }}
        footer={null}
      />
    ),
    [isShowDiscountCodeDialog, colorGroup, isHiddenPromotion],
  );

  const ContentNotificationInvalidTime = () => {
    const estimateTime =
      deliveryAddress?.orderType === enumOrderType.DELIVERY ? currentDeliveryMethodSelected?.estimateTime : cookingTime;
    const nowWithExtraTime = moment().clone().add(estimateTime, "minutes");
    const formattedTime = nowWithExtraTime.format(DateFormat.HH_MM);
    const contentDialog =
      deliveryAddress?.orderType === enumOrderType.DELIVERY ? (
        <div className="warning-schedule-time">
          <span>{translateData.deliveryTimeChanged}</span>
          <br />
          <span>
            {translateData.newDeliveryTime}: {roundedFormattedTime(formattedTime)}
          </span>
        </div>
      ) : (
        <div>
          <span>{translateData.pickupTimeChanged}</span>
          <br />
          <span>
            {translateData.newPickupTime}: {roundedFormattedTime(formattedTime)}
          </span>
        </div>
      );

    return contentDialog;
  };

  const roundedFormattedTime = (timeString) => {
    // Round the minutes of the time format hh:mm, for example: 07:03 => 07:05, 08:00 => 08:00.
    const [hours, minutes] = timeString.split(":").map(Number);
    const roundedMinutes = Math.ceil(minutes / 5) * 5;
    const roundedHours = roundedMinutes === 60 ? hours + 1 : hours;
    const roundedTime = `${String(roundedHours).padStart(2, "0")}:${String(
      roundedMinutes === 60 ? 0 : roundedMinutes,
    ).padStart(2, "0")}`;
    return roundedTime;
  };

  const StyledIcon = styled.span`
    display: flex;
    svg {
      stroke: ${colorGroup?.buttonBackgroundColor};
      path {
        stroke: ${colorGroup?.buttonBackgroundColor};
      }
    }
  `;

  return (
    <>
      {/*Check delivery time or pickup time compare current*/}
      <ConfirmationDialog
        open={isShowCheckEstimateTime}
        onCancel={() => {}}
        onConfirm={handleOkayCheckEstimateTime}
        confirmLoading={false}
        className="checkout-theme2-notify-dialog"
        title={translateData.notification}
        content={<ContentNotificationInvalidTime />}
        footer={[
          <Button
            className="ant-btn-primary btn-got-it"
            style={{ backgroundColor: colorGroup?.buttonBackgroundColor, borderColor: colorGroup?.buttonBorderColor }}
            onClick={handleOkayCheckEstimateTime}
          >
            {"Okay"}
          </Button>,
        ]}
        colorGroup={colorGroup}
      />
      {/* Show the dialog if payment method another MOMO */}
      <PaymentStatusDialog
        orderInfo={orderResponseData}
        open={isOpenCreateOrderStatusDialog}
        onCompleted={() => {
          setIsOpenCreateOrderStatusDialog(false);
        }}
        colorGroup={colorGroup}
      />

      {/* Loyalty point dialog */}
      <ConfirmationDialog
        className={"confirm-modal-config-switch-button"}
        title={translateData.titleNotificationSwitch}
        content={t(verifyUsePointDialogMessage)}
        open={showConfirmCheckLoyaltyPoint}
        okText={translateData.iGotIt}
        onConfirm={() => onConfirmCheckLoyaltyPoint()}
      />

      <CheckoutMomoDialog
        ref={checkoutMomoDialogRef}
        open={isOpenCheckoutMomoDialog}
        onCompleted={() => {
          handlePaymentCompleted(true);
        }}
        onCancel={() => {
          setIsOpenCheckoutMomoDialog(false);
          setIsCreateOrderProcessing(false);
          window.isCreateOrderCheckoutProcessing = false;
        }}
        onMomoExpire={() => {
          if (checkoutMomoDialogRef && checkoutMomoDialogRef.current) {
            checkoutMomoDialogRef.current.showPaymentFailed();
          }
          setIsCreateOrderProcessing(false);
          window.isCreateOrderCheckoutProcessing = false;
        }}
        colorGroup={colorGroup}
      />
      {/* Verify product price dialog */}
      <ConfirmationDialog
        open={isShowVerifyProductPriceDialog}
        title={translateData.titleNotificationSwitch}
        onCancel={() => {
          setIsShowVerifyProductPriceDialog(false);
          handleOkayVerifyProductPrice();
        }}
        onConfirm={handleOkayVerifyProductPrice}
        confirmLoading={false}
        className="checkout-theme2-notify-dialog"
        content={verifyCartChangeMessage}
        maskClosable={true}
        footer={[
          <Button className="ant-btn-primary btn-got-it" onClick={handleOkayVerifyProductPrice}>
            {translateData.iGotIt}
          </Button>,
        ]}
      />
      {/* Verify Membership Discount */}
      <ConfirmationDialog
        open={isShowVerifyMembershipDiscount}
        onCancel={() => {}}
        onConfirm={handleOkayVerifyMembershipDiscount}
        confirmLoading={false}
        className="checkout-theme2-notify-dialog"
        title={translateData.notification}
        content={translateData.verifyCustomerRank}
        footer={[
          <Button className="ant-btn-primary btn-got-it" onClick={handleOkayVerifyMembershipDiscount}>
            {"Okay"}
          </Button>,
        ]}
      />
      {/* Verify flash sale dialog */}
      <ConfirmationDialog
        open={isShowNotifyDialog}
        onCancel={() => {}}
        onConfirm={handleConfirmNotify}
        confirmLoading={false}
        className="checkout-theme2-notify-dialog"
        title={translateData.notification}
        content={contentNotifyDialog}
        footer={[
          <Button className="ant-btn-primary btn-got-it" onClick={handleConfirmNotify}>
            {translateData.okay}
          </Button>,
        ]}
      />
      {/* Discount code dialog */}
      {PromotionsDialogComponent}
      <div
        id="themeCheckoutCheckout"
        onClick={() => {
          if (clickToFocusCustomize) clickToFocusCustomize(theme2ElementCustomize.CheckoutCheckout);
        }}
      >
        <div>
          <div className="check_out_theme2_container page-container">
            <div className="check_out_product">
              <CheckoutDeliveryInfo
                configuration={props?.configuration}
                colorGroups={props?.colorGroups}
                deliveryDate={deliveryDate}
                setDeliveryDate={(date) => {
                  setDeliveryDate(date);
                  setCurrentTime(moment());
                }}
                setTimeSlot={(time) => {
                  setTimeSlot(time);
                  setCurrentTime(moment());
                }}
                isReloadSelectTime={isReloadSelectTime}
                timeSlot={timeSlot}
                isEditReceiver={isEditReceiver}
                setIsEditReceiver={setIsEditReceiver}
                onValuesChange={handleChangeReceiver}
                isCustomize={isCustomize}
                currentDeliveryMethodSelected={
                  deliveryAddress?.orderType === enumOrderType.DELIVERY ? currentDeliveryMethodSelected : cookingTime
                }
              />
              <div className="cart-container">
                <div className="product_summary">
                  <div className="total">
                    <div className="shoppingCart" style={{ color: colorGroup?.buttonBackgroundColor }}>
                      {translateData.yourCart}
                    </div>
                    <div className="quantity">
                      <span className="total-quantity">
                        (
                        {!shoppingCart
                          ? 0
                          : getTotalIemsInShoppingCart(shoppingCart.filter((cart) => cart.quantity > 0))}
                      </span>
                      <span>{translateData.items})</span>
                    </div>
                  </div>
                  <div className="add" onClick={addMoreProducts}>
                    <div className="add_icon">
                      <StyledIcon>
                        <CheckoutAddIcon width={isMobile ? 16 : 24} height={isMobile ? 16 : 24} />
                      </StyledIcon>
                    </div>
                    <div
                      className="add_button"
                      style={{ color: colorGroup?.buttonBackgroundColor, fontSize: isMobile ? 12 : 14 }}
                    >
                      {translateData.addMoreProducts}
                    </div>
                  </div>
                </div>
                <div className="header-container">
                  <div className="product_title product-title-web">
                    <div className="title-wrapper">
                      <div style={{ flex: 2 }}>{translateData.cartProduct}</div>
                      <div style={{ flex: 1 }}>{translateData.cartPrice}</div>
                      <div style={{ flex: 1 }}>{translateData.cartQuantity}</div>
                      <div style={{ flex: 1 }}>{translateData.cartTotal}</div>
                    </div>
                  </div>
                  <div className="product_title product-title-mobile">{translateData.cartProduct}</div>
                  <div className="product_detail" style={{ marginTop: 27 }}>
                    {!mockupCustomize && shoppingCart?.length > 0 ? (
                      shoppingCart?.map((cart, index) => {
                        return (
                          <CheckoutOrderItems
                            key={cart.id + index}
                            cartItem={cart}
                            currentIndex={index}
                            onUpdateCartQuantity={onUpdateCartQuantity}
                            onDeleteProduct={onDeleteProduct}
                            setCurrentCartItems={(cartItems) => {
                              setShoppingCart(cartItems);
                              callApiValidateCartItems(false, null, isUsePoint);
                            }}
                            index={index}
                            storageConfig={storageConfig}
                            initDataShoppingCart={initDataShoppingCart}
                            calculateShoppingCart={calculateShoppingCart}
                          />
                        );
                      })
                    ) : (
                      <div className="noProductInCart">
                        <img src={noProductInCart} alt=""></img>
                        <div className="noProductInCartText">{translateData.emptyCart}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Right side */}
            <div className="check_out_shipping">
              {(isCustomize ||
                (deliveryAddress?.orderType === enumOrderType.DELIVERY &&
                  deliveryAddress?.receiverAddress?.address)) && (
                <div className="mb-24">
                  <CheckoutDeliveryMethod
                    onChange={(e) => onChangeDeliveryMethod(e)}
                    value={currentDeliveryMethodSelected}
                    isDefault={isDefault}
                    isCustomize={isCustomize}
                    mockupData={mockupDelivery}
                    configuration={props?.configuration}
                    colorGroups={props?.colorGroups}
                    colorConfig={colorGroup}
                    isCashPaymentMethod={currentPaymentMethodSelected?.paymentMethodEnumId === PaymentMethodType.Cash}
                  />
                </div>
              )}
              <div className="mb-24">
                <CheckoutPaymentMethod
                  title={translateData.paymentMethod?.toUpperCase()}
                  onChange={(e) => onChangePaymentMethod(e)}
                  value={currentPaymentMethodSelected}
                  isDefault={isDefault}
                  isCustomize={isCustomize}
                  mockupData={mockupPaymentMethod}
                  configuration={props?.configuration}
                  colorGroups={props?.colorGroups}
                  colorConfig={colorGroup}
                  isDisableCashlessMethod={
                    currentDeliveryMethodSelected?.enumId === EnumDeliveryMethod.GrabExpress &&
                    deliveryAddress?.orderType === enumOrderType.DELIVERY
                  }
                />
              </div>
              <div className="w-100">
                <BtnSelectDiscountCode
                  title={titleBtnSelectDiscountCode}
                  isApply={isApplyDiscount}
                  onClick={() => {
                    if (isCustomize) return;
                    setIsShowDiscountCodeDialog(true);
                  }}
                />
              </div>
              {isShowUsePointContent && (
                <div className="w-100 available-point">
                  <UseAvailablePoint
                    onClick={handleIsActiveAvailablePoint}
                    isLoadingSwitchExchangePoint={isLoadingSwitchExchangePoint}
                    isActiveAvailablePoint={isUsePoint}
                  />
                </div>
              )}
              <div className="mb-24 note">
                <div style={{ display: "flex", position: "relative" }}>
                  {orderNotes ? <OrderNote className="order-note-icon" /> : <NoteIcon className="order-note-icon" />}
                  <TextArea
                    className="note-input"
                    placeholder={translateData.orderNote}
                    onChange={(e) => setOrderNotes(e.target.value)}
                    maxLength={255}
                    autoSize={{ minRows: 1, maxRows: 10 }}
                  />
                </div>
              </div>
              <div className="summary-container mb-24">
                <CheckoutSummary
                  calculateShoppingCart={calculateShoppingCart}
                  earnPoint={earnPoint}
                  currentCartItem={currentCartItem}
                  colorConfig={colorGroup}
                />
                <div style={isMobile ? { display: "flex", flexDirection: "row", gap: 8 } : {}}>
                  <CreateOrderButton style={{ flex: 1 }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationDialog
        open={isShowNotifyWorkingHoursDialog}
        title={translateData.notification}
        confirmLoading={false}
        className="checkout-theme1-notify-dialog"
        content={
          <span
            dangerouslySetInnerHTML={{
              __html: t(translateData.soSorryNotificationWorkingHour, {
                timeWorkingHour: timeWorkingHour,
                dayOfWeek: t(dayOfWeek),
              }),
            }}
          ></span>
        }
        footer={[<Button onClick={() => setIsShowNotifyWorkingHoursDialog(false)}>{translateData.iGotIt}</Button>]}
        closable={true}
      />

      {/* Out of stock dialog */}
      <NotificationDialog
        open={isShowDialogOutOfStock}
        title={translateData.notification}
        confirmLoading={false}
        content={translateData.textOutOfStock}
        footer={[<Button onClick={() => setIsShowDialogOutOfStock(false)}>{translateData.iGotIt}</Button>]}
        closable={true}
      />

      {/* Remove item out of stock from cart dialog */}
      <NotificationDialog
        open={isShowDialogRemoveFromCart}
        title={translateData.notification}
        confirmLoading={false}
        content={
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: t(translateData.someProductOutOfStock),
              }}
            ></p>
            <p style={{ marginTop: 12 }}>{translateData.willBeRemoveFromCart}</p>
          </div>
        }
        footer={[<Button onClick={handleConfirmNotifyOutOfStock}>{translateData.okay}</Button>]}
        closable={true}
      />

      {/*Loading check discount code*/}
      {isLoading && (
        <div className="loading-full-screen">
          <OverlayLoadingFullScreenComponent />
        </div>
      )}
    </>
  );
};

export default CheckOutDetail;
